import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as B, useState as u, useEffect as Z } from "react";
import { i as t } from "./index-B3vUTrRT.js";
import { a as Q, P as V, S as K, c } from "./index.esm-BV4iWo1a.js";
import { u as G } from "./useConfiguration-B-lyaBB9.js";
import { F as H } from "./provider-DZPLM8yJ.js";
import { O as D } from "./OrderStatus-DVg5uaSo.js";
import { u as A, O as w } from "./OtpForm-ZSChm5PA.js";
import { i as z, k as P, n as O, C as ee, j as te, m as $, P as ne, Q as oe } from "./utils-BTh-HRzb.js";
import { r as J } from "./sentry-ZQO5C1U6.js";
import { P as R } from "./Product-DewIFE9c.js";
import { ErrorPage as se } from "./ErrorPage.js";
import { S as ae } from "./StatusMessage-DFmLnokA.js";
function ge({ investorProfile: S, id: b, onSuccess: v, showSchemeDetails: h = !0, action: M }) {
  const { fpapi: j } = B(H), [T, m] = u(!1), [k, x] = u(""), [n, E] = u(), [s, I] = u(), [a, N] = u(null), { tenantConfig: g, amcs: F } = G(), d = g == null ? void 0 : g.fallbackLogo, C = g == null ? void 0 : g.showSchemeLogo, q = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  Z(() => {
    y();
  }, []);
  const { collectedAcquisition: _, initiateSendOTP: U } = A(), W = (r, o) => {
    const p = C ? {
      ...r,
      schemeLogo: F[r == null ? void 0 : r.amc_id] || d
    } : {
      ...r
    }, f = C ? {
      ...o,
      schemeLogo: F[o == null ? void 0 : o.amc_id] || d
    } : {
      ...o
    };
    E(p), I(f);
  }, y = async () => {
    const r = b == null ? void 0 : b.split(",");
    m(!0);
    try {
      const o = await j.fpClient.mf_switches().fetch(b), p = await j.fpClient.master_data().fetchScheme(o.switch_in_scheme), f = await j.fpClient.master_data().fetchScheme(o.switch_out_scheme);
      W(p, f), N(o), o.state === "pending" ? x("consent") : x("consent_not_required");
      const X = {
        given_for_object: o == null ? void 0 : o.object,
        action: M,
        given_for: r
      };
      await U(X), m(!1);
    } catch (o) {
      m(!1), x("error"), J(o);
    }
  }, L = async () => {
    var r, o, p;
    try {
      m(!0);
      const f = await j.fpClient.mf_switches().update({
        id: a == null ? void 0 : a.id,
        state: "confirmed",
        consent: {
          email: (r = _ == null ? void 0 : _.acquisition_details) == null ? void 0 : r.email,
          isd_code: (o = _ == null ? void 0 : _.acquisition_details) == null ? void 0 : o.isd,
          mobile: (p = _ == null ? void 0 : _.acquisition_details) == null ? void 0 : p.mobile_number
        }
      });
      N(f), v ? v(f) : x("success"), m(!1);
    } catch (f) {
      window.debug.error(f), x("error"), m(!1), J(f);
    }
  }, Y = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(V, {
        title: t._({
          id: "72BAzM"
        })
      }),
      /* @__PURE__ */ e.jsx(R, {
        scheme: n
      })
    ]
  }), i = () => !z(a) && /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx(K, {
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "-ml-2 -mr-2 flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-full pl-2 pr-2",
            children: /* @__PURE__ */ e.jsx(c, {
              label: t._({
                id: "6Xqu8f"
              }),
              value: s && /* @__PURE__ */ e.jsxs("div", {
                className: "flex",
                children: [
                  (s == null ? void 0 : s.schemeLogo) && /* @__PURE__ */ e.jsx("img", {
                    src: s == null ? void 0 : s.schemeLogo,
                    alt: "logo",
                    className: q
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "flex flex-col",
                    children: /* @__PURE__ */ e.jsx("div", {
                      children: `${P(s.name)}`
                    })
                  })
                ]
              })
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(c, {
              label: t._({
                id: "fezFD7"
              }),
              value: O(s == null ? void 0 : s.investment_option)
            })
          }),
          a.amount && /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(c, {
              label: t._({
                id: "pxS7Um"
              }),
              value: a.amount ? `₹ ${ee(a.amount, 2)}` : "-"
            })
          }),
          a.units && /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(c, {
              label: t._({
                id: "6ssn5V"
              }),
              value: a.units ? a.units : "-"
            })
          }),
          a.folio_number && /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(c, {
              label: t._({
                id: "cXcPd1"
              }),
              value: a.folio_number ? a.folio_number : "New folio"
            })
          }),
          !a.amount && !a.units && /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(c, {
              label: t._({
                id: "RBIA5y"
              }),
              value: "Switch All"
            })
          })
        ]
      })
    })
  });
  return T ? /* @__PURE__ */ e.jsx(Q, {
    variant: "fullpage"
  }) : (() => {
    switch (k) {
      case "consent":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            h && Y(),
            i(),
            /* @__PURE__ */ e.jsx(w, {
              handleSubmit: L,
              pageTitle: t._({
                id: "s/ORP1"
              }),
              investorProfile: S,
              collectAcquisition: _
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            i(),
            /* @__PURE__ */ e.jsx(ae, {
              title: t._({
                id: "AY1MwY"
              }),
              description: t._({
                id: "9JLnsx"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            i(),
            /* @__PURE__ */ e.jsx(se, {
              title: t._({
                id: "w7WdjZ"
              }),
              description: t._({
                id: "mujfra"
              }),
              showDetails: !1
            })
          ]
        });
      case "success":
        return !z(n) && !z(a) ? /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(R, {
              scheme: n
            }),
            /* @__PURE__ */ e.jsx(D, {
              scheme: n,
              mf_order: a
            })
          ]
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function ve({ investorProfile: S, id: b, showSchemeDetails: v = !0, onSuccess: h, action: M }) {
  const { fpapi: j } = B(H), [T, m] = u(!1), [k, x] = u(""), { collectedAcquisition: n, initiateSendOTP: E } = A(), [s, I] = u({}), { tenantConfig: a, amcs: N } = G(), [g, F] = u(), [d, C] = u(), q = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  Z(() => {
    _();
  }, []);
  const _ = async () => {
    const i = b == null ? void 0 : b.split(",");
    try {
      m(!0);
      const l = await j.fpClient.mf_switch_plans().fetch(b);
      if (v) {
        const f = await j.fpClient.master_data().fetchScheme(l == null ? void 0 : l.switch_in_scheme);
        U(f);
      }
      const r = await j.fpClient.master_data().fetchScheme(l.switch_out_scheme), o = a != null && a.showSchemeLogo ? {
        ...r,
        schemeLogo: N[r == null ? void 0 : r.amc_id] || (a == null ? void 0 : a.fallbackLogo)
      } : {
        ...r
      };
      C(o), I(l);
      const p = {
        given_for_object: l == null ? void 0 : l.object,
        action: M,
        given_for: i
      };
      await E(p), x("consent"), m(!1);
    } catch (l) {
      m(!1), x("error"), J(l);
    }
  }, U = (i) => {
    const l = a != null && a.showSchemeLogo ? {
      ...i,
      schemeLogo: N[i == null ? void 0 : i.amc_id] || (a == null ? void 0 : a.fallbackLogo)
    } : {
      ...i
    };
    F(l);
  }, W = async () => {
    var i, l, r, o, p, f;
    if (h) {
      m(!0);
      try {
        await j.fpClient.mf_switch_plans().update({
          id: s == null ? void 0 : s.id,
          consent: {
            email: (i = n == null ? void 0 : n.acquisition_details) == null ? void 0 : i.email,
            isd_code: (l = n == null ? void 0 : n.acquisition_details) == null ? void 0 : l.isd,
            mobile: (r = n == null ? void 0 : n.acquisition_details) == null ? void 0 : r.mobile_number
          }
        }), h(s), m(!1);
      } catch {
        x("error"), m(!1);
      }
    } else {
      m(!0);
      try {
        await j.fpClient.mf_switch_plans().update({
          id: s == null ? void 0 : s.id,
          consent: {
            email: (o = n == null ? void 0 : n.acquisition_details) == null ? void 0 : o.email,
            isd_code: (p = n == null ? void 0 : n.acquisition_details) == null ? void 0 : p.isd,
            mobile: (f = n == null ? void 0 : n.acquisition_details) == null ? void 0 : f.mobile_number
          }
        }), x("success"), m(!1);
      } catch {
        x("error"), m(!1);
      }
    }
  }, y = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(V, {
        title: t._({
          id: "72BAzM"
        })
      }),
      /* @__PURE__ */ e.jsx(R, {
        scheme: g
      })
    ]
  }), L = () => {
    var i;
    return /* @__PURE__ */ e.jsx("div", {
      style: {
        marginTop: "-20px"
      },
      children: /* @__PURE__ */ e.jsx(K, {
        customClass: "mb-8",
        children: /* @__PURE__ */ e.jsxs("div", {
          className: "-mr-2 -ml-2 flex flex-wrap items-center",
          children: [
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-3 basis-full pl-2 pr-2",
              children: /* @__PURE__ */ e.jsx(c, {
                label: t._({
                  id: "6Xqu8f"
                }),
                value: d && /* @__PURE__ */ e.jsxs("div", {
                  className: "flex",
                  children: [
                    (d == null ? void 0 : d.schemeLogo) && /* @__PURE__ */ e.jsx("img", {
                      src: d == null ? void 0 : d.schemeLogo,
                      alt: "logo",
                      className: q
                    }),
                    /* @__PURE__ */ e.jsx("div", {
                      className: "flex flex-col",
                      children: /* @__PURE__ */ e.jsx("div", {
                        children: `${P(d.name)}`
                      })
                    })
                  ]
                })
              })
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ e.jsx(c, {
                label: t._({
                  id: "fezFD7"
                }),
                value: O(d == null ? void 0 : d.investment_option)
              })
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ e.jsx(c, {
                label: t._({
                  id: "vOD7hD"
                }),
                value: s != null && s.amount ? `₹ ${ee(s == null ? void 0 : s.amount, 2)}` : "-"
              })
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ e.jsx(c, {
                label: t._({
                  id: "UMXPdU"
                }),
                value: s != null && s.frequency ? (i = te(s == null ? void 0 : s.frequency)) == null ? void 0 : i.replace(/_/g, " ") : "-"
              })
            }),
            s != null && s.requested_activation_date && !(s != null && s.start_date) ? /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ e.jsx(c, {
                    label: t._({
                      id: "+KWVFJ"
                    }),
                    value: s != null && s.requested_activation_date ? $(new Date(s == null ? void 0 : s.requested_activation_date), "dd-MMM-yyyy") : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ e.jsx(c, {
                    label: t._({
                      id: "cki4uJ"
                    }),
                    value: s != null && s.installment_day ? ne(s == null ? void 0 : s.installment_day) : "-"
                  })
                })
              ]
            }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                s != null && s.start_date ? /* @__PURE__ */ e.jsx("div", {
                  className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ e.jsx(c, {
                    label: t._({
                      id: "NdlT2Q"
                    }),
                    value: s != null && s.start_date ? $(new Date(s == null ? void 0 : s.start_date), "dd-MMM-yyyy") : "-"
                  })
                }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
                s != null && s.end_date ? /* @__PURE__ */ e.jsx("div", {
                  className: "mt-2 mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ e.jsx(c, {
                    label: t._({
                      id: "gqWUiZ"
                    }),
                    value: s != null && s.end_date ? oe(s == null ? void 0 : s.end_date) : "-"
                  })
                }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
              ]
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ e.jsx(c, {
                label: t._({
                  id: "cXcPd1"
                }),
                value: s != null && s.folio_number ? s == null ? void 0 : s.folio_number : "New folio"
              })
            })
          ]
        })
      })
    });
  };
  return T ? /* @__PURE__ */ e.jsx(Q, {
    variant: "fullpage"
  }) : (() => {
    switch (k) {
      case "consent":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            v && y(),
            L(),
            /* @__PURE__ */ e.jsx(w, {
              handleSubmit: W,
              pageTitle: t._({
                id: "s/ORP1"
              }),
              investorProfile: S,
              collectAcquisition: n
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            v && y(),
            L(),
            /* @__PURE__ */ e.jsx(ae, {
              title: t._({
                id: "AY1MwY"
              }),
              description: t._({
                id: "9JLnsx"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ e.jsx(se, {
          title: t._({
            id: "w7WdjZ"
          }),
          description: t._({
            id: "O8W8Ev"
          }),
          showDetails: !1
        });
      case "success":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            v && y(),
            /* @__PURE__ */ e.jsx(D, {
              scheme: g,
              mf_order: s
            })
          ]
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
export {
  ve as S,
  ge as a
};
