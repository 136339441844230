import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as f, useState as n, useEffect as P } from "react";
import { AppContext as G } from "./AppContext.js";
import { F as M } from "./provider-DZPLM8yJ.js";
import { LoginContext as H } from "./LoginContext.js";
import { P as Z, a as q } from "./index.esm-BV4iWo1a.js";
import { i as o } from "./index-B3vUTrRT.js";
import { r as z } from "./sentry-ZQO5C1U6.js";
import { W as N } from "./Welcome-3PCWRbTF.js";
import { ErrorPage as b } from "./ErrorPage.js";
import { B as Q } from "./BankAccountListing-47D9bb2a.js";
import { A as V } from "./Auth-CjS0P6BG.js";
function ft() {
  const { fpapi: i } = f(M), { loggedInUser: t } = f(H), { AppState: { showWelcomePage: d, tenant: j }, setShowWelcomePage: k } = f(G), [v, F] = n(null), [E, m] = n(x), [S, a] = n(!1), [p, U] = n(!1), [y, L] = n(""), O = [
    {
      step: 1,
      content: o._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: o._({
        id: "ElbsPp"
      })
    },
    {
      step: 3,
      content: o._({
        id: "r6nqxS"
      })
    }
  ], u = () => {
    (async () => {
      var A, h, _, g, w, C;
      if (i && t)
        try {
          a(!0);
          const { data: c } = await i.fpClient.ciam_user_management().listAllUsers(`fpa_${j}`), l = c[0];
          if (((A = l == null ? void 0 : l.annexure) == null ? void 0 : A.isonboarded) === "true") {
            const T = i.getInvestorProfile((h = t == null ? void 0 : t.profile) == null ? void 0 : h.fp_identifier), W = ((g = (_ = t == null ? void 0 : t.profile) == null ? void 0 : _.preferred_username) == null ? void 0 : g.toUpperCase()) ?? "", B = i.fpClient.mf_investment_accounts().fetchAll({
              investor: W
            }), [r, s] = await Promise.all([
              T,
              B
            ]);
            ((w = s == null ? void 0 : s.data) == null ? void 0 : w.length) && ((C = s == null ? void 0 : s.data) == null ? void 0 : C.find((D) => D.primary_investor === (r == null ? void 0 : r.id))) && (r == null ? void 0 : r.pan) && (F(r), U(!0));
          }
        } catch (c) {
          L("Unable to fetch details"), z(c);
        } finally {
          a(!1);
        }
    })();
  };
  P(() => {
    u();
  }, [
    t
  ]), P(() => {
    m(x());
  }, [
    d
  ]);
  function x() {
    return d ? "welcome" : "bankAccount";
  }
  function I() {
    k(!1), a(!1), m("bankAccount");
  }
  const R = () => E === "welcome" ? /* @__PURE__ */ e.jsx(N, {
    onAck: I,
    welcomeSteps: O
  }) : S ? /* @__PURE__ */ e.jsx(q, {
    variant: "fullpage"
  }) : y ? /* @__PURE__ */ e.jsx(b, {
    title: o._({
      id: "Qk4uVC"
    }),
    description: o._({
      id: "sD5oGo"
    }),
    showDetails: !1,
    showAction: !0,
    actionText: "Retry",
    actionHandler: () => {
      u();
    }
  }) : t && p ? /* @__PURE__ */ e.jsx(Q, {
    investorProfile: v
  }) : t && !p ? /* @__PURE__ */ e.jsx(b, {
    title: o._({
      id: "4LZdlC"
    }),
    description: o._({
      id: "3GilYU"
    }),
    showDetails: !1,
    customClass: "mt-5"
  }) : /* @__PURE__ */ e.jsx(V, {
    redirectURL: window.location.href,
    allowRegistration: !1,
    showTestPan: {
      onboarded: !0
    }
  });
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(Z, {
        title: o._({
          id: "ozHvvf"
        })
      }),
      R()
    ]
  });
}
export {
  ft as ManageBankAccounts
};
