import { aw as le, ax as br, ay as ln, az as ft, aA as f, aB as F, aC as I, aD as Sr, aE as v, aF as wt, aG as fe, aH as Ve, aI as fn, aJ as J, aK as pt, aL as ht, aM as Z, aN as ee, aO as Ir, aP as vr, aQ as me, aR as m, aS as R, aT as re, aU as xe, aV as pe, aW as Rr, aX as Me, aY as pn, aZ as kr, a_ as wr, a$ as Nt, b0 as D, b1 as Fe, b2 as hn, b3 as Nr, b4 as mn, b5 as Or, b6 as Ar, b7 as Cr, b8 as Ot, b9 as mt, ba as At, bb as Dr, bc as gt, bd as $r, be as Ke, bf as gn, bg as Ct, bh as M, bi as ge, bj as _t, bk as w, bl as he, bm as _n, bn as Tn, bo as yn, bp as Je, bq as Pr, br as xr, bs as Mr, bt as Fr, bu as Qe, bv as De, bw as En, bx as Tt, by as Dt, bz as Lr, bA as Hr, bB as yt, bC as Ur, bD as Br, bE as jr, bF as U, bG as $t, bH as bn, bI as qr, bJ as Gr, bK as Pt, bL as xt, t as Wr, bM as Yr, bN as zr, bO as Xr, bP as Vr, bQ as Kr } from "./utils-BTh-HRzb.js";
function Jr(t, e, n = 250, r, s, i, a) {
  if (!i.exception || !i.exception.values || !a || !le(a.originalException, Error))
    return;
  const o = i.exception.values.length > 0 ? i.exception.values[i.exception.values.length - 1] : void 0;
  o && (i.exception.values = Qr(
    Ze(
      t,
      e,
      s,
      a.originalException,
      r,
      i.exception.values,
      o,
      0
    ),
    n
  ));
}
function Ze(t, e, n, r, s, i, a, o) {
  if (i.length >= n + 1)
    return i;
  let c = [...i];
  if (le(r[s], Error)) {
    Mt(a, o);
    const u = t(e, r[s]), d = c.length;
    Ft(u, s, d, o), c = Ze(
      t,
      e,
      n,
      r[s],
      s,
      [u, ...c],
      u,
      d
    );
  }
  return Array.isArray(r.errors) && r.errors.forEach((u, d) => {
    if (le(u, Error)) {
      Mt(a, o);
      const l = t(e, u), p = c.length;
      Ft(l, `errors[${d}]`, p, o), c = Ze(
        t,
        e,
        n,
        u,
        s,
        [l, ...c],
        l,
        p
      );
    }
  }), c;
}
function Mt(t, e) {
  t.mechanism = t.mechanism || { type: "generic", handled: !0 }, t.mechanism = {
    ...t.mechanism,
    ...t.type === "AggregateError" && { is_exception_group: !0 },
    exception_id: e
  };
}
function Ft(t, e, n, r) {
  t.mechanism = t.mechanism || { type: "generic", handled: !0 }, t.mechanism = {
    ...t.mechanism,
    type: "chained",
    source: e,
    exception_id: n,
    parent_id: r
  };
}
function Qr(t, e) {
  return t.map((n) => (n.value && (n.value = br(n.value, e)), n));
}
const Zr = /^(?:(\w+):)\/\/(?:(\w+)(?::(\w+)?)?@)([\w.-]+)(?::(\d+))?\/(.+)/;
function es(t) {
  return t === "http" || t === "https";
}
function se(t, e = !1) {
  const { host: n, path: r, pass: s, port: i, projectId: a, protocol: o, publicKey: c } = t;
  return `${o}://${c}${e && s ? `:${s}` : ""}@${n}${i ? `:${i}` : ""}/${r && `${r}/`}${a}`;
}
function ts(t) {
  const e = Zr.exec(t);
  if (!e) {
    ln(() => {
      console.error(`Invalid Sentry Dsn: ${t}`);
    });
    return;
  }
  const [n, r, s = "", i, a = "", o] = e.slice(1);
  let c = "", u = o;
  const d = u.split("/");
  if (d.length > 1 && (c = d.slice(0, -1).join("/"), u = d.pop()), u) {
    const l = u.match(/^\d+/);
    l && (u = l[0]);
  }
  return Sn({ host: i, pass: s, path: c, projectId: u, port: a, protocol: n, publicKey: r });
}
function Sn(t) {
  return {
    protocol: t.protocol,
    publicKey: t.publicKey || "",
    pass: t.pass || "",
    host: t.host,
    port: t.port || "",
    path: t.path || "",
    projectId: t.projectId
  };
}
function ns(t) {
  if (!ft)
    return !0;
  const { port: e, projectId: n, protocol: r } = t;
  return ["protocol", "publicKey", "host", "projectId"].find((a) => t[a] ? !1 : (f.error(`Invalid Sentry Dsn: ${a} missing`), !0)) ? !1 : n.match(/^\d+$/) ? es(r) ? e && isNaN(parseInt(e, 10)) ? (f.error(`Invalid Sentry Dsn: Invalid port ${e}`), !1) : !0 : (f.error(`Invalid Sentry Dsn: Invalid protocol ${r}`), !1) : (f.error(`Invalid Sentry Dsn: Invalid projectId ${n}`), !1);
}
function rs(t) {
  const e = typeof t == "string" ? ts(t) : Sn(t);
  if (!(!e || !ns(e)))
    return e;
}
class C extends Error {
  /** Display name of this error instance. */
  constructor(e, n = "warn") {
    super(e), this.message = e, this.name = new.target.prototype.constructor.name, Object.setPrototypeOf(this, new.target.prototype), this.logLevel = n;
  }
}
const Ne = {}, Lt = {};
function j(t, e) {
  Ne[t] = Ne[t] || [], Ne[t].push(e);
}
function q(t, e) {
  Lt[t] || (e(), Lt[t] = !0);
}
function N(t, e) {
  const n = t && Ne[t];
  if (n)
    for (const r of n)
      try {
        r(e);
      } catch (s) {
        ft && f.error(
          `Error while triggering instrumentation handler.
Type: ${t}
Name: ${F(r)}
Error:`,
          s
        );
      }
}
function ss(t) {
  const e = "console";
  j(e, t), q(e, is);
}
function is() {
  "console" in I && Sr.forEach(function(t) {
    t in I.console && v(I.console, t, function(e) {
      return wt[t] = e, function(...n) {
        N("console", { args: n, level: t });
        const s = wt[t];
        s && s.apply(I.console, n);
      };
    });
  });
}
const z = I, as = 1e3;
let Ht, et, tt;
function os(t) {
  const e = "dom";
  j(e, t), q(e, cs);
}
function cs() {
  if (!z.document)
    return;
  const t = N.bind(null, "dom"), e = Ut(t, !0);
  z.document.addEventListener("click", e, !1), z.document.addEventListener("keypress", e, !1), ["EventTarget", "Node"].forEach((n) => {
    const r = z[n] && z[n].prototype;
    !r || !r.hasOwnProperty || !r.hasOwnProperty("addEventListener") || (v(r, "addEventListener", function(s) {
      return function(i, a, o) {
        if (i === "click" || i == "keypress")
          try {
            const c = this, u = c.__sentry_instrumentation_handlers__ = c.__sentry_instrumentation_handlers__ || {}, d = u[i] = u[i] || { refCount: 0 };
            if (!d.handler) {
              const l = Ut(t);
              d.handler = l, s.call(this, i, l, o);
            }
            d.refCount++;
          } catch {
          }
        return s.call(this, i, a, o);
      };
    }), v(
      r,
      "removeEventListener",
      function(s) {
        return function(i, a, o) {
          if (i === "click" || i == "keypress")
            try {
              const c = this, u = c.__sentry_instrumentation_handlers__ || {}, d = u[i];
              d && (d.refCount--, d.refCount <= 0 && (s.call(this, i, d.handler, o), d.handler = void 0, delete u[i]), Object.keys(u).length === 0 && delete c.__sentry_instrumentation_handlers__);
            } catch {
            }
          return s.call(this, i, a, o);
        };
      }
    ));
  });
}
function us(t) {
  if (t.type !== et)
    return !1;
  try {
    if (!t.target || t.target._sentryId !== tt)
      return !1;
  } catch {
  }
  return !0;
}
function ds(t, e) {
  return t !== "keypress" ? !1 : !e || !e.tagName ? !0 : !(e.tagName === "INPUT" || e.tagName === "TEXTAREA" || e.isContentEditable);
}
function Ut(t, e = !1) {
  return (n) => {
    if (!n || n._sentryCaptured)
      return;
    const r = ls(n);
    if (ds(n.type, r))
      return;
    fe(n, "_sentryCaptured", !0), r && !r._sentryId && fe(r, "_sentryId", Ve());
    const s = n.type === "keypress" ? "input" : n.type;
    us(n) || (t({ event: n, name: s, global: e }), et = n.type, tt = r ? r._sentryId : void 0), clearTimeout(Ht), Ht = z.setTimeout(() => {
      tt = void 0, et = void 0;
    }, as);
  };
}
function ls(t) {
  try {
    return t.target;
  } catch {
    return null;
  }
}
const nt = fn();
function In() {
  if (!("fetch" in nt))
    return !1;
  try {
    return new Headers(), new Request("http://www.example.com"), new Response(), !0;
  } catch {
    return !1;
  }
}
function rt(t) {
  return t && /^function fetch\(\)\s+\{\s+\[native code\]\s+\}$/.test(t.toString());
}
function fs() {
  if (typeof EdgeRuntime == "string")
    return !0;
  if (!In())
    return !1;
  if (rt(nt.fetch))
    return !0;
  let t = !1;
  const e = nt.document;
  if (e && typeof e.createElement == "function")
    try {
      const n = e.createElement("iframe");
      n.hidden = !0, e.head.appendChild(n), n.contentWindow && n.contentWindow.fetch && (t = rt(n.contentWindow.fetch)), e.head.removeChild(n);
    } catch (n) {
      ft && f.warn("Could not create sandbox iframe for pure fetch check, bailing to window.fetch: ", n);
    }
  return t;
}
function vn(t) {
  const e = "fetch";
  j(e, t), q(e, ps);
}
function ps() {
  fs() && v(I, "fetch", function(t) {
    return function(...e) {
      const { method: n, url: r } = hs(e), s = {
        args: e,
        fetchData: {
          method: n,
          url: r
        },
        startTimestamp: Date.now()
      };
      return N("fetch", {
        ...s
      }), t.apply(I, e).then(
        (i) => {
          const a = {
            ...s,
            endTimestamp: Date.now(),
            response: i
          };
          return N("fetch", a), i;
        },
        (i) => {
          const a = {
            ...s,
            endTimestamp: Date.now(),
            error: i
          };
          throw N("fetch", a), i;
        }
      );
    };
  });
}
function st(t, e) {
  return !!t && typeof t == "object" && !!t[e];
}
function Bt(t) {
  return typeof t == "string" ? t : t ? st(t, "url") ? t.url : t.toString ? t.toString() : "" : "";
}
function hs(t) {
  if (t.length === 0)
    return { method: "GET", url: "" };
  if (t.length === 2) {
    const [n, r] = t;
    return {
      url: Bt(n),
      method: st(r, "method") ? String(r.method).toUpperCase() : "GET"
    };
  }
  const e = t[0];
  return {
    url: Bt(e),
    method: st(e, "method") ? String(e.method).toUpperCase() : "GET"
  };
}
let Ee = null;
function Rn(t) {
  const e = "error";
  j(e, t), q(e, ms);
}
function ms() {
  Ee = I.onerror, I.onerror = function(t, e, n, r, s) {
    return N("error", {
      column: r,
      error: s,
      line: n,
      msg: t,
      url: e
    }), Ee && !Ee.__SENTRY_LOADER__ ? Ee.apply(this, arguments) : !1;
  }, I.onerror.__SENTRY_INSTRUMENTED__ = !0;
}
let be = null;
function kn(t) {
  const e = "unhandledrejection";
  j(e, t), q(e, gs);
}
function gs() {
  be = I.onunhandledrejection, I.onunhandledrejection = function(t) {
    return N("unhandledrejection", t), be && !be.__SENTRY_LOADER__ ? be.apply(this, arguments) : !0;
  }, I.onunhandledrejection.__SENTRY_INSTRUMENTED__ = !0;
}
const Se = fn();
function _s() {
  const t = Se.chrome, e = t && t.app && t.app.runtime, n = "history" in Se && !!Se.history.pushState && !!Se.history.replaceState;
  return !e && n;
}
const oe = I;
let Ie;
function Et(t) {
  const e = "history";
  j(e, t), q(e, Ts);
}
function Ts() {
  if (!_s())
    return;
  const t = oe.onpopstate;
  oe.onpopstate = function(...n) {
    const r = oe.location.href, s = Ie;
    if (Ie = r, N("history", { from: s, to: r }), t)
      try {
        return t.apply(this, n);
      } catch {
      }
  };
  function e(n) {
    return function(...r) {
      const s = r.length > 2 ? r[2] : void 0;
      if (s) {
        const i = Ie, a = String(s);
        Ie = a, N("history", { from: i, to: a });
      }
      return n.apply(this, r);
    };
  }
  v(oe.history, "pushState", e), v(oe.history, "replaceState", e);
}
const ys = I, X = "__sentry_xhr_v3__";
function wn(t) {
  const e = "xhr";
  j(e, t), q(e, Es);
}
function Es() {
  if (!ys.XMLHttpRequest)
    return;
  const t = XMLHttpRequest.prototype;
  v(t, "open", function(e) {
    return function(...n) {
      const r = Date.now(), s = J(n[0]) ? n[0].toUpperCase() : void 0, i = bs(n[1]);
      if (!s || !i)
        return e.apply(this, n);
      this[X] = {
        method: s,
        url: i,
        request_headers: {}
      }, s === "POST" && i.match(/sentry_key/) && (this.__sentry_own_request__ = !0);
      const a = () => {
        const o = this[X];
        if (o && this.readyState === 4) {
          try {
            o.status_code = this.status;
          } catch {
          }
          const c = {
            args: [s, i],
            endTimestamp: Date.now(),
            startTimestamp: r,
            xhr: this
          };
          N("xhr", c);
        }
      };
      return "onreadystatechange" in this && typeof this.onreadystatechange == "function" ? v(this, "onreadystatechange", function(o) {
        return function(...c) {
          return a(), o.apply(this, c);
        };
      }) : this.addEventListener("readystatechange", a), v(this, "setRequestHeader", function(o) {
        return function(...c) {
          const [u, d] = c, l = this[X];
          return l && J(u) && J(d) && (l.request_headers[u.toLowerCase()] = d), o.apply(this, c);
        };
      }), e.apply(this, n);
    };
  }), v(t, "send", function(e) {
    return function(...n) {
      const r = this[X];
      if (!r)
        return e.apply(this, n);
      n[0] !== void 0 && (r.body = n[0]);
      const s = {
        args: [r.method, r.url],
        startTimestamp: Date.now(),
        xhr: this
      };
      return N("xhr", s), e.apply(this, n);
    };
  });
}
function bs(t) {
  if (J(t))
    return t;
  try {
    return t.toString();
  } catch {
  }
}
function Ss() {
  return typeof __SENTRY_BROWSER_BUNDLE__ < "u" && !!__SENTRY_BROWSER_BUNDLE__;
}
function Is() {
  return "npm";
}
function vs() {
  return !Ss() && Object.prototype.toString.call(typeof process < "u" ? process : 0) === "[object process]";
}
function H(t, e) {
  return t.require(e);
}
function Rs(t) {
  let e;
  try {
    e = H(module, t);
  } catch {
  }
  try {
    const { cwd: n } = H(module, "process");
    e = H(module, `${n()}/node_modules/${t}`);
  } catch {
  }
  return e;
}
function ks(t) {
  const e = [];
  function n() {
    return t === void 0 || e.length < t;
  }
  function r(a) {
    return e.splice(e.indexOf(a), 1)[0];
  }
  function s(a) {
    if (!n())
      return pt(new C("Not adding Promise because buffer limit was reached."));
    const o = a();
    return e.indexOf(o) === -1 && e.push(o), o.then(() => r(o)).then(
      null,
      () => r(o).then(null, () => {
      })
    ), o;
  }
  function i(a) {
    return new ht((o, c) => {
      let u = e.length;
      if (!u)
        return o(!0);
      const d = setTimeout(() => {
        a && a > 0 && o(!1);
      }, a);
      e.forEach((l) => {
        Z(l).then(() => {
          --u || (clearTimeout(d), o(!0));
        }, c);
      });
    });
  }
  return {
    $: e,
    add: s,
    drain: i
  };
}
function Oe(t) {
  if (!t)
    return {};
  const e = t.match(/^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?$/);
  if (!e)
    return {};
  const n = e[6] || "", r = e[8] || "";
  return {
    host: e[4],
    path: e[5],
    protocol: e[2],
    search: n,
    hash: r,
    relative: e[5] + n + r
    // everything minus origin
  };
}
const ws = ["fatal", "error", "warning", "log", "info", "debug"];
function Ns(t) {
  return t === "warn" ? "warning" : ws.includes(t) ? t : "log";
}
function G(t, e = []) {
  return [t, e];
}
function Os(t, e) {
  const [n, r] = t;
  return [n, [...r, e]];
}
function jt(t, e) {
  const n = t[1];
  for (const r of n) {
    const s = r[0].type;
    if (e(r, s))
      return !0;
  }
  return !1;
}
function it(t, e) {
  return (e || new TextEncoder()).encode(t);
}
function As(t, e) {
  const [n, r] = t;
  let s = JSON.stringify(n);
  function i(a) {
    typeof s == "string" ? s = typeof a == "string" ? s + a : [it(s, e), a] : s.push(typeof a == "string" ? it(a, e) : a);
  }
  for (const a of r) {
    const [o, c] = a;
    if (i(`
${JSON.stringify(o)}
`), typeof c == "string" || c instanceof Uint8Array)
      i(c);
    else {
      let u;
      try {
        u = JSON.stringify(c);
      } catch {
        u = JSON.stringify(Ir(c));
      }
      i(u);
    }
  }
  return typeof s == "string" ? s : Cs(s);
}
function Cs(t) {
  const e = t.reduce((s, i) => s + i.length, 0), n = new Uint8Array(e);
  let r = 0;
  for (const s of t)
    n.set(s, r), r += s.length;
  return n;
}
function Ds(t, e) {
  const n = typeof t.data == "string" ? it(t.data, e) : t.data;
  return [
    ee({
      type: "attachment",
      length: n.length,
      filename: t.filename,
      content_type: t.contentType,
      attachment_type: t.attachmentType
    }),
    n
  ];
}
const $s = {
  session: "session",
  sessions: "session",
  attachment: "attachment",
  transaction: "transaction",
  event: "error",
  client_report: "internal",
  user_report: "default",
  profile: "profile",
  replay_event: "replay",
  replay_recording: "replay",
  check_in: "monitor",
  feedback: "feedback",
  span: "span",
  // TODO: This is a temporary workaround until we have a proper data category for metrics
  statsd: "unknown"
};
function qt(t) {
  return $s[t];
}
function Nn(t) {
  if (!t || !t.sdk)
    return;
  const { name: e, version: n } = t.sdk;
  return { name: e, version: n };
}
function Ps(t, e, n, r) {
  const s = t.sdkProcessingMetadata && t.sdkProcessingMetadata.dynamicSamplingContext;
  return {
    event_id: t.event_id,
    sent_at: (/* @__PURE__ */ new Date()).toISOString(),
    ...e && { sdk: e },
    ...!!n && r && { dsn: se(r) },
    ...s && {
      trace: ee({ ...s })
    }
  };
}
function xs(t, e, n) {
  const r = [
    { type: "client_report" },
    {
      timestamp: vr(),
      discarded_events: t
    }
  ];
  return G(e ? { dsn: e } : {}, [r]);
}
const Ms = 60 * 1e3;
function Fs(t, e = Date.now()) {
  const n = parseInt(`${t}`, 10);
  if (!isNaN(n))
    return n * 1e3;
  const r = Date.parse(`${t}`);
  return isNaN(r) ? Ms : r - e;
}
function Ls(t, e) {
  return t[e] || t.all || 0;
}
function Hs(t, e, n = Date.now()) {
  return Ls(t, e) > n;
}
function Us(t, { statusCode: e, headers: n }, r = Date.now()) {
  const s = {
    ...t
  }, i = n && n["x-sentry-rate-limits"], a = n && n["retry-after"];
  if (i)
    for (const o of i.trim().split(",")) {
      const [c, u] = o.split(":", 2), d = parseInt(c, 10), l = (isNaN(d) ? 60 : d) * 1e3;
      if (!u)
        s.all = r + l;
      else
        for (const p of u.split(";"))
          s[p] = r + l;
    }
  else a ? s.all = r + Fs(a, r) : e === 429 && (s.all = r + 60 * 1e3);
  return s;
}
function B(t) {
  return (t || me()).getScope().getTransaction();
}
let Gt = !1;
function Bs() {
  Gt || (Gt = !0, Rn(at), kn(at));
}
function at() {
  const t = B();
  if (t) {
    const e = "internal_error";
    m && f.log(`[Tracing] Transaction: ${e} -> Global error occured`), t.setStatus(e);
  }
}
at.tag = "sentry_tracingErrorCallback";
var Wt;
(function(t) {
  const e = "ok";
  t.Ok = e;
  const n = "deadline_exceeded";
  t.DeadlineExceeded = n;
  const r = "unauthenticated";
  t.Unauthenticated = r;
  const s = "permission_denied";
  t.PermissionDenied = s;
  const i = "not_found";
  t.NotFound = i;
  const a = "resource_exhausted";
  t.ResourceExhausted = a;
  const o = "invalid_argument";
  t.InvalidArgument = o;
  const c = "unimplemented";
  t.Unimplemented = c;
  const u = "unavailable";
  t.Unavailable = u;
  const d = "internal_error";
  t.InternalError = d;
  const l = "unknown_error";
  t.UnknownError = l;
  const p = "cancelled";
  t.Cancelled = p;
  const h = "already_exists";
  t.AlreadyExists = h;
  const g = "failed_precondition";
  t.FailedPrecondition = g;
  const E = "aborted";
  t.Aborted = E;
  const k = "out_of_range";
  t.OutOfRange = k;
  const $ = "data_loss";
  t.DataLoss = $;
})(Wt || (Wt = {}));
function js(t) {
  if (t < 400 && t >= 100)
    return "ok";
  if (t >= 400 && t < 500)
    switch (t) {
      case 401:
        return "unauthenticated";
      case 403:
        return "permission_denied";
      case 404:
        return "not_found";
      case 409:
        return "already_exists";
      case 413:
        return "failed_precondition";
      case 429:
        return "resource_exhausted";
      default:
        return "invalid_argument";
    }
  if (t >= 500 && t < 600)
    switch (t) {
      case 501:
        return "unimplemented";
      case 503:
        return "unavailable";
      case 504:
        return "deadline_exceeded";
      default:
        return "internal_error";
    }
  return "unknown_error";
}
function bt(t, e) {
  t.setTag("http.status_code", String(e)), t.setData("http.response.status_code", e);
  const n = js(e);
  n !== "unknown_error" && t.setStatus(n);
}
function ie(t) {
  if (typeof __SENTRY_TRACING__ == "boolean" && !__SENTRY_TRACING__)
    return !1;
  const e = R(), n = t || e && e.getOptions();
  return !!n && (n.enableTracing || "tracesSampleRate" in n || "tracesSampler" in n);
}
function On(t) {
  if (!ie())
    return;
  const e = Ws(t), n = me(), r = t.scope ? (
    // eslint-disable-next-line deprecation/deprecation
    t.scope.getSpan()
  ) : qs();
  if (t.onlyIfParent && !r)
    return;
  const a = (t.scope || re()).clone();
  return Gs(n, {
    parentSpan: r,
    spanContext: e,
    forceTransaction: t.forceTransaction,
    scope: a
  });
}
function qs() {
  return re().getSpan();
}
function Gs(t, {
  parentSpan: e,
  spanContext: n,
  forceTransaction: r,
  scope: s
}) {
  if (!ie())
    return;
  const i = xe();
  let a;
  if (e && !r)
    a = e.startChild(n);
  else if (e) {
    const o = pe(e), { traceId: c, spanId: u } = e.spanContext(), d = Rr(e);
    a = t.startTransaction({
      traceId: c,
      parentSpanId: u,
      parentSampled: d,
      ...n,
      metadata: {
        dynamicSamplingContext: o,
        // eslint-disable-next-line deprecation/deprecation
        ...n.metadata
      }
    });
  } else {
    const { traceId: o, dsc: c, parentSpanId: u, sampled: d } = {
      ...i.getPropagationContext(),
      ...s.getPropagationContext()
    };
    a = t.startTransaction({
      traceId: o,
      parentSpanId: u,
      parentSampled: d,
      ...n,
      metadata: {
        dynamicSamplingContext: c,
        // eslint-disable-next-line deprecation/deprecation
        ...n.metadata
      }
    });
  }
  return s.setSpan(a), Ys(a, s, i), a;
}
function Ws(t) {
  if (t.startTime) {
    const e = { ...t };
    return e.startTimestamp = Me(t.startTime), delete e.startTime, e;
  }
  return t;
}
const An = "_sentryScope", Cn = "_sentryIsolationScope";
function Ys(t, e, n) {
  t && (fe(t, Cn, n), fe(t, An, e));
}
function zs(t) {
  return {
    scope: t[An],
    isolationScope: t[Cn]
  };
}
const Q = "sentry.source", V = "sentry.sample_rate", ve = "sentry.op", K = "sentry.origin", Xs = "profile_id";
class Dn {
  constructor(e = 1e3) {
    this._maxlen = e, this.spans = [];
  }
  /**
   * This is just so that we don't run out of memory while recording a lot
   * of spans. At some point we just stop and flush out the start of the
   * trace tree (i.e.the first n spans with the smallest
   * start_timestamp).
   */
  add(e) {
    this.spans.length > this._maxlen ? e.spanRecorder = void 0 : this.spans.push(e);
  }
}
class Le {
  /**
   * Tags for the span.
   * @deprecated Use `spanToJSON(span).atttributes` instead.
   */
  /**
   * Data for the span.
   * @deprecated Use `spanToJSON(span).atttributes` instead.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /**
   * List of spans that were finalized
   *
   * @deprecated This property will no longer be public. Span recording will be handled internally.
   */
  /**
   * @inheritDoc
   * @deprecated Use top level `Sentry.getRootSpan()` instead
   */
  /**
   * The instrumenter that created this span.
   *
   * TODO (v8): This can probably be replaced by an `instanceOf` check of the span class.
   *            the instrumenter can only be sentry or otel so we can check the span instance
   *            to verify which one it is and remove this field entirely.
   *
   * @deprecated This field will be removed.
   */
  /** Epoch timestamp in seconds when the span started. */
  /** Epoch timestamp in seconds when the span ended. */
  /** Internal keeper of the status */
  /**
   * You should never call the constructor manually, always use `Sentry.startTransaction()`
   * or call `startChild()` on an existing span.
   * @internal
   * @hideconstructor
   * @hidden
   */
  constructor(e = {}) {
    this._traceId = e.traceId || Ve(), this._spanId = e.spanId || Ve().substring(16), this._startTime = e.startTimestamp || pn(), this.tags = e.tags ? { ...e.tags } : {}, this.data = e.data ? { ...e.data } : {}, this.instrumenter = e.instrumenter || "sentry", this._attributes = {}, this.setAttributes({
      [K]: e.origin || "manual",
      [ve]: e.op,
      ...e.attributes
    }), this._name = e.name || e.description, e.parentSpanId && (this._parentSpanId = e.parentSpanId), "sampled" in e && (this._sampled = e.sampled), e.status && (this._status = e.status), e.endTimestamp && (this._endTime = e.endTimestamp), e.exclusiveTime && (this._exclusiveTime = e.exclusiveTime), this._measurements = e.measurements ? { ...e.measurements } : {};
  }
  // This rule conflicts with another eslint rule :(
  /* eslint-disable @typescript-eslint/member-ordering */
  /**
   * An alias for `description` of the Span.
   * @deprecated Use `spanToJSON(span).description` instead.
   */
  get name() {
    return this._name || "";
  }
  /**
   * Update the name of the span.
   * @deprecated Use `spanToJSON(span).description` instead.
   */
  set name(e) {
    this.updateName(e);
  }
  /**
   * Get the description of the Span.
   * @deprecated Use `spanToJSON(span).description` instead.
   */
  get description() {
    return this._name;
  }
  /**
   * Get the description of the Span.
   * @deprecated Use `spanToJSON(span).description` instead.
   */
  set description(e) {
    this._name = e;
  }
  /**
   * The ID of the trace.
   * @deprecated Use `spanContext().traceId` instead.
   */
  get traceId() {
    return this._traceId;
  }
  /**
   * The ID of the trace.
   * @deprecated You cannot update the traceId of a span after span creation.
   */
  set traceId(e) {
    this._traceId = e;
  }
  /**
   * The ID of the span.
   * @deprecated Use `spanContext().spanId` instead.
   */
  get spanId() {
    return this._spanId;
  }
  /**
   * The ID of the span.
   * @deprecated You cannot update the spanId of a span after span creation.
   */
  set spanId(e) {
    this._spanId = e;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `startSpan` functions instead.
   */
  set parentSpanId(e) {
    this._parentSpanId = e;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `spanToJSON(span).parent_span_id` instead.
   */
  get parentSpanId() {
    return this._parentSpanId;
  }
  /**
   * Was this span chosen to be sent as part of the sample?
   * @deprecated Use `isRecording()` instead.
   */
  get sampled() {
    return this._sampled;
  }
  /**
   * Was this span chosen to be sent as part of the sample?
   * @deprecated You cannot update the sampling decision of a span after span creation.
   */
  set sampled(e) {
    this._sampled = e;
  }
  /**
   * Attributes for the span.
   * @deprecated Use `spanToJSON(span).atttributes` instead.
   */
  get attributes() {
    return this._attributes;
  }
  /**
   * Attributes for the span.
   * @deprecated Use `setAttributes()` instead.
   */
  set attributes(e) {
    this._attributes = e;
  }
  /**
   * Timestamp in seconds (epoch time) indicating when the span started.
   * @deprecated Use `spanToJSON()` instead.
   */
  get startTimestamp() {
    return this._startTime;
  }
  /**
   * Timestamp in seconds (epoch time) indicating when the span started.
   * @deprecated In v8, you will not be able to update the span start time after creation.
   */
  set startTimestamp(e) {
    this._startTime = e;
  }
  /**
   * Timestamp in seconds when the span ended.
   * @deprecated Use `spanToJSON()` instead.
   */
  get endTimestamp() {
    return this._endTime;
  }
  /**
   * Timestamp in seconds when the span ended.
   * @deprecated Set the end time via `span.end()` instead.
   */
  set endTimestamp(e) {
    this._endTime = e;
  }
  /**
   * The status of the span.
   *
   * @deprecated Use `spanToJSON().status` instead to get the status.
   */
  get status() {
    return this._status;
  }
  /**
   * The status of the span.
   *
   * @deprecated Use `.setStatus()` instead to set or update the status.
   */
  set status(e) {
    this._status = e;
  }
  /**
   * Operation of the span
   *
   * @deprecated Use `spanToJSON().op` to read the op instead.
   */
  get op() {
    return this._attributes[ve];
  }
  /**
   * Operation of the span
   *
   * @deprecated Use `startSpan()` functions to set or `span.setAttribute(SEMANTIC_ATTRIBUTE_SENTRY_OP, 'op')
   *             to update the span instead.
   */
  set op(e) {
    this.setAttribute(ve, e);
  }
  /**
   * The origin of the span, giving context about what created the span.
   *
   * @deprecated Use `spanToJSON().origin` to read the origin instead.
   */
  get origin() {
    return this._attributes[K];
  }
  /**
   * The origin of the span, giving context about what created the span.
   *
   * @deprecated Use `startSpan()` functions to set the origin instead.
   */
  set origin(e) {
    this.setAttribute(K, e);
  }
  /* eslint-enable @typescript-eslint/member-ordering */
  /** @inheritdoc */
  spanContext() {
    const { _spanId: e, _traceId: n, _sampled: r } = this;
    return {
      spanId: e,
      traceId: n,
      traceFlags: r ? kr : wr
    };
  }
  /**
   * Creates a new `Span` while setting the current `Span.id` as `parentSpanId`.
   * Also the `sampled` decision will be inherited.
   *
   * @deprecated Use `startSpan()`, `startSpanManual()` or `startInactiveSpan()` instead.
   */
  startChild(e) {
    const n = new Le({
      ...e,
      parentSpanId: this._spanId,
      sampled: this._sampled,
      traceId: this._traceId
    });
    n.spanRecorder = this.spanRecorder, n.spanRecorder && n.spanRecorder.add(n);
    const r = Nt(this);
    if (n.transaction = r, m && r) {
      const s = e && e.op || "< unknown op >", i = D(n).description || "< unknown name >", a = r.spanContext().spanId, o = `[Tracing] Starting '${s}' span on transaction '${i}' (${a}).`;
      f.log(o), this._logMessage = o;
    }
    return n;
  }
  /**
   * Sets the tag attribute on the current span.
   *
   * Can also be used to unset a tag, by passing `undefined`.
   *
   * @param key Tag key
   * @param value Tag value
   * @deprecated Use `setAttribute()` instead.
   */
  setTag(e, n) {
    return this.tags = { ...this.tags, [e]: n }, this;
  }
  /**
   * Sets the data attribute on the current span
   * @param key Data key
   * @param value Data value
   * @deprecated Use `setAttribute()` instead.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setData(e, n) {
    return this.data = { ...this.data, [e]: n }, this;
  }
  /** @inheritdoc */
  setAttribute(e, n) {
    n === void 0 ? delete this._attributes[e] : this._attributes[e] = n;
  }
  /** @inheritdoc */
  setAttributes(e) {
    Object.keys(e).forEach((n) => this.setAttribute(n, e[n]));
  }
  /**
   * @inheritDoc
   */
  setStatus(e) {
    return this._status = e, this;
  }
  /**
   * @inheritDoc
   * @deprecated Use top-level `setHttpStatus()` instead.
   */
  setHttpStatus(e) {
    return bt(this, e), this;
  }
  /**
   * @inheritdoc
   *
   * @deprecated Use `.updateName()` instead.
   */
  setName(e) {
    this.updateName(e);
  }
  /**
   * @inheritDoc
   */
  updateName(e) {
    return this._name = e, this;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `spanToJSON(span).status === 'ok'` instead.
   */
  isSuccess() {
    return this._status === "ok";
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `.end()` instead.
   */
  finish(e) {
    return this.end(e);
  }
  /** @inheritdoc */
  end(e) {
    if (this._endTime)
      return;
    const n = Nt(this);
    if (m && // Don't call this for transactions
    n && n.spanContext().spanId !== this._spanId) {
      const r = this._logMessage;
      r && f.log(r.replace("Starting", "Finishing"));
    }
    this._endTime = Me(e);
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `spanToTraceHeader()` instead.
   */
  toTraceparent() {
    return Fe(this);
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `spanToJSON()` or access the fields directly instead.
   */
  toContext() {
    return ee({
      data: this._getData(),
      description: this._name,
      endTimestamp: this._endTime,
      // eslint-disable-next-line deprecation/deprecation
      op: this.op,
      parentSpanId: this._parentSpanId,
      sampled: this._sampled,
      spanId: this._spanId,
      startTimestamp: this._startTime,
      status: this._status,
      // eslint-disable-next-line deprecation/deprecation
      tags: this.tags,
      traceId: this._traceId
    });
  }
  /**
   * @inheritDoc
   *
   * @deprecated Update the fields directly instead.
   */
  updateWithContext(e) {
    return this.data = e.data || {}, this._name = e.name || e.description, this._endTime = e.endTimestamp, this.op = e.op, this._parentSpanId = e.parentSpanId, this._sampled = e.sampled, this._spanId = e.spanId || this._spanId, this._startTime = e.startTimestamp || this._startTime, this._status = e.status, this.tags = e.tags || {}, this._traceId = e.traceId || this._traceId, this;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `spanToTraceContext()` util function instead.
   */
  getTraceContext() {
    return hn(this);
  }
  /**
   * Get JSON representation of this span.
   *
   * @hidden
   * @internal This method is purely for internal purposes and should not be used outside
   * of SDK code. If you need to get a JSON representation of a span,
   * use `spanToJSON(span)` instead.
   */
  getSpanJSON() {
    return ee({
      data: this._getData(),
      description: this._name,
      op: this._attributes[ve],
      parent_span_id: this._parentSpanId,
      span_id: this._spanId,
      start_timestamp: this._startTime,
      status: this._status,
      // eslint-disable-next-line deprecation/deprecation
      tags: Object.keys(this.tags).length > 0 ? this.tags : void 0,
      timestamp: this._endTime,
      trace_id: this._traceId,
      origin: this._attributes[K],
      _metrics_summary: void 0,
      profile_id: this._attributes[Xs],
      exclusive_time: this._exclusiveTime,
      measurements: Object.keys(this._measurements).length > 0 ? this._measurements : void 0
    });
  }
  /** @inheritdoc */
  isRecording() {
    return !this._endTime && !!this._sampled;
  }
  /**
   * Convert the object to JSON.
   * @deprecated Use `spanToJSON(span)` instead.
   */
  toJSON() {
    return this.getSpanJSON();
  }
  /**
   * Get the merged data for this span.
   * For now, this combines `data` and `attributes` together,
   * until eventually we can ingest `attributes` directly.
   */
  _getData() {
    const { data: e, _attributes: n } = this, r = Object.keys(e).length > 0, s = Object.keys(n).length > 0;
    if (!(!r && !s))
      return r && s ? {
        ...e,
        ...n
      } : r ? e : n;
  }
}
class $n extends Le {
  /**
   * The reference to the current hub.
   */
  // DO NOT yet remove this property, it is used in a hack for v7 backwards compatibility.
  /**
   * This constructor should never be called manually. Those instrumenting tracing should use
   * `Sentry.startTransaction()`, and internal methods should use `hub.startTransaction()`.
   * @internal
   * @hideconstructor
   * @hidden
   *
   * @deprecated Transactions will be removed in v8. Use spans instead.
   */
  constructor(e, n) {
    super(e), this._contexts = {}, this._hub = n || me(), this._name = e.name || "", this._metadata = {
      // eslint-disable-next-line deprecation/deprecation
      ...e.metadata
    }, this._trimEnd = e.trimEnd, this.transaction = this;
    const r = this._metadata.dynamicSamplingContext;
    r && (this._frozenDynamicSamplingContext = { ...r });
  }
  // This sadly conflicts with the getter/setter ordering :(
  /* eslint-disable @typescript-eslint/member-ordering */
  /**
   * Getter for `name` property.
   * @deprecated Use `spanToJSON(span).description` instead.
   */
  get name() {
    return this._name;
  }
  /**
   * Setter for `name` property, which also sets `source` as custom.
   * @deprecated Use `updateName()` and `setMetadata()` instead.
   */
  set name(e) {
    this.setName(e);
  }
  /**
   * Get the metadata for this transaction.
   * @deprecated Use `spanGetMetadata(transaction)` instead.
   */
  get metadata() {
    return {
      // Defaults
      // eslint-disable-next-line deprecation/deprecation
      source: "custom",
      spanMetadata: {},
      // Legacy metadata
      ...this._metadata,
      // From attributes
      ...this._attributes[Q] && {
        source: this._attributes[Q]
      },
      ...this._attributes[V] && {
        sampleRate: this._attributes[V]
      }
    };
  }
  /**
   * Update the metadata for this transaction.
   * @deprecated Use `spanGetMetadata(transaction)` instead.
   */
  set metadata(e) {
    this._metadata = e;
  }
  /* eslint-enable @typescript-eslint/member-ordering */
  /**
   * Setter for `name` property, which also sets `source` on the metadata.
   *
   * @deprecated Use `.updateName()` and `.setAttribute()` instead.
   */
  setName(e, n = "custom") {
    this._name = e, this.setAttribute(Q, n);
  }
  /** @inheritdoc */
  updateName(e) {
    return this._name = e, this;
  }
  /**
   * Attaches SpanRecorder to the span itself
   * @param maxlen maximum number of spans that can be recorded
   */
  initSpanRecorder(e = 1e3) {
    this.spanRecorder || (this.spanRecorder = new Dn(e)), this.spanRecorder.add(this);
  }
  /**
   * Set the context of a transaction event.
   * @deprecated Use either `.setAttribute()`, or set the context on the scope before creating the transaction.
   */
  setContext(e, n) {
    n === null ? delete this._contexts[e] : this._contexts[e] = n;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use top-level `setMeasurement()` instead.
   */
  setMeasurement(e, n, r = "") {
    this._measurements[e] = { value: n, unit: r };
  }
  /**
   * Store metadata on this transaction.
   * @deprecated Use attributes or store data on the scope instead.
   */
  setMetadata(e) {
    this._metadata = { ...this._metadata, ...e };
  }
  /**
   * @inheritDoc
   */
  end(e) {
    const n = Me(e), r = this._finishTransaction(n);
    if (r)
      return this._hub.captureEvent(r);
  }
  /**
   * @inheritDoc
   */
  toContext() {
    const e = super.toContext();
    return ee({
      ...e,
      name: this._name,
      trimEnd: this._trimEnd
    });
  }
  /**
   * @inheritDoc
   */
  updateWithContext(e) {
    return super.updateWithContext(e), this._name = e.name || "", this._trimEnd = e.trimEnd, this;
  }
  /**
   * @inheritdoc
   *
   * @experimental
   *
   * @deprecated Use top-level `getDynamicSamplingContextFromSpan` instead.
   */
  getDynamicSamplingContext() {
    return pe(this);
  }
  /**
   * Override the current hub with a new one.
   * Used if you want another hub to finish the transaction.
   *
   * @internal
   */
  setHub(e) {
    this._hub = e;
  }
  /**
   * Get the profile id of the transaction.
   */
  getProfileId() {
    if (this._contexts !== void 0 && this._contexts.profile !== void 0)
      return this._contexts.profile.profile_id;
  }
  /**
   * Finish the transaction & prepare the event to send to Sentry.
   */
  _finishTransaction(e) {
    if (this._endTime !== void 0)
      return;
    this._name || (m && f.warn("Transaction has no name, falling back to `<unlabeled transaction>`."), this._name = "<unlabeled transaction>"), super.end(e);
    const n = this._hub.getClient();
    if (n && n.emit && n.emit("finishTransaction", this), this._sampled !== !0) {
      m && f.log("[Tracing] Discarding transaction because its trace was not chosen to be sampled."), n && n.recordDroppedEvent("sample_rate", "transaction");
      return;
    }
    const r = this.spanRecorder ? (
      // eslint-disable-next-line deprecation/deprecation
      this.spanRecorder.spans.filter((d) => d !== this && D(d).timestamp)
    ) : [];
    if (this._trimEnd && r.length > 0) {
      const d = r.map((l) => D(l).timestamp).filter(Boolean);
      this._endTime = d.reduce((l, p) => l > p ? l : p);
    }
    const { scope: s, isolationScope: i } = zs(this), { metadata: a } = this, { source: o } = a, c = {
      contexts: {
        ...this._contexts,
        // We don't want to override trace context
        trace: hn(this)
      },
      // TODO: Pass spans serialized via `spanToJSON()` here instead in v8.
      spans: r,
      start_timestamp: this._startTime,
      // eslint-disable-next-line deprecation/deprecation
      tags: this.tags,
      timestamp: this._endTime,
      transaction: this._name,
      type: "transaction",
      sdkProcessingMetadata: {
        ...a,
        capturedSpanScope: s,
        capturedSpanIsolationScope: i,
        ...ee({
          dynamicSamplingContext: pe(this)
        })
      },
      _metrics_summary: void 0,
      ...o && {
        transaction_info: {
          source: o
        }
      }
    };
    return Object.keys(this._measurements).length > 0 && (m && f.log(
      "[Measurements] Adding measurements to transaction",
      JSON.stringify(this._measurements, void 0, 2)
    ), c.measurements = this._measurements), m && f.log(`[Tracing] Finishing ${this.op} transaction: ${this._name}.`), c;
  }
}
const Ae = {
  idleTimeout: 1e3,
  finalTimeout: 3e4,
  heartbeatInterval: 5e3
}, Vs = "finishReason", Y = [
  "heartbeatFailed",
  "idleTimeout",
  "documentHidden",
  "finalTimeout",
  "externalFinish",
  "cancelled"
];
class Ks extends Dn {
  constructor(e, n, r, s) {
    super(s), this._pushActivity = e, this._popActivity = n, this.transactionSpanId = r;
  }
  /**
   * @inheritDoc
   */
  add(e) {
    if (e.spanContext().spanId !== this.transactionSpanId) {
      const n = e.end;
      e.end = (...r) => (this._popActivity(e.spanContext().spanId), n.apply(e, r)), D(e).timestamp === void 0 && this._pushActivity(e.spanContext().spanId);
    }
    super.add(e);
  }
}
class Js extends $n {
  // Activities store a list of active spans
  // Track state of activities in previous heartbeat
  // Amount of times heartbeat has counted. Will cause transaction to finish after 3 beats.
  // We should not use heartbeat if we finished a transaction
  // Idle timeout was canceled and we should finish the transaction with the last span end.
  /**
   * Timer that tracks Transaction idleTimeout
   */
  /**
   * @deprecated Transactions will be removed in v8. Use spans instead.
   */
  constructor(e, n, r = Ae.idleTimeout, s = Ae.finalTimeout, i = Ae.heartbeatInterval, a = !1, o = !1) {
    super(e, n), this._idleHub = n, this._idleTimeout = r, this._finalTimeout = s, this._heartbeatInterval = i, this._onScope = a, this.activities = {}, this._heartbeatCounter = 0, this._finished = !1, this._idleTimeoutCanceledPermanently = !1, this._beforeFinishCallbacks = [], this._finishReason = Y[4], this._autoFinishAllowed = !o, a && (m && f.log(`Setting idle transaction on scope. Span ID: ${this.spanContext().spanId}`), n.getScope().setSpan(this)), o || this._restartIdleTimeout(), setTimeout(() => {
      this._finished || (this.setStatus("deadline_exceeded"), this._finishReason = Y[3], this.end());
    }, this._finalTimeout);
  }
  /** {@inheritDoc} */
  end(e) {
    const n = Me(e);
    if (this._finished = !0, this.activities = {}, this.op === "ui.action.click" && this.setAttribute(Vs, this._finishReason), this.spanRecorder) {
      m && // eslint-disable-next-line deprecation/deprecation
      f.log("[Tracing] finishing IdleTransaction", new Date(n * 1e3).toISOString(), this.op);
      for (const r of this._beforeFinishCallbacks)
        r(this, n);
      this.spanRecorder.spans = this.spanRecorder.spans.filter((r) => {
        if (r.spanContext().spanId === this.spanContext().spanId)
          return !0;
        D(r).timestamp || (r.setStatus("cancelled"), r.end(n), m && f.log("[Tracing] cancelling span since transaction ended early", JSON.stringify(r, void 0, 2)));
        const { start_timestamp: s, timestamp: i } = D(r), a = s && s < n, o = (this._finalTimeout + this._idleTimeout) / 1e3, c = i && s && i - s < o;
        if (m) {
          const u = JSON.stringify(r, void 0, 2);
          a ? c || f.log("[Tracing] discarding Span since it finished after Transaction final timeout", u) : f.log("[Tracing] discarding Span since it happened after Transaction was finished", u);
        }
        return a && c;
      }), m && f.log("[Tracing] flushing IdleTransaction");
    } else
      m && f.log("[Tracing] No active IdleTransaction");
    if (this._onScope) {
      const r = this._idleHub.getScope();
      r.getTransaction() === this && r.setSpan(void 0);
    }
    return super.end(e);
  }
  /**
   * Register a callback function that gets executed before the transaction finishes.
   * Useful for cleanup or if you want to add any additional spans based on current context.
   *
   * This is exposed because users have no other way of running something before an idle transaction
   * finishes.
   */
  registerBeforeFinishCallback(e) {
    this._beforeFinishCallbacks.push(e);
  }
  /**
   * @inheritDoc
   */
  initSpanRecorder(e) {
    if (!this.spanRecorder) {
      const n = (s) => {
        this._finished || this._pushActivity(s);
      }, r = (s) => {
        this._finished || this._popActivity(s);
      };
      this.spanRecorder = new Ks(n, r, this.spanContext().spanId, e), m && f.log("Starting heartbeat"), this._pingHeartbeat();
    }
    this.spanRecorder.add(this);
  }
  /**
   * Cancels the existing idle timeout, if there is one.
   * @param restartOnChildSpanChange Default is `true`.
   *                                 If set to false the transaction will end
   *                                 with the last child span.
   */
  cancelIdleTimeout(e, {
    restartOnChildSpanChange: n
  } = {
    restartOnChildSpanChange: !0
  }) {
    this._idleTimeoutCanceledPermanently = n === !1, this._idleTimeoutID && (clearTimeout(this._idleTimeoutID), this._idleTimeoutID = void 0, Object.keys(this.activities).length === 0 && this._idleTimeoutCanceledPermanently && (this._finishReason = Y[5], this.end(e)));
  }
  /**
   * Temporary method used to externally set the transaction's `finishReason`
   *
   * ** WARNING**
   * This is for the purpose of experimentation only and will be removed in the near future, do not use!
   *
   * @internal
   *
   */
  setFinishReason(e) {
    this._finishReason = e;
  }
  /**
   * Permits the IdleTransaction to automatically end itself via the idle timeout and heartbeat mechanisms when the `delayAutoFinishUntilSignal` option was set to `true`.
   */
  sendAutoFinishSignal() {
    this._autoFinishAllowed || (m && f.log("[Tracing] Received finish signal for idle transaction."), this._restartIdleTimeout(), this._autoFinishAllowed = !0);
  }
  /**
   * Restarts idle timeout, if there is no running idle timeout it will start one.
   */
  _restartIdleTimeout(e) {
    this.cancelIdleTimeout(), this._idleTimeoutID = setTimeout(() => {
      !this._finished && Object.keys(this.activities).length === 0 && (this._finishReason = Y[1], this.end(e));
    }, this._idleTimeout);
  }
  /**
   * Start tracking a specific activity.
   * @param spanId The span id that represents the activity
   */
  _pushActivity(e) {
    this.cancelIdleTimeout(void 0, { restartOnChildSpanChange: !this._idleTimeoutCanceledPermanently }), m && f.log(`[Tracing] pushActivity: ${e}`), this.activities[e] = !0, m && f.log("[Tracing] new activities count", Object.keys(this.activities).length);
  }
  /**
   * Remove an activity from usage
   * @param spanId The span id that represents the activity
   */
  _popActivity(e) {
    if (this.activities[e] && (m && f.log(`[Tracing] popActivity ${e}`), delete this.activities[e], m && f.log("[Tracing] new activities count", Object.keys(this.activities).length)), Object.keys(this.activities).length === 0) {
      const n = pn();
      this._idleTimeoutCanceledPermanently ? this._autoFinishAllowed && (this._finishReason = Y[5], this.end(n)) : this._restartIdleTimeout(n + this._idleTimeout / 1e3);
    }
  }
  /**
   * Checks when entries of this.activities are not changing for 3 beats.
   * If this occurs we finish the transaction.
   */
  _beat() {
    if (this._finished)
      return;
    const e = Object.keys(this.activities).join("");
    e === this._prevHeartbeatString ? this._heartbeatCounter++ : this._heartbeatCounter = 1, this._prevHeartbeatString = e, this._heartbeatCounter >= 3 ? this._autoFinishAllowed && (m && f.log("[Tracing] Transaction finished because of no change for 3 heart beats"), this.setStatus("deadline_exceeded"), this._finishReason = Y[0], this.end()) : this._pingHeartbeat();
  }
  /**
   * Pings the heartbeat
   */
  _pingHeartbeat() {
    m && f.log(`pinging Heartbeat -> current counter: ${this._heartbeatCounter}`), setTimeout(() => {
      this._beat();
    }, this._heartbeatInterval);
  }
}
function Pn(t, e, n) {
  if (!ie(e))
    return t.sampled = !1, t;
  if (t.sampled !== void 0)
    return t.setAttribute(V, Number(t.sampled)), t;
  let r;
  return typeof e.tracesSampler == "function" ? (r = e.tracesSampler(n), t.setAttribute(V, Number(r))) : n.parentSampled !== void 0 ? r = n.parentSampled : typeof e.tracesSampleRate < "u" ? (r = e.tracesSampleRate, t.setAttribute(V, Number(r))) : (r = 1, t.setAttribute(V, r)), xn(r) ? r ? (t.sampled = Math.random() < r, t.sampled ? (m && // eslint-disable-next-line deprecation/deprecation
  f.log(`[Tracing] starting ${t.op} transaction - ${D(t).description}`), t) : (m && f.log(
    `[Tracing] Discarding transaction because it's not included in the random sample (sampling rate = ${Number(
      r
    )})`
  ), t)) : (m && f.log(
    `[Tracing] Discarding transaction because ${typeof e.tracesSampler == "function" ? "tracesSampler returned 0 or false" : "a negative sampling decision was inherited or tracesSampleRate is set to 0"}`
  ), t.sampled = !1, t) : (m && f.warn("[Tracing] Discarding transaction because of invalid sample rate."), t.sampled = !1, t);
}
function xn(t) {
  return Nr(t) || !(typeof t == "number" || typeof t == "boolean") ? (m && f.warn(
    `[Tracing] Given sample rate is invalid. Sample rate must be a boolean or a number between 0 and 1. Got ${JSON.stringify(
      t
    )} of type ${JSON.stringify(typeof t)}.`
  ), !1) : t < 0 || t > 1 ? (m && f.warn(`[Tracing] Given sample rate is invalid. Sample rate must be between 0 and 1. Got ${t}.`), !1) : !0;
}
function Qs() {
  const e = this.getScope().getSpan();
  return e ? {
    "sentry-trace": Fe(e)
  } : {};
}
function Zs(t, e) {
  const n = this.getClient(), r = n && n.getOptions() || {}, s = r.instrumenter || "sentry", i = t.instrumenter || "sentry";
  s !== i && (m && f.error(
    `A transaction was started with instrumenter=\`${i}\`, but the SDK is configured with the \`${s}\` instrumenter.
The transaction will not be sampled. Please use the ${s} instrumentation to start transactions.`
  ), t.sampled = !1);
  let a = new $n(t, this);
  return a = Pn(a, r, {
    name: t.name,
    parentSampled: t.parentSampled,
    transactionContext: t,
    attributes: {
      // eslint-disable-next-line deprecation/deprecation
      ...t.data,
      ...t.attributes
    },
    ...e
  }), a.isRecording() && a.initSpanRecorder(r._experiments && r._experiments.maxSpans), n && n.emit && n.emit("startTransaction", a), a;
}
function Yt(t, e, n, r, s, i, a, o = !1) {
  const c = t.getClient(), u = c && c.getOptions() || {};
  let d = new Js(
    e,
    t,
    n,
    r,
    a,
    s,
    o
  );
  return d = Pn(d, u, {
    name: e.name,
    parentSampled: e.parentSampled,
    transactionContext: e,
    attributes: {
      // eslint-disable-next-line deprecation/deprecation
      ...e.data,
      ...e.attributes
    },
    ...i
  }), d.isRecording() && d.initSpanRecorder(u._experiments && u._experiments.maxSpans), c && c.emit && c.emit("startTransaction", d), d;
}
function Mn() {
  const t = mn();
  t.__SENTRY__ && (t.__SENTRY__.extensions = t.__SENTRY__.extensions || {}, t.__SENTRY__.extensions.startTransaction || (t.__SENTRY__.extensions.startTransaction = Zs), t.__SENTRY__.extensions.traceHeaders || (t.__SENTRY__.extensions.traceHeaders = Qs), Bs());
}
function ei(t, e, n) {
  const r = B();
  r && r.setMeasurement(t, e, n);
}
function ti(t, e) {
  return e && (t.sdk = t.sdk || {}, t.sdk.name = t.sdk.name || e.name, t.sdk.version = t.sdk.version || e.version, t.sdk.integrations = [...t.sdk.integrations || [], ...e.integrations || []], t.sdk.packages = [...t.sdk.packages || [], ...e.packages || []]), t;
}
function ni(t, e, n, r) {
  const s = Nn(n), i = {
    sent_at: (/* @__PURE__ */ new Date()).toISOString(),
    ...s && { sdk: s },
    ...!!r && e && { dsn: se(e) }
  }, a = "aggregates" in t ? [{ type: "sessions" }, t] : [{ type: "session" }, t.toJSON()];
  return G(i, [a]);
}
function ri(t, e, n, r) {
  const s = Nn(n), i = t.type && t.type !== "replay_event" ? t.type : "event";
  ti(t, n && n.sdk);
  const a = Ps(t, s, r, e);
  return delete t.sdkProcessingMetadata, G(a, [[{ type: i }, t]]);
}
const si = "7";
function ii(t) {
  const e = t.protocol ? `${t.protocol}:` : "", n = t.port ? `:${t.port}` : "";
  return `${e}//${t.host}${n}${t.path ? `/${t.path}` : ""}/api/`;
}
function ai(t) {
  return `${ii(t)}${t.projectId}/envelope/`;
}
function oi(t, e) {
  return Or({
    // We send only the minimum set of required information. See
    // https://github.com/getsentry/sentry-javascript/issues/2572.
    sentry_key: t.publicKey,
    sentry_version: si,
    ...e && { sentry_client: `${e.name}/${e.version}` }
  });
}
function ci(t, e = {}) {
  const n = typeof e == "string" ? e : e.tunnel, r = typeof e == "string" || !e._metadata ? void 0 : e._metadata.sdk;
  return n || `${ai(t)}?${oi(t, r)}`;
}
const zt = [];
function ui(t) {
  const e = {};
  return t.forEach((n) => {
    const { name: r } = n, s = e[r];
    s && !s.isDefaultInstance && n.isDefaultInstance || (e[r] = n);
  }), Object.keys(e).map((n) => e[n]);
}
function di(t) {
  const e = t.defaultIntegrations || [], n = t.integrations;
  e.forEach((a) => {
    a.isDefaultInstance = !0;
  });
  let r;
  Array.isArray(n) ? r = [...e, ...n] : typeof n == "function" ? r = Ar(n(e)) : r = e;
  const s = ui(r), i = fi(s, (a) => a.name === "Debug");
  if (i !== -1) {
    const [a] = s.splice(i, 1);
    s.push(a);
  }
  return s;
}
function li(t, e) {
  const n = {};
  return e.forEach((r) => {
    r && Fn(t, r, n);
  }), n;
}
function Xt(t, e) {
  for (const n of e)
    n && n.afterAllSetup && n.afterAllSetup(t);
}
function Fn(t, e, n) {
  if (n[e.name]) {
    m && f.log(`Integration skipped because it was already installed: ${e.name}`);
    return;
  }
  if (n[e.name] = e, zt.indexOf(e.name) === -1 && (e.setupOnce(Cr, me), zt.push(e.name)), e.setup && typeof e.setup == "function" && e.setup(t), t.on && typeof e.preprocessEvent == "function") {
    const r = e.preprocessEvent.bind(e);
    t.on("preprocessEvent", (s, i) => r(s, i, t));
  }
  if (t.addEventProcessor && typeof e.processEvent == "function") {
    const r = e.processEvent.bind(e), s = Object.assign((i, a) => r(i, a, t), {
      id: e.name
    });
    t.addEventProcessor(s);
  }
  m && f.log(`Integration installed: ${e.name}`);
}
function fi(t, e) {
  for (let n = 0; n < t.length; n++)
    if (e(t[n]) === !0)
      return n;
  return -1;
}
function L(t, e) {
  return Object.assign(
    function(...r) {
      return e(...r);
    },
    { id: t }
  );
}
function pi(t) {
  let e = "";
  for (const n of t) {
    const r = Object.entries(n.tags), s = r.length > 0 ? `|#${r.map(([i, a]) => `${i}:${a}`).join(",")}` : "";
    e += `${n.name}@${n.unit}:${n.metric}|${n.metricType}${s}|T${n.timestamp}
`;
  }
  return e;
}
function hi(t, e, n, r) {
  const s = {
    sent_at: (/* @__PURE__ */ new Date()).toISOString()
  };
  n && n.sdk && (s.sdk = {
    name: n.sdk.name,
    version: n.sdk.version
  }), r && e && (s.dsn = se(e));
  const i = mi(t);
  return G(s, [i]);
}
function mi(t) {
  const e = pi(t);
  return [{
    type: "statsd",
    length: e.length
  }, e];
}
const Vt = "Not capturing exception because it's already been captured.";
class gi {
  /**
   * A reference to a metrics aggregator
   *
   * @experimental Note this is alpha API. It may experience breaking changes in the future.
   */
  /** Options passed to the SDK. */
  /** The client Dsn, if specified in options. Without this Dsn, the SDK will be disabled. */
  /** Array of set up integrations. */
  /** Indicates whether this client's integrations have been set up. */
  /** Number of calls being processed */
  /** Holds flushable  */
  // eslint-disable-next-line @typescript-eslint/ban-types
  /**
   * Initializes this client instance.
   *
   * @param options Options for the client.
   */
  constructor(e) {
    if (this._options = e, this._integrations = {}, this._integrationsInitialized = !1, this._numProcessing = 0, this._outcomes = {}, this._hooks = {}, this._eventProcessors = [], e.dsn ? this._dsn = rs(e.dsn) : m && f.warn("No DSN provided, client will not send events."), this._dsn) {
      const n = ci(this._dsn, e);
      this._transport = e.transport({
        recordDroppedEvent: this.recordDroppedEvent.bind(this),
        ...e.transportOptions,
        url: n
      });
    }
  }
  /**
   * @inheritDoc
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  captureException(e, n, r) {
    if (Ot(e)) {
      m && f.log(Vt);
      return;
    }
    let s = n && n.event_id;
    return this._process(
      this.eventFromException(e, n).then((i) => this._captureEvent(i, n, r)).then((i) => {
        s = i;
      })
    ), s;
  }
  /**
   * @inheritDoc
   */
  captureMessage(e, n, r, s) {
    let i = r && r.event_id;
    const a = gn(e) ? e : String(e), o = mt(e) ? this.eventFromMessage(a, n, r) : this.eventFromException(e, r);
    return this._process(
      o.then((c) => this._captureEvent(c, r, s)).then((c) => {
        i = c;
      })
    ), i;
  }
  /**
   * @inheritDoc
   */
  captureEvent(e, n, r) {
    if (n && n.originalException && Ot(n.originalException)) {
      m && f.log(Vt);
      return;
    }
    let s = n && n.event_id;
    const a = (e.sdkProcessingMetadata || {}).capturedSpanScope;
    return this._process(
      this._captureEvent(e, n, a || r).then((o) => {
        s = o;
      })
    ), s;
  }
  /**
   * @inheritDoc
   */
  captureSession(e) {
    typeof e.release != "string" ? m && f.warn("Discarded session because of missing or non-string release") : (this.sendSession(e), At(e, { init: !1 }));
  }
  /**
   * @inheritDoc
   */
  getDsn() {
    return this._dsn;
  }
  /**
   * @inheritDoc
   */
  getOptions() {
    return this._options;
  }
  /**
   * @see SdkMetadata in @sentry/types
   *
   * @return The metadata of the SDK
   */
  getSdkMetadata() {
    return this._options._metadata;
  }
  /**
   * @inheritDoc
   */
  getTransport() {
    return this._transport;
  }
  /**
   * @inheritDoc
   */
  flush(e) {
    const n = this._transport;
    return n ? (this.metricsAggregator && this.metricsAggregator.flush(), this._isClientDoneProcessing(e).then((r) => n.flush(e).then((s) => r && s))) : Z(!0);
  }
  /**
   * @inheritDoc
   */
  close(e) {
    return this.flush(e).then((n) => (this.getOptions().enabled = !1, this.metricsAggregator && this.metricsAggregator.close(), n));
  }
  /** Get all installed event processors. */
  getEventProcessors() {
    return this._eventProcessors;
  }
  /** @inheritDoc */
  addEventProcessor(e) {
    this._eventProcessors.push(e);
  }
  /**
   * This is an internal function to setup all integrations that should run on the client.
   * @deprecated Use `client.init()` instead.
   */
  setupIntegrations(e) {
    (e && !this._integrationsInitialized || this._isEnabled() && !this._integrationsInitialized) && this._setupIntegrations();
  }
  /** @inheritdoc */
  init() {
    this._isEnabled() && this._setupIntegrations();
  }
  /**
   * Gets an installed integration by its `id`.
   *
   * @returns The installed integration or `undefined` if no integration with that `id` was installed.
   * @deprecated Use `getIntegrationByName()` instead.
   */
  getIntegrationById(e) {
    return this.getIntegrationByName(e);
  }
  /**
   * Gets an installed integration by its name.
   *
   * @returns The installed integration or `undefined` if no integration with that `name` was installed.
   */
  getIntegrationByName(e) {
    return this._integrations[e];
  }
  /**
   * Returns the client's instance of the given integration class, it any.
   * @deprecated Use `getIntegrationByName()` instead.
   */
  getIntegration(e) {
    try {
      return this._integrations[e.id] || null;
    } catch {
      return m && f.warn(`Cannot retrieve integration ${e.id} from the current Client`), null;
    }
  }
  /**
   * @inheritDoc
   */
  addIntegration(e) {
    const n = this._integrations[e.name];
    Fn(this, e, this._integrations), n || Xt(this, [e]);
  }
  /**
   * @inheritDoc
   */
  sendEvent(e, n = {}) {
    this.emit("beforeSendEvent", e, n);
    let r = ri(e, this._dsn, this._options._metadata, this._options.tunnel);
    for (const i of n.attachments || [])
      r = Os(
        r,
        Ds(
          i,
          this._options.transportOptions && this._options.transportOptions.textEncoder
        )
      );
    const s = this._sendEnvelope(r);
    s && s.then((i) => this.emit("afterSendEvent", e, i), null);
  }
  /**
   * @inheritDoc
   */
  sendSession(e) {
    const n = ni(e, this._dsn, this._options._metadata, this._options.tunnel);
    this._sendEnvelope(n);
  }
  /**
   * @inheritDoc
   */
  recordDroppedEvent(e, n, r) {
    if (this._options.sendClientReports) {
      const s = `${e}:${n}`;
      m && f.log(`Adding outcome: "${s}"`), this._outcomes[s] = this._outcomes[s] + 1 || 1;
    }
  }
  /**
   * @inheritDoc
   */
  captureAggregateMetrics(e) {
    m && f.log(`Flushing aggregated metrics, number of metrics: ${e.length}`);
    const n = hi(
      e,
      this._dsn,
      this._options._metadata,
      this._options.tunnel
    );
    this._sendEnvelope(n);
  }
  // Keep on() & emit() signatures in sync with types' client.ts interface
  /* eslint-disable @typescript-eslint/unified-signatures */
  /** @inheritdoc */
  /** @inheritdoc */
  on(e, n) {
    this._hooks[e] || (this._hooks[e] = []), this._hooks[e].push(n);
  }
  /** @inheritdoc */
  /** @inheritdoc */
  emit(e, ...n) {
    this._hooks[e] && this._hooks[e].forEach((r) => r(...n));
  }
  /* eslint-enable @typescript-eslint/unified-signatures */
  /** Setup integrations for this client. */
  _setupIntegrations() {
    const { integrations: e } = this._options;
    this._integrations = li(this, e), Xt(this, e), this._integrationsInitialized = !0;
  }
  /** Updates existing session based on the provided event */
  _updateSessionFromEvent(e, n) {
    let r = !1, s = !1;
    const i = n.exception && n.exception.values;
    if (i) {
      s = !0;
      for (const c of i) {
        const u = c.mechanism;
        if (u && u.handled === !1) {
          r = !0;
          break;
        }
      }
    }
    const a = e.status === "ok";
    (a && e.errors === 0 || a && r) && (At(e, {
      ...r && { status: "crashed" },
      errors: e.errors || Number(s || r)
    }), this.captureSession(e));
  }
  /**
   * Determine if the client is finished processing. Returns a promise because it will wait `timeout` ms before saying
   * "no" (resolving to `false`) in order to give the client a chance to potentially finish first.
   *
   * @param timeout The time, in ms, after which to resolve to `false` if the client is still busy. Passing `0` (or not
   * passing anything) will make the promise wait as long as it takes for processing to finish before resolving to
   * `true`.
   * @returns A promise which will resolve to `true` if processing is already done or finishes before the timeout, and
   * `false` otherwise
   */
  _isClientDoneProcessing(e) {
    return new ht((n) => {
      let r = 0;
      const s = 1, i = setInterval(() => {
        this._numProcessing == 0 ? (clearInterval(i), n(!0)) : (r += s, e && r >= e && (clearInterval(i), n(!1)));
      }, s);
    });
  }
  /** Determines whether this SDK is enabled and a transport is present. */
  _isEnabled() {
    return this.getOptions().enabled !== !1 && this._transport !== void 0;
  }
  /**
   * Adds common information to events.
   *
   * The information includes release and environment from `options`,
   * breadcrumbs and context (extra, tags and user) from the scope.
   *
   * Information that is already present in the event is never overwritten. For
   * nested objects, such as the context, keys are merged.
   *
   * @param event The original event.
   * @param hint May contain additional information about the original exception.
   * @param scope A scope containing event metadata.
   * @returns A new event with more information.
   */
  _prepareEvent(e, n, r, s = xe()) {
    const i = this.getOptions(), a = Object.keys(this._integrations);
    return !n.integrations && a.length > 0 && (n.integrations = a), this.emit("preprocessEvent", e, n), Dr(i, e, n, r, this, s).then((o) => {
      if (o === null)
        return o;
      const c = {
        ...s.getPropagationContext(),
        ...r ? r.getPropagationContext() : void 0
      };
      if (!(o.contexts && o.contexts.trace) && c) {
        const { traceId: d, spanId: l, parentSpanId: p, dsc: h } = c;
        o.contexts = {
          trace: {
            trace_id: d,
            span_id: l,
            parent_span_id: p
          },
          ...o.contexts
        };
        const g = h || gt(d, this, r);
        o.sdkProcessingMetadata = {
          dynamicSamplingContext: g,
          ...o.sdkProcessingMetadata
        };
      }
      return o;
    });
  }
  /**
   * Processes the event and logs an error in case of rejection
   * @param event
   * @param hint
   * @param scope
   */
  _captureEvent(e, n = {}, r) {
    return this._processEvent(e, n, r).then(
      (s) => s.event_id,
      (s) => {
        if (m) {
          const i = s;
          i.logLevel === "log" ? f.log(i.message) : f.warn(i);
        }
      }
    );
  }
  /**
   * Processes an event (either error or message) and sends it to Sentry.
   *
   * This also adds breadcrumbs and context information to the event. However,
   * platform specific meta data (such as the User's IP address) must be added
   * by the SDK implementor.
   *
   *
   * @param event The event to send to Sentry.
   * @param hint May contain additional information about the original exception.
   * @param scope A scope containing event metadata.
   * @returns A SyncPromise that resolves with the event or rejects in case event was/will not be send.
   */
  _processEvent(e, n, r) {
    const s = this.getOptions(), { sampleRate: i } = s, a = Hn(e), o = Ln(e), c = e.type || "error", u = `before send for type \`${c}\``;
    if (o && typeof i == "number" && Math.random() > i)
      return this.recordDroppedEvent("sample_rate", "error", e), pt(
        new C(
          `Discarding event because it's not included in the random sample (sampling rate = ${i})`,
          "log"
        )
      );
    const d = c === "replay_event" ? "replay" : c, p = (e.sdkProcessingMetadata || {}).capturedSpanIsolationScope;
    return this._prepareEvent(e, n, r, p).then((h) => {
      if (h === null)
        throw this.recordDroppedEvent("event_processor", d, e), new C("An event processor returned `null`, will not send event.", "log");
      if (n.data && n.data.__sentry__ === !0)
        return h;
      const E = Ti(s, h, n);
      return _i(E, u);
    }).then((h) => {
      if (h === null)
        throw this.recordDroppedEvent("before_send", d, e), new C(`${u} returned \`null\`, will not send event.`, "log");
      const g = r && r.getSession();
      !a && g && this._updateSessionFromEvent(g, h);
      const E = h.transaction_info;
      if (a && E && h.transaction !== e.transaction) {
        const k = "custom";
        h.transaction_info = {
          ...E,
          source: k
        };
      }
      return this.sendEvent(h, n), h;
    }).then(null, (h) => {
      throw h instanceof C ? h : (this.captureException(h, {
        data: {
          __sentry__: !0
        },
        originalException: h
      }), new C(
        `Event processing pipeline threw an error, original event will not be sent. Details have been sent as a new event.
Reason: ${h}`
      ));
    });
  }
  /**
   * Occupies the client with processing and event
   */
  _process(e) {
    this._numProcessing++, e.then(
      (n) => (this._numProcessing--, n),
      (n) => (this._numProcessing--, n)
    );
  }
  /**
   * @inheritdoc
   */
  _sendEnvelope(e) {
    if (this.emit("beforeEnvelope", e), this._isEnabled() && this._transport)
      return this._transport.send(e).then(null, (n) => {
        m && f.error("Error while sending event:", n);
      });
    m && f.error("Transport disabled");
  }
  /**
   * Clears outcomes on this client and returns them.
   */
  _clearOutcomes() {
    const e = this._outcomes;
    return this._outcomes = {}, Object.keys(e).map((n) => {
      const [r, s] = n.split(":");
      return {
        reason: r,
        category: s,
        quantity: e[n]
      };
    });
  }
  /**
   * @inheritDoc
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
}
function _i(t, e) {
  const n = `${e} must return \`null\` or a valid event.`;
  if ($r(t))
    return t.then(
      (r) => {
        if (!Ke(r) && r !== null)
          throw new C(n);
        return r;
      },
      (r) => {
        throw new C(`${e} rejected with ${r}`);
      }
    );
  if (!Ke(t) && t !== null)
    throw new C(n);
  return t;
}
function Ti(t, e, n) {
  const { beforeSend: r, beforeSendTransaction: s } = t;
  return Ln(e) && r ? r(e, n) : Hn(e) && s ? s(e, n) : e;
}
function Ln(t) {
  return t.type === void 0;
}
function Hn(t) {
  return t.type === "transaction";
}
function yi(t, e) {
  e.debug === !0 && (m ? f.enable() : ln(() => {
    console.warn("[Sentry] Cannot initialize SDK with `debug` option using a non-debug bundle.");
  })), re().update(e.initialScope);
  const r = new t(e);
  Ei(r), bi(r);
}
function Ei(t) {
  const n = me().getStackTop();
  n.client = t, n.scope.setClient(t);
}
function bi(t) {
  t.init ? t.init() : t.setupIntegrations && t.setupIntegrations();
}
const Si = 30;
function Un(t, e, n = ks(
  t.bufferSize || Si
)) {
  let r = {};
  const s = (a) => n.drain(a);
  function i(a) {
    const o = [];
    if (jt(a, (l, p) => {
      const h = qt(p);
      if (Hs(r, h)) {
        const g = Kt(l, p);
        t.recordDroppedEvent("ratelimit_backoff", h, g);
      } else
        o.push(l);
    }), o.length === 0)
      return Z();
    const c = G(a[0], o), u = (l) => {
      jt(c, (p, h) => {
        const g = Kt(p, h);
        t.recordDroppedEvent(l, qt(h), g);
      });
    }, d = () => e({ body: As(c, t.textEncoder) }).then(
      (l) => (l.statusCode !== void 0 && (l.statusCode < 200 || l.statusCode >= 300) && m && f.warn(`Sentry responded with status code ${l.statusCode} to sent event.`), r = Us(r, l), l),
      (l) => {
        throw u("network_error"), l;
      }
    );
    return n.add(d).then(
      (l) => l,
      (l) => {
        if (l instanceof C)
          return m && f.error("Skipped sending event because buffer is full."), u("queue_overflow"), Z();
        throw l;
      }
    );
  }
  return i.__sentry__baseTransport__ = !0, {
    send: i,
    flush: s
  };
}
function Kt(t, e) {
  if (!(e !== "event" && e !== "transaction"))
    return Array.isArray(t) ? t[1] : void 0;
}
function Ii(t, e) {
  const n = {
    sent_at: (/* @__PURE__ */ new Date()).toISOString()
  };
  e && (n.dsn = se(e));
  const r = t.map(vi);
  return G(n, r);
}
function vi(t) {
  return [{
    type: "span"
  }, t];
}
function Bn(t, e, n = [e], r = "npm") {
  const s = t._metadata || {};
  s.sdk || (s.sdk = {
    name: `sentry.javascript.${e}`,
    packages: n.map((i) => ({
      name: `${r}:@sentry/${i}`,
      version: Ct
    })),
    version: Ct
  }), t._metadata = s;
}
const Ri = [/^Script error\.?$/, /^Javascript error: Script error\.? on line 0$/], ki = [
  /^.*\/healthcheck$/,
  /^.*\/healthy$/,
  /^.*\/live$/,
  /^.*\/ready$/,
  /^.*\/heartbeat$/,
  /^.*\/health$/,
  /^.*\/healthz$/
], jn = "InboundFilters", wi = (t = {}) => ({
  name: jn,
  // TODO v8: Remove this
  setupOnce() {
  },
  // eslint-disable-line @typescript-eslint/no-empty-function
  processEvent(e, n, r) {
    const s = r.getOptions(), i = Ni(t, s);
    return Oi(e, i) ? null : e;
  }
}), qn = wi;
L(
  jn,
  qn
);
function Ni(t = {}, e = {}) {
  return {
    allowUrls: [...t.allowUrls || [], ...e.allowUrls || []],
    denyUrls: [...t.denyUrls || [], ...e.denyUrls || []],
    ignoreErrors: [
      ...t.ignoreErrors || [],
      ...e.ignoreErrors || [],
      ...t.disableErrorDefaults ? [] : Ri
    ],
    ignoreTransactions: [
      ...t.ignoreTransactions || [],
      ...e.ignoreTransactions || [],
      ...t.disableTransactionDefaults ? [] : ki
    ],
    ignoreInternal: t.ignoreInternal !== void 0 ? t.ignoreInternal : !0
  };
}
function Oi(t, e) {
  return e.ignoreInternal && xi(t) ? (m && f.warn(`Event dropped due to being internal Sentry Error.
Event: ${M(t)}`), !0) : Ai(t, e.ignoreErrors) ? (m && f.warn(
    `Event dropped due to being matched by \`ignoreErrors\` option.
Event: ${M(t)}`
  ), !0) : Ci(t, e.ignoreTransactions) ? (m && f.warn(
    `Event dropped due to being matched by \`ignoreTransactions\` option.
Event: ${M(t)}`
  ), !0) : Di(t, e.denyUrls) ? (m && f.warn(
    `Event dropped due to being matched by \`denyUrls\` option.
Event: ${M(
      t
    )}.
Url: ${$e(t)}`
  ), !0) : $i(t, e.allowUrls) ? !1 : (m && f.warn(
    `Event dropped due to not being matched by \`allowUrls\` option.
Event: ${M(
      t
    )}.
Url: ${$e(t)}`
  ), !0);
}
function Ai(t, e) {
  return t.type || !e || !e.length ? !1 : Pi(t).some((n) => ge(n, e));
}
function Ci(t, e) {
  if (t.type !== "transaction" || !e || !e.length)
    return !1;
  const n = t.transaction;
  return n ? ge(n, e) : !1;
}
function Di(t, e) {
  if (!e || !e.length)
    return !1;
  const n = $e(t);
  return n ? ge(n, e) : !1;
}
function $i(t, e) {
  if (!e || !e.length)
    return !0;
  const n = $e(t);
  return n ? ge(n, e) : !0;
}
function Pi(t) {
  const e = [];
  t.message && e.push(t.message);
  let n;
  try {
    n = t.exception.values[t.exception.values.length - 1];
  } catch {
  }
  return n && n.value && (e.push(n.value), n.type && e.push(`${n.type}: ${n.value}`)), m && e.length === 0 && f.error(`Could not extract message for event ${M(t)}`), e;
}
function xi(t) {
  try {
    return t.exception.values[0].type === "SentryError";
  } catch {
  }
  return !1;
}
function Mi(t = []) {
  for (let e = t.length - 1; e >= 0; e--) {
    const n = t[e];
    if (n && n.filename !== "<anonymous>" && n.filename !== "[native code]")
      return n.filename || null;
  }
  return null;
}
function $e(t) {
  try {
    let e;
    try {
      e = t.exception.values[0].stacktrace.frames;
    } catch {
    }
    return e ? Mi(e) : null;
  } catch {
    return m && f.error(`Cannot extract url for event ${M(t)}`), null;
  }
}
let Jt;
const Gn = "FunctionToString", Qt = /* @__PURE__ */ new WeakMap(), Fi = () => ({
  name: Gn,
  setupOnce() {
    Jt = Function.prototype.toString;
    try {
      Function.prototype.toString = function(...t) {
        const e = _t(this), n = Qt.has(R()) && e !== void 0 ? e : this;
        return Jt.apply(n, t);
      };
    } catch {
    }
  },
  setup(t) {
    Qt.set(t, !0);
  }
}), Wn = Fi;
L(
  Gn,
  Wn
);
const y = typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__, _ = I;
function Li() {
  _ && _.document ? _.document.addEventListener("visibilitychange", () => {
    const t = B();
    if (_.document.hidden && t) {
      const e = "cancelled", { op: n, status: r } = D(t);
      y && f.log(`[Tracing] Transaction: ${e} -> since tab moved to the background, op: ${n}`), r || t.setStatus(e), t.setTag("visibilitychange", "document.hidden"), t.end();
    }
  }) : y && f.warn("[Tracing] Could not set up background tab detection due to lack of global document");
}
const He = (t, e, n) => {
  let r, s;
  return (i) => {
    e.value >= 0 && (i || n) && (s = e.value - (r || 0), (s || r === void 0) && (r = e.value, e.delta = s, t(e)));
  };
}, Hi = () => `v3-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`, Ui = () => {
  const t = _.performance.timing, e = _.performance.navigation.type, n = {
    entryType: "navigation",
    startTime: 0,
    type: e == 2 ? "back_forward" : e === 1 ? "reload" : "navigate"
  };
  for (const r in t)
    r !== "navigationStart" && r !== "toJSON" && (n[r] = Math.max(t[r] - t.navigationStart, 0));
  return n;
}, Yn = () => _.__WEB_VITALS_POLYFILL__ ? _.performance && (performance.getEntriesByType && performance.getEntriesByType("navigation")[0] || Ui()) : _.performance && performance.getEntriesByType && performance.getEntriesByType("navigation")[0], zn = () => {
  const t = Yn();
  return t && t.activationStart || 0;
}, Ue = (t, e) => {
  const n = Yn();
  let r = "navigate";
  return n && (_.document.prerendering || zn() > 0 ? r = "prerender" : r = n.type.replace(/_/g, "-")), {
    name: t,
    value: typeof e > "u" ? -1 : e,
    rating: "good",
    // Will be updated if the value changes.
    delta: 0,
    entries: [],
    id: Hi(),
    navigationType: r
  };
}, ae = (t, e, n) => {
  try {
    if (PerformanceObserver.supportedEntryTypes.includes(t)) {
      const r = new PerformanceObserver((s) => {
        e(s.getEntries());
      });
      return r.observe(
        Object.assign(
          {
            type: t,
            buffered: !0
          },
          n || {}
        )
      ), r;
    }
  } catch {
  }
}, _e = (t, e) => {
  const n = (r) => {
    (r.type === "pagehide" || _.document.visibilityState === "hidden") && (t(r), e && (removeEventListener("visibilitychange", n, !0), removeEventListener("pagehide", n, !0)));
  };
  addEventListener("visibilitychange", n, !0), addEventListener("pagehide", n, !0);
}, Bi = (t) => {
  const e = Ue("CLS", 0);
  let n, r = 0, s = [];
  const i = (o) => {
    o.forEach((c) => {
      if (!c.hadRecentInput) {
        const u = s[0], d = s[s.length - 1];
        r && s.length !== 0 && c.startTime - d.startTime < 1e3 && c.startTime - u.startTime < 5e3 ? (r += c.value, s.push(c)) : (r = c.value, s = [c]), r > e.value && (e.value = r, e.entries = s, n && n());
      }
    });
  }, a = ae("layout-shift", i);
  if (a) {
    n = He(t, e);
    const o = () => {
      i(a.takeRecords()), n(!0);
    };
    return _e(o), o;
  }
};
let Ce = -1;
const ji = () => _.document.visibilityState === "hidden" && !_.document.prerendering ? 0 : 1 / 0, qi = () => {
  _e(({ timeStamp: t }) => {
    Ce = t;
  }, !0);
}, St = () => (Ce < 0 && (Ce = ji(), qi()), {
  get firstHiddenTime() {
    return Ce;
  }
}), Gi = (t) => {
  const e = St(), n = Ue("FID");
  let r;
  const s = (o) => {
    o.startTime < e.firstHiddenTime && (n.value = o.processingStart - o.startTime, n.entries.push(o), r(!0));
  }, i = (o) => {
    o.forEach(s);
  }, a = ae("first-input", i);
  r = He(t, n), a && _e(() => {
    i(a.takeRecords()), a.disconnect();
  }, !0);
};
let Xn = 0, Ge = 1 / 0, Re = 0;
const Wi = (t) => {
  t.forEach((e) => {
    e.interactionId && (Ge = Math.min(Ge, e.interactionId), Re = Math.max(Re, e.interactionId), Xn = Re ? (Re - Ge) / 7 + 1 : 0);
  });
};
let ot;
const Yi = () => ot ? Xn : performance.interactionCount || 0, zi = () => {
  "interactionCount" in performance || ot || (ot = ae("event", Wi, {
    type: "event",
    buffered: !0,
    durationThreshold: 0
  }));
}, Vn = () => Yi(), Zt = 10, P = [], We = {}, en = (t) => {
  const e = P[P.length - 1], n = We[t.interactionId];
  if (n || P.length < Zt || t.duration > e.latency) {
    if (n)
      n.entries.push(t), n.latency = Math.max(n.latency, t.duration);
    else {
      const r = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: t.interactionId,
        latency: t.duration,
        entries: [t]
      };
      We[r.id] = r, P.push(r);
    }
    P.sort((r, s) => s.latency - r.latency), P.splice(Zt).forEach((r) => {
      delete We[r.id];
    });
  }
}, Xi = () => {
  const t = Math.min(
    P.length - 1,
    Math.floor(Vn() / 50)
  );
  return P[t];
}, Vi = (t, e) => {
  e = e || {}, zi();
  const n = Ue("INP");
  let r;
  const s = (a) => {
    a.forEach((c) => {
      c.interactionId && en(c), c.entryType === "first-input" && !P.some((d) => d.entries.some((l) => c.duration === l.duration && c.startTime === l.startTime)) && en(c);
    });
    const o = Xi();
    o && o.latency !== n.value && (n.value = o.latency, n.entries = o.entries, r());
  }, i = ae("event", s, {
    // Event Timing entries have their durations rounded to the nearest 8ms,
    // so a duration of 40ms would be any event that spans 2.5 or more frames
    // at 60Hz. This threshold is chosen to strike a balance between usefulness
    // and performance. Running this callback for any interaction that spans
    // just one or two frames is likely not worth the insight that could be
    // gained.
    durationThreshold: e.durationThreshold || 40
  });
  r = He(t, n, e.reportAllChanges), i && (i.observe({ type: "first-input", buffered: !0 }), _e(() => {
    s(i.takeRecords()), n.value < 0 && Vn() > 0 && (n.value = 0, n.entries = []), r(!0);
  }));
}, tn = {}, Ki = (t) => {
  const e = St(), n = Ue("LCP");
  let r;
  const s = (a) => {
    const o = a[a.length - 1];
    if (o) {
      const c = Math.max(o.startTime - zn(), 0);
      c < e.firstHiddenTime && (n.value = c, n.entries = [o], r());
    }
  }, i = ae("largest-contentful-paint", s);
  if (i) {
    r = He(t, n);
    const a = () => {
      tn[n.id] || (s(i.takeRecords()), i.disconnect(), tn[n.id] = !0, r(!0));
    };
    return ["keydown", "click"].forEach((o) => {
      addEventListener(o, a, { once: !0, capture: !0 });
    }), _e(a, !0), a;
  }
}, ue = {}, Pe = {};
let Kn, Jn, Qn, Zn;
function Ji(t, e = !1) {
  return je("cls", t, ta, Kn, e);
}
function Qi(t, e = !1) {
  return je("lcp", t, ra, Qn, e);
}
function Zi(t) {
  return je("fid", t, na, Jn);
}
function ea(t) {
  return je("inp", t, sa, Zn);
}
function Be(t, e) {
  return er(t, e), Pe[t] || (ia(t), Pe[t] = !0), tr(t, e);
}
function Te(t, e) {
  const n = ue[t];
  if (!(!n || !n.length))
    for (const r of n)
      try {
        r(e);
      } catch (s) {
        y && f.error(
          `Error while triggering instrumentation handler.
Type: ${t}
Name: ${F(r)}
Error:`,
          s
        );
      }
}
function ta() {
  return Bi((t) => {
    Te("cls", {
      metric: t
    }), Kn = t;
  });
}
function na() {
  return Gi((t) => {
    Te("fid", {
      metric: t
    }), Jn = t;
  });
}
function ra() {
  return Ki((t) => {
    Te("lcp", {
      metric: t
    }), Qn = t;
  });
}
function sa() {
  return Vi((t) => {
    Te("inp", {
      metric: t
    }), Zn = t;
  });
}
function je(t, e, n, r, s = !1) {
  er(t, e);
  let i;
  return Pe[t] || (i = n(), Pe[t] = !0), r && e({ metric: r }), tr(t, e, s ? i : void 0);
}
function ia(t) {
  const e = {};
  t === "event" && (e.durationThreshold = 0), ae(
    t,
    (n) => {
      Te(t, { entries: n });
    },
    e
  );
}
function er(t, e) {
  ue[t] = ue[t] || [], ue[t].push(e);
}
function tr(t, e, n) {
  return () => {
    n && n();
    const r = ue[t];
    if (!r)
      return;
    const s = r.indexOf(e);
    s !== -1 && r.splice(s, 1);
  };
}
function Ye(t) {
  return typeof t == "number" && isFinite(t);
}
function te(t, { startTimestamp: e, ...n }) {
  return e && t.startTimestamp > e && (t.startTimestamp = e), t.startChild({
    startTimestamp: e,
    ...n
  });
}
const aa = 2147483647;
function S(t) {
  return t / 1e3;
}
function It() {
  return _ && _.addEventListener && _.performance;
}
let nn = 0, b = {}, A, de;
function oa() {
  const t = It();
  if (t && w) {
    t.mark && _.performance.mark("sentry-tracing-init");
    const e = pa(), n = la(), r = fa();
    return () => {
      e(), n(), r();
    };
  }
  return () => {
  };
}
function ca() {
  Be("longtask", ({ entries: t }) => {
    for (const e of t) {
      const n = B();
      if (!n)
        return;
      const r = S(w + e.startTime), s = S(e.duration);
      n.startChild({
        description: "Main UI thread blocked",
        op: "ui.long-task",
        origin: "auto.ui.browser.metrics",
        startTimestamp: r,
        endTimestamp: r + s
      });
    }
  });
}
function ua() {
  Be("event", ({ entries: t }) => {
    for (const e of t) {
      const n = B();
      if (!n)
        return;
      if (e.name === "click") {
        const r = S(w + e.startTime), s = S(e.duration), i = {
          description: he(e.target),
          op: `ui.interaction.${e.name}`,
          origin: "auto.ui.browser.metrics",
          startTimestamp: r,
          endTimestamp: r + s
        }, a = _n(e.target);
        a && (i.attributes = { "ui.component_name": a }), n.startChild(i);
      }
    }
  });
}
function da(t) {
  if (It() && w) {
    const n = ha(t);
    return () => {
      n();
    };
  }
  return () => {
  };
}
function la() {
  return Ji(({ metric: t }) => {
    const e = t.entries[t.entries.length - 1];
    e && (y && f.log("[Measurements] Adding CLS"), b.cls = { value: t.value, unit: "" }, de = e);
  }, !0);
}
function fa() {
  return Qi(({ metric: t }) => {
    const e = t.entries[t.entries.length - 1];
    e && (y && f.log("[Measurements] Adding LCP"), b.lcp = { value: t.value, unit: "millisecond" }, A = e);
  }, !0);
}
function pa() {
  return Zi(({ metric: t }) => {
    const e = t.entries[t.entries.length - 1];
    if (!e)
      return;
    const n = S(w), r = S(e.startTime);
    y && f.log("[Measurements] Adding FID"), b.fid = { value: t.value, unit: "millisecond" }, b["mark.fid"] = { value: n + r, unit: "second" };
  });
}
function ha(t) {
  return ea(({ metric: e }) => {
    const n = e.entries.find((k) => k.name === "click" || k.name === "pointerdown"), r = R();
    if (!n || !r)
      return;
    const s = r.getOptions(), i = S(w + n.startTime), a = S(e.value), { routeName: o, parentContext: c, activeTransaction: u, user: d, replayId: l } = n.interactionId !== void 0 ? t[n.interactionId] : {
      routeName: void 0,
      parentContext: void 0,
      activeTransaction: void 0,
      user: void 0,
      replayId: void 0
    }, p = d !== void 0 ? d.email || d.id || d.ip_address : void 0, h = u !== void 0 ? u.getProfileId() : void 0, g = new Le({
      startTimestamp: i,
      endTimestamp: i + a,
      op: "ui.interaction.click",
      name: he(n.target),
      attributes: {
        release: s.release,
        environment: s.environment,
        transaction: o,
        ...p !== void 0 && p !== "" ? { user: p } : {},
        ...h !== void 0 ? { profile_id: h } : {},
        ...l !== void 0 ? { replay_id: l } : {}
      },
      exclusiveTime: e.value,
      measurements: {
        inp: { value: e.value, unit: "millisecond" }
      }
    }), E = Ia(c, s);
    if (E && Math.random() < E) {
      const k = g ? Ii([g], r.getDsn()) : void 0, $ = r && r.getTransport();
      $ && k && $.send(k).then(null, (W) => {
        y && f.error("Error while sending interaction:", W);
      });
      return;
    }
  });
}
function ma(t) {
  const e = It();
  if (!e || !_.performance.getEntries || !w)
    return;
  y && f.log("[Tracing] Adding & adjusting spans using Performance API");
  const n = S(w), r = e.getEntries();
  let s, i;
  const { op: a, start_timestamp: o } = D(t);
  if (r.slice(nn).forEach((c) => {
    const u = S(c.startTime), d = S(c.duration);
    if (!(t.op === "navigation" && o && n + u < o))
      switch (c.entryType) {
        case "navigation": {
          _a(t, c, n), s = n + S(c.responseStart), i = n + S(c.requestStart);
          break;
        }
        case "mark":
        case "paint":
        case "measure": {
          ga(t, c, u, d, n);
          const l = St(), p = c.startTime < l.firstHiddenTime;
          c.name === "first-paint" && p && (y && f.log("[Measurements] Adding FP"), b.fp = { value: c.startTime, unit: "millisecond" }), c.name === "first-contentful-paint" && p && (y && f.log("[Measurements] Adding FCP"), b.fcp = { value: c.startTime, unit: "millisecond" });
          break;
        }
        case "resource": {
          ya(t, c, c.name, u, d, n);
          break;
        }
      }
  }), nn = Math.max(r.length - 1, 0), Ea(t), a === "pageload") {
    Sa(b, s, i, o), ["fcp", "fp", "lcp"].forEach((u) => {
      if (!b[u] || !o || n >= o)
        return;
      const d = b[u].value, l = n + S(d), p = Math.abs((l - o) * 1e3), h = p - d;
      y && f.log(`[Measurements] Normalized ${u} from ${d} to ${p} (${h})`), b[u].value = p;
    });
    const c = b["mark.fid"];
    c && b.fid && (te(t, {
      description: "first input delay",
      endTimestamp: c.value + S(b.fid.value),
      op: "ui.action",
      origin: "auto.ui.browser.metrics",
      startTimestamp: c.value
    }), delete b["mark.fid"]), "fcp" in b || delete b.cls, Object.keys(b).forEach((u) => {
      ei(u, b[u].value, b[u].unit);
    }), ba(t);
  }
  A = void 0, de = void 0, b = {};
}
function ga(t, e, n, r, s) {
  const i = s + n, a = i + r;
  return te(t, {
    description: e.name,
    endTimestamp: a,
    op: e.entryType,
    origin: "auto.resource.browser.metrics",
    startTimestamp: i
  }), i;
}
function _a(t, e, n) {
  ["unloadEvent", "redirect", "domContentLoadedEvent", "loadEvent", "connect"].forEach((r) => {
    ke(t, e, r, n);
  }), ke(t, e, "secureConnection", n, "TLS/SSL", "connectEnd"), ke(t, e, "fetch", n, "cache", "domainLookupStart"), ke(t, e, "domainLookup", n, "DNS"), Ta(t, e, n);
}
function ke(t, e, n, r, s, i) {
  const a = i ? e[i] : e[`${n}End`], o = e[`${n}Start`];
  !o || !a || te(t, {
    op: "browser",
    origin: "auto.browser.browser.metrics",
    description: s || n,
    startTimestamp: r + S(o),
    endTimestamp: r + S(a)
  });
}
function Ta(t, e, n) {
  e.responseEnd && (te(t, {
    op: "browser",
    origin: "auto.browser.browser.metrics",
    description: "request",
    startTimestamp: n + S(e.requestStart),
    endTimestamp: n + S(e.responseEnd)
  }), te(t, {
    op: "browser",
    origin: "auto.browser.browser.metrics",
    description: "response",
    startTimestamp: n + S(e.responseStart),
    endTimestamp: n + S(e.responseEnd)
  }));
}
function ya(t, e, n, r, s, i) {
  if (e.initiatorType === "xmlhttprequest" || e.initiatorType === "fetch")
    return;
  const a = Oe(n), o = {};
  ze(o, e, "transferSize", "http.response_transfer_size"), ze(o, e, "encodedBodySize", "http.response_content_length"), ze(o, e, "decodedBodySize", "http.decoded_response_content_length"), "renderBlockingStatus" in e && (o["resource.render_blocking_status"] = e.renderBlockingStatus), a.protocol && (o["url.scheme"] = a.protocol.split(":").pop()), a.host && (o["server.address"] = a.host), o["url.same_origin"] = n.includes(_.location.origin);
  const c = i + r, u = c + s;
  te(t, {
    description: n.replace(_.location.origin, ""),
    endTimestamp: u,
    op: e.initiatorType ? `resource.${e.initiatorType}` : "resource.other",
    origin: "auto.resource.browser.metrics",
    startTimestamp: c,
    data: o
  });
}
function Ea(t) {
  const e = _.navigator;
  if (!e)
    return;
  const n = e.connection;
  n && (n.effectiveType && t.setTag("effectiveConnectionType", n.effectiveType), n.type && t.setTag("connectionType", n.type), Ye(n.rtt) && (b["connection.rtt"] = { value: n.rtt, unit: "millisecond" })), Ye(e.deviceMemory) && t.setTag("deviceMemory", `${e.deviceMemory} GB`), Ye(e.hardwareConcurrency) && t.setTag("hardwareConcurrency", String(e.hardwareConcurrency));
}
function ba(t) {
  A && (y && f.log("[Measurements] Adding LCP Data"), A.element && t.setTag("lcp.element", he(A.element)), A.id && t.setTag("lcp.id", A.id), A.url && t.setTag("lcp.url", A.url.trim().slice(0, 200)), t.setTag("lcp.size", A.size)), de && de.sources && (y && f.log("[Measurements] Adding CLS Data"), de.sources.forEach(
    (e, n) => (
      // TODO: Can we rewrite this to an attribute?
      // eslint-disable-next-line deprecation/deprecation
      t.setTag(`cls.source.${n + 1}`, he(e.node))
    )
  ));
}
function ze(t, e, n, r) {
  const s = e[n];
  s != null && s < aa && (t[r] = s);
}
function Sa(t, e, n, r) {
  typeof e == "number" && r && (y && f.log("[Measurements] Adding TTFB"), t.ttfb = {
    // As per https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming/responseStart,
    // responseStart can be 0 if the request is coming straight from the cache.
    // This might lead us to calculate a negative ttfb if we don't use Math.max here.
    //
    // This logic is the same as what is in the web-vitals library to calculate ttfb
    // https://github.com/GoogleChrome/web-vitals/blob/2301de5015e82b09925238a228a0893635854587/src/onTTFB.ts#L92
    // TODO(abhi): We should use the web-vitals library instead of this custom calculation.
    value: Math.max(e - r, 0) * 1e3,
    unit: "millisecond"
  }, typeof n == "number" && n <= e && (t["ttfb.requestTime"] = {
    value: (e - n) * 1e3,
    unit: "millisecond"
  }));
}
function Ia(t, e) {
  if (!ie(e))
    return !1;
  let n;
  return t !== void 0 && typeof e.tracesSampler == "function" ? n = e.tracesSampler({
    transactionContext: t,
    name: t.name,
    parentSampled: t.parentSampled,
    attributes: {
      // eslint-disable-next-line deprecation/deprecation
      ...t.data,
      ...t.attributes
    },
    location: _.location
  }) : t !== void 0 && t.sampled !== void 0 ? n = t.sampled : typeof e.tracesSampleRate < "u" ? n = e.tracesSampleRate : n = 1, xn(n) ? n : (y && f.warn("[Tracing] Discarding transaction because of invalid sample rate."), !1);
}
function va(t, e, n, r, s = "auto.http.browser") {
  if (!ie() || !t.fetchData)
    return;
  const i = e(t.fetchData.url);
  if (t.endTimestamp && i) {
    const l = t.fetchData.__span;
    if (!l) return;
    const p = r[l];
    if (p) {
      if (t.response) {
        bt(p, t.response.status);
        const h = t.response && t.response.headers && t.response.headers.get("content-length");
        if (h) {
          const g = parseInt(h);
          g > 0 && p.setAttribute("http.response_content_length", g);
        }
      } else t.error && p.setStatus("internal_error");
      p.end(), delete r[l];
    }
    return;
  }
  const a = re(), o = R(), { method: c, url: u } = t.fetchData, d = i ? On({
    name: `${c} ${u}`,
    onlyIfParent: !0,
    attributes: {
      url: u,
      type: "fetch",
      "http.method": c,
      [K]: s
    },
    op: "http.client"
  }) : void 0;
  if (d && (t.fetchData.__span = d.spanContext().spanId, r[d.spanContext().spanId] = d), n(t.fetchData.url) && o) {
    const l = t.args[0];
    t.args[1] = t.args[1] || {};
    const p = t.args[1];
    p.headers = Ra(l, o, a, p, d);
  }
  return d;
}
function Ra(t, e, n, r, s) {
  const i = s || n.getSpan(), a = xe(), { traceId: o, spanId: c, sampled: u, dsc: d } = {
    ...a.getPropagationContext(),
    ...n.getPropagationContext()
  }, l = i ? Fe(i) : Tn(o, c, u), p = yn(
    d || (i ? pe(i) : gt(o, e, n))
  ), h = r.headers || (typeof Request < "u" && le(t, Request) ? t.headers : void 0);
  if (h)
    if (typeof Headers < "u" && le(h, Headers)) {
      const g = new Headers(h);
      return g.append("sentry-trace", l), p && g.append(Je, p), g;
    } else if (Array.isArray(h)) {
      const g = [...h, ["sentry-trace", l]];
      return p && g.push([Je, p]), g;
    } else {
      const g = "baggage" in h ? h.baggage : void 0, E = [];
      return Array.isArray(g) ? E.push(...g) : g && E.push(g), p && E.push(p), {
        ...h,
        "sentry-trace": l,
        baggage: E.length > 0 ? E.join(",") : void 0
      };
    }
  else return { "sentry-trace": l, baggage: p };
}
const ct = ["localhost", /^\/(?!\/)/], ut = {
  traceFetch: !0,
  traceXHR: !0,
  enableHTTPTimings: !0,
  // TODO (v8): Remove this property
  tracingOrigins: ct,
  tracePropagationTargets: ct
};
function ka(t) {
  const {
    traceFetch: e,
    traceXHR: n,
    // eslint-disable-next-line deprecation/deprecation
    tracePropagationTargets: r,
    // eslint-disable-next-line deprecation/deprecation
    tracingOrigins: s,
    shouldCreateSpanForRequest: i,
    enableHTTPTimings: a
  } = {
    traceFetch: ut.traceFetch,
    traceXHR: ut.traceXHR,
    ...t
  }, o = typeof i == "function" ? i : (d) => !0, c = (d) => Aa(d, r || s), u = {};
  e && vn((d) => {
    const l = va(d, o, c, u);
    a && l && rn(l);
  }), n && wn((d) => {
    const l = Ca(d, o, c, u);
    a && l && rn(l);
  });
}
function wa(t) {
  return t.entryType === "resource" && "initiatorType" in t && typeof t.nextHopProtocol == "string" && (t.initiatorType === "fetch" || t.initiatorType === "xmlhttprequest");
}
function rn(t) {
  const { url: e } = D(t).data || {};
  if (!e || typeof e != "string")
    return;
  const n = Be("resource", ({ entries: r }) => {
    r.forEach((s) => {
      wa(s) && s.name.endsWith(e) && (Oa(s).forEach((a) => t.setAttribute(...a)), setTimeout(n));
    });
  });
}
function Na(t) {
  let e = "unknown", n = "unknown", r = "";
  for (const s of t) {
    if (s === "/") {
      [e, n] = t.split("/");
      break;
    }
    if (!isNaN(Number(s))) {
      e = r === "h" ? "http" : r, n = t.split(r)[1];
      break;
    }
    r += s;
  }
  return r === t && (e = r), { name: e, version: n };
}
function O(t = 0) {
  return ((w || performance.timeOrigin) + t) / 1e3;
}
function Oa(t) {
  const { name: e, version: n } = Na(t.nextHopProtocol), r = [];
  return r.push(["network.protocol.version", n], ["network.protocol.name", e]), w ? [
    ...r,
    ["http.request.redirect_start", O(t.redirectStart)],
    ["http.request.fetch_start", O(t.fetchStart)],
    ["http.request.domain_lookup_start", O(t.domainLookupStart)],
    ["http.request.domain_lookup_end", O(t.domainLookupEnd)],
    ["http.request.connect_start", O(t.connectStart)],
    ["http.request.secure_connection_start", O(t.secureConnectionStart)],
    ["http.request.connection_end", O(t.connectEnd)],
    ["http.request.request_start", O(t.requestStart)],
    ["http.request.response_start", O(t.responseStart)],
    ["http.request.response_end", O(t.responseEnd)]
  ] : r;
}
function Aa(t, e) {
  return ge(t, e || ct);
}
function Ca(t, e, n, r) {
  const s = t.xhr, i = s && s[X];
  if (!ie() || !s || s.__sentry_own_request__ || !i)
    return;
  const a = e(i.url);
  if (t.endTimestamp && a) {
    const l = s.__sentry_xhr_span_id__;
    if (!l) return;
    const p = r[l];
    p && i.status_code !== void 0 && (bt(p, i.status_code), p.end(), delete r[l]);
    return;
  }
  const o = re(), c = xe(), u = a ? On({
    name: `${i.method} ${i.url}`,
    onlyIfParent: !0,
    attributes: {
      type: "xhr",
      "http.method": i.method,
      url: i.url,
      [K]: "auto.http.browser"
    },
    op: "http.client"
  }) : void 0;
  u && (s.__sentry_xhr_span_id__ = u.spanContext().spanId, r[s.__sentry_xhr_span_id__] = u);
  const d = R();
  if (s.setRequestHeader && n(i.url) && d) {
    const { traceId: l, spanId: p, sampled: h, dsc: g } = {
      ...c.getPropagationContext(),
      ...o.getPropagationContext()
    }, E = u ? Fe(u) : Tn(l, p, h), k = yn(
      g || (u ? pe(u) : gt(l, d, o))
    );
    Da(s, E, k);
  }
  return u;
}
function Da(t, e, n) {
  try {
    t.setRequestHeader("sentry-trace", e), n && t.setRequestHeader(Je, n);
  } catch {
  }
}
function $a(t, e = !0, n = !0) {
  if (!_ || !_.location) {
    y && f.warn("Could not initialize routing instrumentation due to invalid location");
    return;
  }
  let r = _.location.href, s;
  e && (s = t({
    name: _.location.pathname,
    // pageload should always start at timeOrigin (and needs to be in s, not ms)
    startTimestamp: w ? w / 1e3 : void 0,
    op: "pageload",
    origin: "auto.pageload.browser",
    metadata: { source: "url" }
  })), n && Et(({ to: i, from: a }) => {
    if (a === void 0 && r && r.indexOf(i) !== -1) {
      r = void 0;
      return;
    }
    a !== i && (r = void 0, s && (y && f.log(`[Tracing] Finishing current transaction with op: ${s.op}`), s.end()), s = t({
      name: _.location.pathname,
      op: "navigation",
      origin: "auto.navigation.browser",
      metadata: { source: "url" }
    }));
  });
}
const Pa = "BrowserTracing", xa = {
  ...Ae,
  markBackgroundTransactions: !0,
  routingInstrumentation: $a,
  startTransactionOnLocationChange: !0,
  startTransactionOnPageLoad: !0,
  enableLongTask: !0,
  enableInp: !1,
  _experiments: {},
  ...ut
}, Ma = 10;
let Fa = class {
  // This class currently doesn't have a static `id` field like the other integration classes, because it prevented
  // @sentry/tracing from being treeshaken. Tree shakers do not like static fields, because they behave like side effects.
  // TODO: Come up with a better plan, than using static fields on integration classes, and use that plan on all
  // integrations.
  /** Browser Tracing integration options */
  /**
   * @inheritDoc
   */
  constructor(e) {
    this.name = Pa, this._hasSetTracePropagationTargets = !1, Mn(), y && (this._hasSetTracePropagationTargets = !!(e && // eslint-disable-next-line deprecation/deprecation
    (e.tracePropagationTargets || e.tracingOrigins))), this.options = {
      ...xa,
      ...e
    }, this.options._experiments.enableLongTask !== void 0 && (this.options.enableLongTask = this.options._experiments.enableLongTask), e && !e.tracePropagationTargets && e.tracingOrigins && (this.options.tracePropagationTargets = e.tracingOrigins), this._collectWebVitals = oa(), this._interactionIdtoRouteNameMapping = {}, this.options.enableInp && da(this._interactionIdtoRouteNameMapping), this.options.enableLongTask && ca(), this.options._experiments.enableInteractions && ua(), this._latestRoute = {
      name: void 0,
      context: void 0
    };
  }
  /**
   * @inheritDoc
   */
  setupOnce(e, n) {
    this._getCurrentHub = n;
    const s = n().getClient(), i = s && s.getOptions(), {
      routingInstrumentation: a,
      startTransactionOnLocationChange: o,
      startTransactionOnPageLoad: c,
      markBackgroundTransactions: u,
      traceFetch: d,
      traceXHR: l,
      shouldCreateSpanForRequest: p,
      enableHTTPTimings: h,
      _experiments: g
    } = this.options, E = i && i.tracePropagationTargets, k = E || this.options.tracePropagationTargets;
    y && this._hasSetTracePropagationTargets && E && f.warn(
      "[Tracing] The `tracePropagationTargets` option was set in the BrowserTracing integration and top level `Sentry.init`. The top level `Sentry.init` value is being used."
    ), a(
      ($) => {
        const W = this._createRouteTransaction($);
        return this.options._experiments.onStartRouteTransaction && this.options._experiments.onStartRouteTransaction(W, $, n), W;
      },
      c,
      o
    ), u && Li(), g.enableInteractions && this._registerInteractionListener(), this.options.enableInp && this._registerInpInteractionListener(), ka({
      traceFetch: d,
      traceXHR: l,
      tracePropagationTargets: k,
      shouldCreateSpanForRequest: p,
      enableHTTPTimings: h
    });
  }
  /** Create routing idle transaction. */
  _createRouteTransaction(e) {
    if (!this._getCurrentHub) {
      y && f.warn(`[Tracing] Did not create ${e.op} transaction because _getCurrentHub is invalid.`);
      return;
    }
    const n = this._getCurrentHub(), { beforeNavigate: r, idleTimeout: s, finalTimeout: i, heartbeatInterval: a } = this.options, o = e.op === "pageload";
    let c;
    if (o) {
      const h = o ? sn("sentry-trace") : "", g = o ? sn("baggage") : void 0, { traceId: E, dsc: k, parentSpanId: $, sampled: W } = Pr(h, g);
      c = {
        traceId: E,
        parentSpanId: $,
        parentSampled: W,
        ...e,
        metadata: {
          // eslint-disable-next-line deprecation/deprecation
          ...e.metadata,
          dynamicSamplingContext: k
        },
        trimEnd: !0
      };
    } else
      c = {
        trimEnd: !0,
        ...e
      };
    const u = typeof r == "function" ? r(c) : c, d = u === void 0 ? { ...c, sampled: !1 } : u;
    d.metadata = d.name !== c.name ? (
      // eslint-disable-next-line deprecation/deprecation
      { ...d.metadata, source: "custom" }
    ) : (
      // eslint-disable-next-line deprecation/deprecation
      d.metadata
    ), this._latestRoute.name = d.name, this._latestRoute.context = d, d.sampled === !1 && y && f.log(`[Tracing] Will not send ${d.op} transaction because of beforeNavigate.`), y && f.log(`[Tracing] Starting ${d.op} transaction on scope`);
    const { location: l } = _, p = Yt(
      n,
      d,
      s,
      i,
      !0,
      { location: l },
      // for use in the tracesSampler
      a,
      o
      // should wait for finish signal if it's a pageload transaction
    );
    return o && (_.document.addEventListener("readystatechange", () => {
      ["interactive", "complete"].includes(_.document.readyState) && p.sendAutoFinishSignal();
    }), ["interactive", "complete"].includes(_.document.readyState) && p.sendAutoFinishSignal()), p.registerBeforeFinishCallback((h) => {
      this._collectWebVitals(), ma(h);
    }), p;
  }
  /** Start listener for interaction transactions */
  _registerInteractionListener() {
    let e;
    const n = () => {
      const { idleTimeout: r, finalTimeout: s, heartbeatInterval: i } = this.options, a = "ui.action.click", o = B();
      if (o && o.op && ["navigation", "pageload"].includes(o.op)) {
        y && f.warn(
          `[Tracing] Did not create ${a} transaction because a pageload or navigation transaction is in progress.`
        );
        return;
      }
      if (e && (e.setFinishReason("interactionInterrupted"), e.end(), e = void 0), !this._getCurrentHub) {
        y && f.warn(`[Tracing] Did not create ${a} transaction because _getCurrentHub is invalid.`);
        return;
      }
      if (!this._latestRoute.name) {
        y && f.warn(`[Tracing] Did not create ${a} transaction because _latestRouteName is missing.`);
        return;
      }
      const c = this._getCurrentHub(), { location: u } = _, d = {
        name: this._latestRoute.name,
        op: a,
        trimEnd: !0,
        data: {
          [Q]: this._latestRoute.context ? La(this._latestRoute.context) : "url"
        }
      };
      e = Yt(
        c,
        d,
        r,
        s,
        !0,
        { location: u },
        // for use in the tracesSampler
        i
      );
    };
    ["click"].forEach((r) => {
      addEventListener(r, n, { once: !1, capture: !0 });
    });
  }
  /** Creates a listener on interaction entries, and maps interactionIds to the origin path of the interaction */
  _registerInpInteractionListener() {
    Be("event", ({ entries: e }) => {
      const n = R(), r = n !== void 0 && n.getIntegrationByName !== void 0 ? n.getIntegrationByName("Replay") : void 0, s = r !== void 0 ? r.getReplayId() : void 0, i = B(), a = re(), o = a !== void 0 ? a.getUser() : void 0;
      for (const c of e)
        if (Ha(c)) {
          const u = c.duration, d = Object.keys(this._interactionIdtoRouteNameMapping), l = d.length > 0 ? d.reduce((p, h) => this._interactionIdtoRouteNameMapping[p].duration < this._interactionIdtoRouteNameMapping[h].duration ? p : h) : void 0;
          if (l === void 0 || u > this._interactionIdtoRouteNameMapping[l].duration) {
            const p = c.interactionId, h = this._latestRoute.name, g = this._latestRoute.context;
            p && h && g && (l && Object.keys(this._interactionIdtoRouteNameMapping).length >= Ma && delete this._interactionIdtoRouteNameMapping[l], this._interactionIdtoRouteNameMapping[p] = {
              routeName: h,
              duration: u,
              parentContext: g,
              user: o,
              activeTransaction: i,
              replayId: s
            });
          }
        }
    });
  }
};
function sn(t) {
  const e = xr(`meta[name=${t}]`);
  return e ? e.getAttribute("content") : void 0;
}
function La(t) {
  const e = t.attributes && t.attributes[Q], n = t.data && t.data[Q], r = t.metadata && t.metadata.source;
  return e || n || r;
}
function Ha(t) {
  return "duration" in t;
}
function Ua() {
  const t = mn();
  if (!t.__SENTRY__)
    return;
  const e = {
    mongodb() {
      const r = H(module, "./node/integrations/mongo");
      return new r.Mongo();
    },
    mongoose() {
      const r = H(module, "./node/integrations/mongo");
      return new r.Mongo();
    },
    mysql() {
      const r = H(module, "./node/integrations/mysql");
      return new r.Mysql();
    },
    pg() {
      const r = H(module, "./node/integrations/postgres");
      return new r.Postgres();
    }
  }, n = Object.keys(e).filter((r) => !!Rs(r)).map((r) => {
    try {
      return e[r]();
    } catch {
      return;
    }
  }).filter((r) => r);
  n.length > 0 && (t.__SENTRY__.integrations = [...t.__SENTRY__.integrations || [], ...n]);
}
function Ba() {
  Mn(), vs() && Ua();
}
const T = I;
let dt = 0;
function nr() {
  return dt > 0;
}
function ja() {
  dt++, setTimeout(() => {
    dt--;
  });
}
function ne(t, e = {}, n) {
  if (typeof t != "function")
    return t;
  try {
    const s = t.__sentry_wrapped__;
    if (s)
      return s;
    if (_t(t))
      return t;
  } catch {
    return t;
  }
  const r = function() {
    const s = Array.prototype.slice.call(arguments);
    try {
      const i = s.map((a) => ne(a, e));
      return t.apply(this, i);
    } catch (i) {
      throw ja(), Fr((a) => {
        a.addEventProcessor((o) => (e.mechanism && (Qe(o, void 0), De(o, e.mechanism)), o.extra = {
          ...o.extra,
          arguments: s
        }, o)), En(i);
      }), i;
    }
  };
  try {
    for (const s in t)
      Object.prototype.hasOwnProperty.call(t, s) && (r[s] = t[s]);
  } catch {
  }
  Mr(r, t), fe(t, "__sentry_wrapped__", r);
  try {
    Object.getOwnPropertyDescriptor(r, "name").configurable && Object.defineProperty(r, "name", {
      get() {
        return t.name;
      }
    });
  } catch {
  }
  return r;
}
const x = typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__;
function rr(t, e) {
  const n = vt(t, e), r = {
    type: e && e.name,
    value: Ya(e)
  };
  return n.length && (r.stacktrace = { frames: n }), r.type === void 0 && r.value === "" && (r.value = "Unrecoverable error caught"), r;
}
function qa(t, e, n, r) {
  const s = R(), i = s && s.getOptions().normalizeDepth, a = {
    exception: {
      values: [
        {
          type: yt(e) ? e.constructor.name : r ? "UnhandledRejection" : "Error",
          value: Va(e, { isUnhandledRejection: r })
        }
      ]
    },
    extra: {
      __serialized__: Ur(e, i)
    }
  };
  if (n) {
    const o = vt(t, n);
    o.length && (a.exception.values[0].stacktrace = { frames: o });
  }
  return a;
}
function Xe(t, e) {
  return {
    exception: {
      values: [rr(t, e)]
    }
  };
}
function vt(t, e) {
  const n = e.stacktrace || e.stack || "", r = Wa(e);
  try {
    return t(n, r);
  } catch {
  }
  return [];
}
const Ga = /Minified React error #\d+;/i;
function Wa(t) {
  if (t) {
    if (typeof t.framesToPop == "number")
      return t.framesToPop;
    if (Ga.test(t.message))
      return 1;
  }
  return 0;
}
function Ya(t) {
  const e = t && t.message;
  return e ? e.error && typeof e.error.message == "string" ? e.error.message : e : "No error message";
}
function za(t, e, n, r) {
  const s = n && n.syntheticException || void 0, i = Rt(t, e, s, r);
  return De(i), i.level = "error", n && n.event_id && (i.event_id = n.event_id), Z(i);
}
function Xa(t, e, n = "info", r, s) {
  const i = r && r.syntheticException || void 0, a = lt(t, e, i, s);
  return a.level = n, r && r.event_id && (a.event_id = r.event_id), Z(a);
}
function Rt(t, e, n, r, s) {
  let i;
  if (Tt(e) && e.error)
    return Xe(t, e.error);
  if (Dt(e) || Lr(e)) {
    const a = e;
    if ("stack" in e)
      i = Xe(t, e);
    else {
      const o = a.name || (Dt(a) ? "DOMError" : "DOMException"), c = a.message ? `${o}: ${a.message}` : o;
      i = lt(t, c, n, r), Qe(i, c);
    }
    return "code" in a && (i.tags = { ...i.tags, "DOMException.code": `${a.code}` }), i;
  }
  return Hr(e) ? Xe(t, e) : Ke(e) || yt(e) ? (i = qa(t, e, n, s), De(i, {
    synthetic: !0
  }), i) : (i = lt(t, e, n, r), Qe(i, `${e}`), De(i, {
    synthetic: !0
  }), i);
}
function lt(t, e, n, r) {
  const s = {};
  if (r && n) {
    const i = vt(t, n);
    i.length && (s.exception = {
      values: [{ value: e, stacktrace: { frames: i } }]
    });
  }
  if (gn(e)) {
    const { __sentry_template_string__: i, __sentry_template_values__: a } = e;
    return s.logentry = {
      message: i,
      params: a
    }, s;
  }
  return s.message = e, s;
}
function Va(t, { isUnhandledRejection: e }) {
  const n = Br(t), r = e ? "promise rejection" : "exception";
  return Tt(t) ? `Event \`ErrorEvent\` captured as ${r} with message \`${t.message}\`` : yt(t) ? `Event \`${Ka(t)}\` (type=${t.type}) captured as ${r}` : `Object captured as ${r} with keys: ${n}`;
}
function Ka(t) {
  try {
    const e = Object.getPrototypeOf(t);
    return e ? e.constructor.name : void 0;
  } catch {
  }
}
function Ja(t, {
  metadata: e,
  tunnel: n,
  dsn: r
}) {
  const s = {
    event_id: t.event_id,
    sent_at: (/* @__PURE__ */ new Date()).toISOString(),
    ...e && e.sdk && {
      sdk: {
        name: e.sdk.name,
        version: e.sdk.version
      }
    },
    ...!!n && !!r && { dsn: se(r) }
  }, i = Qa(t);
  return G(s, [i]);
}
function Qa(t) {
  return [{
    type: "user_report"
  }, t];
}
class Za extends gi {
  /**
   * Creates a new Browser SDK instance.
   *
   * @param options Configuration options for this SDK.
   */
  constructor(e) {
    const n = T.SENTRY_SDK_SOURCE || Is();
    Bn(e, "browser", ["browser"], n), super(e), e.sendClientReports && T.document && T.document.addEventListener("visibilitychange", () => {
      T.document.visibilityState === "hidden" && this._flushOutcomes();
    });
  }
  /**
   * @inheritDoc
   */
  eventFromException(e, n) {
    return za(this._options.stackParser, e, n, this._options.attachStacktrace);
  }
  /**
   * @inheritDoc
   */
  eventFromMessage(e, n = "info", r) {
    return Xa(this._options.stackParser, e, n, r, this._options.attachStacktrace);
  }
  /**
   * Sends user feedback to Sentry.
   */
  captureUserFeedback(e) {
    if (!this._isEnabled()) {
      x && f.warn("SDK not enabled, will not capture user feedback.");
      return;
    }
    const n = Ja(e, {
      metadata: this.getSdkMetadata(),
      dsn: this.getDsn(),
      tunnel: this.getOptions().tunnel
    });
    this._sendEnvelope(n);
  }
  /**
   * @inheritDoc
   */
  _prepareEvent(e, n, r) {
    return e.platform = e.platform || "javascript", super._prepareEvent(e, n, r);
  }
  /**
   * Sends client reports as an envelope.
   */
  _flushOutcomes() {
    const e = this._clearOutcomes();
    if (e.length === 0) {
      x && f.log("No outcomes to send");
      return;
    }
    if (!this._dsn) {
      x && f.log("No dsn provided, will not send outcomes");
      return;
    }
    x && f.log("Sending outcomes:", e);
    const n = xs(e, this._options.tunnel && se(this._dsn));
    this._sendEnvelope(n);
  }
}
let ce;
function eo() {
  if (ce)
    return ce;
  if (rt(T.fetch))
    return ce = T.fetch.bind(T);
  const t = T.document;
  let e = T.fetch;
  if (t && typeof t.createElement == "function")
    try {
      const n = t.createElement("iframe");
      n.hidden = !0, t.head.appendChild(n);
      const r = n.contentWindow;
      r && r.fetch && (e = r.fetch), t.head.removeChild(n);
    } catch (n) {
      x && f.warn("Could not create sandbox iframe for pure fetch check, bailing to window.fetch: ", n);
    }
  return ce = e.bind(T);
}
function to() {
  ce = void 0;
}
function no(t, e = eo()) {
  let n = 0, r = 0;
  function s(i) {
    const a = i.body.length;
    n += a, r++;
    const o = {
      body: i.body,
      method: "POST",
      referrerPolicy: "origin",
      headers: t.headers,
      // Outgoing requests are usually cancelled when navigating to a different page, causing a "TypeError: Failed to
      // fetch" error and sending a "network_error" client-outcome - in Chrome, the request status shows "(cancelled)".
      // The `keepalive` flag keeps outgoing requests alive, even when switching pages. We want this since we're
      // frequently sending events right before the user is switching pages (eg. whenfinishing navigation transactions).
      // Gotchas:
      // - `keepalive` isn't supported by Firefox
      // - As per spec (https://fetch.spec.whatwg.org/#http-network-or-cache-fetch):
      //   If the sum of contentLength and inflightKeepaliveBytes is greater than 64 kibibytes, then return a network error.
      //   We will therefore only activate the flag when we're below that limit.
      // There is also a limit of requests that can be open at the same time, so we also limit this to 15
      // See https://github.com/getsentry/sentry-javascript/pull/7553 for details
      keepalive: n <= 6e4 && r < 15,
      ...t.fetchOptions
    };
    try {
      return e(t.url, o).then((c) => (n -= a, r--, {
        statusCode: c.status,
        headers: {
          "x-sentry-rate-limits": c.headers.get("X-Sentry-Rate-Limits"),
          "retry-after": c.headers.get("Retry-After")
        }
      }));
    } catch (c) {
      return to(), n -= a, r--, pt(c);
    }
  }
  return Un(t, s);
}
const ro = 4;
function so(t) {
  function e(n) {
    return new ht((r, s) => {
      const i = new XMLHttpRequest();
      i.onerror = s, i.onreadystatechange = () => {
        i.readyState === ro && r({
          statusCode: i.status,
          headers: {
            "x-sentry-rate-limits": i.getResponseHeader("X-Sentry-Rate-Limits"),
            "retry-after": i.getResponseHeader("Retry-After")
          }
        });
      }, i.open("POST", t.url);
      for (const a in t.headers)
        Object.prototype.hasOwnProperty.call(t.headers, a) && i.setRequestHeader(a, t.headers[a]);
      i.send(n.body);
    });
  }
  return Un(t, e);
}
const qe = "?", io = 30, ao = 40, oo = 50;
function kt(t, e, n, r) {
  const s = {
    filename: t,
    function: e,
    in_app: !0
    // All browser frames are considered in_app
  };
  return n !== void 0 && (s.lineno = n), r !== void 0 && (s.colno = r), s;
}
const co = /^\s*at (?:(.+?\)(?: \[.+\])?|.*?) ?\((?:address at )?)?(?:async )?((?:<anonymous>|[-a-z]+:|.*bundle|\/)?.*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i, uo = /\((\S*)(?::(\d+))(?::(\d+))\)/, lo = (t) => {
  const e = co.exec(t);
  if (e) {
    if (e[2] && e[2].indexOf("eval") === 0) {
      const i = uo.exec(e[2]);
      i && (e[2] = i[1], e[3] = i[2], e[4] = i[3]);
    }
    const [r, s] = sr(e[1] || qe, e[2]);
    return kt(s, r, e[3] ? +e[3] : void 0, e[4] ? +e[4] : void 0);
  }
}, fo = [io, lo], po = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)?((?:[-a-z]+)?:\/.*?|\[native code\]|[^@]*(?:bundle|\d+\.js)|\/[\w\-. /=]+)(?::(\d+))?(?::(\d+))?\s*$/i, ho = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i, mo = (t) => {
  const e = po.exec(t);
  if (e) {
    if (e[3] && e[3].indexOf(" > eval") > -1) {
      const i = ho.exec(e[3]);
      i && (e[1] = e[1] || "eval", e[3] = i[1], e[4] = i[2], e[5] = "");
    }
    let r = e[3], s = e[1] || qe;
    return [s, r] = sr(s, r), kt(r, s, e[4] ? +e[4] : void 0, e[5] ? +e[5] : void 0);
  }
}, go = [oo, mo], _o = /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:[-a-z]+):.*?):(\d+)(?::(\d+))?\)?\s*$/i, To = (t) => {
  const e = _o.exec(t);
  return e ? kt(e[2], e[1] || qe, +e[3], e[4] ? +e[4] : void 0) : void 0;
}, yo = [ao, To], Eo = [fo, go, yo], bo = jr(...Eo), sr = (t, e) => {
  const n = t.indexOf("safari-extension") !== -1, r = t.indexOf("safari-web-extension") !== -1;
  return n || r ? [
    t.indexOf("@") !== -1 ? t.split("@")[0] : qe,
    n ? `safari-extension:${e}` : `safari-web-extension:${e}`
  ] : [t, e];
}, we = 1024, ir = "Breadcrumbs", So = (t = {}) => {
  const e = {
    console: !0,
    dom: !0,
    fetch: !0,
    history: !0,
    sentry: !0,
    xhr: !0,
    ...t
  };
  return {
    name: ir,
    // TODO v8: Remove this
    setupOnce() {
    },
    // eslint-disable-line @typescript-eslint/no-empty-function
    setup(n) {
      e.console && ss(Ro(n)), e.dom && os(vo(n, e.dom)), e.xhr && wn(ko(n)), e.fetch && vn(wo(n)), e.history && Et(No(n)), e.sentry && n.on && n.on("beforeSendEvent", Io(n));
    }
  };
}, ar = So;
L(ir, ar);
function Io(t) {
  return function(n) {
    R() === t && U(
      {
        category: `sentry.${n.type === "transaction" ? "transaction" : "event"}`,
        event_id: n.event_id,
        level: n.level,
        message: M(n)
      },
      {
        event: n
      }
    );
  };
}
function vo(t, e) {
  return function(r) {
    if (R() !== t)
      return;
    let s, i, a = typeof e == "object" ? e.serializeAttribute : void 0, o = typeof e == "object" && typeof e.maxStringLength == "number" ? e.maxStringLength : void 0;
    o && o > we && (x && f.warn(
      `\`dom.maxStringLength\` cannot exceed ${we}, but a value of ${o} was configured. Sentry will use ${we} instead.`
    ), o = we), typeof a == "string" && (a = [a]);
    try {
      const u = r.event, d = Oo(u) ? u.target : u;
      s = he(d, { keyAttrs: a, maxStringLength: o }), i = _n(d);
    } catch {
      s = "<unknown>";
    }
    if (s.length === 0)
      return;
    const c = {
      category: `ui.${r.name}`,
      message: s
    };
    i && (c.data = { "ui.component_name": i }), U(c, {
      event: r.event,
      name: r.name,
      global: r.global
    });
  };
}
function Ro(t) {
  return function(n) {
    if (R() !== t)
      return;
    const r = {
      category: "console",
      data: {
        arguments: n.args,
        logger: "console"
      },
      level: Ns(n.level),
      message: $t(n.args, " ")
    };
    if (n.level === "assert")
      if (n.args[0] === !1)
        r.message = `Assertion failed: ${$t(n.args.slice(1), " ") || "console.assert"}`, r.data.arguments = n.args.slice(1);
      else
        return;
    U(r, {
      input: n.args,
      level: n.level
    });
  };
}
function ko(t) {
  return function(n) {
    if (R() !== t)
      return;
    const { startTimestamp: r, endTimestamp: s } = n, i = n.xhr[X];
    if (!r || !s || !i)
      return;
    const { method: a, url: o, status_code: c, body: u } = i, d = {
      method: a,
      url: o,
      status_code: c
    }, l = {
      xhr: n.xhr,
      input: u,
      startTimestamp: r,
      endTimestamp: s
    };
    U(
      {
        category: "xhr",
        data: d,
        type: "http"
      },
      l
    );
  };
}
function wo(t) {
  return function(n) {
    if (R() !== t)
      return;
    const { startTimestamp: r, endTimestamp: s } = n;
    if (s && !(n.fetchData.url.match(/sentry_key/) && n.fetchData.method === "POST"))
      if (n.error) {
        const i = n.fetchData, a = {
          data: n.error,
          input: n.args,
          startTimestamp: r,
          endTimestamp: s
        };
        U(
          {
            category: "fetch",
            data: i,
            level: "error",
            type: "http"
          },
          a
        );
      } else {
        const i = n.response, a = {
          ...n.fetchData,
          status_code: i && i.status
        }, o = {
          input: n.args,
          response: i,
          startTimestamp: r,
          endTimestamp: s
        };
        U(
          {
            category: "fetch",
            data: a,
            type: "http"
          },
          o
        );
      }
  };
}
function No(t) {
  return function(n) {
    if (R() !== t)
      return;
    let r = n.from, s = n.to;
    const i = Oe(T.location.href);
    let a = r ? Oe(r) : void 0;
    const o = Oe(s);
    (!a || !a.path) && (a = i), i.protocol === o.protocol && i.host === o.host && (s = o.relative), i.protocol === a.protocol && i.host === a.host && (r = a.relative), U({
      category: "navigation",
      data: {
        from: r,
        to: s
      }
    });
  };
}
function Oo(t) {
  return !!t && !!t.target;
}
const or = "Dedupe", Ao = () => {
  let t;
  return {
    name: or,
    // TODO v8: Remove this
    setupOnce() {
    },
    // eslint-disable-line @typescript-eslint/no-empty-function
    processEvent(e) {
      if (e.type)
        return e;
      try {
        if (Co(e, t))
          return x && f.warn("Event dropped due to being a duplicate of previously captured event."), null;
      } catch {
      }
      return t = e;
    }
  };
}, cr = Ao;
L(or, cr);
function Co(t, e) {
  return e ? !!(Do(t, e) || $o(t, e)) : !1;
}
function Do(t, e) {
  const n = t.message, r = e.message;
  return !(!n && !r || n && !r || !n && r || n !== r || !dr(t, e) || !ur(t, e));
}
function $o(t, e) {
  const n = an(e), r = an(t);
  return !(!n || !r || n.type !== r.type || n.value !== r.value || !dr(t, e) || !ur(t, e));
}
function ur(t, e) {
  let n = on(t), r = on(e);
  if (!n && !r)
    return !0;
  if (n && !r || !n && r || (n = n, r = r, r.length !== n.length))
    return !1;
  for (let s = 0; s < r.length; s++) {
    const i = r[s], a = n[s];
    if (i.filename !== a.filename || i.lineno !== a.lineno || i.colno !== a.colno || i.function !== a.function)
      return !1;
  }
  return !0;
}
function dr(t, e) {
  let n = t.fingerprint, r = e.fingerprint;
  if (!n && !r)
    return !0;
  if (n && !r || !n && r)
    return !1;
  n = n, r = r;
  try {
    return n.join("") === r.join("");
  } catch {
    return !1;
  }
}
function an(t) {
  return t.exception && t.exception.values && t.exception.values[0];
}
function on(t) {
  const e = t.exception;
  if (e)
    try {
      return e.values[0].stacktrace.frames;
    } catch {
      return;
    }
}
const lr = "GlobalHandlers", Po = (t = {}) => {
  const e = {
    onerror: !0,
    onunhandledrejection: !0,
    ...t
  };
  return {
    name: lr,
    setupOnce() {
      Error.stackTraceLimit = 50;
    },
    setup(n) {
      e.onerror && (xo(n), cn("onerror")), e.onunhandledrejection && (Mo(n), cn("onunhandledrejection"));
    }
  };
}, fr = Po;
L(
  lr,
  fr
);
function xo(t) {
  Rn((e) => {
    const { stackParser: n, attachStacktrace: r } = hr();
    if (R() !== t || nr())
      return;
    const { msg: s, url: i, line: a, column: o, error: c } = e, u = c === void 0 && J(s) ? Ho(s, i, a, o) : pr(
      Rt(n, c || s, void 0, r, !1),
      i,
      a,
      o
    );
    u.level = "error", bn(u, {
      originalException: c,
      mechanism: {
        handled: !1,
        type: "onerror"
      }
    });
  });
}
function Mo(t) {
  kn((e) => {
    const { stackParser: n, attachStacktrace: r } = hr();
    if (R() !== t || nr())
      return;
    const s = Fo(e), i = mt(s) ? Lo(s) : Rt(n, s, void 0, r, !0);
    i.level = "error", bn(i, {
      originalException: s,
      mechanism: {
        handled: !1,
        type: "onunhandledrejection"
      }
    });
  });
}
function Fo(t) {
  if (mt(t))
    return t;
  const e = t;
  try {
    if ("reason" in e)
      return e.reason;
    if ("detail" in e && "reason" in e.detail)
      return e.detail.reason;
  } catch {
  }
  return t;
}
function Lo(t) {
  return {
    exception: {
      values: [
        {
          type: "UnhandledRejection",
          // String() is needed because the Primitive type includes symbols (which can't be automatically stringified)
          value: `Non-Error promise rejection captured with value: ${String(t)}`
        }
      ]
    }
  };
}
function Ho(t, e, n, r) {
  const s = /^(?:[Uu]ncaught (?:exception: )?)?(?:((?:Eval|Internal|Range|Reference|Syntax|Type|URI|)Error): )?(.*)$/i;
  let i = Tt(t) ? t.message : t, a = "Error";
  const o = i.match(s);
  return o && (a = o[1], i = o[2]), pr({
    exception: {
      values: [
        {
          type: a,
          value: i
        }
      ]
    }
  }, e, n, r);
}
function pr(t, e, n, r) {
  const s = t.exception = t.exception || {}, i = s.values = s.values || [], a = i[0] = i[0] || {}, o = a.stacktrace = a.stacktrace || {}, c = o.frames = o.frames || [], u = isNaN(parseInt(r, 10)) ? void 0 : r, d = isNaN(parseInt(n, 10)) ? void 0 : n, l = J(e) && e.length > 0 ? e : qr();
  return c.length === 0 && c.push({
    colno: u,
    filename: l,
    function: "?",
    in_app: !0,
    lineno: d
  }), t;
}
function cn(t) {
  x && f.log(`Global Handler attached: ${t}`);
}
function hr() {
  const t = R();
  return t && t.getOptions() || {
    stackParser: () => [],
    attachStacktrace: !1
  };
}
const mr = "HttpContext", Uo = () => ({
  name: mr,
  // TODO v8: Remove this
  setupOnce() {
  },
  // eslint-disable-line @typescript-eslint/no-empty-function
  preprocessEvent(t) {
    if (!T.navigator && !T.location && !T.document)
      return;
    const e = t.request && t.request.url || T.location && T.location.href, { referrer: n } = T.document || {}, { userAgent: r } = T.navigator || {}, s = {
      ...t.request && t.request.headers,
      ...n && { Referer: n },
      ...r && { "User-Agent": r }
    }, i = { ...t.request, ...e && { url: e }, headers: s };
    t.request = i;
  }
}), gr = Uo;
L(mr, gr);
const Bo = "cause", jo = 5, _r = "LinkedErrors", qo = (t = {}) => {
  const e = t.limit || jo, n = t.key || Bo;
  return {
    name: _r,
    // TODO v8: Remove this
    setupOnce() {
    },
    // eslint-disable-line @typescript-eslint/no-empty-function
    preprocessEvent(r, s, i) {
      const a = i.getOptions();
      Jr(
        // This differs from the LinkedErrors integration in core by using a different exceptionFromError function
        rr,
        a.stackParser,
        a.maxValueLength,
        n,
        e,
        r,
        s
      );
    }
  };
}, Tr = qo;
L(_r, Tr);
const Go = [
  "EventTarget",
  "Window",
  "Node",
  "ApplicationCache",
  "AudioTrackList",
  "BroadcastChannel",
  "ChannelMergerNode",
  "CryptoOperation",
  "EventSource",
  "FileReader",
  "HTMLUnknownElement",
  "IDBDatabase",
  "IDBRequest",
  "IDBTransaction",
  "KeyOperation",
  "MediaController",
  "MessagePort",
  "ModalWindow",
  "Notification",
  "SVGElementInstance",
  "Screen",
  "SharedWorker",
  "TextTrack",
  "TextTrackCue",
  "TextTrackList",
  "WebSocket",
  "WebSocketWorker",
  "Worker",
  "XMLHttpRequest",
  "XMLHttpRequestEventTarget",
  "XMLHttpRequestUpload"
], yr = "TryCatch", Wo = (t = {}) => {
  const e = {
    XMLHttpRequest: !0,
    eventTarget: !0,
    requestAnimationFrame: !0,
    setInterval: !0,
    setTimeout: !0,
    ...t
  };
  return {
    name: yr,
    // TODO: This currently only works for the first client this is setup
    // We may want to adjust this to check for client etc.
    setupOnce() {
      e.setTimeout && v(T, "setTimeout", un), e.setInterval && v(T, "setInterval", un), e.requestAnimationFrame && v(T, "requestAnimationFrame", zo), e.XMLHttpRequest && "XMLHttpRequest" in T && v(XMLHttpRequest.prototype, "send", Xo);
      const n = e.eventTarget;
      n && (Array.isArray(n) ? n : Go).forEach(Vo);
    }
  };
}, Er = Wo, Yo = L(
  yr,
  Er
);
function un(t) {
  return function(...e) {
    const n = e[0];
    return e[0] = ne(n, {
      mechanism: {
        data: { function: F(t) },
        handled: !1,
        type: "instrument"
      }
    }), t.apply(this, e);
  };
}
function zo(t) {
  return function(e) {
    return t.apply(this, [
      ne(e, {
        mechanism: {
          data: {
            function: "requestAnimationFrame",
            handler: F(t)
          },
          handled: !1,
          type: "instrument"
        }
      })
    ]);
  };
}
function Xo(t) {
  return function(...e) {
    const n = this;
    return ["onload", "onerror", "onprogress", "onreadystatechange"].forEach((s) => {
      s in n && typeof n[s] == "function" && v(n, s, function(i) {
        const a = {
          mechanism: {
            data: {
              function: s,
              handler: F(i)
            },
            handled: !1,
            type: "instrument"
          }
        }, o = _t(i);
        return o && (a.mechanism.data.handler = F(o)), ne(i, a);
      });
    }), t.apply(this, e);
  };
}
function Vo(t) {
  const e = T, n = e[t] && e[t].prototype;
  !n || !n.hasOwnProperty || !n.hasOwnProperty("addEventListener") || (v(n, "addEventListener", function(r) {
    return function(s, i, a) {
      try {
        typeof i.handleEvent == "function" && (i.handleEvent = ne(i.handleEvent, {
          mechanism: {
            data: {
              function: "handleEvent",
              handler: F(i),
              target: t
            },
            handled: !1,
            type: "instrument"
          }
        }));
      } catch {
      }
      return r.apply(this, [
        s,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ne(i, {
          mechanism: {
            data: {
              function: "addEventListener",
              handler: F(i),
              target: t
            },
            handled: !1,
            type: "instrument"
          }
        }),
        a
      ]);
    };
  }), v(
    n,
    "removeEventListener",
    function(r) {
      return function(s, i, a) {
        const o = i;
        try {
          const c = o && o.__sentry_wrapped__;
          c && r.call(this, s, c, a);
        } catch {
        }
        return r.call(this, s, o, a);
      };
    }
  ));
}
const Ko = [
  qn(),
  Wn(),
  Er(),
  ar(),
  fr(),
  Tr(),
  cr(),
  gr()
];
function Jo(t) {
  return [
    // eslint-disable-next-line deprecation/deprecation
    ...Ko
  ];
}
function Qo(t = {}) {
  t.defaultIntegrations === void 0 && (t.defaultIntegrations = Jo()), t.release === void 0 && (typeof __SENTRY_RELEASE__ == "string" && (t.release = __SENTRY_RELEASE__), T.SENTRY_RELEASE && T.SENTRY_RELEASE.id && (t.release = T.SENTRY_RELEASE.id)), t.autoSessionTracking === void 0 && (t.autoSessionTracking = !0), t.sendClientReports === void 0 && (t.sendClientReports = !0);
  const e = {
    ...t,
    stackParser: Gr(t.stackParser || bo),
    integrations: di(t),
    transport: t.transport || (In() ? no : so)
  };
  yi(Za, e), t.autoSessionTracking && Zo();
}
function Zo() {
  if (typeof T.document > "u") {
    x && f.warn("Session tracking in non-browser environment with @sentry/browser is not supported.");
    return;
  }
  Pt({ ignoreDuration: !0 }), xt(), Et(({ from: t, to: e }) => {
    t !== void 0 && t !== e && (Pt({ ignoreDuration: !0 }), xt());
  });
}
function ec(t) {
  const e = {
    ...t
  };
  Bn(e, "react"), Qo(e);
}
const tc = Fa;
(typeof __SENTRY_TRACING__ > "u" || __SENTRY_TRACING__) && Ba();
const ye = Wr.isQaOrStaging, sc = () => {
  ye || ec({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: Yr,
    attachStacktrace: !0,
    integrations: [
      new tc(),
      new Yo()
    ],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring
    tracesSampleRate: 1,
    normalizeDepth: 10,
    release: process.env.REACT_APP_RELEASE_VERSION
  });
}, ic = ({ tagName: t, tagValue: e }) => {
  ye || zr(t, e);
}, ac = ({ id: t = null, username: e = null, email: n = null }) => {
  if (ye)
    return;
  Kr({
    id: t,
    username: e,
    email: n
  });
}, dn = (t, e) => {
  ye || En(t, {
    contexts: {
      support_data: e
    }
  });
}, oc = (t) => {
  ye || (Xr(t) ? dn(t == null ? void 0 : t.data, Vr(t)) : dn(t));
};
export {
  ac as a,
  dn as b,
  sc as i,
  oc as r,
  ic as s
};
