import e from "react";
import { A as o } from "./jwt-decode.esm-B7Q3ANEx.js";
const n = () => {
  const t = e.useContext(o);
  if (!t)
    throw new Error("useAuth must be used within an <AuthProvider/>");
  return t;
};
export {
  n as u
};
