import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as _, useState as l, useEffect as g } from "react";
import { P as x, a as w } from "./index.esm-BV4iWo1a.js";
import { AppContext as T } from "./AppContext.js";
import { u as U } from "./useConfiguration-B-lyaBB9.js";
import { F as H } from "./provider-DZPLM8yJ.js";
import { i as t } from "./index-B3vUTrRT.js";
import { a as N, B as Z } from "./utils-BTh-HRzb.js";
import { I as $ } from "./Investment-WXLmCVph.js";
import { P as j } from "./Product-DewIFE9c.js";
import { W as z } from "./Welcome-3PCWRbTF.js";
import { ErrorPage as m } from "./ErrorPage.js";
import { FundList as J } from "./FundList.js";
function ce({ isin: a, employer: i }) {
  const { fpapi: S } = _(H), { AppState: { showWelcomePage: u }, setShowWelcomePage: v, setEmployer: L } = _(T), { tenantConfig: s, amcs: F } = U(), A = s == null ? void 0 : s.fallbackLogo, P = s == null ? void 0 : s.showSchemeLogo, [k, r] = l("investment"), [I, f] = l(p), [c, W] = l(), [E, d] = l(!1), h = N(i, s), b = [
    {
      step: 1,
      content: t._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: t._({
        id: "v4fm2D"
      })
    },
    {
      step: 3,
      content: t._({
        id: "Lx+DB3"
      })
    }
  ];
  function p() {
    return u ? "welcome" : "render_investment";
  }
  g(() => {
    f(p());
  }, [
    u
  ]), g(() => {
    var o, n;
    if (!(s != null && s.groupInvestment)) {
      r("group_checkout_disabled");
      return;
    }
    if (!a) {
      r("fund_list");
      return;
    }
    if (!i || !((n = (o = s == null ? void 0 : s.groupInvestment) == null ? void 0 : o.allowedEmployers) != null && n.includes(i))) {
      r("invalid_employer");
      return;
    }
    if (h.length && !h.includes(a)) {
      r("invalid_scheme");
      return;
    }
    L({
      id: i
    }), B(a);
  }, [
    a,
    i
  ]);
  async function B(o) {
    d(!0), S.semiPublicFpClient.master_data().fetchScheme(o).then((n) => {
      D(n);
    }).catch(() => {
      r("scheme_not_found"), d(!1);
    });
  }
  function D(o) {
    if (!o)
      r("scheme_not_found");
    else {
      const n = P ? {
        ...o,
        schemeLogo: F[o == null ? void 0 : o.amc_id] || A
      } : {
        ...o
      };
      W(n);
    }
    d(!1);
  }
  function G(o) {
    const n = new URLSearchParams(window.location.search);
    n.set("isin", o), i && n.set("employer", i);
    const Q = n.toString();
    window.location.href = window.location.origin + Z + `/group-checkout?${Q}`;
  }
  function R() {
    v(!1), f("render_investment");
  }
  function X() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(x, {
          title: t._({
            id: "Qx20fI"
          })
        }),
        /* @__PURE__ */ e.jsx(j, {
          scheme: c
        }),
        /* @__PURE__ */ e.jsx($, {
          scheme: c,
          isGroupInvestment: !0
        })
      ]
    });
  }
  function q() {
    switch (k) {
      case "investment":
        return i && c ? X() : /* @__PURE__ */ e.jsx(w, {
          variant: "fullpage"
        });
      case "fund_list":
        return /* @__PURE__ */ e.jsx(J, {
          onFundSelection: G,
          employer: i
        });
      case "scheme_not_found":
        return /* @__PURE__ */ e.jsx(m, {
          title: t._({
            id: "/+1l7D"
          }),
          description: t._({
            id: "H8AXWD"
          }),
          showDetails: !1,
          showAction: !1
        });
      case "group_checkout_disabled":
        return /* @__PURE__ */ e.jsx(m, {
          title: t._({
            id: "W2q+qT"
          }),
          description: t._({
            id: "94jjGc"
          }),
          showDetails: !1,
          showAction: !1
        });
      case "invalid_employer":
        return /* @__PURE__ */ e.jsx(m, {
          title: t._({
            id: "BNmdns"
          }),
          description: t._({
            id: "D5rsX1"
          }),
          showDetails: !1,
          showAction: !1
        });
      case "invalid_scheme":
        return /* @__PURE__ */ e.jsx(m, {
          title: t._({
            id: "2/Bpoo"
          }),
          description: t._({
            id: "D5rsX1"
          }),
          showDetails: !1,
          showAction: !1
        });
    }
  }
  function C() {
    return I === "welcome" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(x, {
          title: c ? t._({
            id: "Qx20fI"
          }) : t._({
            id: "FhpR0a"
          })
        }),
        /* @__PURE__ */ e.jsx(j, {
          scheme: c
        }),
        /* @__PURE__ */ e.jsx(z, {
          onAck: R,
          welcomeSteps: b
        })
      ]
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: E ? /* @__PURE__ */ e.jsx(w, {
        variant: "fullpage"
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {
        children: q()
      })
    });
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: C()
  });
}
export {
  ce as GroupCheckout
};
