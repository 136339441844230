import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as G, useState as j, useRef as nt, useEffect as Y, useMemo as at } from "react";
import { R as st, F as S } from "./react-final-form.es-DAN9JLvE.js";
import { P as lt, D as w, a as ut, e as T, i as U, M as h, B as ct } from "./index.esm-BV4iWo1a.js";
import { F as dt } from "./provider-DZPLM8yJ.js";
import { r as M } from "./sentry-ZQO5C1U6.js";
import { s as mt, A as pt, z as ft } from "./utils-BTh-HRzb.js";
import { f as V, i as A, j as O, r as q } from "./validators-BArvL7cB.js";
import { i as n } from "./index-B3vUTrRT.js";
import { AppContext as ht } from "./AppContext.js";
const Bt = ({ user: l, kycDetails: s, onCompletion: z, isKycOnly: k }) => {
  const { fpapi: v } = G(dt), { AppState: { tenant: Z } } = G(ht), [$, J] = j(null), [K, Q] = j(null), [L, R] = j(null), [C, y] = j(!1), [g, _] = j(null), B = {}, u = nt({
    identity_proof: !1,
    photo: !1,
    signature: !1
  }), [c, D] = j({
    identity_proof: null,
    photo: null,
    signature: null
  }), E = {}, N = {
    "image/jpeg": 1,
    "image/png": 1,
    "image/jpg": 1,
    "application/pdf": 1
  };
  Y(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const H = (i) => [
    "photo",
    "signature",
    "identity_proof"
  ].every((r) => i[r]), P = async (i) => {
    const t = await v.fpClient.files().fetch(i), r = t.url, p = await (await fetch(r)).blob();
    return {
      file: new File([
        p
      ], t == null ? void 0 : t.filename, {
        type: p.type
      }),
      fileObj: t
    };
  };
  Y(() => {
    (async () => {
      try {
        if (y(!0), H(s)) {
          const i = [
            P(s.identity_proof),
            P(s.photo),
            P(s.signature)
          ], [t, r, o] = await Promise.all(i);
          J(t.file), Q(r.file), R(o.file), D({
            identity_proof: t.fileObj,
            photo: r.fileObj,
            signature: o.fileObj
          });
        }
      } catch (i) {
        window.debug.error(i), M(i), _({
          main: n._({
            id: "naOBN/"
          })
        });
      } finally {
        y(!1);
      }
    })();
  }, []);
  const W = at(() => ({
    identity_proof: $ ?? null,
    photo: K ?? null,
    signature: L ?? null
  }), [
    $,
    K,
    L
  ]), X = async (i) => {
    var o;
    const t = [], r = s == null ? void 0 : s.pan;
    for (const p of i) {
      const x = p.purpose, f = p.value, b = (o = f == null ? void 0 : f.type) == null ? void 0 : o.split("/")[1], a = new FormData();
      a.append("file", f, `${r}_${x}.${b}`), a.append("purpose", x), t.push(v.fpClient.files().create(a));
    }
    return t;
  }, I = (i) => {
    const t = {
      identity_proof: "",
      photo: "",
      signature: ""
    }, r = {
      0: "identity_proof",
      1: "photo",
      2: "signature"
    };
    for (let o = 0; o < i.length; o++)
      i[o].status === "fulfilled" ? t[`${i[o].value.purpose}`] = i[o].value.id : B[r[o]] = i[o].reason;
    return t;
  }, tt = async () => {
    const i = l.id, r = {
      ...ft(l == null ? void 0 : l.annexure),
      completed_step: "kyc_file_uploads"
    }, o = await v.updateUser(i, r, null, Z);
    if (o)
      return o;
    throw new Error(o);
  }, et = async (i) => {
    var x, f, b;
    const { identity_proof: t, photo: r, signature: o } = i, p = [
      {
        purpose: "identity_proof",
        value: t
      },
      {
        purpose: "photo",
        value: r
      },
      {
        purpose: "signature",
        value: o
      }
    ].filter((a) => {
      var F;
      return !((F = u == null ? void 0 : u.current) != null && F[a.purpose]);
    });
    try {
      y(!0);
      const a = await X(p), F = await Promise.allSettled(a), d = I(F), m = {
        identity_proof: (x = c == null ? void 0 : c.identity_proof) == null ? void 0 : x.id,
        photo: (f = c == null ? void 0 : c.photo) == null ? void 0 : f.id,
        signature: (b = c == null ? void 0 : c.signature) == null ? void 0 : b.id
      };
      if (Object.keys(B).length)
        _({
          main: n._({
            id: "T8oZBk"
          })
        }), window.debug.error(B);
      else {
        const rt = {
          identity_proof: (d == null ? void 0 : d.identity_proof) || (m == null ? void 0 : m.identity_proof),
          photo: (d == null ? void 0 : d.photo) || (m == null ? void 0 : m.photo),
          signature: (d == null ? void 0 : d.signature) || (m == null ? void 0 : m.signature)
        };
        _(null);
        const ot = await v.fpClient.kyc_requests().update(s == null ? void 0 : s.id, rt);
        l && await tt(), z({
          updatedKycDetails: ot
        });
      }
    } catch (a) {
      _({
        main: n._({
          id: "T8oZBk"
        })
      }), window.debug.error(a), M(a), pt(a) && window.location.reload();
    } finally {
      y(!1);
    }
  }, it = async () => {
    try {
      y(!0);
      const i = l.id, t = l == null ? void 0 : l.annexure;
      t.completed_step = "contact_details", await v.updateUser(i, t, null, Z), z();
    } catch (i) {
      window.debug.error(i), M(i);
    } finally {
      y(!1);
    }
  };
  return /* @__PURE__ */ e.jsxs("div", {
    children: [
      /* @__PURE__ */ e.jsx(lt, {
        title: n._({
          id: "G1xTCl"
        }),
        icon: k ? null : "Back",
        onClick: it
      }),
      /* @__PURE__ */ e.jsx(w, {
        type: "text",
        text: n._({
          id: "0vCxNc"
        })
      }),
      C && /* @__PURE__ */ e.jsx(ut, {
        variant: "fullpage"
      }),
      /* @__PURE__ */ e.jsx(st, {
        onSubmit: et,
        validate: () => ({}),
        initialValues: W,
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        children: (i) => /* @__PURE__ */ e.jsxs("form", {
          onSubmit: i.handleSubmit,
          children: [
            E.identity_proof ? null : /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(T, {
                  message: n._({
                    id: "m9snku"
                  })
                }),
                /* @__PURE__ */ e.jsx(w, {
                  type: "labelDesc",
                  text: n._({
                    id: "ZbEcEj"
                  })
                }),
                /* @__PURE__ */ e.jsx(S, {
                  name: "identity_proof",
                  validate: V(q, O(5, "File size should be less than 5 MB"), A(N, "Format not supported")),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0,
                    pristine: !0
                  },
                  validateFields: [],
                  children: (t) => {
                    var r;
                    return u.current = {
                      ...u.current,
                      identity_proof: ((r = t == null ? void 0 : t.meta) == null ? void 0 : r.pristine) ?? !1
                    }, /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(U, {
                          inputName: "identity_proof",
                          customClass: "customClassName",
                          value: t.input.value,
                          handleFile: t.input.onChange
                        }),
                        t.meta.error && t.meta.touched ? /* @__PURE__ */ e.jsx(h, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        }) : /* @__PURE__ */ e.jsx(h, {
                          type: "helper",
                          variant: "field",
                          title: n._({
                            id: "OIjqUC"
                          })
                        })
                      ]
                    });
                  }
                })
              ]
            }),
            E.photo ? null : /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(T, {
                  message: "Photo"
                }),
                /* @__PURE__ */ e.jsx(w, {
                  type: "labelDesc",
                  text: n._({
                    id: "ZoyfdQ"
                  })
                }),
                /* @__PURE__ */ e.jsx(S, {
                  name: "photo",
                  validate: V(q, O(5, "File size should be less than 5 MB"), A(N, "Format not supported")),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0,
                    pristine: !0
                  },
                  validateFields: [],
                  children: (t) => {
                    var r;
                    return u.current = {
                      ...u.current,
                      photo: ((r = t == null ? void 0 : t.meta) == null ? void 0 : r.pristine) ?? !1
                    }, /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(U, {
                          inputName: "photo",
                          customClass: "customClassName",
                          value: t.input.value,
                          handleFile: t.input.onChange
                        }),
                        t.meta.error && t.meta.touched ? /* @__PURE__ */ e.jsx(h, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        }) : /* @__PURE__ */ e.jsx(h, {
                          type: "helper",
                          variant: "field",
                          title: n._({
                            id: "OIjqUC"
                          })
                        })
                      ]
                    });
                  }
                })
              ]
            }),
            E.signature ? null : /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(T, {
                  message: n._({
                    id: "YsE8lo"
                  })
                }),
                /* @__PURE__ */ e.jsx(w, {
                  type: "labelDesc",
                  text: n._({
                    id: "JBDnbV"
                  })
                }),
                /* @__PURE__ */ e.jsx(S, {
                  name: "signature",
                  validate: V(q, O(5, "File size should be less than 5 MB"), A(N, "Format not supported")),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0,
                    pristine: !0
                  },
                  validateFields: [],
                  children: (t) => {
                    var r;
                    return u.current = {
                      ...u.current,
                      signature: ((r = t == null ? void 0 : t.meta) == null ? void 0 : r.pristine) ?? !1
                    }, /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(U, {
                          inputName: "signature",
                          customClass: "customClassName",
                          value: t.input.value,
                          handleFile: t.input.onChange
                        }),
                        t.meta.error && t.meta.touched ? /* @__PURE__ */ e.jsx(h, {
                          type: "error",
                          variant: "field",
                          title: t.meta.error
                        }) : /* @__PURE__ */ e.jsx(h, {
                          type: "helper",
                          variant: "field",
                          title: n._({
                            id: "OIjqUC"
                          })
                        })
                      ]
                    });
                  }
                })
              ]
            }),
            (g == null ? void 0 : g.main) && /* @__PURE__ */ e.jsx(h, {
              type: "error",
              variant: "flash",
              title: g == null ? void 0 : g.main,
              customClass: "mb-5"
            }),
            /* @__PURE__ */ e.jsx(ct, {
              variant: C ? "disabled" : "primary",
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                const t = Object.keys(i.errors);
                t.length && mt(t[0]);
              },
              customClass: "mb-8",
              children: C ? n._({
                id: "1DBP38"
              }) : n._({
                id: "2iVSoY"
              })
            })
          ]
        })
      })
    ]
  });
};
export {
  Bt as default
};
