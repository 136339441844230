import { r as l } from "./utils-BTh-HRzb.js";
let e = null, c = null, u = !1;
const d = (t, s) => {
  const { data: r } = t;
  if (r.source === "fpapps_window_redirect") {
    if (u = !0, e == null || e.close(), s) {
      s();
      return;
    }
    const n = r.postBackURL;
    window.location.href = n;
  }
}, p = (t, s, r, n, a) => {
  const o = window.open(t, s, r);
  if (!o || o.closed || typeof o.closed > "u")
    return l.removeRedirectionState(), a(), null;
  l.saveRedirectionState();
  const i = setInterval(function() {
    o != null && o.closed && !u && (clearInterval(i), l.removeRedirectionState(), n());
  }, 500);
  return o;
}, m = (t, s, r, n, a) => {
  window.removeEventListener("message", d), (e === null || e.closed || c !== t) && (e = p(t, s, "toolbar=no, menubar=no, fullscreen=yes", r, n)), e == null || e.focus(), window.addEventListener("message", (i) => {
    const f = window.location.origin;
    i.origin === f && d(i, a);
  }, !1), c = t;
};
export {
  m as o
};
