import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as J, useState as x, useEffect as $, useRef as Ee, useMemo as _e, useCallback as Pe } from "react";
import { i as o } from "./index-B3vUTrRT.js";
import { P as Ce, D as Ae, a as ve, e as F, T as Q, M as y, k as Oe, R as we, l as Ie, j as Fe, B as Le } from "./index.esm-BV4iWo1a.js";
import { AppContext as Ne } from "./AppContext.js";
import { F as Re } from "./provider-DZPLM8yJ.js";
import { LoginContext as Me } from "./LoginContext.js";
import { r as M } from "./sentry-ZQO5C1U6.js";
import { z as Se, u as I, I as Ue, J as Be, v as Ve, o as re, K as Ge, s as ze, A as qe, m as le, L as Ze, M as We } from "./utils-BTh-HRzb.js";
import { W as He } from "./Welcome-3PCWRbTF.js";
import { K as Xe } from "./KycCheckStatus-D23-VNWq.js";
import { ErrorPage as Qe } from "./ErrorPage.js";
import { K as $e } from "./KycRequestStatus-Bik2h3cU.js";
import Je from "./KycEsign-BpRAHiSG.js";
import Ye from "./KycIpv-BpBWSA9u.js";
import ke from "./KycFileUploads-Cj0sNJaD.js";
import { C as De, B as et, F as tt } from "./FinancialDetails-UX515BRe.js";
import { R as at, F as C } from "./react-final-form.es-DAN9JLvE.js";
import { u as nt } from "./useConfiguration-B-lyaBB9.js";
import { f as se, g as it, h as rt, r as R, k as lt, l as st, d as ye, m as ot } from "./validators-BArvL7cB.js";
import { A as dt } from "./Auth-CjS0P6BG.js";
const ut = ({ user: c, onCompletion: P, kycDetails: s, folioDetails: Y, kycCheckData: m, isPartner: b, isGroupInvestment: k, isKycCompliant: A, isKycOnly: L }) => {
  var xe;
  const { fpapi: S } = J(Re), { tenantConfig: j } = nt(), [z, q] = x(!1), [Z, U] = x(!1);
  $(() => {
    var a;
    const l = (a = j == null ? void 0 : j.data_sources_usage_policy) == null ? void 0 : a.KYC;
    q(l === "strict" || l === "flexible");
  }, [
    (xe = j == null ? void 0 : j.data_sources_usage_policy) == null ? void 0 : xe.KYC
  ]), $(() => {
    U(!!(z && A));
  }, [
    z,
    A
  ]);
  const { AppState: { username: oe, employer: K, tenant: D }, setUsername: ee } = J(Ne), de = Ee(null), [ue, ce] = x(null), [W, me] = x(null), [O, te] = x(null), [T, E] = x([]), [B, V] = x(null), [fe, i] = x(!1), n = Se(c == null ? void 0 : c.annexure), u = !(n != null && n.dob), p = !T.length, h = /* @__PURE__ */ new Date();
  h.setFullYear(h.getFullYear() - 18);
  const w = [
    {
      label: o._({
        id: "6BjygT"
      }),
      value: "not_applicable"
    },
    {
      label: o._({
        id: "0n+8KZ"
      }),
      value: "pep_related"
    },
    {
      label: o._({
        id: "JpLyV/"
      }),
      value: "pep_exposed"
    }
  ], f = [
    {
      label: o._({
        id: "mhekaN"
      }),
      value: "male"
    },
    {
      label: o._({
        id: "MbGkoQ"
      }),
      value: "female"
    },
    {
      label: o._({
        id: "WRKGRB"
      }),
      value: "transgender"
    }
  ], N = _e(() => [
    {
      label: "Married",
      value: "married"
    },
    {
      label: "Unmarried",
      value: "unmarried"
    },
    {
      label: "Others",
      value: "others"
    }
  ], []), H = _e(() => [
    {
      label: "Father",
      value: "father"
    },
    {
      label: "Spouse",
      value: "spouse"
    }
  ], []), ge = () => {
    var a, d, e, r, _, g;
    let l = null;
    return z && m && (l = {
      // fieldName : validate(KYCRawData?.field) ?KYCRawData?.field : null
      name: (a = m == null ? void 0 : m.entity_details) != null && a.name ? (d = m == null ? void 0 : m.entity_details) == null ? void 0 : d.name : null,
      gender: ot((e = m == null ? void 0 : m.entity_details) == null ? void 0 : e.gender) ? f[I(f, (r = m == null ? void 0 : m.entity_details) == null ? void 0 : r.gender)] : null,
      date_of_birth: new Date(n == null ? void 0 : n.dob),
      tax_status: ((_ = m == null ? void 0 : m.entity_details) == null ? void 0 : _.residential_status) === "resident_individual" ? (g = m == null ? void 0 : m.entity_details) == null ? void 0 : g.residential_status : null
    }), l;
  }, v = _e(() => {
    var d, e, r;
    const l = ge();
    let a = {
      name: null,
      date_of_birth: null,
      gender: null,
      country_of_birth: T.length ? T.find((_) => _.ansi_code === "IN") : null,
      place_of_birth: null,
      pep_details: w[I(w, "not_applicable")],
      tax_status: "resident_individual"
    };
    if ((!A || L) && (a = {
      ...a,
      ...{
        relation_type: null,
        relation_name: null,
        marital_status: null,
        mother_name: null
      }
    }), l && (a.name = (l == null ? void 0 : l.name) || a.name, a.gender = (l == null ? void 0 : l.gender) || a.gender, a.date_of_birth = (l == null ? void 0 : l.date_of_birth) || a.date_of_birth, a.tax_status = (l == null ? void 0 : l.tax_status) || a.tax_status), s && (((d = s == null ? void 0 : s.verification) == null ? void 0 : d.status) === "rejected" || ((e = s == null ? void 0 : s.verification) == null ? void 0 : e.status) === "expired")) {
      a.relation_type = H[I(H, Ue(s))], a.relation_name = Be(s);
      const _ = {
        name: {
          initFieldName: "name",
          initFieldValue: (s == null ? void 0 : s.name) || a.name
        },
        date_of_birth: {
          initFieldName: "date_of_birth",
          initFieldValue: (s == null ? void 0 : s.date_of_birth) || a.date_of_birth
        },
        gender: {
          initFieldName: "gender",
          initFieldValue: f[I(f, s == null ? void 0 : s.gender)] || a.gender
        },
        country_of_birth: {
          initFieldName: "country_of_birth",
          initFieldValue: T[I(T, (r = s == null ? void 0 : s.country_of_birth) == null ? void 0 : r.toUpperCase(), {
            customProperty: "ansi_code"
          })] || a.country_of_birth
        },
        residential_status: {
          initFieldName: "tax_status",
          initFieldValue: s == null ? void 0 : s.residential_status
        },
        mother_name: {
          initFieldName: "mother_name",
          initFieldValue: (s == null ? void 0 : s.mother_name) || (a == null ? void 0 : a.mother_name)
        },
        marital_status: {
          initFieldName: "marital_status",
          initFieldValue: N[I(N, s == null ? void 0 : s.marital_status)] || (a == null ? void 0 : a.marital_status)
        }
      }, g = Ve(_, s);
      a = {
        ...a,
        ...g
      };
    }
    return a;
  }, [
    T
  ]);
  $(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []), $(() => {
    (async () => {
      try {
        i(!0);
        const { countries: l } = await S.fpClient.master_data().fetchCountries();
        if (l.length) {
          const a = [], d = [];
          l.forEach((e) => {
            const r = {
              id: e.ansi_code,
              ansi_code: e.ansi_code,
              label: e.name
            };
            e.ansi_code === "IN" ? d[0] = r : e.ansi_code === "US" ? d[1] = r : e.ansi_code === "GB" ? d[2] = r : e.ansi_code === "AE" ? d[3] = r : a.push(r);
          }), E([
            ...d,
            ...a
          ]);
        }
        if (L || !A)
          try {
            const a = await S.fpClient.investor_profiles().fetch(c == null ? void 0 : c.profile), d = [
              S.fpClient.email_addresses().fetchAll({
                profile: a == null ? void 0 : a.id
              }),
              S.fpClient.phone_numbers().fetchAll({
                profile: a == null ? void 0 : a.id
              })
            ], [e, r] = await Promise.all(d);
            me(e == null ? void 0 : e.data[0]), te(r == null ? void 0 : r.data[0]), ce(a == null ? void 0 : a.pan);
          } catch (a) {
            window.debug.log(a), V(o._({
              id: "Sto4kq"
            })), M(B);
          }
      } catch (l) {
        window.debug.log(l), M(l);
      } finally {
        i(!1);
      }
    })();
  }, []);
  async function ae({ name: l, date_of_birth: a, gender: d, country_of_birth: e, marital_status: r, relation_name: _, relation_type: g, mother_name: G, tax_status: he }) {
    var be, je;
    let X = {
      name: l,
      pan: ue,
      email: W == null ? void 0 : W.email,
      date_of_birth: u ? le(a, "yyyy-mm-dd") : le(new Date(n == null ? void 0 : n.dob), "yyyy-mm-dd"),
      mobile: {
        isd: `+${O == null ? void 0 : O.isd}`,
        number: O == null ? void 0 : O.number
      },
      gender: d == null ? void 0 : d.value,
      country_of_birth: e == null ? void 0 : e.ansi_code,
      residential_status: he,
      marital_status: r == null ? void 0 : r.value,
      mother_name: G
    };
    const Te = (r == null ? void 0 : r.value) !== "married", pe = {
      spouse: "spouse_name",
      father: "father_name"
    };
    return Te ? X[pe.father] = _ : X[pe[g == null ? void 0 : g.value]] = _, s && (((be = s == null ? void 0 : s.verification) == null ? void 0 : be.status) === "rejected" || ((je = s == null ? void 0 : s.verification) == null ? void 0 : je.status) === "expired") && (X = {
      ...Ze(s),
      ...X
    }), S.fpClient.kyc_requests().create(X);
  }
  async function ne({ name: l, country_of_birth: a, place_of_birth: d, gender: e, pep_details: r, tax_status: _, date_of_birth: g }) {
    const G = {
      id: c == null ? void 0 : c.profile,
      name: l,
      date_of_birth: u ? le(g, "yyyy-mm-dd") : le(new Date(n == null ? void 0 : n.dob), "yyyy-mm-dd"),
      country_of_birth: a == null ? void 0 : a.ansi_code,
      place_of_birth: d,
      gender: (e == null ? void 0 : e.value) || e,
      pep_details: r == null ? void 0 : r.value,
      tax_status: _
    };
    return k && (K != null && K.id) && (G.employer = K == null ? void 0 : K.id, G.tax_status = "resident_individual"), S.fpClient.investor_profiles().update(G);
  }
  async function ie() {
    const l = c.id, a = {
      ...n,
      completed_step: "personal_details"
    };
    b && (a.partner_completed_step = "personal_details");
    const d = await S.updateUser(l, a, null, D);
    if (d)
      return d;
    throw new Error(d);
  }
  async function Ke(l) {
    if (L)
      try {
        i(!0);
        const a = await ae(l);
        P({
          updatedKycDetails: a
        });
      } catch (a) {
        V(o._({
          id: "cuvfTX"
        })), window.debug.error(a), M(a), qe(a) && window.location.reload();
      } finally {
        i(!1);
      }
    else if (A)
      try {
        i(!0);
        const a = await ne(l);
        await ie(), !oe && ee(a == null ? void 0 : a.name), P();
      } catch (a) {
        window.debug.error(a), V(o._({
          id: "cuvfTX"
        })), M(a);
      } finally {
        i(!1);
      }
    else
      try {
        i(!0);
        const a = [
          ae(l),
          ne(l)
        ], [d, e] = await Promise.all(a);
        await ie(), ee(e == null ? void 0 : e.name), P({
          updatedKycDetails: d
        });
      } catch (a) {
        window.debug.error(a), V(o._({
          id: "cuvfTX"
        })), M(a);
      } finally {
        i(!1);
      }
  }
  return /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(Ce, {
        title: o._({
          id: "Ih9v5T"
        })
      }),
      /* @__PURE__ */ t.jsx(Ae, {
        type: "text",
        text: b ? o._({
          id: "C4aY37"
        }) : o._({
          id: "n+VFc3"
        })
      }),
      fe && /* @__PURE__ */ t.jsx(ve, {
        variant: "fullpage"
      }),
      /* @__PURE__ */ t.jsx(at, {
        onSubmit: Ke,
        validate: (l) => ({}),
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        initialValues: v,
        children: (l) => {
          var a, d;
          return de.current = l.form, /* @__PURE__ */ t.jsxs("form", {
            onSubmit: l.handleSubmit,
            children: [
              /* @__PURE__ */ t.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ t.jsx(F, {
                    message: o._({
                      id: "uR3xiz"
                    })
                  }),
                  /* @__PURE__ */ t.jsx(C, {
                    name: "name",
                    initialValue: v.name,
                    validate: se(R, rt(4), it(120)),
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validateFields: [],
                    children: (e) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                      children: [
                        /* @__PURE__ */ t.jsx(Q, {
                          id: e.input.name,
                          autoComplete: "off",
                          name: e.input.name,
                          value: e.input.value,
                          onChange: e.input.onChange,
                          onBlur: () => {
                            e.input.onChange(e.input.value.trim());
                          },
                          onInput: (r) => {
                            r.target.value = r.target.value.replace(re("A-Za-z "), "");
                          },
                          disabled: Z,
                          placeholder: o._({
                            id: "q2sTsF"
                          }),
                          maxLength: 70,
                          status: e.meta.error && e.meta.touched ? "error" : void 0,
                          autoFocus: !0
                        }),
                        e.meta.error && e.meta.touched && /* @__PURE__ */ t.jsx(y, {
                          type: "error",
                          variant: "field",
                          title: e.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              u && /* @__PURE__ */ t.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ t.jsx(F, {
                    message: o._({
                      id: "HiZNu3"
                    })
                  }),
                  /* @__PURE__ */ t.jsx(C, {
                    name: "date_of_birth",
                    initialValue: v.date_of_birth,
                    validate: se(R, st, lt(o._({
                      id: "DH1QQr"
                    }))),
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validateFields: [],
                    children: (e) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                      children: [
                        /* @__PURE__ */ t.jsx(Oe, {
                          name: e.input.name,
                          variant: "keyboardInputDOB",
                          placeholder: o._({
                            id: "a7XFrx"
                          }),
                          format: "dd-MMM-yyyy",
                          selectedDate: e.input.value || null,
                          maxDate: h,
                          initialFocusedDate: h,
                          error: e.meta.error && e.meta.touched,
                          helperText: null,
                          onKeyDown: (r) => r.preventDefault(),
                          handleDateChange: (r, _) => e.input.onChange(r)
                        }),
                        e.meta.error && e.meta.touched && /* @__PURE__ */ t.jsx(y, {
                          type: "error",
                          variant: "field",
                          title: e.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              /* @__PURE__ */ t.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ t.jsx(F, {
                    message: o._({
                      id: "568G4c"
                    })
                  }),
                  /* @__PURE__ */ t.jsx(C, {
                    name: "gender",
                    validate: R,
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validateFields: [],
                    children: (e) => {
                      var r, _, g;
                      return /* @__PURE__ */ t.jsxs(t.Fragment, {
                        children: [
                          /* @__PURE__ */ t.jsx(we, {
                            name: e.input.name,
                            size: "sm",
                            checked: I(f, (r = e.input.value) == null ? void 0 : r.value),
                            onChange: (G, he) => e.input.onChange(he),
                            group: f,
                            disabled: Z && ((_ = v == null ? void 0 : v.gender) != null && _.value) ? Ge(f, (g = v == null ? void 0 : v.gender) == null ? void 0 : g.value) : []
                          }),
                          e.meta.error && e.meta.touched && /* @__PURE__ */ t.jsx(y, {
                            type: "error",
                            variant: "field",
                            title: e.meta.error
                          })
                        ]
                      });
                    }
                  })
                ]
              }),
              /* @__PURE__ */ t.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ t.jsx(F, {
                    message: o._({
                      id: "r3WMz0"
                    })
                  }),
                  /* @__PURE__ */ t.jsx(C, {
                    name: "country_of_birth",
                    initialValue: v.country_of_birth,
                    validate: R,
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validateFields: [],
                    children: (e) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                      children: [
                        /* @__PURE__ */ t.jsx(Ie, {
                          value: e.input.value,
                          onChange: (r) => e.input.onChange(r),
                          options: T,
                          searchAttribute: "label",
                          variant: "autocomplete"
                        }),
                        (e.meta.error && e.meta.touched || p) && /* @__PURE__ */ t.jsx(y, {
                          type: "error",
                          variant: "field",
                          title: p ? o._({
                            id: "lEyTi5"
                          }) : e.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              !A || L ? /* @__PURE__ */ t.jsxs(t.Fragment, {
                children: [
                  /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(F, {
                        message: "Marital status"
                      }),
                      /* @__PURE__ */ t.jsx(C, {
                        name: "marital_status",
                        initialValue: v.marital_status,
                        validate: R,
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validateFields: [],
                        children: (e) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                          children: [
                            /* @__PURE__ */ t.jsx(Fe, {
                              name: e.input.name,
                              value: e.input.value,
                              onChange: (r) => e.input.onChange(r),
                              options: N,
                              status: e.meta.error && e.meta.touched ? "error" : void 0
                            }),
                            e.meta.error && e.meta.touched && /* @__PURE__ */ t.jsx(y, {
                              type: "error",
                              variant: "field",
                              title: e.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(F, {
                        message: `Father's  ${((a = l.values.marital_status) == null ? void 0 : a.value) === "married" ? "or Spouse's" : ""} name in full`
                      }),
                      /* @__PURE__ */ t.jsx(C, {
                        name: "relation_name",
                        validate: se(R, ye("Invalid Name")),
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validateFields: [],
                        children: (e) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                          children: [
                            /* @__PURE__ */ t.jsx(Q, {
                              id: e.input.name,
                              autoComplete: "off",
                              name: e.input.name,
                              value: e.input.value,
                              onChange: e.input.onChange,
                              onBlur: () => {
                                e.input.onChange(e.input.value.trim());
                              },
                              onInput: (r) => {
                                r.target.value = r.target.value.replace(re("A-Za-z "), "");
                              },
                              maxLength: 70,
                              status: e.meta.error && e.meta.touched ? "error" : void 0
                            }),
                            e.meta.error && e.meta.touched && /* @__PURE__ */ t.jsx(y, {
                              type: "error",
                              variant: "field",
                              title: e.meta.error
                            })
                          ]
                        })
                      }),
                      ((d = l.values.marital_status) == null ? void 0 : d.value) === "married" && /* @__PURE__ */ t.jsxs("div", {
                        className: "pt-3",
                        children: [
                          /* @__PURE__ */ t.jsx(F, {
                            message: "Relationship with the person mentioned above"
                          }),
                          /* @__PURE__ */ t.jsx(C, {
                            name: "relation_type",
                            validate: R,
                            validateFields: [],
                            subscription: {
                              touched: !0,
                              error: !0,
                              value: !0
                            },
                            children: (e) => {
                              var r;
                              return /* @__PURE__ */ t.jsxs(t.Fragment, {
                                children: [
                                  /* @__PURE__ */ t.jsx(we, {
                                    name: e.input.name,
                                    size: "sm",
                                    value: e.input.value,
                                    checked: I(H, (r = e.input.value) == null ? void 0 : r.value),
                                    onChange: (_, g) => {
                                      e.input.onChange(g);
                                    },
                                    group: H
                                  }),
                                  e.meta.error && e.meta.touched && /* @__PURE__ */ t.jsx(y, {
                                    type: "error",
                                    variant: "field",
                                    title: e.meta.error
                                  })
                                ]
                              });
                            }
                          })
                        ]
                      })
                    ]
                  }),
                  /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(F, {
                        message: "Mother's name in full"
                      }),
                      /* @__PURE__ */ t.jsx(C, {
                        name: "mother_name",
                        validate: se(R, ye("Invalid Name")),
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validateFields: [],
                        children: (e) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                          children: [
                            /* @__PURE__ */ t.jsx(Q, {
                              id: e.input.name,
                              autoComplete: "off",
                              name: e.input.name,
                              value: e.input.value,
                              onChange: e.input.onChange,
                              onBlur: () => {
                                e.input.onChange(e.input.value.trim());
                              },
                              onInput: (r) => {
                                r.target.value = r.target.value.replace(re("A-Za-z "), "");
                              },
                              maxLength: 70,
                              status: e.meta.error && e.meta.touched ? "error" : void 0
                            }),
                            e.meta.error && e.meta.touched && /* @__PURE__ */ t.jsx(y, {
                              type: "error",
                              variant: "field",
                              title: e.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  })
                ]
              }) : null,
              L ? null : /* @__PURE__ */ t.jsxs(t.Fragment, {
                children: [
                  /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(F, {
                        message: o._({
                          id: "cvvS9u"
                        })
                      }),
                      /* @__PURE__ */ t.jsx(C, {
                        name: "place_of_birth",
                        initialValue: v.place_of_birth,
                        validate: R,
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validateFields: [],
                        children: (e) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                          children: [
                            /* @__PURE__ */ t.jsx(Q, {
                              id: e.input.name,
                              autoComplete: "off",
                              name: e.input.name,
                              value: e.input.value,
                              onChange: e.input.onChange,
                              onBlur: () => {
                                e.input.onChange(e.input.value.trim());
                              },
                              onInput: (r) => {
                                r.target.value = r.target.value.replace(re("A-Za-z "), "");
                              },
                              placeholder: o._({
                                id: "CJG120"
                              }),
                              maxLength: 60,
                              status: e.meta.error && e.meta.touched ? "error" : void 0
                            }),
                            e.meta.error && e.meta.touched && /* @__PURE__ */ t.jsx(y, {
                              type: "error",
                              variant: "field",
                              title: e.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(F, {
                        message: o._({
                          id: "KgGQqC"
                        })
                      }),
                      /* @__PURE__ */ t.jsx(C, {
                        name: "pep_details",
                        initialValue: v.pep_details,
                        validate: R,
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (e) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                          children: [
                            /* @__PURE__ */ t.jsx(Fe, {
                              name: e.input.name,
                              value: e.input.value,
                              onChange: (r) => {
                                e.input.onChange(r);
                              },
                              options: w,
                              status: e.meta.error && e.meta.touched ? "error" : void 0
                            }),
                            e.meta.error && e.meta.touched && /* @__PURE__ */ t.jsx(y, {
                              type: "error",
                              variant: "field",
                              title: e.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(F, {
                        message: o._({
                          id: "m53GGj"
                        })
                      }),
                      /* @__PURE__ */ t.jsx(C, {
                        name: "tax_status",
                        initialValue: v.tax_status,
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (e) => /* @__PURE__ */ t.jsx(t.Fragment, {
                          children: /* @__PURE__ */ t.jsx(Q, {
                            id: e.input.name,
                            autoComplete: "off",
                            name: e.input.name,
                            value: "Resident Indian",
                            disabled: !0
                          })
                        })
                      })
                    ]
                  })
                ]
              }),
              B && /* @__PURE__ */ t.jsx(y, {
                type: "error",
                variant: "field",
                title: B
              }),
              /* @__PURE__ */ t.jsx(Le, {
                type: "submit",
                fullwidth: !0,
                onClick: () => {
                  const e = Object.keys(l.errors);
                  e.length && ze(e[0]);
                },
                customClass: "mb-8",
                children: o._({
                  id: "AtXdm9"
                })
              })
            ]
          });
        }
      })
    ]
  });
}, At = () => {
  const { fpapi: c } = J(Re), { loggedInUser: P } = J(Me), { AppState: { showWelcomePage: s, tenant: Y }, setShowWelcomePage: m } = J(Ne), [b, k] = x(null), [A, L] = x(null), [S, j] = x(null), [z, q] = x(!0), [Z, U] = x(null), [oe, K] = x(""), [D, ee] = x(""), de = [
    {
      step: 1,
      content: o._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: o._({
        id: "xwuL3C"
      })
    }
  ];
  function ue() {
    m(!1);
  }
  const ce = Pe(async (i) => {
    var p;
    const n = Se(i == null ? void 0 : i.annexure);
    let u = null;
    if (n != null && n.kycCheckId)
      try {
        const h = await c.fpClient.kyc_checks().fetch(n == null ? void 0 : n.kycCheckId);
        if (We(h.updated_at))
          try {
            u = {
              ...await c.fpClient.kyc_checks().refetch(n == null ? void 0 : n.kycCheckId)
            };
          } catch (f) {
            throw window.debug.error(f), f;
          }
        else
          u = {
            ...h
          };
      } catch (h) {
        throw window.debug.error(h), h;
      }
    else if (i.id) {
      const h = i.id;
      try {
        u = {
          ...await c.fpClient.kyc_checks().create({
            pan: i != null && i.username ? (p = i == null ? void 0 : i.username) == null ? void 0 : p.toUpperCase() : null
          })
        };
        const f = {
          ...n,
          kycCheckId: u.id
        };
        try {
          const N = await c.updateUser(h, f, null, Y);
          if (!N)
            throw window.debug.error("USER UPDATION ERROR"), N;
        } catch (N) {
          throw window.debug.error(N), N;
        }
      } catch (w) {
        throw window.debug.error(w), w;
      }
    }
    return u;
  }, [
    P
  ]), W = (i) => {
    if (i.status)
      return "kyc_compliant";
    {
      const n = {
        unavailable: 1,
        rejected: 1,
        legacy: 1,
        unknown: 1
      };
      return i.action === "disallowed" || !n[i == null ? void 0 : i.reason] ? "kyc_disallowed" : "kyc_allowed";
    }
  }, me = async (i) => {
    const n = await ce(i);
    switch (ee(n), W(n)) {
      case "kyc_compliant":
        return U(o._({
          id: "IN6RGB"
        })), K("kyc_compliant"), "kyc_compliant";
      case "kyc_disallowed":
        return U(o._({
          id: "ZG6cou"
        })), K("kyc_disallowed"), "kyc_disallowed";
      case "kyc_allowed":
        return "kyc_allowed";
      default:
        return;
    }
  }, O = async (i) => {
    const n = await c.fpClient.ciam_user_management().searchUser(`fpa_${Y}`, i);
    if (n != null && n.email && (n != null && n.phone_number)) {
      const { data: u } = await c.fpClient.email_addresses().fetchAll({
        profile: i
      }), { data: p } = await c.fpClient.phone_numbers().fetchAll({
        profile: i
      }), h = u.some((f) => f.email === (n == null ? void 0 : n.email)), w = p.some((f) => f.number === (n == null ? void 0 : n.phone_number));
      h || await c.fpClient.email_addresses().create({
        profile: i,
        email: n == null ? void 0 : n.email,
        belongs_to: "self"
      }), w || await c.fpClient.phone_numbers().create({
        profile: i,
        isd: "91",
        number: n == null ? void 0 : n.phone_number,
        belongs_to: "self"
      });
    }
  }, te = (i) => {
    var n, u;
    if (i) {
      if ([
        "successful",
        "submitted",
        "expired",
        "rejected"
      ].includes(i == null ? void 0 : i.status))
        return `kyc_${i == null ? void 0 : i.status}`;
      if ((n = i == null ? void 0 : i.requirements) != null && n.fields_needed.length) {
        const p = (u = i == null ? void 0 : i.requirements) == null ? void 0 : u.fields_needed, v = {
          personal_details: [
            "name",
            "date_of_birth",
            "gender",
            "country_of_birth",
            "gender",
            "residential_status",
            "marital_status",
            "father_name_or_spouse_name",
            "mother_name"
          ],
          financial_details: [
            "occupation_type"
          ],
          banking_details: [
            "bank_account"
          ],
          contact_details: [
            "address",
            "aadhaar_number"
          ],
          kyc_file_uploads: [
            "identity_proof",
            "photo",
            "signature"
          ],
          kyc_ipv: [
            "geolocation",
            "ipv_video"
          ]
        };
        for (const [ae, ne] of Object.entries(v))
          for (const ie of ne)
            if (p.includes(ie))
              return ae;
        return;
      } else
        return "kyc_esign";
    } else return "personal_details";
  }, T = async (i) => {
    var n;
    try {
      const u = await c.fpClient.kyc_requests().fetchAll({
        pan: i
      }), p = (n = u == null ? void 0 : u.data) == null ? void 0 : n[0], h = te(p);
      p && k(p), j(h), q(!1);
    } catch (u) {
      window.debug.error(u), U(o._({
        id: "yL67N0"
      })), M(u);
    }
  };
  $(() => {
    P ? (async () => {
      try {
        const n = (await c.fetchUser(P.profile.preferred_username, Y))[0];
        L(n), await me(n) === "kyc_allowed" && (await O(n == null ? void 0 : n.profile), await T(n == null ? void 0 : n.username));
      } catch (i) {
        U(o._({
          id: "ujiE2G"
        })), window.debug.error(i), M(i);
      } finally {
        q(!1);
      }
    })() : (j("login"), q(!1));
  }, [
    P
  ]);
  const E = ({ updatedKycDetails: i }) => {
    const n = te(i);
    j(n), k(i);
  }, B = () => {
    j("personal_details");
  };
  function V() {
    switch (S) {
      case "login":
        return /* @__PURE__ */ t.jsx(dt, {
          redirectURL: window.location.href,
          allowRegistration: !0,
          showTestPan: {
            kycNonCompliant: !0
          }
        });
      case "personal_details":
        return /* @__PURE__ */ t.jsx(ut, {
          user: A,
          kycDetails: b,
          isKycOnly: !0,
          onCompletion: E
        });
      case "financial_details":
        return /* @__PURE__ */ t.jsx(tt, {
          kycDetails: b,
          isKycOnly: !0,
          onCompletion: E
        });
      case "banking_details":
        return /* @__PURE__ */ t.jsx(et, {
          kycDetails: b,
          isKycOnly: !0,
          onCompletion: E
        });
      case "contact_details":
        return /* @__PURE__ */ t.jsx(De, {
          kycDetails: b,
          isKycOnly: !0,
          onCompletion: E
        });
      case "kyc_file_uploads":
        return /* @__PURE__ */ t.jsx(ke, {
          kycDetails: b,
          isKycOnly: !0,
          onCompletion: E
        });
      case "kyc_ipv":
        return /* @__PURE__ */ t.jsx(Ye, {
          kycDetails: b,
          isKycOnly: !0,
          onCompletion: E
        });
      case "kyc_esign":
        return /* @__PURE__ */ t.jsx(Je, {
          kycDetails: b,
          isKycOnly: !0,
          onCompletion: E
        });
      case "kyc_submitted":
      case "kyc_successful":
      case "kyc_rejected":
      case "kyc_expired":
        return /* @__PURE__ */ t.jsx($e, {
          kycRequestObject: b,
          handleRejectedAction: B,
          handleExpiredAction: B
        });
      default:
        return /* @__PURE__ */ t.jsx(ve, {
          variant: "fullpage"
        });
    }
  }
  function fe() {
    return z ? /* @__PURE__ */ t.jsx(ve, {
      variant: "fullpage"
    }) : Z ? oe === "kyc_compliant" && D ? /* @__PURE__ */ t.jsx("div", {
      className: "mt-10",
      children: /* @__PURE__ */ t.jsx(Xe, {
        kycCheckObject: D
      })
    }) : /* @__PURE__ */ t.jsx("div", {
      className: "mt-10",
      children: /* @__PURE__ */ t.jsx(Qe, {
        title: "Error",
        description: Z,
        showAction: !0,
        actionText: "Retry",
        actionHandler: () => window.location.reload()
      })
    }) : V();
  }
  return /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(Ce, {
        title: o._({
          id: "kyVIEo"
        })
      }),
      s ? /* @__PURE__ */ t.jsx(He, {
        onAck: ue,
        welcomeSteps: de
      }) : fe()
    ]
  });
};
export {
  At as ManageKyc
};
