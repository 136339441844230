import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as s, useState as i, useEffect as p } from "react";
import { P as S, a as A } from "./index.esm-BV4iWo1a.js";
import { AppContext as C } from "./AppContext.js";
import { F as L } from "./provider-DZPLM8yJ.js";
import { LoginContext as F } from "./LoginContext.js";
import { i as a } from "./index-B3vUTrRT.js";
import { r as v } from "./sentry-ZQO5C1U6.js";
import { W } from "./Welcome-3PCWRbTF.js";
import { H as _ } from "./Holdings-LbXaFrPe.js";
import { A as E } from "./Auth-CjS0P6BG.js";
function q() {
  const { fpapi: r } = s(L), { loggedInUser: t } = s(F), { AppState: { showWelcomePage: m }, setShowWelcomePage: u } = s(C), [d, f] = i(l), [g, n] = i(!0), [x, w] = i({}), h = [
    {
      step: 1,
      content: a._({
        id: "bwiJz/"
      })
    },
    {
      step: 2,
      content: a._({
        id: "X7wgSI"
      })
    }
  ];
  p(() => {
    var c;
    r ? r.getInvestorProfile((c = t == null ? void 0 : t.profile) == null ? void 0 : c.fp_identifier).then((o) => {
      o && w(o), n(!1);
    }).catch((o) => {
      n(!1), v(o);
    }) : n(!1);
  }, [
    r
  ]), p(() => {
    f(l());
  }, [
    m
  ]);
  function l() {
    return m ? "welcome" : "statement";
  }
  function j() {
    u(!1), f("statement");
  }
  function P() {
    return d === "welcome" ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsx(W, {
        onAck: j,
        welcomeSteps: h
      })
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: g ? /* @__PURE__ */ e.jsx(A, {
        variant: "fullpage"
      }) : t ? /* @__PURE__ */ e.jsx(_, {
        investorProfile: x
      }) : /* @__PURE__ */ e.jsx(E, {
        redirectURL: window.location.href,
        allowRegistration: !1,
        showTestPan: {
          onboarded: !0
        }
      })
    });
  }
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(S, {
        title: a._({
          id: "JwUPeL"
        })
      }),
      P()
    ]
  });
}
export {
  q as Statement
};
