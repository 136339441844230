import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { i as s } from "./index-B3vUTrRT.js";
import { a as b, P as w, D as T, e as x, T as h, M as n, B as I, S as R, o as W, C as g, c as j } from "./index.esm-BV4iWo1a.js";
import { useContext as F, useState as u, useEffect as B } from "react";
import { AppContext as k } from "./AppContext.js";
import { u as z } from "./useAuth-D55hgrWW.js";
import { F as L } from "./provider-DZPLM8yJ.js";
import { R as U, F as f } from "./react-final-form.es-DAN9JLvE.js";
import { f as v, g as E, h as A, r as p, b as V, a as G } from "./validators-BArvL7cB.js";
import { o as y } from "./utils-BTh-HRzb.js";
import { r as N } from "./sentry-ZQO5C1U6.js";
import { S as P } from "./StatusMessage-DFmLnokA.js";
import { W as Z } from "./Welcome-3PCWRbTF.js";
const $ = ({ onComplete: C }) => {
  const { fpapi: _ } = F(L), [m, i] = u("CREATE_TENANT_FORM"), [S, l] = u(!1), [d, c] = u(null), [r, M] = u(null), O = async (o) => {
    var t;
    try {
      l(!0);
      const a = {
        tenant_name: (t = o.domain_name) == null ? void 0 : t.toLowerCase(),
        admin_email: o.email,
        admin_name: o.name,
        admin_phone_number: o.phone
      };
      await _.fpClient.tenant_management().create(a), M(a), i("SUCCESS");
    } catch (a) {
      (a == null ? void 0 : a.status) === 500 ? (window.debug.error(a), N(a), c(s._({
        id: "8+73yZ"
      }))) : (window.debug.error(a), N(a), c(s._({
        id: "qMcu4/"
      })));
    } finally {
      l(!1);
    }
  };
  return S ? /* @__PURE__ */ e.jsx(b, {
    variant: "fullpage"
  }) : (() => {
    switch (m) {
      case "SUCCESS":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(P, {
              title: "Tenant created successfully",
              iconName: "successInfo"
            }),
            /* @__PURE__ */ e.jsx(R, {
              header: {
                title: /* @__PURE__ */ e.jsx("span", {
                  className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
                  children: s._({
                    id: "ByO75L"
                  })
                })
              },
              customClass: "mb-4",
              children: /* @__PURE__ */ e.jsxs(W, {
                children: [
                  /* @__PURE__ */ e.jsx(g, {
                    span: 6,
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: s._({
                        id: "UYJNdz"
                      }),
                      value: `${r == null ? void 0 : r.tenant_name}`
                    })
                  }),
                  /* @__PURE__ */ e.jsx(g, {
                    span: 6,
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: s._({
                        id: "y+5NwF"
                      }),
                      value: `${r == null ? void 0 : r.admin_name}`
                    })
                  }),
                  /* @__PURE__ */ e.jsx(g, {
                    span: 6,
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: s._({
                        id: "oyjr24"
                      }),
                      value: `${r == null ? void 0 : r.admin_email}`
                    })
                  }),
                  /* @__PURE__ */ e.jsx(g, {
                    span: 6,
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: s._({
                        id: "0VsDgp"
                      }),
                      value: `${r == null ? void 0 : r.admin_phone_number}`
                    })
                  })
                ]
              })
            })
          ]
        });
      case "CREATE_TENANT_FORM":
        return /* @__PURE__ */ e.jsxs("div", {
          children: [
            /* @__PURE__ */ e.jsx(w, {
              title: s._({
                id: "tiU6Em"
              })
            }),
            /* @__PURE__ */ e.jsx(T, {
              type: "text",
              text: "Tell us about your business"
            }),
            /* @__PURE__ */ e.jsx(U, {
              onSubmit: O,
              subscription: {
                submitting: !0,
                values: !0,
                errors: !0,
                touched: !0
              },
              children: (o) => /* @__PURE__ */ e.jsxs("form", {
                onSubmit: o.handleSubmit,
                children: [
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ e.jsx(x, {
                        message: s._({
                          id: "UYJNdz"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(f, {
                        name: "domain_name",
                        validate: v(p, A(5), E(15)),
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(h, {
                              name: t.input.name,
                              id: t.input.name,
                              autoComplete: "off",
                              value: t.input.value,
                              onInput: (a) => {
                                a.target.value = a.target.value.replace(y("A-Za-z"), "");
                              },
                              onChange: (a) => t.input.onChange(a.target.value)
                            }),
                            t.meta.error && t.meta.touched ? /* @__PURE__ */ e.jsx(n, {
                              type: "error",
                              variant: "field",
                              title: t.meta.error
                            }) : /* @__PURE__ */ e.jsx(n, {
                              type: "helper",
                              variant: "field",
                              title: "Business or domain name of your MF distribution app",
                              customClass: "mb-5"
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ e.jsx(x, {
                        message: s._({
                          id: "y+5NwF"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(f, {
                        name: "name",
                        validate: v(p, A(3), E(75)),
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(h, {
                              name: t.input.name,
                              value: t.input.value,
                              onChange: (a) => t.input.onChange(a.target.value),
                              onInput: (a) => {
                                a.target.value = a.target.value.replace(y("A-Za-z "), "");
                              },
                              id: t.input.name,
                              autoComplete: "off"
                            }),
                            t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(n, {
                              type: "error",
                              variant: "field",
                              title: t.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ e.jsx(x, {
                        message: s._({
                          id: "oyjr24"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(f, {
                        name: "email",
                        validate: v(p, V("Enter valid email")),
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(h, {
                              name: t.input.name,
                              value: t.input.value,
                              onChange: (a) => t.input.onChange(a.target.value),
                              id: t.input.name,
                              autoComplete: "off"
                            }),
                            t.meta.error && t.meta.touched ? /* @__PURE__ */ e.jsx(n, {
                              type: "error",
                              variant: "field",
                              title: t.meta.error
                            }) : /* @__PURE__ */ e.jsx(n, {
                              type: "helper",
                              variant: "field",
                              title: "Account details will be sent to this email",
                              customClass: "mb-5"
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ e.jsx(x, {
                        message: s._({
                          id: "0VsDgp"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(f, {
                        name: "phone",
                        validate: v(p, G("Enter valid 10 digit mobile number")),
                        validateFields: [],
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(h, {
                              name: t.input.name,
                              value: t.input.value,
                              onChange: (a) => t.input.onChange(a.target.value),
                              id: t.input.name,
                              autoComplete: "off",
                              onInput: (a) => {
                                a.target.value = a.target.value.replace(y("0-9"), "");
                              },
                              maxLength: 10
                            }),
                            t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(n, {
                              type: "error",
                              variant: "field",
                              title: t.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  d && /* @__PURE__ */ e.jsx(n, {
                    type: "error",
                    variant: "field",
                    title: d,
                    customClass: "mb-2"
                  }),
                  /* @__PURE__ */ e.jsx(I, {
                    type: "submit",
                    fullwidth: !0,
                    customClass: "mb-2 mt-4",
                    children: s._({
                      id: "2iVSoY"
                    })
                  })
                ]
              })
            })
          ]
        });
      default:
        return /* @__PURE__ */ e.jsx(b, {
          variant: "fullpage"
        });
    }
  })();
};
function ne() {
  const { AppState: { showWelcomePage: C }, setShowWelcomePage: _ } = F(k), { isFpapiLoading: m } = F(L), i = z(), [S, l] = u("LOADING"), d = () => {
    _(!1), l("LOADING");
  }, c = [
    {
      step: 1,
      content: s._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: s._({
        id: "9TfQxF"
      })
    }
  ];
  return B(() => {
    if (C)
      return l("WELCOME");
    i.isLoading || (i.isAuthenticated ? m || l("TMS_ONBOARD") : i.login());
  }, [
    i.isAuthenticated,
    i.isLoading,
    C,
    m
  ]), (() => {
    switch (S) {
      case "TMS_ONBOARD":
        return /* @__PURE__ */ e.jsx($, {});
      case "LOADING":
        return /* @__PURE__ */ e.jsx(b, {
          variant: "fullpage"
        });
      case "WELCOME":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(w, {
              title: s._({
                id: "+rIyxV"
              }),
              customClass: "mb-6"
            }),
            /* @__PURE__ */ e.jsx(Z, {
              onAck: d,
              welcomeSteps: c
            })
          ]
        });
      default:
        return /* @__PURE__ */ e.jsx(b, {
          variant: "fullpage"
        });
    }
  })();
}
export {
  ne as TenantManagementServiceOnboard
};
