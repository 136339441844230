import { _ as R, n as Se } from "./index.esm-BV4iWo1a.js";
import * as T from "react";
import me from "react";
var Ke = 46, ke = /\\(\\)?/g, Ce = RegExp(
  // Match anything that isn't a dot or bracket.
  `[^.[\\]]+|\\[(?:([^"'][^[]*)|(["'])((?:(?!\\2)[^\\\\]|\\\\.)*?)\\2)\\]|(?=(?:\\.|\\[\\])(?:\\.|\\[\\]|$))`,
  "g"
), Te = function(e) {
  var a = [];
  return e.charCodeAt(0) === Ke && a.push(""), e.replace(Ce, function(u, o, d, f) {
    var c = u;
    d ? c = f.replace(ke, "$1") : o && (c = o.trim()), a.push(c);
  }), a;
}, be = {}, He = /[.[\]]+/, Pe = function(e) {
  if (e == null || !e.length)
    return [];
  if (typeof e != "string")
    throw new Error("toPath() expects a string");
  return be[e] == null && (e.endsWith("[]") ? be[e] = e.split(He).filter(Boolean) : be[e] = Te(e)), be[e];
}, L = function(e, a) {
  for (var u = Pe(a), o = e, d = 0; d < u.length; d++) {
    var f = u[d];
    if (o == null || typeof o != "object" || Array.isArray(o) && isNaN(f))
      return;
    o = o[f];
  }
  return o;
};
function Ye(i) {
  var e = Ge(i, "string");
  return typeof e == "symbol" ? e : String(e);
}
function Ge(i, e) {
  if (typeof i != "object" || i === null) return i;
  var a = i[Symbol.toPrimitive];
  if (a !== void 0) {
    var u = a.call(i, e);
    if (typeof u != "object") return u;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return String(i);
}
var Je = function i(e, a, u, o, d) {
  if (a >= u.length)
    return o;
  var f = u[a];
  if (isNaN(f)) {
    var c;
    if (e == null) {
      var V, r = i(void 0, a + 1, u, o, d);
      return r === void 0 ? void 0 : (V = {}, V[f] = r, V);
    }
    if (Array.isArray(e))
      throw new Error("Cannot set a non-numeric property on an array");
    var O = i(e[f], a + 1, u, o, d);
    if (O === void 0) {
      var A = Object.keys(e).length;
      if (e[f] === void 0 && A === 0)
        return;
      if (e[f] !== void 0 && A <= 1)
        return !isNaN(u[a - 1]) && !d ? {} : void 0;
      e[f];
      var P = Se(e, [f].map(Ye));
      return P;
    }
    return R({}, e, (c = {}, c[f] = O, c));
  }
  var j = Number(f);
  if (e == null) {
    var I = i(void 0, a + 1, u, o, d);
    if (I === void 0)
      return;
    var N = [];
    return N[j] = I, N;
  }
  if (!Array.isArray(e))
    throw new Error("Cannot set a numeric property on an object");
  var Y = e[j], S = i(Y, a + 1, u, o, d), M = [].concat(e);
  if (d && S === void 0) {
    if (M.splice(j, 1), M.length === 0)
      return;
  } else
    M[j] = S;
  return M;
}, H = function(e, a, u, o) {
  if (o === void 0 && (o = !1), e == null)
    throw new Error("Cannot call setIn() with " + String(e) + " state");
  if (a == null)
    throw new Error("Cannot call setIn() with " + String(a) + " key");
  return Je(e, 0, Pe(a), u, o);
}, we = "FINAL_FORM/form-error", ve = "FINAL_FORM/array-error";
function Le(i, e) {
  var a = i.errors, u = i.initialValues, o = i.lastSubmittedValues, d = i.submitErrors, f = i.submitFailed, c = i.submitSucceeded, V = i.submitting, r = i.values, O = e.active, A = e.blur, P = e.change, j = e.data, I = e.focus, N = e.modified, Y = e.modifiedSinceLastSubmit, S = e.name, M = e.touched, x = e.validating, q = e.visited, K = L(r, S), $ = L(a, S);
  $ && $[ve] && ($ = $[ve]);
  var k = d && L(d, S), E = u && L(u, S), v = e.isEqual(E, K), oe = !!(o && !e.isEqual(L(o, S), K)), ae = !$ && !k;
  return {
    active: O,
    blur: A,
    change: P,
    data: j,
    dirty: !v,
    dirtySinceLastSubmit: oe,
    error: $,
    focus: I,
    initial: E,
    invalid: !ae,
    length: Array.isArray(K) ? K.length : void 0,
    modified: N,
    modifiedSinceLastSubmit: Y,
    name: S,
    pristine: v,
    submitError: k,
    submitFailed: f,
    submitSucceeded: c,
    submitting: V,
    touched: M,
    valid: ae,
    value: K,
    visited: q,
    validating: x
  };
}
var Ne = ["active", "data", "dirty", "dirtySinceLastSubmit", "error", "initial", "invalid", "length", "modified", "modifiedSinceLastSubmit", "pristine", "submitError", "submitFailed", "submitSucceeded", "submitting", "touched", "valid", "value", "visited", "validating"], Q = function(e, a) {
  if (e === a)
    return !0;
  if (typeof e != "object" || !e || typeof a != "object" || !a)
    return !1;
  var u = Object.keys(e), o = Object.keys(a);
  if (u.length !== o.length)
    return !1;
  for (var d = Object.prototype.hasOwnProperty.bind(a), f = 0; f < u.length; f++) {
    var c = u[f];
    if (!d(c) || e[c] !== a[c])
      return !1;
  }
  return !0;
};
function qe(i, e, a, u, o, d) {
  var f = !1;
  return o.forEach(function(c) {
    u[c] && (i[c] = e[c], (!a || (~d.indexOf(c) ? !Q(e[c], a[c]) : e[c] !== a[c])) && (f = !0));
  }), f;
}
var Qe = ["data"], Xe = function(e, a, u, o) {
  var d = {
    blur: e.blur,
    change: e.change,
    focus: e.focus,
    name: e.name
  }, f = qe(d, e, a, u, Ne, Qe) || !a;
  return f || o ? d : void 0;
}, Be = ["active", "dirty", "dirtyFields", "dirtyFieldsSinceLastSubmit", "dirtySinceLastSubmit", "error", "errors", "hasSubmitErrors", "hasValidationErrors", "initialValues", "invalid", "modified", "modifiedSinceLastSubmit", "pristine", "submitting", "submitError", "submitErrors", "submitFailed", "submitSucceeded", "touched", "valid", "validating", "values", "visited"], Ze = ["touched", "visited"];
function Re(i, e, a, u) {
  var o = {}, d = qe(o, i, e, a, Be, Ze) || !e;
  return d || u ? o : void 0;
}
var je = function(e) {
  var a, u;
  return function() {
    for (var o = arguments.length, d = new Array(o), f = 0; f < o; f++)
      d[f] = arguments[f];
    return (!a || d.length !== a.length || d.some(function(c, V) {
      return !Q(a[V], c);
    })) && (a = d, u = e.apply(void 0, d)), u;
  };
}, pe = function(i) {
  return !!i && (typeof i == "object" || typeof i == "function") && typeof i.then == "function";
}, _e = "4.20.10", et = function(e, a) {
  return e === a;
}, fe = function i(e) {
  return Object.keys(e).some(function(a) {
    var u = e[a];
    return u && typeof u == "object" && !(u instanceof Error) ? i(u) : typeof u < "u";
  });
};
function tt(i) {
  var e = i.active, a = i.dirtySinceLastSubmit, u = i.modifiedSinceLastSubmit, o = i.error, d = i.errors, f = i.initialValues, c = i.pristine, V = i.submitting, r = i.submitFailed, O = i.submitSucceeded, A = i.submitError, P = i.submitErrors, j = i.valid, I = i.validating, N = i.values;
  return {
    active: e,
    dirty: !c,
    dirtySinceLastSubmit: a,
    modifiedSinceLastSubmit: u,
    error: o,
    errors: d,
    hasSubmitErrors: !!(A || P && fe(P)),
    hasValidationErrors: !!(o || fe(d)),
    invalid: !j,
    initialValues: f,
    pristine: c,
    submitting: V,
    submitFailed: r,
    submitSucceeded: O,
    submitError: A,
    submitErrors: P,
    valid: j,
    validating: I > 0,
    values: N
  };
}
function $e(i, e, a, u, o, d) {
  var f = o(a, u, e, d);
  return f ? (i(f), !0) : !1;
}
function Ae(i, e, a, u, o) {
  var d = i.entries;
  Object.keys(d).forEach(function(f) {
    var c = d[Number(f)];
    if (c) {
      var V = c.subscription, r = c.subscriber, O = c.notified;
      $e(r, V, e, a, u, o || !O) && (c.notified = !0);
    }
  });
}
function it(i) {
  if (!i)
    throw new Error("No config specified");
  var e = i.debug, a = i.destroyOnUnregister, u = i.keepDirtyOnReinitialize, o = i.initialValues, d = i.mutators, f = i.onSubmit, c = i.validate, V = i.validateOnBlur;
  if (!f)
    throw new Error("No onSubmit function specified");
  var r = {
    subscribers: {
      index: 0,
      entries: {}
    },
    fieldSubscribers: {},
    fields: {},
    formState: {
      asyncErrors: {},
      dirtySinceLastSubmit: !1,
      modifiedSinceLastSubmit: !1,
      errors: {},
      initialValues: o && R({}, o),
      invalid: !1,
      pristine: !0,
      submitting: !1,
      submitFailed: !1,
      submitSucceeded: !1,
      resetWhileSubmitting: !1,
      valid: !0,
      validating: 0,
      values: o ? R({}, o) : {}
    },
    lastFormState: void 0
  }, O = 0, A = !1, P = !1, j = !1, I = 0, N = {}, Y = function(t) {
    return function(n) {
      return delete N[t], n;
    };
  }, S = function(t, n, s) {
    var l = L(t.formState.values, n), m = s(l);
    t.formState.values = H(t.formState.values, n, m) || {};
  }, M = function(t, n, s) {
    if (t.fields[n]) {
      var l, m;
      t.fields = R({}, t.fields, (l = {}, l[s] = R({}, t.fields[n], {
        name: s,
        // rebind event handlers
        blur: function() {
          return U.blur(s);
        },
        change: function(F) {
          return U.change(s, F);
        },
        focus: function() {
          return U.focus(s);
        },
        lastFieldState: void 0
      }), l)), delete t.fields[n], t.fieldSubscribers = R({}, t.fieldSubscribers, (m = {}, m[s] = t.fieldSubscribers[n], m)), delete t.fieldSubscribers[n];
      var h = L(t.formState.values, n);
      t.formState.values = H(t.formState.values, n, void 0) || {}, t.formState.values = H(t.formState.values, s, h), delete t.lastFormState;
    }
  }, x = function(t) {
    return function() {
      if (d) {
        for (var n = {
          formState: r.formState,
          fields: r.fields,
          fieldSubscribers: r.fieldSubscribers,
          lastFormState: r.lastFormState
        }, s = arguments.length, l = new Array(s), m = 0; m < s; m++)
          l[m] = arguments[m];
        var h = d[t](l, n, {
          changeValue: S,
          getIn: L,
          renameField: M,
          resetFieldState: U.resetFieldState,
          setIn: H,
          shallowEqual: Q
        });
        return r.formState = n.formState, r.fields = n.fields, r.fieldSubscribers = n.fieldSubscribers, r.lastFormState = n.lastFormState, E(void 0, function() {
          v(), w();
        }), h;
      }
    };
  }, q = d ? Object.keys(d).reduce(function(b, t) {
    return b[t] = x(t), b;
  }, {}) : {}, K = function(t) {
    var n = [];
    if (c) {
      var s = c(R({}, r.formState.values));
      pe(s) ? n.push(s.then(function(l) {
        return t(l, !0);
      })) : t(s, !1);
    }
    return n;
  }, $ = function(t) {
    return Object.keys(t.validators).reduce(function(n, s) {
      var l = t.validators[Number(s)]();
      return l && n.push(l), n;
    }, []);
  }, k = function(t, n) {
    var s = [], l = $(t);
    if (l.length) {
      var m;
      l.forEach(function(h) {
        var y = h(L(r.formState.values, t.name), r.formState.values, h.length === 0 || h.length === 3 ? Le(r.formState, r.fields[t.name]) : void 0);
        if (y && pe(y)) {
          t.validating = !0;
          var F = y.then(function(g) {
            r.fields[t.name] && (r.fields[t.name].validating = !1, n(g));
          });
          s.push(F);
        } else m || (m = y);
      }), n(m);
    }
    return s;
  }, E = function(t, n) {
    if (A) {
      P = !0, n();
      return;
    }
    var s = r.fields, l = r.formState, m = R({}, s), h = Object.keys(m);
    if (!c && !h.some(function(ie) {
      return $(m[ie]).length;
    })) {
      n();
      return;
    }
    var y = !1;
    if (t) {
      var F = m[t];
      if (F) {
        var g = F.validateFields;
        g && (y = !0, h = g.length ? g.concat(t) : [t]);
      }
    }
    var z = {}, W = {}, G = {}, te = [].concat(K(function(ie, ue) {
      ue ? W = ie || {} : z = ie || {};
    }), h.reduce(function(ie, ue) {
      return ie.concat(k(s[ue], function(J) {
        G[ue] = J;
      }));
    }, [])), D = te.length > 0, B = ++I, le = Promise.all(te).then(Y(B));
    D && (N[B] = le);
    var Ee = function(ue) {
      var J = R({}, y ? l.errors : {}, z, ue ? W : l.asyncErrors), Oe = function(se) {
        h.forEach(function(re) {
          if (s[re]) {
            var ce = L(z, re), xe = L(J, re), We = $(m[re]).length, Me = G[re];
            se(re, We && Me || c && ce || (!ce && !y ? xe : void 0));
          }
        });
      };
      Oe(function(de, se) {
        J = H(J, de, se) || {};
      }), Oe(function(de, se) {
        if (se && se[ve]) {
          var re = L(J, de), ce = [].concat(re);
          ce[ve] = se[ve], J = H(J, de, ce);
        }
      }), Q(l.errors, J) || (l.errors = J), ue && (l.asyncErrors = W), l.error = z[we];
    };
    if (D && (r.formState.validating++, n()), Ee(!1), n(), D) {
      var Ve = function() {
        r.formState.validating--, n(), r.formState.validating === 0 && r.lastFormState.validating && w();
      };
      le.then(function() {
        I > B || Ee(!0);
      }).then(Ve, Ve);
    }
  }, v = function(t) {
    if (!O) {
      var n = r.fields, s = r.fieldSubscribers, l = r.formState, m = R({}, n), h = function(F) {
        var g = m[F], z = Le(l, g), W = g.lastFieldState;
        g.lastFieldState = z;
        var G = s[F];
        G && Ae(G, z, W, Xe, W === void 0);
      };
      t ? h(t) : Object.keys(m).forEach(h);
    }
  }, oe = function() {
    Object.keys(r.fields).forEach(function(t) {
      r.fields[t].touched = !0;
    });
  }, ae = function() {
    return !!(r.formState.error || fe(r.formState.errors));
  }, _ = function() {
    var t = r.fields, n = r.formState, s = r.lastFormState, l = R({}, t), m = Object.keys(l), h = !1, y = m.reduce(function(D, B) {
      var le = !l[B].isEqual(L(n.values, B), L(n.initialValues || {}, B));
      return le && (h = !0, D[B] = !0), D;
    }, {}), F = m.reduce(function(D, B) {
      var le = n.lastSubmittedValues || {};
      return l[B].isEqual(L(n.values, B), L(le, B)) || (D[B] = !0), D;
    }, {});
    n.pristine = !h, n.dirtySinceLastSubmit = !!(n.lastSubmittedValues && Object.values(F).some(function(D) {
      return D;
    })), n.modifiedSinceLastSubmit = !!(n.lastSubmittedValues && // Object.values would treat values as mixed (facebook/flow#2221)
    Object.keys(l).some(function(D) {
      return l[D].modifiedSinceLastSubmit;
    })), n.valid = !n.error && !n.submitError && !fe(n.errors) && !(n.submitErrors && fe(n.submitErrors));
    var g = tt(n), z = m.reduce(function(D, B) {
      return D.modified[B] = l[B].modified, D.touched[B] = l[B].touched, D.visited[B] = l[B].visited, D;
    }, {
      modified: {},
      touched: {},
      visited: {}
    }), W = z.modified, G = z.touched, te = z.visited;
    return g.dirtyFields = s && Q(s.dirtyFields, y) ? s.dirtyFields : y, g.dirtyFieldsSinceLastSubmit = s && Q(s.dirtyFieldsSinceLastSubmit, F) ? s.dirtyFieldsSinceLastSubmit : F, g.modified = s && Q(s.modified, W) ? s.modified : W, g.touched = s && Q(s.touched, G) ? s.touched : G, g.visited = s && Q(s.visited, te) ? s.visited : te, s && Q(s, g) ? s : g;
  }, he = function() {
    return e && !0 && e(_(), Object.keys(r.fields).reduce(function(t, n) {
      return t[n] = r.fields[n], t;
    }, {}));
  }, p = !1, C = !1, w = function b() {
    if (p)
      C = !0;
    else {
      if (p = !0, he(), !O && !(A && j)) {
        var t = r.lastFormState, n = _();
        n !== t && (r.lastFormState = n, Ae(r.subscribers, n, t, Re));
      }
      p = !1, C && (C = !1, b());
    }
  }, ee = function() {
    return Object.keys(r.fields).some(function(t) {
      return r.fields[t].beforeSubmit && r.fields[t].beforeSubmit() === !1;
    });
  }, X = function() {
    return Object.keys(r.fields).forEach(function(t) {
      return r.fields[t].afterSubmit && r.fields[t].afterSubmit();
    });
  }, ne = function() {
    return Object.keys(r.fields).forEach(function(t) {
      return r.fields[t].modifiedSinceLastSubmit = !1;
    });
  };
  E(void 0, function() {
    w();
  });
  var U = {
    batch: function(t) {
      O++, t(), O--, v(), w();
    },
    blur: function(t) {
      var n = r.fields, s = r.formState, l = n[t];
      l && (delete s.active, n[t] = R({}, l, {
        active: !1,
        touched: !0
      }), V ? E(t, function() {
        v(), w();
      }) : (v(), w()));
    },
    change: function(t, n) {
      var s = r.fields, l = r.formState;
      if (L(l.values, t) !== n) {
        S(r, t, function() {
          return n;
        });
        var m = s[t];
        m && (s[t] = R({}, m, {
          modified: !0,
          modifiedSinceLastSubmit: !!l.lastSubmittedValues
        })), V ? (v(), w()) : E(t, function() {
          v(), w();
        });
      }
    },
    get destroyOnUnregister() {
      return !!a;
    },
    set destroyOnUnregister(b) {
      a = b;
    },
    focus: function(t) {
      var n = r.fields[t];
      n && !n.active && (r.formState.active = t, n.active = !0, n.visited = !0, v(), w());
    },
    mutators: q,
    getFieldState: function(t) {
      var n = r.fields[t];
      return n && n.lastFieldState;
    },
    getRegisteredFields: function() {
      return Object.keys(r.fields);
    },
    getState: function() {
      return _();
    },
    initialize: function(t) {
      var n = r.fields, s = r.formState, l = R({}, n), m = typeof t == "function" ? t(s.values) : t;
      u || (s.values = m);
      var h = u ? Object.keys(l).reduce(function(y, F) {
        var g = l[F], z = g.isEqual(L(s.values, F), L(s.initialValues || {}, F));
        return z || (y[F] = L(s.values, F)), y;
      }, {}) : {};
      s.initialValues = m, s.values = m, Object.keys(h).forEach(function(y) {
        s.values = H(s.values, y, h[y]) || {};
      }), E(void 0, function() {
        v(), w();
      });
    },
    isValidationPaused: function() {
      return A;
    },
    pauseValidation: function(t) {
      t === void 0 && (t = !0), A = !0, j = t;
    },
    registerField: function(t, n, s, l) {
      s === void 0 && (s = {}), r.fieldSubscribers[t] || (r.fieldSubscribers[t] = {
        index: 0,
        entries: {}
      });
      var m = r.fieldSubscribers[t].index++;
      r.fieldSubscribers[t].entries[m] = {
        subscriber: je(n),
        subscription: s,
        notified: !1
      };
      var h = r.fields[t] || {
        active: !1,
        afterSubmit: l && l.afterSubmit,
        beforeSubmit: l && l.beforeSubmit,
        data: l && l.data || {},
        isEqual: l && l.isEqual || et,
        lastFieldState: void 0,
        modified: !1,
        modifiedSinceLastSubmit: !1,
        name: t,
        touched: !1,
        valid: !0,
        validateFields: l && l.validateFields,
        validators: {},
        validating: !1,
        visited: !1
      };
      h.blur = h.blur || function() {
        return U.blur(t);
      }, h.change = h.change || function(W) {
        return U.change(t, W);
      }, h.focus = h.focus || function() {
        return U.focus(t);
      }, r.fields[t] = h;
      var y = !1, F = l && l.silent, g = function() {
        F && r.fields[t] ? v(t) : (w(), v());
      };
      if (l) {
        y = !!(l.getValidator && l.getValidator()), l.getValidator && (r.fields[t].validators[m] = l.getValidator);
        var z = L(r.formState.values, t) === void 0;
        l.initialValue !== void 0 && (z || L(r.formState.values, t) === L(r.formState.initialValues, t)) && (r.formState.initialValues = H(r.formState.initialValues || {}, t, l.initialValue), r.formState.values = H(r.formState.values, t, l.initialValue), E(void 0, g)), l.defaultValue !== void 0 && l.initialValue === void 0 && L(r.formState.initialValues, t) === void 0 && z && (r.formState.values = H(r.formState.values, t, l.defaultValue));
      }
      return y ? E(void 0, g) : g(), function() {
        var W = !1;
        r.fields[t] && (W = !!(r.fields[t].validators[m] && r.fields[t].validators[m]()), delete r.fields[t].validators[m]);
        var G = !!r.fieldSubscribers[t];
        G && delete r.fieldSubscribers[t].entries[m];
        var te = G && !Object.keys(r.fieldSubscribers[t].entries).length;
        te && (delete r.fieldSubscribers[t], delete r.fields[t], W && (r.formState.errors = H(r.formState.errors, t, void 0) || {}), a && (r.formState.values = H(r.formState.values, t, void 0, !0) || {})), F || (W ? E(void 0, function() {
          w(), v();
        }) : te && w());
      };
    },
    reset: function(t) {
      t === void 0 && (t = r.formState.initialValues), r.formState.submitting && (r.formState.resetWhileSubmitting = !0), r.formState.submitFailed = !1, r.formState.submitSucceeded = !1, delete r.formState.submitError, delete r.formState.submitErrors, delete r.formState.lastSubmittedValues, U.initialize(t || {});
    },
    /**
     * Resets all field flags (e.g. touched, visited, etc.) to their initial state
     */
    resetFieldState: function(t) {
      r.fields[t] = R({}, r.fields[t], {
        active: !1,
        lastFieldState: void 0,
        modified: !1,
        touched: !1,
        valid: !0,
        validating: !1,
        visited: !1
      }), E(void 0, function() {
        v(), w();
      });
    },
    /**
     * Returns the form to a clean slate; that is:
     * - Clear all values
     * - Resets all fields to their initial state
     */
    restart: function(t) {
      t === void 0 && (t = r.formState.initialValues), U.batch(function() {
        for (var n in r.fields)
          U.resetFieldState(n), r.fields[n] = R({}, r.fields[n], {
            active: !1,
            lastFieldState: void 0,
            modified: !1,
            modifiedSinceLastSubmit: !1,
            touched: !1,
            valid: !0,
            validating: !1,
            visited: !1
          });
        U.reset(t);
      });
    },
    resumeValidation: function() {
      A = !1, j = !1, P && E(void 0, function() {
        v(), w();
      }), P = !1;
    },
    setConfig: function(t, n) {
      switch (t) {
        case "debug":
          e = n;
          break;
        case "destroyOnUnregister":
          a = n;
          break;
        case "initialValues":
          U.initialize(n);
          break;
        case "keepDirtyOnReinitialize":
          u = n;
          break;
        case "mutators":
          d = n, n ? (Object.keys(q).forEach(function(s) {
            s in n || delete q[s];
          }), Object.keys(n).forEach(function(s) {
            q[s] = x(s);
          })) : Object.keys(q).forEach(function(s) {
            delete q[s];
          });
          break;
        case "onSubmit":
          f = n;
          break;
        case "validate":
          c = n, E(void 0, function() {
            v(), w();
          });
          break;
        case "validateOnBlur":
          V = n;
          break;
        default:
          throw new Error("Unrecognised option " + t);
      }
    },
    submit: function() {
      var t = r.formState;
      if (!t.submitting) {
        if (delete t.submitErrors, delete t.submitError, t.lastSubmittedValues = R({}, t.values), ae()) {
          oe(), ne(), r.formState.submitFailed = !0, w(), v();
          return;
        }
        var n = Object.keys(N);
        if (n.length) {
          Promise.all(n.map(function(F) {
            return N[Number(F)];
          })).then(U.submit, console.error);
          return;
        }
        var s = ee();
        if (!s) {
          var l, m = !1, h = function(g) {
            t.submitting = !1;
            var z = t.resetWhileSubmitting;
            return z && (t.resetWhileSubmitting = !1), g && fe(g) ? (t.submitFailed = !0, t.submitSucceeded = !1, t.submitErrors = g, t.submitError = g[we], oe()) : (z || (t.submitFailed = !1, t.submitSucceeded = !0), X()), w(), v(), m = !0, l && l(g), g;
          };
          t.submitting = !0, t.submitFailed = !1, t.submitSucceeded = !1, t.lastSubmittedValues = R({}, t.values), ne();
          var y = f(t.values, U, h);
          if (!m) {
            if (y && pe(y))
              return w(), v(), y.then(h, function(F) {
                throw h(), F;
              });
            if (f.length >= 3)
              return w(), v(), new Promise(function(F) {
                l = F;
              });
            h(y);
          }
        }
      }
    },
    subscribe: function(t, n) {
      if (!t)
        throw new Error("No callback given.");
      if (!n)
        throw new Error("No subscription provided. What values do you want to listen to?");
      var s = je(t), l = r.subscribers, m = l.index++;
      l.entries[m] = {
        subscriber: s,
        subscription: n,
        notified: !1
      };
      var h = _();
      return $e(s, n, h, h, Re, !0), function() {
        delete l.entries[m];
      };
    }
  };
  return U;
}
var rt = ["render", "children", "component"];
function ze(i, e, a) {
  var u = i.render, o = i.children, d = i.component, f = Se(i, rt);
  if (d)
    return /* @__PURE__ */ T.createElement(d, Object.assign(e, f, {
      children: o,
      render: u
    }));
  if (u)
    return u(o === void 0 ? Object.assign(e, f) : (
      // inject children back in
      Object.assign(e, f, {
        children: o
      })
    ));
  if (typeof o != "function")
    throw new Error("Must specify either a render prop, a render function as children, or a component prop to " + a);
  return o(Object.assign(e, f));
}
function Z(i, e, a) {
  a === void 0 && (a = function(d, f) {
    return d === f;
  });
  var u = me.useRef(i);
  me.useEffect(function() {
    a(i, u.current) || (e(), u.current = i);
  });
}
function at(i) {
  var e = me.useRef();
  return e.current || (e.current = i()), e.current;
}
var ge = function(e, a) {
  if (e === a)
    return !0;
  if (typeof e != "object" || !e || typeof a != "object" || !a)
    return !1;
  var u = Object.keys(e), o = Object.keys(a);
  if (u.length !== o.length)
    return !1;
  for (var d = Object.prototype.hasOwnProperty.bind(a), f = 0; f < u.length; f++) {
    var c = u[f];
    if (!d(c) || e[c] !== a[c])
      return !1;
  }
  return !0;
}, nt = function(e) {
  return !!(e && typeof e.stopPropagation == "function");
}, De = /* @__PURE__ */ T.createContext();
function Ie(i) {
  var e = me.useRef(i);
  return me.useEffect(function() {
    e.current = i;
  }), e;
}
var ut = "6.5.8", Ue = function(e, a, u) {
  u.forEach(function(o) {
    Object.defineProperty(e, o, {
      get: function() {
        return a[o];
      },
      enumerable: !0
    });
  });
}, st = function(e, a) {
  return Ue(e, a, ["active", "dirty", "dirtyFields", "dirtySinceLastSubmit", "dirtyFieldsSinceLastSubmit", "error", "errors", "hasSubmitErrors", "hasValidationErrors", "initialValues", "invalid", "modified", "modifiedSinceLastSubmit", "pristine", "submitError", "submitErrors", "submitFailed", "submitSucceeded", "submitting", "touched", "valid", "validating", "values", "visited"]);
}, ot = function(e, a) {
  return Ue(e, a, ["active", "data", "dirty", "dirtySinceLastSubmit", "error", "initial", "invalid", "length", "modified", "modifiedSinceLastSubmit", "pristine", "submitError", "submitFailed", "submitSucceeded", "submitting", "touched", "valid", "validating", "visited"]);
}, lt = ["debug", "decorators", "destroyOnUnregister", "form", "initialValues", "initialValuesEqual", "keepDirtyOnReinitialize", "mutators", "onSubmit", "subscription", "validate", "validateOnBlur"], ft = {
  "final-form": _e,
  "react-final-form": ut
}, dt = Be.reduce(function(i, e) {
  return i[e] = !0, i;
}, {});
function Vt(i) {
  var e = i.debug, a = i.decorators, u = a === void 0 ? [] : a, o = i.destroyOnUnregister, d = i.form, f = i.initialValues, c = i.initialValuesEqual, V = i.keepDirtyOnReinitialize, r = i.mutators, O = i.onSubmit, A = i.subscription, P = A === void 0 ? dt : A, j = i.validate, I = i.validateOnBlur, N = Se(i, lt), Y = {
    debug: e,
    destroyOnUnregister: o,
    initialValues: f,
    keepDirtyOnReinitialize: V,
    mutators: r,
    onSubmit: O,
    validate: j,
    validateOnBlur: I
  }, S = at(function() {
    var E = d || it(Y);
    return E.pauseValidation(), E;
  }), M = T.useState(function() {
    var E = {};
    return S.subscribe(function(v) {
      E = v;
    }, P)(), E;
  }), x = M[0], q = M[1], K = Ie(x);
  T.useEffect(function() {
    S.isValidationPaused() && S.resumeValidation();
    var E = [S.subscribe(function(v) {
      ge(v, K.current) || q(v);
    }, P)].concat(u ? u.map(function(v) {
      return (
        // this noop ternary is to appease the flow gods
        // istanbul ignore next
        v(S)
      );
    }) : []);
    return function() {
      S.pauseValidation(), E.reverse().forEach(function(v) {
        return v();
      });
    };
  }, u), process.env.NODE_ENV !== "production" && Z(u, function() {
    console.error("Form decorators should not change from one render to the next as new values will be ignored");
  }, ge), Z(e, function() {
    S.setConfig("debug", e);
  }), Z(o, function() {
    S.destroyOnUnregister = !!o;
  }), Z(V, function() {
    S.setConfig("keepDirtyOnReinitialize", V);
  }), Z(f, function() {
    S.setConfig("initialValues", f);
  }, c || ge), Z(r, function() {
    S.setConfig("mutators", r);
  }), Z(O, function() {
    S.setConfig("onSubmit", O);
  }), Z(j, function() {
    S.setConfig("validate", j);
  }), Z(I, function() {
    S.setConfig("validateOnBlur", I);
  });
  var $ = function(v) {
    return v && (typeof v.preventDefault == "function" && v.preventDefault(), typeof v.stopPropagation == "function" && v.stopPropagation()), S.submit();
  }, k = {
    form: R({}, S, {
      reset: function(v) {
        nt(v) ? S.reset() : S.reset(v);
      }
    }),
    handleSubmit: $
  };
  return st(k, x), /* @__PURE__ */ T.createElement(De.Provider, {
    value: S
  }, ze(R({}, N, {
    __versions: ft
  }), k, "ReactFinalForm"));
}
function ct(i) {
  var e = T.useContext(De);
  if (!e)
    throw new Error((i || "useForm") + " must be used inside of a <Form> component");
  return e;
}
var vt = typeof window < "u" && window.navigator && window.navigator.product && window.navigator.product === "ReactNative", mt = function(e) {
  var a = [];
  if (e)
    for (var u = 0; u < e.length; u++) {
      var o = e[u];
      o.selected && a.push(o.value);
    }
  return a;
}, bt = function(e, a, u, o) {
  if (!o && e.nativeEvent && e.nativeEvent.text !== void 0 || o && e.nativeEvent)
    return e.nativeEvent.text;
  var d = e, f = d.target, c = f.type, V = f.value, r = f.checked;
  switch (c) {
    case "checkbox":
      if (u !== void 0) {
        if (r)
          return Array.isArray(a) ? a.concat(u) : [u];
        if (!Array.isArray(a))
          return a;
        var O = a.indexOf(u);
        return O < 0 ? a : a.slice(0, O).concat(a.slice(O + 1));
      } else
        return !!r;
    case "select-multiple":
      return mt(e.target.options);
    default:
      return V;
  }
};
function ye(i) {
  var e = T.useRef(i);
  return T.useEffect(function() {
    e.current = i;
  }), T.useCallback(function() {
    for (var a = arguments.length, u = new Array(a), o = 0; o < a; o++)
      u[o] = arguments[o];
    return e.current.apply(null, u);
  }, []);
}
var St = Ne.reduce(function(i, e) {
  return i[e] = !0, i;
}, {}), Fe = function(e, a) {
  return e === void 0 ? "" : e;
}, ht = function(e, a) {
  return e === "" ? void 0 : e;
}, pt = function(e, a) {
  return e === a;
};
function gt(i, e) {
  e === void 0 && (e = {});
  var a = e, u = a.afterSubmit, o = a.allowNull, d = a.component, f = a.data, c = a.defaultValue, V = a.format, r = V === void 0 ? Fe : V, O = a.formatOnBlur, A = a.initialValue, P = a.multiple, j = a.parse, I = j === void 0 ? ht : j, N = a.subscription, Y = N === void 0 ? St : N, S = a.type, M = a.validateFields, x = a.value, q = ct("useField"), K = Ie(e), $ = function(C, w) {
    return (
      // avoid using `state` const in any closures created inside `register`
      // because they would refer `state` from current execution context
      // whereas actual `state` would defined in the subsequent `useField` hook
      // execution
      // (that would be caused by `setState` call performed in `register` callback)
      q.registerField(i, C, Y, {
        afterSubmit: u,
        beforeSubmit: function() {
          var X = K.current, ne = X.beforeSubmit, U = X.formatOnBlur, b = X.format, t = b === void 0 ? Fe : b;
          if (U) {
            var n = q.getFieldState(i), s = n.value, l = t(s, i);
            l !== s && q.change(i, l);
          }
          return ne && ne();
        },
        data: f,
        defaultValue: c,
        getValidator: function() {
          return K.current.validate;
        },
        initialValue: A,
        isEqual: function(X, ne) {
          return (K.current.isEqual || pt)(X, ne);
        },
        silent: w,
        validateFields: M
      })
    );
  }, k = T.useRef(!0), E = T.useState(function() {
    var p = {}, C = q.destroyOnUnregister;
    return q.destroyOnUnregister = !1, $(function(w) {
      p = w;
    }, !0)(), q.destroyOnUnregister = C, p;
  }), v = E[0], oe = E[1];
  T.useEffect(
    function() {
      return $(function(p) {
        k.current ? k.current = !1 : oe(p);
      }, !1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      i,
      f,
      c,
      // If we want to allow inline fat-arrow field-level validation functions, we
      // cannot reregister field every time validate function !==.
      // validate,
      A
      // The validateFields array is often passed as validateFields={[]}, creating
      // a !== new array every time. If it needs to be changed, a rerender/reregister
      // can be forced by changing the key prop
      // validateFields
    ]
  );
  var ae = {};
  ot(ae, v);
  var _ = {
    name: i,
    get value() {
      var p = v.value;
      return O ? d === "input" && (p = Fe(p)) : p = r(p, i), p === null && !o && (p = ""), S === "checkbox" || S === "radio" ? x : d === "select" && P ? p || [] : p;
    },
    get checked() {
      var p = v.value;
      if (S === "checkbox")
        return p = r(p, i), x === void 0 ? !!p : !!(Array.isArray(p) && ~p.indexOf(x));
      if (S === "radio")
        return r(p, i) === x;
    },
    onBlur: ye(function(p) {
      if (v.blur(), O) {
        var C = q.getFieldState(v.name);
        v.change(r(C.value, v.name));
      }
    }),
    onChange: ye(function(p) {
      if (process.env.NODE_ENV !== "production" && p && p.target) {
        var C = p.target.type, w = ~["checkbox", "radio", "select-multiple"].indexOf(C) && !S && d !== "select", ee = C === "select-multiple" ? v.value : x;
        w && console.error('You must pass `type="' + (C === "select-multiple" ? "select" : C) + '"` prop to your Field(' + i + `) component.
` + ("Without it we don't know how to unpack your `value` prop - " + (Array.isArray(ee) ? "[" + ee + "]" : '"' + ee + '"') + "."));
      }
      var X = p && p.target ? bt(p, v.value, x, vt) : p;
      v.change(I(X, i));
    }),
    onFocus: ye(function(p) {
      return v.focus();
    })
  };
  P && (_.multiple = P), S !== void 0 && (_.type = S);
  var he = {
    input: _,
    meta: ae
  };
  return he;
}
var yt = ["afterSubmit", "allowNull", "beforeSubmit", "children", "component", "data", "defaultValue", "format", "formatOnBlur", "initialValue", "isEqual", "multiple", "name", "parse", "subscription", "type", "validate", "validateFields", "value"], Ot = /* @__PURE__ */ T.forwardRef(function(e, a) {
  var u = e.afterSubmit, o = e.allowNull, d = e.beforeSubmit, f = e.children, c = e.component, V = e.data, r = e.defaultValue, O = e.format, A = e.formatOnBlur, P = e.initialValue, j = e.isEqual, I = e.multiple, N = e.name, Y = e.parse, S = e.subscription, M = e.type, x = e.validate, q = e.validateFields, K = e.value, $ = Se(e, yt), k = gt(N, {
    afterSubmit: u,
    allowNull: o,
    beforeSubmit: d,
    children: f,
    component: c,
    data: V,
    defaultValue: r,
    format: O,
    formatOnBlur: A,
    initialValue: P,
    isEqual: j,
    multiple: I,
    parse: Y,
    subscription: S,
    type: M,
    validate: x,
    validateFields: q,
    value: K
  });
  if (typeof f == "function")
    return f(R({}, k, $));
  if (typeof c == "string")
    return /* @__PURE__ */ T.createElement(c, R({}, k.input, {
      children: f,
      ref: a
    }, $));
  if (!N)
    throw new Error("prop name cannot be undefined in <Field> component");
  return ze(R({
    children: f,
    component: c,
    ref: a
  }, $), k, "Field(" + N + ")");
});
export {
  ve as A,
  Ot as F,
  Vt as R,
  gt as a,
  ut as b,
  Ne as f,
  ct as u,
  _e as v
};
