import { j as r } from "./jsx-runtime-C8rcB1Lr.js";
import { k as t, n, j as l } from "./utils-BTh-HRzb.js";
function x({ scheme: a }) {
  return /* @__PURE__ */ r.jsx(r.Fragment, {
    children: !!a && /* @__PURE__ */ r.jsx("section", {
      className: "py-4 px-4 rounded-md border border-solid border-neutral-10 mb-8 dark:bg-neutral-dark-5 dark:border-neutral-dark-10",
      children: /* @__PURE__ */ r.jsxs("div", {
        className: "flex",
        children: [
          (a == null ? void 0 : a.schemeLogo) && /* @__PURE__ */ r.jsx("img", {
            src: a == null ? void 0 : a.schemeLogo,
            alt: "logo",
            className: `mr-2 bg-neutral-10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`
          }),
          /* @__PURE__ */ r.jsxs("div", {
            className: "flex-1",
            children: [
              /* @__PURE__ */ r.jsx("h5", {
                className: "text-neutral leading-6 dark:text-neutral-dark-110",
                children: `${a != null && a.name ? t(a == null ? void 0 : a.name) : ""}`
              }),
              /* @__PURE__ */ r.jsx("div", {
                className: "flex",
                children: /* @__PURE__ */ r.jsx("div", {
                  className: "mr-2 flex md:mr-4",
                  children: /* @__PURE__ */ r.jsx("span", {
                    className: "text-primary-N40 text-size-12 block pr-1",
                    children: a != null && a.investment_option ? `${n(a == null ? void 0 : a.investment_option)} ${a != null && a.plan_type ? `(${n(l(a == null ? void 0 : a.plan_type) ?? "")})` : ""}` : ""
                  })
                })
              })
            ]
          })
        ]
      })
    })
  });
}
export {
  x as P
};
