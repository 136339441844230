import { j as s } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as ms, useState as f, useEffect as as } from "react";
import { a as xs, S as ls, c as n, M as vs, h as ds, F as ws, B as es, o as Vs, C as Q, b as fs, d as Es, p as Zs } from "./index.esm-BV4iWo1a.js";
import { u as ps } from "./useConfiguration-B-lyaBB9.js";
import { F as cs } from "./provider-DZPLM8yJ.js";
import { r as O } from "./sentry-ZQO5C1U6.js";
import { m as Ls } from "./MandateListing-BxVtIJW-.js";
import { i as G, k as os, j as k, d as ys, V as Js, H as Ys, B as Qs, f as us, n as Cs, E as gs, m as K, P as Ks } from "./utils-BTh-HRzb.js";
import { u as Ss } from "./useScrollToBottomRef-CqDzM0dm.js";
import { D as Gs } from "./DetailsCard-Bl9Kv8vN.js";
import { i as t } from "./index-B3vUTrRT.js";
import { AppContext as Rs } from "./AppContext.js";
import { ErrorPage as _s } from "./ErrorPage.js";
import { c as Is, a as js, b as bs } from "./StopPlanStatus-DkYrEtm2.js";
function Os({ currentPlanObject: l, isGroupInvestment: e, isPartner: T }) {
  const { fpapi: y } = ms(cs), { AppState: { tenant: L } } = ms(Rs), [u, S] = f(!1), [v, F] = f([]), [X, A] = f("initial"), [p, w] = f({}), { tenantConfig: m, amcs: r } = ps(), c = m == null ? void 0 : m.fallbackLogo, V = m == null ? void 0 : m.showSchemeLogo, [R, j] = f(null), J = Ss(R), [b, q] = f(!1), [D, U] = f(10), [E, I] = f([]), [M, is] = f({}), [$, P] = f({});
  as(() => {
    if (J.hasScrolledToBottom) {
      q(!0);
      const i = D + 10;
      if ((v == null ? void 0 : v.length) < (E == null ? void 0 : E.length)) {
        const o = E == null ? void 0 : E.slice(D, i);
        o == null || o.map(async (d) => await a(d)), F((d) => d.concat(o));
      }
      U((o) => o + 10);
    }
    q(!1);
  }, [
    J.hasScrolledToBottom
  ]);
  const a = async (i) => {
    try {
      if ((i == null ? void 0 : i.object) === "mf_purchase") {
        const o = await y.fpClient.payments().fetchAll({
          amc_order_ids: i == null ? void 0 : i.old_id
        });
        G(o == null ? void 0 : o.payments[0]) || P(o == null ? void 0 : o.payments[0]);
      }
    } catch (o) {
      window.debug.error(o), O(o);
    }
  }, h = async () => {
    try {
      S(!0);
      let i = [];
      (l == null ? void 0 : l.object) === "mf_purchase_plan" ? await y.fpClient.mf_purchases().fetchAll({
        plan: l == null ? void 0 : l.id
      }).then((d) => {
        d.data.length <= 0 && A("no_installments"), i = d.data;
      }) : (l == null ? void 0 : l.object) === "mf_redemption_plan" ? await y.fpClient.mf_redemptions().fetchAll({
        plan: l == null ? void 0 : l.id
      }).then((d) => {
        d.data.length <= 0 && A("no_installments"), i = d.data;
      }) : (l == null ? void 0 : l.object) === "mf_switch_plan" && await y.fpClient.mf_switches().fetchAll({
        plan: l == null ? void 0 : l.id
      }).then((d) => {
        d.data.length <= 0 && A("no_installments"), i = d.data;
      }), I(i);
      const o = i == null ? void 0 : i.slice(0, D);
      F(o), o == null || o.map(async (d) => await a(d)), S(!1);
    } catch (i) {
      window.debug.error(i), S(!1), O(i);
    }
  }, C = async () => {
    const i = (l == null ? void 0 : l.object) === "mf_switch_plan" ? l == null ? void 0 : l.switch_in_scheme : l == null ? void 0 : l.scheme, o = await y.fpClient.master_data().fetchScheme(i);
    if (w(o), (l == null ? void 0 : l.object) === "mf_switch_plan") {
      const d = await y.fpClient.master_data().fetchScheme(l == null ? void 0 : l.switch_out_scheme);
      is(d);
    }
  }, B = (i, o) => {
    if (T)
      Js(Ys(L) + "/payment?order_id=" + i.id);
    else {
      const d = window.location.origin + Qs + "/payment?order_id=" + i.id;
      window.location.href = d;
    }
  };
  as(() => {
    h(), C();
  }, []);
  function Z() {
    var i;
    return (v == null ? void 0 : v.length) > 0 ? /* @__PURE__ */ s.jsxs("div", {
      children: [
        /* @__PURE__ */ s.jsx(ls, {
          customClass: "mb-8",
          children: /* @__PURE__ */ s.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3 basis-full pr-2 pl-2",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: t._({
                    id: "UMs2dC"
                  }),
                  value: G(p) ? "-" : os(p == null ? void 0 : p.name),
                  logo: V ? r[p == null ? void 0 : p.amc_id] || c : ""
                })
              }),
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: t._({
                    id: "0c+2Wu"
                  }),
                  value: l != null && l.folio_number ? l == null ? void 0 : l.folio_number : "New folio"
                })
              }),
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: t._({
                    id: "UMXPdU"
                  }),
                  value: (i = k(l == null ? void 0 : l.frequency)) == null ? void 0 : i.replace(/_/g, " ")
                })
              }),
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3 basis-full pr-2 pl-2",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: t._({
                    id: "FVoOCT",
                    values: {
                      0: ys(l == null ? void 0 : l.id)
                    }
                  }),
                  value: l == null ? void 0 : l.id
                })
              })
            ]
          })
        }),
        v.map((o, d) => /* @__PURE__ */ s.jsx("div", {
          ref: j,
          children: /* @__PURE__ */ s.jsx(Gs, {
            order: o,
            currentPlanObject: l,
            isInstallmentListing: !0,
            handlePayment: B,
            scheme: p,
            switchOutScheme: M,
            isGroupInvestment: e,
            isPartner: T,
            paymentDetails: $
          })
        }, o == null ? void 0 : o.id)),
        b ? /* @__PURE__ */ s.jsx("div", {
          className: "flex justify-center",
          children: /* @__PURE__ */ s.jsx(vs, {
            type: "important",
            variant: "field",
            title: "Loading..",
            loader: !0,
            customClass: "mb-5 "
          })
        }) : /* @__PURE__ */ s.jsx(s.Fragment, {})
      ]
    }) : /* @__PURE__ */ s.jsx(s.Fragment, {});
  }
  function H() {
    switch (X) {
      default:
        return Z();
      case "initial":
        return Z();
      case "no_installments":
        return /* @__PURE__ */ s.jsx(_s, {
          title: t._({
            id: "6hZNkr"
          })
        });
    }
  }
  return u ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : H();
}
function Ps({ currentPlanObject: l }) {
  const { fpapi: e } = ms(cs), [T, y] = f(!1), [L, u] = f(""), [S, v] = f([]);
  as(() => {
    F();
  }, [
    l
  ]);
  const F = async () => {
    var m;
    try {
      y(!0);
      const r = await e.fpClient.skip_instructions().fetchAll((l == null ? void 0 : l.object) + "s", l == null ? void 0 : l.id);
      if (us(r == null ? void 0 : r.data)) {
        u("no_skip_history"), y(!1);
        return;
      }
      (m = r == null ? void 0 : r.data) == null || m.sort((c, V) => new Date(V.created_at).valueOf() - new Date(c.created_at).valueOf()), v(r == null ? void 0 : r.data), u("initial"), y(!1);
    } catch {
      u("error"), y(!1);
    }
  }, X = (m) => m === "PENDING" ? "warning" : m === "ACTIVE" || m === "COMPLETED" ? "success" : "error", A = ({ skipInstruction: m }) => /* @__PURE__ */ s.jsx(ls, {
    customClass: "mb-20",
    header: {
      title: /* @__PURE__ */ s.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: t._({
          id: "igt8o5"
        })
      })
    },
    children: /* @__PURE__ */ s.jsxs("div", {
      className: "flex flex-wrap py-2",
      children: [
        /* @__PURE__ */ s.jsx("div", {
          className: "mb-3 basis-1/2",
          children: /* @__PURE__ */ s.jsx(n, {
            label: t._({
              id: "4ewD+v"
            }),
            value: m != null && m.skipped_installments ? m == null ? void 0 : m.skipped_installments : "-"
          })
        }),
        /* @__PURE__ */ s.jsx("div", {
          className: "mb-3 basis-1/2",
          children: /* @__PURE__ */ s.jsx(n, {
            label: t._({
              id: "v+kUb2"
            }),
            value: m != null && m.remaining_installments ? m == null ? void 0 : m.remaining_installments : "-"
          })
        }),
        /* @__PURE__ */ s.jsx("div", {
          className: "mb-3 basis-1/2",
          children: /* @__PURE__ */ s.jsx(n, {
            label: t._({
              id: "e7LnWq"
            }),
            value: m.state ? /* @__PURE__ */ s.jsx(ds, {
              variant: "outline",
              message: `${k(m.state)}`,
              status: X(m.state)
            }) : "-"
          })
        })
      ]
    })
  }), p = () => S.map((m) => /* @__PURE__ */ s.jsx(A, {
    skipInstruction: m
  }, m == null ? void 0 : m.id));
  function w() {
    switch (L) {
      default:
        return /* @__PURE__ */ s.jsx(s.Fragment, {});
      case "initial":
        return p();
      case "no_skip_history":
        return /* @__PURE__ */ s.jsx(_s, {
          title: t._({
            id: "HfZlKF"
          }),
          showDetails: !1,
          showAction: !1
        });
      case "error":
        return /* @__PURE__ */ s.jsx(_s, {
          title: t._({
            id: "w7WdjZ"
          }),
          showDetails: !1,
          showAction: !1
        });
    }
  }
  return T ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : w();
}
function ge({ investorProfile: l, currentPlanObject: e, handleAuthorize: T, onModifySuccess: y, isGroupInvestment: L, isPartner: u, assistedWorkflowPAN: S }) {
  const { tenantConfig: v, banks: F, amcs: X } = ps(), { AppState: { tenant: A } } = ms(Rs), { fpapi: p } = ms(cs), w = v == null ? void 0 : v.fallbackLogo, m = v == null ? void 0 : v.showSchemeLogo, [r, c] = f(!1), [V, R] = f(!1), [j, J] = f(!1), [b, q] = f(!1), [D, U] = f(!1), [E, I] = f([]), [M, is] = f({}), [$, P] = f({}), [a, h] = f({}), [C, B] = f({}), [Z, H] = f(!1), [i, o] = f({}), [d, x] = f(!1), [W, z] = f(!1);
  as(() => {
    ss(), (async () => {
      if (L) {
        const _ = await p.fetchUser(l == null ? void 0 : l.pan, A);
        o(_[0]);
      }
    })();
  }, [
    e
  ]);
  const ss = async () => {
    if (H(!0), (e == null ? void 0 : e.object) === "mf_purchase_plan" && e != null && e.payment_method && e != null && e.payment_source)
      try {
        const g = await p.fpClient.mandates().fetch(Number(e == null ? void 0 : e.payment_source));
        is(g);
        const rs = (await p.fpClient.bank_accounts().fetchAll({
          profile: l.id
        })).data.filter((Xs) => Xs.old_id === (g == null ? void 0 : g.bank_account_id))[0];
        P(rs);
      } catch (g) {
        window.debug.error(g), O(g);
      }
    let _;
    if ((e == null ? void 0 : e.object) === "mf_switch_plan") {
      _ = e == null ? void 0 : e.switch_in_scheme;
      try {
        const g = await p.fpClient.master_data().fetchScheme(e == null ? void 0 : e.switch_out_scheme);
        B(g);
      } catch (g) {
        window.debug.error(g), O(g);
      }
    } else
      _ = e == null ? void 0 : e.scheme;
    try {
      const g = await p.fpClient.master_data().fetchScheme(_);
      h(g);
    } catch (g) {
      window.debug.error(g), O(g);
    }
    H(!1);
  }, hs = () => {
    G(M) ? (U(!0), I([
      ...E,
      "payment_method"
    ])) : M.mandate_status === "CREATED" ? T(M) : (U(!0), I([
      ...E,
      "payment_method"
    ]));
  }, N = () => /* @__PURE__ */ s.jsx(ls, {
    customClass: "mb-4",
    header: {
      title: /* @__PURE__ */ s.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: t._({
          id: "YbR7uM"
        })
      })
    },
    children: /* @__PURE__ */ s.jsxs("div", {
      className: "-mr-2 -ml-2 flex flex-wrap items-center",
      children: [
        /* @__PURE__ */ s.jsx("div", {
          className: "mb-3 basis-full pr-2 pl-2",
          children: /* @__PURE__ */ s.jsx(n, {
            label: t._({
              id: "XTJZw8"
            }),
            value: os(a == null ? void 0 : a.name),
            logo: m ? X[a == null ? void 0 : a.amc_id] || w : ""
          })
        }),
        /* @__PURE__ */ s.jsxs("div", {
          className: "flex flex-1 flex-wrap py-2",
          children: [
            /* @__PURE__ */ s.jsx("div", {
              className: "mb-3 flex-1 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "FsNpOH"
                }),
                value: k(a == null ? void 0 : a.plan_type)
              })
            }),
            /* @__PURE__ */ s.jsx("div", {
              className: "mb-3 flex-1 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "fezFD7"
                }),
                value: Cs(a == null ? void 0 : a.investment_option)
              })
            })
          ]
        })
      ]
    })
  }), Y = () => /* @__PURE__ */ s.jsxs(ls, {
    customClass: "mb-4",
    header: {
      title: /* @__PURE__ */ s.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: t._({
          id: "HYDz/e"
        })
      })
    },
    children: [
      /* @__PURE__ */ s.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-full pl-2 pr-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: t._({
                id: "6Xqu8f"
              }),
              value: os(C.name),
              logo: m ? X[C == null ? void 0 : C.amc_id] || w : ""
            })
          }),
          /* @__PURE__ */ s.jsx("div", {
            className: "basis-1/2 pl-2 pr-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: t._({
                id: "FsNpOH"
              }),
              value: k(C.plan_type)
            })
          }),
          /* @__PURE__ */ s.jsx("div", {
            className: "basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: t._({
                id: "fezFD7"
              }),
              value: Cs(C.investment_option)
            })
          })
        ]
      }),
      /* @__PURE__ */ s.jsxs("div", {
        className: "flex flex-wrap py-2",
        children: [
          /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-full",
            children: /* @__PURE__ */ s.jsx(n, {
              label: t._({
                id: "qAf0Wv"
              }),
              value: os(a.name),
              logo: m ? X[a == null ? void 0 : a.amc_id] || w : ""
            })
          }),
          /* @__PURE__ */ s.jsxs("div", {
            className: "flex flex-1 flex-row",
            children: [
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3  flex basis-1/2 pr-2 ",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: t._({
                    id: "FsNpOH"
                  }),
                  value: k(a.plan_type)
                })
              }),
              /* @__PURE__ */ s.jsx("div", {
                className: "mb-3 flex basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ s.jsx(n, {
                  label: t._({
                    id: "fezFD7"
                  }),
                  value: Cs(a.investment_option)
                })
              })
            ]
          })
        ]
      })
    ]
  }), ts = () => {
    var _;
    return /* @__PURE__ */ s.jsxs(ls, {
      customClass: e.object === "mf_purchase_plan" ? "mb-4" : "",
      header: {
        title: /* @__PURE__ */ s.jsx("span", {
          className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
          children: t._({
            id: "Cwt8cU"
          })
        })
      },
      children: [
        /* @__PURE__ */ s.jsxs(Vs, {
          children: [
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "/DJBcB"
                }),
                value: e.amount ? `₹ ${gs(e.amount)}` : "-"
              })
            }),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "6kkUMg"
                }),
                value: e.state ? e.state === "failed" || e.state === "cancelled" ? /* @__PURE__ */ s.jsx(ds, {
                  variant: "outline",
                  message: `${k(e.state)}`,
                  status: "error"
                }) : /* @__PURE__ */ s.jsx(ds, {
                  variant: "outline",
                  message: `${k(e.state)}`,
                  status: "success"
                }) : "-"
              })
            }),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "UMXPdU"
                }),
                value: e.frequency ? (_ = k(e.frequency)) == null ? void 0 : _.replace(/_/g, " ") : "-"
              })
            }),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "cXcPd1"
                }),
                value: e.folio_number ? e.folio_number : "New folio"
              })
            }),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "sOfeX/"
                }),
                value: e.id ? e.id : "-"
              })
            }),
            e.next_installment_date ? /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "AEN2zi"
                }),
                value: e.next_installment_date && e.state !== "cancelled" && e.state !== "completed" ? K(new Date(e.next_installment_date), "dd-MMM-yyyy") : "-"
              })
            }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "a5RnUt"
                }),
                value: e.previous_installment_date ? K(new Date(e.previous_installment_date), "dd-MMM-yyyy") : "-"
              })
            }),
            e != null && e.requested_activation_date && !(e != null && e.start_date) ? /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "N6p5OV"
                }),
                value: e.requested_activation_date ? K(new Date(e.requested_activation_date), "dd-MMM-yyyy") : "-"
              })
            }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
              children: [
                /* @__PURE__ */ s.jsx(Q, {
                  span: 6,
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "AYywau"
                    }),
                    value: e.start_date ? K(new Date(e.start_date), "dd-MMM-yyyy") : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx(Q, {
                  span: 6,
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "Hz1n0Q"
                    }),
                    value: e.end_date ? e.number_of_installments === 1200 ? "Until I stop" : K(new Date(e.end_date), "dd-MMM-yyyy") : "-"
                  })
                })
              ]
            }),
            (e == null ? void 0 : e.frequency) !== "daily" ? /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "cki4uJ"
                }),
                value: e != null && e.installment_day ? Ks(e.installment_day) : "-"
              })
            }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
            /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: t._({
                  id: "6aH/4q"
                }),
                value: e.created_at ? K(new Date(e.created_at), "dd-MMM-yyyy") : "-"
              })
            }),
            e.state === "cancelled" && e.state === "completed" ? /* @__PURE__ */ s.jsx(Q, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(n, {
                label: e.state === "cancelled" && e.state === "completed" ? `${k(e.state)} on` : "-",
                value: e.state === "completed" ? K(new Date(e.completed_at), "dd-MMM-yyyy") : e.state === "cancelled" ? K(new Date(e.cancelled_at), "dd-MMM-yyyy") : "-"
              })
            }) : /* @__PURE__ */ s.jsx(s.Fragment, {})
          ]
        }),
        e.generate_first_installment_now === "mf_purchase_plan" && /* @__PURE__ */ s.jsx("div", {
          className: "mb-3 basis-full pr-2 pl-2",
          children: /* @__PURE__ */ s.jsx(n, {
            label: t._({
              id: "SRApmm"
            }),
            value: e.generate_first_installment_now ? "Yes" : "No"
          })
        })
      ]
    });
  }, ns = (_) => {
    if (!L)
      return _.mandate_status && e.state !== "cancelled" && e.state !== "completed" ? _.mandate_status === "CREATED" && !u ? {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: t._({
              id: "lP0RkG"
            })
          })
        }),
        buttonText: "Authorize mandate"
      } : _.mandate_status === "CREATED" && u ? {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: t._({
              id: "lP0RkG"
            })
          })
        }),
        buttonText: "Send mandate authorization link"
      } : {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: t._({
              id: "QpKNZ9"
            })
          })
        }),
        buttonText: "Update mandate"
      } : G(_) && e.state !== "cancelled" && e.state !== "completed" ? {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: t._({
              id: "GfCtzd"
            })
          })
        }),
        buttonText: "Setup mandate"
      } : void 0;
  }, Ns = () => {
    var _, g, Ms, rs;
    return /* @__PURE__ */ s.jsx(ls, {
      customClass: "mb-4",
      header: {
        title: /* @__PURE__ */ s.jsx("span", {
          className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
          children: t._({
            id: "1QPbvA"
          })
        })
      },
      footer: ns(M),
      onClick: hs,
      children: !G(M) || L ? /* @__PURE__ */ s.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: t._({
                id: "lFCwug"
              }),
              value: L ? "Salary" : "Mandate"
            })
          }),
          !G($) && !L && /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-full pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: t._({
                id: "/dl6Or"
              }),
              value: $.bank_name ? `${$.bank_name} - ****${$.account_number.slice(-4)}` : "-",
              logo: $.bank_name && F[$.ifsc_code.slice(0, 4)] ? F[$.ifsc_code.slice(0, 4)] : w
            })
          }),
          L ? /* @__PURE__ */ s.jsx(s.Fragment, {}) : /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: t._({
                id: "Zp6kam"
              }),
              value: M.mandate_limit ? `₹ ${gs(M.mandate_limit)}` : "-"
            })
          }),
          L ? /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: t._({
                id: "OzNl8x"
              }),
              value: (_ = i == null ? void 0 : i.annexure) != null && _.employeeDeclarationTimeStamp ? "Approved" : "Not approved",
              icon: (g = i == null ? void 0 : i.annexure) != null && g.employeeDeclarationTimeStamp ? "successInfo" : "Error",
              iconPlacement: "left"
            })
          }) : /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(n, {
              label: t._({
                id: "OzNl8x"
              }),
              value: M.mandate_status ? (Ms = Ls[M.mandate_status]) == null ? void 0 : Ms.value : "-",
              icon: M.mandate_status ? (rs = Ls[M.mandate_status]) == null ? void 0 : rs.icon : "",
              iconPlacement: "left"
            })
          })
        ]
      }) : /* @__PURE__ */ s.jsx("p", {
        className: "text-size-14 border-neutral-10 dark:border-neutral-dark-10 dark:bg-primary-40 text-base-color px-1 pb-1",
        children: t._({
          id: "hjAxUg"
        })
      })
    });
  }, Us = () => /* @__PURE__ */ s.jsx(fs, {
    customClass: "md:max-w-29",
    variant: "fullPage",
    title: t._({
      id: "Z+CGHg",
      values: {
        0: e != null && e.object ? e == null ? void 0 : e.object.split("_")[1] : ""
      }
    }),
    isOpen: r,
    contentHeightMax: !0,
    toggleModal: () => {
      c(!1);
    },
    children: /* @__PURE__ */ s.jsx(js, {
      currentPlanObject: e,
      handleStopCompletion: (_) => {
        c(!1), y(_, "stop_finished");
      }
    })
  }), Bs = () => {
    var _;
    return /* @__PURE__ */ s.jsx(fs, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: t._({
        id: "ro5P6t",
        values: {
          0: e != null && e.id ? `${(_ = ys(e == null ? void 0 : e.id)) == null ? void 0 : _.toLowerCase()} plan` : "plan"
        }
      }),
      isOpen: V,
      contentHeightMax: !0,
      toggleModal: () => {
        R(!1);
      },
      children: /* @__PURE__ */ s.jsx(bs, {
        currentPlanObject: e,
        handlePauseCompletion: (g) => {
          R(!1), y(g, "pause_finished");
        }
      })
    });
  }, Ds = () => /* @__PURE__ */ s.jsx(fs, {
    customClass: "md:max-w-29",
    variant: "fullPage",
    title: t._({
      id: "K98hiV",
      values: {
        0: e != null && e.object ? e == null ? void 0 : e.object.split("_")[1] : ""
      }
    }),
    isOpen: D,
    contentHeightMax: !0,
    toggleModal: () => {
      U(!1), I([]);
    },
    children: /* @__PURE__ */ s.jsx(Is, {
      isPartner: u,
      currentPlanObject: e,
      handleEditCompletion: (_) => {
        U(!1), I([]), y(_, "edit_finished");
      },
      editField: E,
      assistedWorkflowPAN: S
    })
  }), Hs = () => /* @__PURE__ */ s.jsx(fs, {
    customClass: "md:max-w-29",
    variant: "fullPage",
    title: t._({
      id: "YRRAVh",
      values: {
        0: ys(e == null ? void 0 : e.id)
      }
    }),
    isOpen: b,
    contentHeightMax: !0,
    toggleModal: () => {
      q(!1);
    },
    children: /* @__PURE__ */ s.jsx(Os, {
      currentPlanObject: e,
      investorProfile: l,
      isGroupInvestment: L,
      isPartner: u
    })
  }), Ws = () => /* @__PURE__ */ s.jsx(fs, {
    customClass: "md:max-w-29",
    variant: "fullPage",
    title: t._({
      id: "KLrVHR"
    }),
    isOpen: W,
    contentHeightMax: !0,
    toggleModal: () => {
      z(!1);
    },
    children: /* @__PURE__ */ s.jsx(Ps, {
      currentPlanObject: e
    })
  }), Fs = () => (e == null ? void 0 : e.state) === "cancelled" || (e == null ? void 0 : e.state) === "completed" || (e == null ? void 0 : e.state) === "created" || (e == null ? void 0 : e.requested_activation_date) !== null && (e == null ? void 0 : e.next_installment_date) === null ? "disabled" : "secondary", ks = () => (e == null ? void 0 : e.state) === "cancelled" || (e == null ? void 0 : e.state) === "completed" || (e == null ? void 0 : e.state) === "created" || (e == null ? void 0 : e.frequency) === "daily" || (e == null ? void 0 : e.frequency) === "day_in_a_week" || (e == null ? void 0 : e.frequency) === "four_times_a_month" || (e == null ? void 0 : e.frequency) === "day_in_a_fortnight" || (e == null ? void 0 : e.frequency) === "twice_a_month" || (e == null ? void 0 : e.requested_activation_date) !== null && (e == null ? void 0 : e.next_installment_date) === null ? "disabled" : "secondary", As = () => (e == null ? void 0 : e.state) === "cancelled" || (e == null ? void 0 : e.state) === "completed" || (e == null ? void 0 : e.requested_activation_date) !== null && (e == null ? void 0 : e.next_installment_date) === null ? "disabled" : "secondary", zs = () => {
    var _;
    return /* @__PURE__ */ s.jsxs(fs, {
      title: t._({
        id: "48jueX",
        values: {
          0: (_ = ys(e == null ? void 0 : e.id)) == null ? void 0 : _.toLowerCase()
        }
      }),
      isOpen: j,
      toggleModal: () => {
        J(!1);
      },
      contentHeightMax: !1,
      children: [
        Fs() === "disabled" && ks() === "disabled" && As() === "disabled" && /* @__PURE__ */ s.jsx(vs, {
          type: "alert",
          variant: "flash",
          title: "You can modify active plans only",
          customClass: "mb-5"
        }),
        /* @__PURE__ */ s.jsxs("div", {
          className: "mb-4",
          children: [
            /* @__PURE__ */ s.jsx(es, {
              variant: Fs(),
              fullwidth: !0,
              onClick: (g) => {
                g.preventDefault(), U(!D);
              },
              children: t._({
                id: "k0hcHs"
              })
            }),
            D && Ds()
          ]
        }),
        /* @__PURE__ */ s.jsxs("div", {
          className: "mb-4",
          children: [
            /* @__PURE__ */ s.jsx(es, {
              variant: ks(),
              fullwidth: !0,
              onClick: (g) => {
                g.preventDefault(), R(!V);
              },
              children: t._({
                id: "PwQ/Gn"
              })
            }),
            V && Bs()
          ]
        }),
        /* @__PURE__ */ s.jsxs("div", {
          className: "mb-4",
          children: [
            /* @__PURE__ */ s.jsx(es, {
              variant: As(),
              fullwidth: !0,
              onClick: (g) => {
                g.preventDefault(), c(!r);
              },
              children: t._({
                id: "r//qYl"
              })
            }),
            r && Us()
          ]
        })
      ]
    });
  }, $s = () => /* @__PURE__ */ s.jsxs(fs, {
    title: t._({
      id: "lVWeQb"
    }),
    isOpen: d,
    toggleModal: () => {
      x(!1);
    },
    contentHeightMax: !1,
    children: [
      /* @__PURE__ */ s.jsx("div", {
        className: "mb-4",
        children: /* @__PURE__ */ s.jsx(es, {
          variant: "secondary",
          fullwidth: !0,
          onClick: (_) => {
            _.preventDefault(), q(!b);
          },
          customClass: "flex-1",
          children: t._({
            id: "uHbvVq"
          })
        })
      }),
      /* @__PURE__ */ s.jsx("div", {
        className: "mb-4",
        children: /* @__PURE__ */ s.jsx(es, {
          variant: "secondary",
          fullwidth: !0,
          onClick: (_) => {
            _.preventDefault(), z(!W);
          },
          customClass: "flex-1",
          children: t._({
            id: "mi6x4x"
          })
        })
      })
    ]
  });
  return Z ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : G(e) ? /* @__PURE__ */ s.jsx(s.Fragment, {}) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      e.object === "mf_switch_plan" ? Y() : N(),
      ts(),
      e.object === "mf_purchase_plan" && Ns(),
      j ? zs() : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      d ? $s() : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      b ? Hs() : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      W ? Ws() : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      D ? Ds() : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      /* @__PURE__ */ s.jsx(ws, {
        customClass: "bottom-0",
        children: /* @__PURE__ */ s.jsxs("div", {
          className: "flex items-center",
          children: [
            u ? /* @__PURE__ */ s.jsx(s.Fragment, {}) : /* @__PURE__ */ s.jsx(es, {
              variant: "secondary",
              fullwidth: !0,
              onClick: (_) => {
                _.preventDefault(), J(!j);
              },
              customClass: "flex-1 mr-2 ",
              children: t._({
                id: "f4PxoX"
              })
            }),
            /* @__PURE__ */ s.jsx(es, {
              variant: "secondary",
              fullwidth: !0,
              onClick: (_) => {
                _.preventDefault(), x(!d);
              },
              customClass: "flex-1 mr-2 ",
              children: t._({
                id: "X9BN92"
              })
            })
          ]
        })
      })
    ]
  });
}
function qs({ investorProfile: l, onModifySuccess: e, togglePlanDetailsModal: T, mfInvestmentAccount: y }) {
  const { fpapi: L } = ms(cs), { amcs: u, tenantConfig: S } = ps(), v = S == null ? void 0 : S.fallbackLogo, F = S == null ? void 0 : S.showSchemeLogo, [X, A] = f(!0), [p, w] = f(null), [m, r] = f([]), [c, V] = f(null), [R, j] = f(null), J = Ss(R), [b, q] = f(!1), [D, U] = f(10);
  as(() => {
    if (J.hasScrolledToBottom) {
      const a = D + 10;
      I(a), U((h) => h + 10);
    }
  }, [
    J.hasScrolledToBottom
  ]), as(() => {
    M();
  }, [
    l
  ]);
  const E = async (a, h, C) => {
    const B = /* @__PURE__ */ new Set(), Z = structuredClone(a), H = [];
    try {
      Z == null || Z.slice(h, C).forEach((d) => {
        B.add(d.scheme), H.push(d);
      });
      const i = [];
      B.forEach((d) => {
        const x = L.fpClient.master_data().fetchScheme(d);
        i.push(x);
      });
      const o = await Promise.all(i);
      o.length > 0 && (H == null || H.forEach((d) => {
        const x = o.find((W) => W.isin === d.scheme);
        x && (d.amc_id = x.amc_id, d.name = x.name, d.plan_type = x.plan_type, d.investment_option = x.investment_option, d.swp_frequency_specific_data = x.swp_frequency_specific_data);
      }));
    } catch (i) {
      window.debug.log(i, "Error");
    }
    return H;
  }, I = async (a) => {
    if (!(D >= (c == null ? void 0 : c.length)))
      try {
        q(!0);
        const h = structuredClone(c);
        if (us(h)) {
          q(!1);
          return;
        }
        const C = await E(c, D, a);
        r((B) => B.concat(C)), G(p) ? w(c[0]) : w(p), q(!1);
      } catch (h) {
        window.debug.log(h, "Error"), q(!1), O(h);
      }
  }, M = async () => {
    try {
      if (A(!0), G(y)) {
        A(!1);
        return;
      }
      const a = await L.fpClient.mf_redemption_plans().fetchAll({
        mf_investment_account: y.id
      }), h = structuredClone(a.data);
      if (V(h), !h.length) {
        A(!1);
        return;
      }
      const C = await E(a == null ? void 0 : a.data, 0, D);
      r(C), w(h[0]), A(!1);
    } catch (a) {
      window.debug.error(a), A(!1), O(a);
    }
  }, is = (a) => {
    w(c[a.target.value]);
  };
  function $(a) {
    if (a.requested_activation_date && a.state === "created")
      return /* @__PURE__ */ s.jsx(s.Fragment, {
        children: /* @__PURE__ */ s.jsx("div", {
          className: "text-center",
          children: t._({
            id: "bpnk88",
            values: {
              0: K(new Date(a.requested_activation_date), "dd-MMM-yyyy")
            }
          })
        })
      });
  }
  const P = () => X ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(Es, {
        onChange: is,
        variant: "selectable",
        selected: 0,
        children: m != null && m.length ? m == null ? void 0 : m.map((a) => {
          var h;
          return /* @__PURE__ */ s.jsx(ls, {
            innerRef: j,
            footerNotes: $(a),
            children: /* @__PURE__ */ s.jsxs("div", {
              className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 w-full pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "XTJZw8"
                    }),
                    value: a.name ? os(a.name) : "-",
                    logo: F ? u[a.amc_id] || v : ""
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "/DJBcB"
                    }),
                    value: a.amount ? `₹ ${gs(a.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "6kkUMg"
                    }),
                    value: a.state ? a.state === "failed" || a.state === "cancelled" ? /* @__PURE__ */ s.jsx(ds, {
                      variant: "outline",
                      message: `${k(a.state)}`,
                      status: "error"
                    }) : /* @__PURE__ */ s.jsx(ds, {
                      variant: "outline",
                      message: `${k(a.state)}`,
                      status: "success"
                    }) : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "UMXPdU"
                    }),
                    value: a.frequency ? (h = k(a.frequency)) == null ? void 0 : h.replace(/_/g, " ") : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "3B8n+e"
                    }),
                    value: a.next_installment_date ? `${K(new Date(a.next_installment_date), "dd-MMM-yyyy")}` : "-"
                  })
                })
              ]
            })
          }, a.id);
        }) : /* @__PURE__ */ s.jsx(_s, {
          title: t._({
            id: "YiwWqS"
          }),
          description: t._({
            id: "MgEzWC"
          })
        })
      }),
      b ? /* @__PURE__ */ s.jsx("div", {
        className: "flex justify-center",
        children: /* @__PURE__ */ s.jsx(vs, {
          type: "important",
          variant: "field",
          title: "Loading..",
          loader: !0,
          customClass: "mb-5 "
        })
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      p && /* @__PURE__ */ s.jsx(ws, {
        children: /* @__PURE__ */ s.jsx(es, {
          variant: "primary",
          fullwidth: !0,
          onClick: (a) => {
            a.preventDefault(), T(p);
          },
          children: t._({
            id: "f9pW3a"
          })
        })
      })
    ]
  });
  return /* @__PURE__ */ s.jsx(s.Fragment, {
    children: P()
  });
}
function se({ investorProfile: l, onModifySuccess: e, togglePlanDetailsModal: T, mfInvestmentAccount: y }) {
  const [L, u] = f(!0), { amcs: S, tenantConfig: v } = ps(), F = v == null ? void 0 : v.fallbackLogo, X = v == null ? void 0 : v.showSchemeLogo, { fpapi: A } = ms(cs), [p, w] = f(null), [m, r] = f([]), [c, V] = f(null), [R, j] = f(null), J = Ss(R), [b, q] = f(!1), [D, U] = f(10);
  as(() => {
    if (J.hasScrolledToBottom) {
      const a = D + 10;
      I(a), U((h) => h + 10);
    }
  }, [
    J.hasScrolledToBottom
  ]), as(() => {
    M();
  }, [
    l
  ]);
  const E = async (a, h, C) => {
    const B = /* @__PURE__ */ new Set(), Z = structuredClone(a), H = [];
    try {
      Z == null || Z.slice(h, C).forEach((d) => {
        B.add(d.switch_in_scheme), B.add(d.switch_out_scheme), H.push(d);
      });
      const i = [];
      B.forEach((d) => {
        const x = A.fpClient.master_data().fetchScheme(d);
        i.push(x);
      });
      const o = await Promise.all(i);
      o.length > 0 && H.forEach((d) => {
        const x = o.find((z) => z.isin === d.switch_in_scheme), W = o.find((z) => z.isin === d.switch_out_scheme);
        x && (d.amc_id = x.amc_id, d.name = x.name, d.plan_type = x.plan_type, d.investment_option = x.investment_option, d.stp_frequency_specific_data = x.stp_frequency_specific_data), W && (d.switchOutSchemeAmcId = W.amc_id, d.switchOutSchemeName = W.name, d.switchOutPlanType = W.plan_type, d.switchOutInvestmentOption = W.investment_option, d.switchOutAmcId = x.amc_id);
      });
    } catch (i) {
      window.debug.log(i, "Error");
    }
    return H;
  }, I = async (a) => {
    if (!(D >= (c == null ? void 0 : c.length)))
      try {
        q(!0);
        const h = structuredClone(c);
        if (us(h)) {
          q(!1);
          return;
        }
        const C = await E(c, D, a);
        r((B) => B.concat(C)), G(p) ? w(c[0]) : w(p), q(!1);
      } catch (h) {
        window.debug.log(h, "Error"), q(!1), O(h);
      }
  }, M = async () => {
    try {
      if (u(!0), G(y)) {
        u(!1);
        return;
      }
      const a = await A.fpClient.mf_switch_plans().fetchAll({
        mf_investment_account: y.id
      }), h = structuredClone(a.data);
      if (V(h), !a.data.length) {
        u(!1);
        return;
      }
      const C = await E(a == null ? void 0 : a.data, 0, D);
      r(C), w(h[0]), u(!1);
    } catch (a) {
      window.debug.error(a), u(!1), O(a);
    }
  }, is = (a) => {
    w(c[a.target.value]);
  };
  function $(a) {
    if (a.requested_activation_date && a.state === "created")
      return /* @__PURE__ */ s.jsx(s.Fragment, {
        children: /* @__PURE__ */ s.jsx("div", {
          className: "text-center",
          children: t._({
            id: "bpnk88",
            values: {
              0: K(new Date(a.requested_activation_date), "dd-MMM-yyyy")
            }
          })
        })
      });
  }
  const P = () => L ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(Es, {
        onChange: is,
        variant: "selectable",
        selected: 0,
        children: m != null && m.length ? m == null ? void 0 : m.map((a) => {
          var h;
          return /* @__PURE__ */ s.jsx(ls, {
            innerRef: j,
            footerNotes: $(a),
            children: /* @__PURE__ */ s.jsxs("div", {
              className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 w-full pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "6Xqu8f"
                    }),
                    value: a.switchOutSchemeName ? os(a.switchOutSchemeName) : "-",
                    logo: X ? S[a.switchOutSchemeAmcId] || F : ""
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 w-full pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "qAf0Wv"
                    }),
                    value: a.name ? os(a.name) : "-",
                    logo: X ? S[a.amc_id] || F : ""
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "/DJBcB"
                    }),
                    value: a.amount ? `₹ ${gs(a.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "6kkUMg"
                    }),
                    value: a.state ? a.state === "failed" || a.state === "cancelled" ? /* @__PURE__ */ s.jsx(ds, {
                      variant: "outline",
                      message: `${k(a.state)}`,
                      status: "error"
                    }) : /* @__PURE__ */ s.jsx(ds, {
                      variant: "outline",
                      message: `${k(a.state)}`,
                      status: "success"
                    }) : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "UMXPdU"
                    }),
                    value: a.frequency ? (h = k(a.frequency)) == null ? void 0 : h.replace(/_/g, " ") : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "3B8n+e"
                    }),
                    value: a.next_installment_date ? `${K(new Date(a.next_installment_date), "dd-MMM-yyyy")}` : "-"
                  })
                })
              ]
            })
          }, a.id);
        }) : /* @__PURE__ */ s.jsx(_s, {
          title: t._({
            id: "un37FN"
          }),
          description: t._({
            id: "cPbaxR"
          })
        })
      }),
      b ? /* @__PURE__ */ s.jsx("div", {
        className: "flex justify-center",
        children: /* @__PURE__ */ s.jsx(vs, {
          type: "important",
          variant: "field",
          title: "Loading..",
          loader: !0,
          customClass: "mb-5 "
        })
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      p && /* @__PURE__ */ s.jsx(ws, {
        children: /* @__PURE__ */ s.jsx(es, {
          variant: "primary",
          fullwidth: !0,
          onClick: (a) => {
            a.preventDefault(), T(p);
          },
          children: t._({
            id: "f9pW3a"
          })
        })
      })
    ]
  });
  return /* @__PURE__ */ s.jsx(s.Fragment, {
    children: P()
  });
}
function Ts({ investorProfile: l, mandate_id: e, togglePlanDetailsModal: T, mfInvestmentAccount: y, handleAuthorize: L, isPartner: u }) {
  const { fpapi: S } = ms(cs), { amcs: v, tenantConfig: F } = ps(), X = F == null ? void 0 : F.fallbackLogo, A = F == null ? void 0 : F.showSchemeLogo, [p, w] = f(!0), [m, r] = f(null), [c, V] = f([]), [R, j] = f(null), [J, b] = f(null), q = Ss(J), [D, U] = f(!1), [E, I] = f(10), [M, is] = f([]);
  as(() => {
    if (q.hasScrolledToBottom) {
      const i = E + 10;
      $(i), I((o) => o + 10);
    }
  }, [
    q.hasScrolledToBottom
  ]), as(() => {
    a();
  }, [
    l
  ]);
  const $ = async (i) => {
    if (!(E >= (R == null ? void 0 : R.length)))
      try {
        U(!0);
        const o = structuredClone(R);
        if (us(o)) {
          U(!1);
          return;
        }
        const d = await P(o, E, i, M);
        V((x) => x.concat(d)), G(m) ? r(R[0]) : r(m), U(!1);
      } catch (o) {
        window.debug.error(o), U(!1), O(o);
      }
  }, P = async (i, o, d, x) => {
    const W = structuredClone(i);
    let z = [];
    const ss = /* @__PURE__ */ new Set();
    W.slice(o, d).forEach((N) => {
      ss.add(N.scheme);
      const Y = x.find((ts) => +(ts.id === Number(N.payment_source)));
      Y && (N.mandate = {
        status: Y.mandate_status,
        limit: Y.mandate_limit
      }), z.push(N);
    }), e && (z = z.filter((N) => Number(N.payment_source) === Number(e)));
    const hs = z;
    try {
      const N = [];
      ss.forEach((ts) => {
        const ns = S.fpClient.master_data().fetchScheme(ts);
        N.push(ns);
      });
      const Y = await Promise.all(N);
      Y.length > 0 && hs.forEach((ts) => {
        const ns = Y.find((Ns) => Ns.isin === ts.scheme);
        ns && (ts.name = ns.name, ts.amc_id = ns.amc_id);
      });
    } catch (N) {
      window.debug.log(N, "Error");
    }
    return hs;
  }, a = async () => {
    var i;
    try {
      if (w(!0), G(y)) {
        w(!1);
        return;
      }
      const o = await S.fpClient.mf_purchase_plans().fetchAll({
        mf_investment_account: y.id
      });
      if (!o.data.length) {
        w(!1);
        return;
      }
      const d = structuredClone(o.data);
      j(d);
      const x = await S.fpClient.bank_accounts().fetchAll({
        profile: l == null ? void 0 : l.id
      }), W = (i = x == null ? void 0 : x.data) == null ? void 0 : i.map((N) => N.old_id);
      if (!W.length) {
        w(!1);
        return;
      }
      const z = await S.fpClient.mandates().fetchAll({
        bank_account_id: W.join(","),
        size: 100
      }), ss = z == null ? void 0 : z.mandates;
      ss.sort((N, Y) => new Date(Y.created_at).valueOf() - new Date(N.created_at).valueOf()), ss.length > 0 && (x.data.forEach((N) => {
        ss.forEach((Y) => {
          Y.bank_account_id === N.old_id && (Y.bank = {
            bank_name: N.bank_name,
            number: N.account_number,
            ifsc_code: N.ifsc_code
          });
        });
      }), is(ss));
      const hs = await P(o == null ? void 0 : o.data, 0, E, ss);
      V(hs), r(d[0]), w(!1);
    } catch (o) {
      window.debug.error(o), w(!1), O(o);
    }
  }, h = (i) => {
    r(R[i.target.value]);
  }, C = (i) => {
    if (i.mandate && i.state !== "cancelled" && i.state !== "completed")
      return i.mandate.status === "CREATED" && !u ? {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: t._({
              id: "lP0RkG"
            })
          })
        }),
        buttonText: "Authorize mandate"
      } : i.mandate.status === "CREATED" && u ? {
        title: /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx("div", {
            className: "text-center",
            children: t._({
              id: "lP0RkG"
            })
          })
        }),
        buttonText: "Send mandate authorization link"
      } : void 0;
  };
  function B(i) {
    if (i.requested_activation_date && i.state === "created")
      return /* @__PURE__ */ s.jsx(s.Fragment, {
        children: /* @__PURE__ */ s.jsx("div", {
          className: "text-center",
          children: t._({
            id: "bpnk88",
            values: {
              0: K(new Date(i.requested_activation_date), "dd-MMM-yyyy")
            }
          })
        })
      });
  }
  const Z = (i) => (M == null ? void 0 : M.filter((d) => Number(d == null ? void 0 : d.id) === Number(i == null ? void 0 : i.payment_source)))[0] ?? {}, H = () => p ? /* @__PURE__ */ s.jsx(xs, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(Es, {
        onChange: h,
        variant: "selectable",
        selected: 0,
        children: c.length ? c == null ? void 0 : c.map((i) => {
          var o, d;
          return /* @__PURE__ */ s.jsx(ls, {
            footer: C(i),
            footerNotes: B(i),
            onClick: () => i.mandate.status === "CREATED" && L(Z(i)),
            innerRef: b,
            children: /* @__PURE__ */ s.jsxs("div", {
              className: "-ml-2 -mr-2 flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 w-full pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "XTJZw8"
                    }),
                    value: i.name ? os(i.name) : "-",
                    logo: A ? v[i.amc_id] || X : ""
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "/DJBcB"
                    }),
                    value: i.amount ? `₹ ${gs(i.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "6kkUMg"
                    }),
                    value: i.state ? i.state === "failed" || i.state === "cancelled" ? /* @__PURE__ */ s.jsx(ds, {
                      variant: "outline",
                      message: `${k(i.state)}`,
                      status: "error"
                    }) : /* @__PURE__ */ s.jsx(ds, {
                      variant: "outline",
                      message: `${k(i.state)}`,
                      status: "success"
                    }) : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "UMXPdU"
                    }),
                    value: i != null && i.frequency ? (o = k(i == null ? void 0 : i.frequency)) == null ? void 0 : o.replace(/_/g, " ") : "-"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(n, {
                    label: t._({
                      id: "lFCwug"
                    }),
                    value: l != null && l.employer ? "Salary" : i.payment_method && i.mandate ? k(i.payment_method) : "Not setup",
                    icon: i.payment_method && i.mandate ? (d = Ls[i.mandate.status]) == null ? void 0 : d.icon : "",
                    iconPlacement: "left"
                  })
                })
              ]
            })
          }, i.id);
        }) : /* @__PURE__ */ s.jsx(_s, {
          title: t._({
            id: "ZQXjwm"
          }),
          description: t._({
            id: "dWFyRp"
          })
        })
      }),
      D ? /* @__PURE__ */ s.jsx("div", {
        className: "flex justify-center",
        children: /* @__PURE__ */ s.jsx(vs, {
          type: "important",
          variant: "field",
          title: "Loading..",
          loader: !0,
          customClass: "mb-5 "
        })
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      m ? /* @__PURE__ */ s.jsx(ws, {
        children: /* @__PURE__ */ s.jsx(es, {
          variant: "primary",
          fullwidth: !0,
          onClick: (i) => {
            i.preventDefault(), T(m);
          },
          children: t._({
            id: "f9pW3a"
          })
        })
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {})
    ]
  });
  return /* @__PURE__ */ s.jsx(s.Fragment, {
    children: H()
  });
}
function ve(l) {
  var u;
  const { tenantConfig: e } = ps(), T = (u = e == null ? void 0 : e.app_constants) == null ? void 0 : u.planList, y = {
    sip: {
      title: t._({
        id: "dmetZq"
      }),
      content: /* @__PURE__ */ s.jsx(Ts, {
        ...l
      })
    },
    swp: {
      title: t._({
        id: "tu5nmH"
      }),
      content: /* @__PURE__ */ s.jsx(qs, {
        ...l
      })
    },
    stp: {
      title: t._({
        id: "+Q/+Mn"
      }),
      content: /* @__PURE__ */ s.jsx(se, {
        ...l
      })
    }
  }, L = T == null ? void 0 : T.map((S) => y[S]);
  return /* @__PURE__ */ s.jsx(Zs, {
    selected: 0,
    group: !l.isGroupInvestment && !l.mandate_id ? L : [
      {
        title: t._({
          id: "dmetZq"
        }),
        content: /* @__PURE__ */ s.jsx(Ts, {
          ...l
        })
      },
      {
        title: t._({
          id: "tu5nmH"
        }),
        content: /* @__PURE__ */ s.jsx(qs, {
          ...l
        })
      }
    ]
  });
}
export {
  ge as P,
  ve as a
};
