import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as u, useState as a, useEffect as v } from "react";
import { AppContext as q } from "./AppContext.js";
import { F as z } from "./provider-DZPLM8yJ.js";
import { LoginContext as H } from "./LoginContext.js";
import { P as J, a as K } from "./index.esm-BV4iWo1a.js";
import { O as Q } from "./OrderStatus-DVg5uaSo.js";
import { u as V } from "./useConfiguration-B-lyaBB9.js";
import { r as f } from "./sentry-ZQO5C1U6.js";
import { e as Y, B as P } from "./utils-BTh-HRzb.js";
import { i as c } from "./index-B3vUTrRT.js";
import { W as Z } from "./Welcome-3PCWRbTF.js";
import { ErrorPage as ee } from "./ErrorPage.js";
import { P as te } from "./Product-DewIFE9c.js";
import { A as oe } from "./Auth-CjS0P6BG.js";
function xe({ order_id: l }) {
  const { fpapi: s } = u(z), { loggedInUser: m } = u(H), { AppState: { showWelcomePage: p }, setShowWelcomePage: A } = u(q), h = Y(l), [d, w] = a(null), [F, L] = a(null), [g, C] = a([]), [O, x] = a(!0), [I, R] = a(null), [S, r] = a(""), { tenantConfig: i, amcs: b } = V(), E = i == null ? void 0 : i.fallbackLogo, T = i == null ? void 0 : i.showSchemeLogo, [W, y] = a(_), k = [
    {
      step: 1,
      content: c._({
        id: "TT3rWl"
      })
    },
    {
      step: 2,
      content: c._({
        id: "yAosOl"
      })
    }
  ];
  v(() => {
    (async () => {
      var o;
      if (m === void 0)
        x(!1), r("login");
      else {
        const t = await s.getInvestorProfile((o = m == null ? void 0 : m.profile) == null ? void 0 : o.fp_identifier);
        R(t), h === "mf_purchase" ? await N(l) : h === "mf_redemption" ? await B(l) : r("invalidOrderId"), x(!1);
      }
    })();
  }, []);
  async function B(o) {
    let t;
    try {
      t = await s.fpClient.mf_redemptions().fetch(o), w(t);
    } catch (n) {
      r("invalidOrderId"), f(n);
      return;
    }
    await j(t.scheme);
  }
  async function N(o) {
    let t;
    try {
      t = await s.fpClient.mf_purchases().fetch(o), w(t);
    } catch (n) {
      r("invalidOrderId"), f(n);
      return;
    }
    if (t) {
      try {
        const n = await s.fpClient.payments().fetchAll({
          amc_order_ids: Number(t.old_id)
        });
        L(n.payments[0]);
      } catch (n) {
        window.debug.log(n), f(n);
      }
      await j(t.scheme);
    }
  }
  async function j(o) {
    try {
      const t = await s.fpClient.master_data().fetchScheme(o);
      M(t), r("orderStatus");
    } catch (t) {
      r("schemeNotFound"), window.debug.log(t), f(t);
    }
  }
  function M(o) {
    if (!o)
      r("schemeNotFound");
    else {
      const t = T ? {
        ...o,
        schemeLogo: b[o == null ? void 0 : o.amc_id] || E
      } : {
        ...o
      };
      C(t);
    }
  }
  v(() => {
    y(_());
  }, [
    p
  ]);
  function _() {
    return p ? "welcome" : "status";
  }
  function $() {
    A(!1), y("status");
  }
  function D() {
    window.location.href = window.location.origin + P + `/payment?order_id=${d.id}`;
  }
  function G() {
    window.location.href = window.location.origin + P + `/checkout?isin=${d.scheme}`;
  }
  const U = () => {
    if (S)
      switch (S) {
        default:
        case "login":
          return /* @__PURE__ */ e.jsx(e.Fragment, {
            children: /* @__PURE__ */ e.jsx(oe, {
              redirectURL: window.location.href,
              allowRegistration: !1,
              showTestPan: {
                onboarded: !0
              }
            })
          });
        case "orderStatus":
          return /* @__PURE__ */ e.jsxs(e.Fragment, {
            children: [
              /* @__PURE__ */ e.jsx(te, {
                scheme: g
              }),
              /* @__PURE__ */ e.jsx(Q, {
                investorProfile: I,
                scheme: g,
                mf_order: d,
                payment: F,
                handleRetry: D,
                handleInvestAgain: G
              })
            ]
          });
        case "invalidOrderId":
          return /* @__PURE__ */ e.jsx(ee, {
            title: c._({
              id: "BdCXGw"
            }),
            description: c._({
              id: "7RfjwM"
            }),
            showDetails: !1,
            showAction: !1
          });
      }
  };
  function X() {
    return W === "welcome" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(J, {
          title: c._({
            id: "EtnR3n"
          })
        }),
        /* @__PURE__ */ e.jsx(Z, {
          onAck: $,
          welcomeSteps: k
        })
      ]
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: O ? /* @__PURE__ */ e.jsx(K, {
        variant: "fullpage"
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {
        children: U()
      })
    });
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: X()
  });
}
export {
  xe as Status
};
