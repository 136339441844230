import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as g, useContext as gt, useEffect as mt, useRef as zt, useMemo as Vt } from "react";
import { a as ft, P as dt, c as ct, R as $t, S as Bt, B as xt, D as Ot, M as bt, q as Ht, b as ht, e as Jt, F as Xt } from "./index.esm-BV4iWo1a.js";
import { u as kt } from "./useConfiguration-B-lyaBB9.js";
import { F as yt } from "./provider-DZPLM8yJ.js";
import { r as H } from "./sentry-ZQO5C1U6.js";
import { W as Ut, f as Yt, C as jt, B as Kt, i as ot, X as Qt, Y as Zt, Z as At, _ as Gt, $ as qt } from "./utils-BTh-HRzb.js";
import { u as Lt, O as Et } from "./OtpForm-ZSChm5PA.js";
import { i as a } from "./index-B3vUTrRT.js";
import { NotFoundPage as Pt } from "./NotFoundPage.js";
import { P as _t } from "./Product-DewIFE9c.js";
import { O as Nt, r as wt, M as Ct } from "./OrderStatus-DVg5uaSo.js";
import { m as Ft, M as It, r as te } from "./MandateListing-BxVtIJW-.js";
import { o as ee } from "./redirection-BniB68oR.js";
import { C as Wt, S as Rt } from "./StatusMessage-DFmLnokA.js";
import { B as se } from "./BankAccountListing-47D9bb2a.js";
import { T as ne } from "./Tnc-CMTkJi80.js";
import { ErrorPage as Dt } from "./ErrorPage.js";
import { f as ie, r as re } from "./validators-BArvL7cB.js";
import { R as ae, F as oe } from "./react-final-form.es-DAN9JLvE.js";
import { LoginContext as de } from "./LoginContext.js";
import { M as ce } from "./MandateSelector-FCOUQdtt.js";
function le({ scheme: o, investorProfile: b, mf_purchase: E, onCompletion: C }) {
  var ut, T;
  const { tenantConfig: W, banks: P } = kt(), I = W == null ? void 0 : W.fallbackLogo, y = E != null && E.amount ? E.amount : 0, [x, p] = g(null), [w, k] = g(!0), [J, l] = g(!1), [S, F] = g("initial"), [B, K] = g(!1), [r, it] = g(null), [f, s] = g(null), { fpapi: d } = gt(yt), [N, z] = g([]);
  let M = ((ut = W == null ? void 0 : W.app_constants) == null ? void 0 : ut.paymentMethods) || [];
  M = y >= 1e5 ? M : M == null ? void 0 : M.filter((i) => i !== "mandate");
  const [$, Q] = g((M || [])[0]), [rt, tt] = g(!1), O = Ut(M), [c, v] = g(""), [R, D] = g(""), _ = (T = W == null ? void 0 : W.app_constants) == null ? void 0 : T.paymentGateway;
  mt(() => {
    Z();
  }, []), mt(() => {
    lt(), X();
  }, [
    x
  ]);
  const Z = async () => {
    try {
      if (b != null && b.id) {
        tt(!0);
        const i = await d.fpClient.bank_accounts().fetchAll({
          profile: b.id
        });
        p(i.data), tt(!1);
      }
    } catch (i) {
      tt(!1), window.debug.error(i), H(i);
    }
  }, lt = async () => {
    const i = x == null ? void 0 : x.map((q) => q.old_id);
    Yt(i) || d.fpClient.mandates().fetchAll({
      bank_account_id: i == null ? void 0 : i.join(","),
      size: 100
    }).then((q) => {
      const n = q.mandates;
      if (n.sort((h, L) => new Date(L.created_at).valueOf() - new Date(h.created_at).valueOf()), n.length > 0) {
        x == null || x.forEach((u) => {
          n == null || n.forEach((st) => {
            st.bank_account_id === u.old_id && (st.bank = {
              bank_name: u.bank_name,
              ifsc: u.ifsc_code,
              number: u.account_number
            });
          });
        });
        const h = [];
        n.forEach((u) => {
          u.mandate_status === "APPROVED" && h.push(u);
        });
        const L = h.filter((u) => u.mandate_limit >= y);
        h.length && L[0] ? s(L[0]) : s(null);
      }
    });
  }, X = async () => (x != null && x.length && (z(x), it(x[0])), x), e = async () => {
    try {
      const i = await d.fpClient.email_addresses().fetchAll({
        profile: b == null ? void 0 : b.id
      }), q = await d.fpClient.phone_numbers().fetchAll({
        profile: b == null ? void 0 : b.id
      });
      return {
        emailData: i,
        phoneData: q
      };
    } catch (i) {
      window.debug.log(i), H(i);
    }
  }, m = (i) => {
    i.stopPropagation(), k(!w);
  }, j = (i) => (i == null ? void 0 : i.mandate_status) !== "APPROVED" ? a._({
    id: "n8egj/"
  }) : (i == null ? void 0 : i.mandate_limit) < y ? a._({
    id: "1go36W"
  }) : "", V = (i) => {
    C({
      success: !0,
      isWindowBlocked: !1,
      paymentId: i
    });
  }, A = (i) => {
    C({
      success: !1,
      isWindowBlocked: !0,
      paymentId: i
    });
  }, Y = (i) => {
    C({
      success: !0,
      isWindowBlocked: !1,
      paymentId: i
    });
  };
  function U(i) {
    i.preventDefault();
    const q = r.old_id;
    if ($ === "mandate") {
      const n = j(f);
      v(n), n || (F("initiated"), d.fpClient.payments().createNachPayment({
        mandate_id: f.id,
        amc_order_ids: [
          E.old_id
        ]
      }).then((h) => {
        C({
          success: !0,
          isWindowBlocked: !1,
          paymentId: h.id
        });
      }).catch((h) => {
        var L, u;
        H(h), h && ((u = (L = h == null ? void 0 : h.data) == null ? void 0 : L.error) == null ? void 0 : u.message) === "consent_not_captured" ? C({
          success: !1,
          isWindowBlocked: !1,
          error: h
        }) : (D("Something went wrong"), F("initial"));
      }));
    } else
      _ && _ === "BILLDESK" ? (F("initiated"), d.fpClient.payments().createNetbankingPayment({
        amc_order_ids: [
          E.old_id
        ],
        method: $,
        bank_account_id: q,
        payment_postback_url: `${window.location.origin + Kt}/redirection`,
        provider_name: "BILLDESK"
      }).then((n) => {
        ee(n == null ? void 0 : n.token_url, "payment-billdesk", () => V(n == null ? void 0 : n.id), () => A(n == null ? void 0 : n.id), () => Y(n == null ? void 0 : n.id));
      }).catch((n) => {
        var h, L;
        H(n), n && ((L = (h = n == null ? void 0 : n.data) == null ? void 0 : h.error) == null ? void 0 : L.message) === "consent_not_captured" ? C({
          success: !1,
          isWindowBlocked: !1,
          error: n
        }) : (D("Something went wrong"), F("initial"));
      })) : (F("initiated"), d.fpClient.payments().createNetbankingPayment({
        amc_order_ids: [
          E.old_id
        ],
        method: $,
        bank_account_id: q
      }).then((n) => {
        e().then((h) => {
          var St, Mt;
          const { emailData: L, phoneData: u } = h, st = n.id, at = n.sdk_options.razorpay;
          let vt = null;
          for (const nt in at.method)
            at.method[nt] && (vt = nt);
          const Tt = {
            amount: at.amount,
            currency: "INR",
            email: (St = L == null ? void 0 : L.data[0]) == null ? void 0 : St.email,
            contact: (Mt = u == null ? void 0 : u.data[0]) == null ? void 0 : Mt.number,
            method: vt,
            bank: at.bank_code,
            order_id: at.order_id
          }, pt = te(at.key);
          pt.on("payment.success", function(nt) {
            d.submitPayment(at.callback_url, nt.razorpay_payment_id, nt.razorpay_order_id, nt.razorpay_signature).then(() => {
              C({
                success: !0,
                isWindowBlocked: !1,
                paymentId: st
              });
            });
          }), pt.on("payment.error", function(nt) {
            d.submitPaymentError(at.callback_url, "error", nt.error.code, nt.error.description).then(() => {
              C({
                success: !1,
                isWindowBlocked: !1,
                paymentId: st
              });
            });
          }), pt.createPayment(Tt);
        });
      }).catch((n) => {
        var h, L;
        H(n), n && ((L = (h = n == null ? void 0 : n.data) == null ? void 0 : h.error) == null ? void 0 : L.message) === "consent_not_captured" ? C({
          success: !1,
          isWindowBlocked: !1,
          error: n
        }) : (D("Something went wrong"), F("initial"));
      }));
  }
  const G = () => {
    var i, q, n, h, L;
    return /* @__PURE__ */ t.jsx(t.Fragment, {
      children: rt ? /* @__PURE__ */ t.jsx(ft, {
        variant: "fullpage"
      }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
        children: [
          /* @__PURE__ */ t.jsx(Wt, {
            check: E.plan,
            children: /* @__PURE__ */ t.jsx(dt, {
              title: a._({
                id: "LgSBEi"
              })
            })
          }),
          o && /* @__PURE__ */ t.jsx(_t, {
            scheme: o
          }),
          /* @__PURE__ */ t.jsx(ct, {
            label: `${E.plan ? a._({
              id: "/DJBcB"
            }) : a._({
              id: "KqeLJ1"
            })}`,
            value: `₹ ${jt(y)}`,
            direction: "row",
            border: !0,
            customClass: "mb-7"
          }),
          /* @__PURE__ */ t.jsx("form", {
            onSubmit: U,
            className: "flex flex-1 flex-col",
            children: /* @__PURE__ */ t.jsxs("div", {
              className: "flex flex-1 flex-col justify-between",
              children: [
                /* @__PURE__ */ t.jsxs("div", {
                  children: [
                    /* @__PURE__ */ t.jsx("div", {
                      className: "mb-8",
                      children: /* @__PURE__ */ t.jsxs("div", {
                        children: [
                          /* @__PURE__ */ t.jsx(dt, {
                            variant: "sub",
                            title: a._({
                              id: "pCX9tn"
                            }),
                            customClass: "mb-3"
                          }),
                          /* @__PURE__ */ t.jsx($t, {
                            variant: "iconBased",
                            name: "radioname",
                            checked: M.indexOf($),
                            group: O,
                            onChange: (u, st) => {
                              Q(st.value), v("");
                            }
                          })
                        ]
                      })
                    }),
                    $ === "mandate" && y >= 1e5 ? /* @__PURE__ */ t.jsxs("div", {
                      className: "mb-8 mt-4",
                      children: [
                        /* @__PURE__ */ t.jsx(dt, {
                          variant: "sub",
                          title: a._({
                            id: "tJ7I/4"
                          })
                        }),
                        f ? /* @__PURE__ */ t.jsx(Bt, {
                          children: /* @__PURE__ */ t.jsxs("div", {
                            className: "flex flex-wrap items-center justify-between",
                            children: [
                              /* @__PURE__ */ t.jsx("div", {
                                className: "w-full",
                                children: /* @__PURE__ */ t.jsx(ct, {
                                  label: a._({
                                    id: "L/u9Dz"
                                  }),
                                  value: f ? `${(i = f == null ? void 0 : f.bank) == null ? void 0 : i.bank_name} - ****${(q = f == null ? void 0 : f.bank) == null ? void 0 : q.number.slice(-4)}` : "-",
                                  logo: P[(h = (n = f == null ? void 0 : f.bank) == null ? void 0 : n.ifsc) == null ? void 0 : h.slice(0, 4)] || I
                                })
                              }),
                              /* @__PURE__ */ t.jsx("div", {
                                children: /* @__PURE__ */ t.jsx(ct, {
                                  label: a._({
                                    id: "x4Fb5/"
                                  }),
                                  value: f ? `₹${jt(f == null ? void 0 : f.mandate_limit)}` : "-"
                                })
                              }),
                              /* @__PURE__ */ t.jsx("div", {
                                children: /* @__PURE__ */ t.jsx(ct, {
                                  label: a._({
                                    id: "MhEf8P"
                                  }),
                                  value: f ? Ft[f.mandate_status].value : "-",
                                  icon: f ? Ft[f.mandate_status].icon : "",
                                  iconPlacement: "left"
                                })
                              }),
                              /* @__PURE__ */ t.jsx("div", {
                                children: /* @__PURE__ */ t.jsx(xt, {
                                  variant: "text",
                                  onClick: (u) => {
                                    u.preventDefault(), u.stopPropagation(), K(!0);
                                  },
                                  children: a._({
                                    id: "ShFidp"
                                  })
                                })
                              })
                            ]
                          })
                        }) : /* @__PURE__ */ t.jsx(t.Fragment, {
                          children: /* @__PURE__ */ t.jsx(Ot, {
                            type: "notes",
                            text: a._({
                              id: "o+Qbf0"
                            }),
                            children: /* @__PURE__ */ t.jsx(xt, {
                              variant: "text",
                              onClick: (u) => {
                                u.preventDefault(), u.stopPropagation(), K(!0);
                              },
                              children: a._({
                                id: "uhQPR2"
                              })
                            })
                          })
                        }),
                        c && /* @__PURE__ */ t.jsx("div", {
                          children: /* @__PURE__ */ t.jsx(bt, {
                            type: "error",
                            variant: "field",
                            title: c,
                            customClass: ""
                          })
                        })
                      ]
                    }) : /* @__PURE__ */ t.jsxs("div", {
                      children: [
                        /* @__PURE__ */ t.jsx(dt, {
                          variant: "sub",
                          title: a._({
                            id: "bu9VTL"
                          }),
                          customClass: "mb-3"
                        }),
                        /* @__PURE__ */ t.jsx(Ot, {
                          type: r ? "borderedNotes" : "notes",
                          // text="ICICI BANK (*****9872)"
                          text: r ? `${r.bank_name} - ****${r.account_number.slice(-4)}` : a._({
                            id: "Hgankl"
                          }),
                          logo: r ? P[(L = r == null ? void 0 : r.ifsc_code) == null ? void 0 : L.slice(0, 4)] : I
                        })
                      ]
                    })
                  ]
                }),
                /* @__PURE__ */ t.jsxs("div", {
                  children: [
                    /* @__PURE__ */ t.jsxs(Ht, {
                      id: "terms-id",
                      checked: w,
                      onChange: m,
                      customClass: "mb-3",
                      children: [
                        a._({
                          id: "PFNsn4"
                        }),
                        /* @__PURE__ */ t.jsx("span", {
                          children: " "
                        }),
                        /* @__PURE__ */ t.jsx("a", {
                          onClick: (u) => {
                            u.preventDefault(), u.stopPropagation(), l(!0);
                          },
                          className: "text-size-14",
                          children: a._({
                            id: "863m1W"
                          })
                        })
                      ]
                    }),
                    R && /* @__PURE__ */ t.jsx(bt, {
                      type: "error",
                      variant: "field",
                      title: "Something went wrong"
                    }),
                    /* @__PURE__ */ t.jsx(xt, {
                      variant: w && N.length ? "primary" : "disabled",
                      fullwidth: !0,
                      customClass: "mb-8",
                      type: "submit",
                      children: a._({
                        id: "AIDv2f",
                        values: {
                          0: jt(y)
                        }
                      })
                    })
                  ]
                })
              ]
            })
          }),
          /* @__PURE__ */ t.jsx(ht, {
            variant: "fullPage",
            title: a._({
              id: "s4HNy/"
            }),
            isOpen: J,
            toggleModal: l,
            contentHeightMax: !0,
            children: /* @__PURE__ */ t.jsx(ne, {
              disableBackButton: !0
            })
          }),
          /* @__PURE__ */ t.jsx(ht, {
            variant: "fullPage",
            title: a._({
              id: "XeOA76"
            }),
            isOpen: B,
            toggleModal: () => {
              K(!1);
            },
            contentHeightMax: !1,
            children: /* @__PURE__ */ t.jsx(It, {
              investorProfile: b,
              status: [
                "approved"
              ],
              limit: y,
              handleMandateSelection: (u) => {
                const st = j(u);
                v(st), s(u), K(!1);
              }
            })
          })
        ]
      })
    });
  }, et = () => {
    switch (S) {
      case "initial":
        return G();
      case "initiated":
        return /* @__PURE__ */ t.jsx(ft, {
          variant: "fullpage"
        });
      case "listing_bankaccount":
        return /* @__PURE__ */ t.jsx(se, {
          investorProfile: b,
          handleBankSelection: (i) => {
            it(i), z([
              i
            ]), F("initial"), Z();
          }
        });
      default:
        return G();
    }
  };
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: et()
  });
}
function Ie({ investorProfile: o, id: b, showSchemeDetails: E = !0, isGroupInvestment: C = !1, action: W, onSuccess: P }) {
  const { fpapi: I } = gt(yt), [y, x] = g(!1), [p, w] = g(""), [k, J] = g(), [l, S] = g(null), { tenantConfig: F, amcs: B } = kt(), K = F == null ? void 0 : F.fallbackLogo, r = F == null ? void 0 : F.showSchemeLogo, [it, f] = g({}), { collectedAcquisition: s, initiateSendOTP: d } = Lt();
  mt(() => {
    N();
  }, []);
  const N = async () => {
    var R, D;
    const O = b == null ? void 0 : b.split(",");
    x(!0);
    let c, v;
    try {
      if (c = await I.fpClient.mf_purchases().fetch(b.split(",")[0]), v = !ot(c) && await I.fpClient.payments().fetchAll({
        amc_order_ids: c == null ? void 0 : c.old_id
      }), E) {
        const Z = await I.fpClient.master_data().fetchScheme(c.scheme);
        z(Z);
      }
      S(c), c.state === "pending" ? w("consent") : w("consent_not_required");
      const _ = {
        given_for_object: c == null ? void 0 : c.object,
        action: W,
        given_for: O
      };
      await d(_), x(!1);
    } catch (_) {
      window.debug.log(_, "error"), !ot(c) && (c == null ? void 0 : c.state) !== "pending" && ((R = v == null ? void 0 : v.payments) != null && R.length) ? (f((D = v == null ? void 0 : v.payments) == null ? void 0 : D[0]), w("finished")) : w("error"), x(!1);
    }
  }, z = (O) => {
    const c = r ? {
      ...O,
      schemeLogo: B[O == null ? void 0 : O.amc_id] || K
    } : {
      ...O
    };
    J(c);
  }, M = async () => {
    var O, c, v, R, D, _, Z, lt;
    if (x(!0), P)
      try {
        const X = await I.fpClient.mf_purchases().update({
          id: l == null ? void 0 : l.id,
          consent: {
            email: (O = s == null ? void 0 : s.acquisition_details) == null ? void 0 : O.email,
            isd_code: (c = s == null ? void 0 : s.acquisition_details) == null ? void 0 : c.isd,
            mobile: (v = s == null ? void 0 : s.acquisition_details) == null ? void 0 : v.mobile_number
          }
        });
        P(X), x(!1);
      } catch {
        w("error"), x(!1);
      }
    else
      try {
        const X = await I.fpClient.mf_purchases().update({
          id: l == null ? void 0 : l.id,
          consent: {
            email: (R = s == null ? void 0 : s.acquisition_details) == null ? void 0 : R.email,
            isd_code: (D = s == null ? void 0 : s.acquisition_details) == null ? void 0 : D.isd,
            mobile: (_ = s == null ? void 0 : s.acquisition_details) == null ? void 0 : _.mobile_number
          }
        });
        S(X), w("payment"), x(!1);
      } catch (X) {
        if (X && ((lt = (Z = X == null ? void 0 : X.data) == null ? void 0 : Z.error) == null ? void 0 : lt.message) === "failed to update investor consent, already exists") {
          w("payment"), x(!1);
          return;
        }
        w("error"), x(!1);
      }
  }, $ = () => /* @__PURE__ */ t.jsxs("div", {
    children: [
      /* @__PURE__ */ t.jsx(dt, {
        title: a._({
          id: "ol5JJc"
        }),
        semiBold: !0
      }),
      /* @__PURE__ */ t.jsx(Bt, {
        customClass: "mb-8",
        children: /* @__PURE__ */ t.jsxs("div", {
          className: "-mr-2 -ml-2  flex flex-wrap items-center",
          children: [
            /* @__PURE__ */ t.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ t.jsx(ct, {
                label: a._({
                  id: "4Zu577"
                }),
                value: l != null && l.amount ? `₹ ${jt(l == null ? void 0 : l.amount, 2)}` : "-"
              })
            }),
            /* @__PURE__ */ t.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ t.jsx(ct, {
                label: a._({
                  id: "rs9/UI"
                }),
                value: "One time"
              })
            }),
            /* @__PURE__ */ t.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ t.jsx(ct, {
                label: a._({
                  id: "cXcPd1"
                }),
                value: l != null && l.folio_number ? l == null ? void 0 : l.folio_number : "New folio"
              })
            })
          ]
        })
      })
    ]
  }), Q = () => /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(dt, {
        title: a._({
          id: "72BAzM"
        })
      }),
      /* @__PURE__ */ t.jsx(_t, {
        scheme: k
      })
    ]
  });
  function rt({ success: O, isWindowBlocked: c, paymentId: v }) {
    (async () => {
      if (v)
        try {
          const R = await I.fpClient.payments().fetch(Number(v));
          f(R);
          const D = await I.fpClient.mf_purchases().fetch(l.id);
          S(D);
        } catch (R) {
          window.debug.log(R), H(R);
        }
      w(O && !c || !O && !c ? "finished" : "finished_error");
    })();
  }
  return y ? /* @__PURE__ */ t.jsx(ft, {
    variant: "fullpage"
  }) : (() => {
    switch (p) {
      case "consent":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            E && Q(),
            $(),
            /* @__PURE__ */ t.jsx(Et, {
              handleSubmit: M,
              pageTitle: a._({
                id: "s/ORP1"
              }),
              isGroupInvestment: C,
              investorProfile: o,
              collectAcquisition: s
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            E && Q(),
            $(),
            /* @__PURE__ */ t.jsx(Rt, {
              title: a._({
                id: "8Erf1o"
              }),
              description: a._({
                id: "dYdyvO"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            E && Q(),
            $(),
            /* @__PURE__ */ t.jsx(Dt, {
              title: a._({
                id: "sS+OUW"
              }),
              showAction: !0,
              actionText: a._({
                id: "RvsoP+"
              }),
              actionHandler: () => {
                w("payment");
              }
            })
          ]
        });
      case "payment":
        return !ot(o) && !ot(l) ? /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(_t, {
              scheme: k
            }),
            /* @__PURE__ */ t.jsx(le, {
              investorProfile: o,
              mf_purchase: l,
              onCompletion: rt
            })
          ]
        }) : /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "finished":
        return !ot(k) && !ot(l) ? /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(_t, {
              scheme: k
            }),
            /* @__PURE__ */ t.jsx(Nt, {
              investorProfile: o,
              scheme: k,
              mf_order: l,
              payment: it,
              isGroupInvestment: C
            })
          ]
        }) : /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "not_allowed":
        return /* @__PURE__ */ t.jsx(Pt, {});
      default:
        return /* @__PURE__ */ t.jsx(t.Fragment, {});
    }
  })();
}
function me({ mfPurchasePlan: o, handleSetupMandateCompletion: b, partner: E, groupRecurringDateObject: C, isFirstInstallmentPayment: W, isGroupInvestment: P }) {
  const { loggedInUser: I } = gt(de), { fpapi: y } = gt(yt), [x, p] = g(!1), [w, k] = g(!1), [J, l] = g(null), S = zt(null), [F, B] = g(null);
  mt(() => {
    (async () => {
      var s;
      if (J && (S != null && S.current) && (o != null && o.payment_source))
        try {
          const d = await y.fpClient.bank_accounts().fetchAll({
            profile: J == null ? void 0 : J.id
          }), N = d == null ? void 0 : d.data;
          if (!(N != null && N.length))
            return;
          const z = await y.fpClient.mandates().fetch(o == null ? void 0 : o.payment_source);
          N == null || N.forEach((M) => {
            (z == null ? void 0 : z.bank_account_id) === M.old_id && (z.bank = {
              bank_name: M.bank_name,
              number: M.account_number,
              ifsc: M.ifsc_code
            });
          }), (s = S == null ? void 0 : S.current) == null || s.input.onChange(z);
        } catch (d) {
          H(d);
        }
    })();
  }, [
    o == null ? void 0 : o.payment_source,
    J,
    S
  ]);
  const K = Vt(() => ({
    mandate: null
  }), []);
  mt(() => {
    r();
  }, []);
  const r = async () => {
    var s;
    try {
      p(!0);
      const d = await y.getInvestorProfile((s = I == null ? void 0 : I.profile) == null ? void 0 : s.fp_identifier);
      l(d), p(!1);
    } catch (d) {
      window.debug.error(d), H(d);
    }
  }, it = async ({ mandate: s }) => {
    var d, N, z, M, $, Q, rt, tt, O, c, v, R, D;
    if (((d = s == null ? void 0 : s.id) == null ? void 0 : d.toString()) === ((N = o == null ? void 0 : o.payment_source) == null ? void 0 : N.toString())) {
      b();
      return;
    }
    p(!0);
    try {
      let _ = {};
      _ = {
        id: o == null ? void 0 : o.id,
        payment_method: "mandate",
        payment_source: s == null ? void 0 : s.id
      }, await y.fpClient.mf_purchase_plans().update(_), b(), p(!1);
    } catch (_) {
      if (Zt(_)) {
        const Z = ($ = (M = (z = _ == null ? void 0 : _.data) == null ? void 0 : z.error) == null ? void 0 : M.errors) != null && $.length && ((O = (tt = (rt = (Q = _ == null ? void 0 : _.data) == null ? void 0 : Q.error) == null ? void 0 : rt.errors) == null ? void 0 : tt[0]) != null && O.message) ? At((v = (c = _ == null ? void 0 : _.data) == null ? void 0 : c.error) == null ? void 0 : v.errors) : Gt((D = (R = _ == null ? void 0 : _.data) == null ? void 0 : R.error) == null ? void 0 : D.message, "Something went wrong. Please try with another mandate or try again later");
        B(Z);
      } else
        B("Something went wrong. Please try with another mandate or try again later");
      p(!1), H(F);
    }
  };
  function f() {
    return /* @__PURE__ */ t.jsxs("div", {
      className: "pb-6",
      children: [
        wt(o, E, C, P, W),
        /* @__PURE__ */ t.jsx(ae, {
          onSubmit: it,
          initialValues: K,
          validate: () => ({}),
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (s) => /* @__PURE__ */ t.jsxs("form", {
            id: "editOrderForm",
            onSubmit: s.handleSubmit,
            children: [
              /* @__PURE__ */ t.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ t.jsx(Jt, {
                    message: a._({
                      id: "lFCwug"
                    })
                  }),
                  /* @__PURE__ */ t.jsx(oe, {
                    name: "mandate",
                    validate: ie(re),
                    children: (d) => (S.current = d, /* @__PURE__ */ t.jsxs(t.Fragment, {
                      children: [
                        /* @__PURE__ */ t.jsx(ce, {
                          mandate: d.input.value,
                          handleMandateModalChange: (N) => {
                            N.preventDefault(), k(!w);
                          }
                        }),
                        Qt(d) && /* @__PURE__ */ t.jsx("div", {
                          className: "mb-2 mt-2",
                          children: /* @__PURE__ */ t.jsx(bt, {
                            type: "error",
                            variant: "field",
                            title: d.meta.error
                          })
                        })
                      ]
                    }))
                  })
                ]
              }),
              /* @__PURE__ */ t.jsx(Xt, {
                children: /* @__PURE__ */ t.jsxs("div", {
                  className: "mb-4 pt-1",
                  children: [
                    /* @__PURE__ */ t.jsx(Wt, {
                      check: !!F,
                      children: /* @__PURE__ */ t.jsx(bt, {
                        type: "error",
                        variant: "field",
                        title: F
                      })
                    }),
                    /* @__PURE__ */ t.jsx(xt, {
                      variant: "primary",
                      type: "submit",
                      fullwidth: !0,
                      children: a._({
                        id: "2iVSoY"
                      })
                    })
                  ]
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ t.jsx(ht, {
          variant: "fullPage",
          title: "Select mandate",
          isOpen: w,
          toggleModal: () => {
            k(!1);
          },
          contentHeightMax: !0,
          children: /* @__PURE__ */ t.jsx(It, {
            investorProfile: J,
            handleMandateSelection: (s) => {
              var d;
              k(!1), B(null), (d = S == null ? void 0 : S.current) == null || d.input.onChange(s);
            },
            limit: o == null ? void 0 : o.amount,
            isPartner: !1
          })
        })
      ]
    });
  }
  return x ? /* @__PURE__ */ t.jsx(ft, {
    variant: "fullpage"
  }) : f();
}
function We({ investorProfile: o, id: b, showSchemeDetails: E = !0, isGroupInvestment: C = !1, onSuccess: W, action: P, isFirstInstallmentPayment: I }) {
  const { fpapi: y } = gt(yt), [x, p] = g(!1), [w, k] = g(""), [J, l] = g(), [S, F] = g(null), [B, K] = g(null), { tenantConfig: r, amcs: it, banks: f } = kt(), { collectedAcquisition: s, initiateSendOTP: d } = Lt(), [N, z] = g(), [M, $] = g(null), Q = r == null ? void 0 : r.fallbackLogo, rt = (e) => {
    var m, j, V, A, Y, U;
    if (C) {
      const G = (j = (m = r == null ? void 0 : r.fundList) == null ? void 0 : m.find((et) => et.isin === (e == null ? void 0 : e.scheme))) == null ? void 0 : j.nfo;
      return ((V = r == null ? void 0 : r.groupInvestment) == null ? void 0 : V.days) && ((A = r == null ? void 0 : r.groupInvestment) == null ? void 0 : A.cutOffDays) && qt({
        salaryDay: (Y = r == null ? void 0 : r.groupInvestment) == null ? void 0 : Y.days[0],
        salaryCutoffDay: (U = r == null ? void 0 : r.groupInvestment) == null ? void 0 : U.cutOffDays[0],
        NFO: G,
        purchaseDate: e.created_at
      });
    }
  };
  mt(() => {
    O();
  }, [
    o
  ]);
  const tt = async (e) => {
    if (!ot(e))
      try {
        const m = await (y == null ? void 0 : y.fpClient.partners().fetchById(e == null ? void 0 : e.partner));
        z(m);
      } catch (m) {
        H(m);
      }
  }, O = async () => {
    try {
      p(!0);
      const e = await y.fpClient.mf_purchase_plans().fetch(b);
      if (K(e), E) {
        const m = await y.fpClient.master_data().fetchScheme(e == null ? void 0 : e.scheme);
        v(m);
      }
      await tt(e), !(e != null && e.payment_method) && !(e != null && e.payment_source) ? k("setup_mandate") : await Z(e), (e == null ? void 0 : e.payment_method) === "mandate" && (e != null && e.payment_source) && c(e);
    } catch (e) {
      window.debug.log(e), p(!1), k("error"), H(e);
    } finally {
      p(!1);
    }
  }, c = async (e) => {
    const m = b == null ? void 0 : b.split(",");
    if ((e == null ? void 0 : e.payment_method) === "mandate" && (e != null && e.payment_source))
      try {
        if (p(!0), (e == null ? void 0 : e.state) === "active" || (e == null ? void 0 : e.state) === "completed") {
          k("success"), p(!1);
          return;
        }
        const j = {
          given_for_object: e == null ? void 0 : e.object,
          action: P,
          given_for: m
        };
        await d(j), k("consent"), F(rt(e)), p(!1);
      } catch (j) {
        window.debug.log(j), p(!1), k("error"), H(j);
      }
  }, v = (e) => {
    const m = r != null && r.showSchemeLogo ? {
      ...e,
      schemeLogo: it[e == null ? void 0 : e.amc_id] || (r == null ? void 0 : r.fallbackLogo)
    } : {
      ...e
    };
    l(m);
  }, R = (e) => {
    const m = new Date(e);
    return m.getDay() === 5 ? 3 : m.getDay() === 6 ? 2 : 1;
  }, D = async (e) => {
    let m;
    try {
      m = await y.fpClient.mf_purchases().fetchAll({
        plan: e == null ? void 0 : e.id
      });
    } catch (et) {
      window.debug.log(et), H(et);
    }
    if (!m.data.length)
      return;
    const j = m.data[0], V = e != null && e.created_at ? new Date(e == null ? void 0 : e.created_at) : /* @__PURE__ */ new Date(), A = new Date(new Date(V).setDate(V.getDate() + 1)), Y = new Date(new Date(V).setDate(V.getDate() + R(V))), U = new Date(j == null ? void 0 : j.scheduled_on);
    if (new Date(j == null ? void 0 : j.created_at).toDateString() === V.toDateString() || U.toDateString() === A.toDateString() || U.toDateString() === Y.toDateString())
      return j;
  }, _ = async () => {
    var e, m, j, V, A, Y, U, G, et, ut;
    if (W) {
      p(!0);
      try {
        await y.fpClient.mf_purchase_plans().update({
          id: B == null ? void 0 : B.id,
          consent: {
            email: (e = s == null ? void 0 : s.acquisition_details) == null ? void 0 : e.email,
            isd_code: (m = s == null ? void 0 : s.acquisition_details) == null ? void 0 : m.isd,
            mobile: (j = s == null ? void 0 : s.acquisition_details) == null ? void 0 : j.mobile_number
          }
        });
        const T = await D(B);
        W(!0, T), p(!1);
      } catch (T) {
        if (T && ((A = (V = T == null ? void 0 : T.data) == null ? void 0 : V.error) == null ? void 0 : A.message) === "consent is already exists for the plan") {
          const i = await D(B);
          W(!0, i), p(!1);
          return;
        }
        k("error"), p(!1);
      }
    } else {
      p(!0);
      try {
        const T = await y.fpClient.mf_purchase_plans().update({
          id: B == null ? void 0 : B.id,
          consent: {
            email: (Y = s == null ? void 0 : s.acquisition_details) == null ? void 0 : Y.email,
            isd_code: (U = s == null ? void 0 : s.acquisition_details) == null ? void 0 : U.isd,
            mobile: (G = s == null ? void 0 : s.acquisition_details) == null ? void 0 : G.mobile_number
          }
        });
        K(T), k("success"), p(!1);
      } catch (T) {
        if (T && ((ut = (et = T == null ? void 0 : T.data) == null ? void 0 : et.error) == null ? void 0 : ut.message) === "consent is already exists for the plan") {
          k("success"), p(!1);
          return;
        }
        k("error"), p(!1);
      }
    }
  }, Z = async (e) => {
    o && (e == null ? void 0 : e.payment_method) === "mandate" && (e != null && e.payment_source) && y.fpClient.bank_accounts().fetchAll({
      profile: o == null ? void 0 : o.id
    }).then(async (m) => {
      var Y, U, G;
      const j = m == null ? void 0 : m.data;
      if (!j.length)
        return;
      const A = {
        ...await y.fpClient.mandates().fetch(e == null ? void 0 : e.payment_source),
        bank: {
          bank_name: (Y = j[0]) == null ? void 0 : Y.bank_name,
          number: (U = j[0]) == null ? void 0 : U.account_number,
          ifsc: (G = j[0]) == null ? void 0 : G.ifsc_code
        }
      };
      $(A);
    }).catch((m) => {
      H(m);
    });
  }, lt = () => /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(dt, {
        title: a._({
          id: "u6vc2m"
        }),
        icon: w === "consent" ? "Back" : null,
        onClick: () => {
          w === "consent" && k("setup_mandate");
        }
      }),
      /* @__PURE__ */ t.jsx(_t, {
        scheme: J
      })
    ]
  }), X = () => {
    switch (w) {
      case "setup_mandate":
        return /* @__PURE__ */ t.jsx(me, {
          mfPurchasePlan: B,
          handleSetupMandateCompletion: () => {
            k(""), O();
          },
          partner: N,
          groupRecurringDateObject: S,
          isGroupInvestment: C,
          isFirstInstallmentPayment: I
        });
      case "consent":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            wt(B, N, S, C, I),
            /* @__PURE__ */ t.jsx(Ct, {
              mandate: M,
              banks: f,
              fallbackLogo: Q
            }),
            /* @__PURE__ */ t.jsx(Et, {
              handleSubmit: _,
              pageTitle: a._({
                id: "nvtnXn"
              }),
              investorProfile: o,
              isGroupInvestment: C,
              collectAcquisition: s
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            wt(B, N, S, C, I),
            /* @__PURE__ */ t.jsx(Ct, {
              mandate: M,
              banks: f,
              fallbackLogo: Q
            }),
            /* @__PURE__ */ t.jsx(Rt, {
              title: a._({
                id: "8Erf1o"
              }),
              description: a._({
                id: "dYdyvO"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ t.jsx(Dt, {
          title: a._({
            id: "kCTQHV"
          }),
          description: a._({
            id: "oahseM"
          }),
          showDetails: !1
        });
      case "success":
        return /* @__PURE__ */ t.jsx(t.Fragment, {
          children: /* @__PURE__ */ t.jsx(Nt, {
            investorProfile: o,
            scheme: J,
            mf_order: B
          })
        });
      default:
        return /* @__PURE__ */ t.jsx(ft, {
          variant: "fullpage"
        });
    }
  };
  return x ? /* @__PURE__ */ t.jsx(ft, {
    variant: "fullpage"
  }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      E && lt(),
      X()
    ]
  });
}
export {
  We as P,
  Ie as a,
  le as b
};
