import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as j, useContext as we, useMemo as Ee, useCallback as Ke, useEffect as Ne, useLayoutEffect as vt } from "react";
import { a as $e, e as me, T as Ae, M as Ge, R as pe, c as gt, B as Ie, b as Me, j as et, I as tt, v as at, q as it, p as mt, P as bt } from "./index.esm-BV4iWo1a.js";
import { u as Te } from "./useConfiguration-B-lyaBB9.js";
import { F as ze } from "./provider-DZPLM8yJ.js";
import { LoginContext as Ye } from "./LoginContext.js";
import { r as ve } from "./sentry-ZQO5C1U6.js";
import { aa as St, i as q, f as nt, C as Le, E as We, a5 as Qe, o as Ze, m as Ue, ab as lt, a9 as st, X as dt, ac as ct, u as ut, ad as rt, $ as ot, j as _t, ae as ft, af as qe, B as Pe } from "./utils-BTh-HRzb.js";
import { i as u } from "./index-B3vUTrRT.js";
import { AppContext as Ve } from "./AppContext.js";
import { c as jt } from "./checkMigratedUser-D-TLMNz-.js";
import { p as Ft } from "./partiallyOnboardMigratedUser-Do2b2Ofg.js";
import { ErrorPage as he } from "./ErrorPage.js";
import { K as kt } from "./KycCheckStatus-D23-VNWq.js";
import { S as Mt, a as Nt } from "./SwitchPlanConsent-xXBUIJV6.js";
import { g as fe, s as je, e as Dt, a as De, b as Ce, c as ye, d as yt, f as Ct, h as ht, i as It, O as Tt } from "./OrderStatus-DVg5uaSo.js";
import { b as Lt, P as $t, a as At } from "./PurchasePlanConsent-CrLghjFe.js";
import { R as Xe, F as ge } from "./react-final-form.es-DAN9JLvE.js";
import { C as W } from "./StatusMessage-DFmLnokA.js";
import { u as He } from "./useMigratedUser-LMscgT3S.js";
import { FolioDetails as Je } from "./FolioDetails.js";
import { F as Oe } from "./FolioListing-CnkLmCIc.js";
import { P as Ut } from "./PurchaseOrderList-BhXn6Y-r.js";
import { f as Fe, r as ke } from "./validators-BArvL7cB.js";
import { B as Et } from "./BankAccountListing-47D9bb2a.js";
import { O as Ht } from "./Onboard-D0pbXqp0.js";
import { A as Bt } from "./Auth-CjS0P6BG.js";
function Kt({ scheme: t, investment_account_id: L, investorProfile: C, onSuccess: M, isGroupInvestment: o, isPartner: v, investment_accounts: _, user: ne, holdings: H, assistedWorkflowPAN: oe, assistedWorkflowFolioNumber: ce }) {
  const [f, b] = j(null), [$, B] = j(!1), [G, z] = j(!1), [P, D] = j(!1), [Z, N] = j(null), [Y, le] = j(null), [p, se] = j(null), [V, xe] = j([]), { fpapi: A } = we(ze), { tenantConfig: S } = Te(), { loggedInPartner: U } = we(Ye), [X, ee] = j(null), [de, i] = j(!1), [te, J] = j(null), [_e, n] = j(!1), [ae, Q] = j("initial"), { isMigratedUser: m } = He({
    user: ne
  }), [r, w] = j(!1), { AppState: { employer: y } } = we(Ve), h = Ee(() => {
    var d, x, k, E, T, R;
    const a = (x = (d = S == null ? void 0 : S.fundList) == null ? void 0 : d.find((Se) => Se.isin === (t == null ? void 0 : t.isin))) == null ? void 0 : x.nfo;
    return ((k = S == null ? void 0 : S.groupInvestment) == null ? void 0 : k.days) && ((E = S == null ? void 0 : S.groupInvestment) == null ? void 0 : E.cutOffDays) && St({
      salaryDay: (T = S == null ? void 0 : S.groupInvestment) == null ? void 0 : T.days[0],
      salaryCutoffDay: (R = S == null ? void 0 : S.groupInvestment) == null ? void 0 : R.cutOffDays[0],
      NFO: a,
      purchaseDate: null
    });
  }, [
    t
  ]), I = S == null ? void 0 : S.groupInvestment, K = Ke((a, d) => {
    const x = [];
    return a.data.forEach((k) => {
      k.amc === d && x.push(k);
    }), x;
  }, []), O = Ke((a) => {
    const d = [];
    return a.data.forEach((x) => {
      x.number === ce && d.push(x);
    }), d;
  }, []);
  Ne(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []), Ne(() => {
    var a;
    if (!o && te) {
      const d = te.filter((x) => {
        const k = x.scheme === (t == null ? void 0 : t.isin) && !x.plan && (x.state === "pending" || x.state === "failed" && x.failure_code === "payment_failure");
        return Z ? k && x.folio_number === Z : k;
      });
      i(!!d.length);
    }
    (a = t == null ? void 0 : t.delivery_mode) != null && a.includes(ie().toUpperCase()) || Q("scheme_not_allowed");
  }, [
    Z
  ]), Ne(() => {
    (async () => {
      D(!0);
      try {
        const a = A.fpClient.mf_folios().fetchAll({
          mf_investment_account: L
        }), d = A.fpClient.master_data().fetchAmcs();
        let x;
        o || (x = A.fpClient.mf_purchases().fetchAll({
          mf_investment_account: L,
          states: "pending,failed"
        }));
        const k = await Promise.all([
          a,
          d,
          x
        ]), E = k[1].amcs.find((R) => R.amc_id === (t == null ? void 0 : t.amc_id));
        let T;
        if (v ? T = O(k[0]) : T = K(k[0], E.amc_code), !o) {
          const R = k[2].data.filter((be) => {
            const Be = be.scheme === (t == null ? void 0 : t.isin) && !be.plan && (be.state === "pending" || be.state === "failed" && be.failure_code === "payment_failure");
            return T.length ? Be && be.folio_number === T[0].number : Be;
          }), Se = R.length ? R[0] : null;
          i(!!Se), J(k[2].data);
        }
        xe(T), se(E.amc_code), ee(E == null ? void 0 : E.amc_id), T.length ? (le(T[0]), N(T[0].number), ue(T[0])) : m && w(!0);
      } catch (a) {
        ve(a);
      } finally {
        D(!1);
      }
    })();
  }, [
    m,
    H
  ]);
  const ie = () => {
    var a;
    return !q(Y) && (Y != null && Y.dp_id) || !nt(_) && ((a = _[0].folio_defaults) != null && a.demat_account) ? "Demat" : "Physical";
  }, ue = (a) => {
    var d, x;
    if (m) {
      const k = (d = H == null ? void 0 : H.folios) == null ? void 0 : d.filter((T) => T.folio_number === (a == null ? void 0 : a.number)), E = k != null && k.length ? (x = k[0].schemes) == null ? void 0 : x.find((T) => T.isin === (t == null ? void 0 : t.isin)) : [];
      q(E) ? w(!0) : w(!1);
    }
  };
  async function re({ amount: a }) {
    var x, k, E;
    const d = {
      mf_investment_account: L,
      scheme: t == null ? void 0 : t.isin,
      amount: +a,
      folio_number: Z,
      order_gateway: "rta"
    };
    o && (h != null && h.scheduled_on) && (d.scheduled_on = Ue(h == null ? void 0 : h.scheduled_on, "yyyy-mm-dd")), v && U && (d.partner = U), o && !q((x = S == null ? void 0 : S.groupInvestment) == null ? void 0 : x.employerPartner) && ((k = S == null ? void 0 : S.groupInvestment) != null && k.employerPartner[y == null ? void 0 : y.id]) && (d.partner = (E = S == null ? void 0 : S.groupInvestment) == null ? void 0 : E.employerPartner[y == null ? void 0 : y.id]), A.fpClient.mf_purchases().create(d).then((T) => {
      M({
        mf_order: T,
        shouldOnboardMigratedUser: r
      });
    }).catch(function(T) {
      b(u._({
        id: "HbQ3GE"
      })), ve(T);
    });
  }
  function F() {
    return /* @__PURE__ */ e.jsx(Me, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${u._({
        id: "50eZGd"
      })}`,
      isOpen: G,
      toggleModal: z,
      contentHeightMax: !0,
      children: /* @__PURE__ */ e.jsx(Je, {
        investorProfile: C,
        investment_accounts: _
      })
    });
  }
  function g() {
    return /* @__PURE__ */ e.jsx(Me, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${u._({
        id: "845eUq"
      })}`,
      isOpen: $,
      toggleModal: B,
      contentHeightMax: !1,
      children: /* @__PURE__ */ e.jsx(Oe, {
        investorProfile: C,
        amc_code: p,
        amcId: X,
        handleFolioSelection: (a) => {
          ue(a), le(a), N(a.number), B(!$);
        },
        handleFolioCreation: () => {
          m && w(!0), N(null), le(null), B(!$);
        },
        scheme_details: void 0,
        isPartner: v,
        assistedWorkflowPAN: oe
      })
    });
  }
  function c() {
    return /* @__PURE__ */ e.jsx(Me, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${u._({
        id: "ohPawJ"
      })}`,
      isOpen: _e,
      toggleModal: n,
      contentHeightMax: !0,
      children: /* @__PURE__ */ e.jsx(Ut, {
        isGroupInvestment: o,
        investor: C,
        mfInvestmentAccount: _ == null ? void 0 : _[0],
        isPartner: v,
        searchParams: {
          type: "purchase",
          status: "pending,failed",
          folio_number: Z,
          isin: t == null ? void 0 : t.isin
        }
      })
    });
  }
  const l = () => f ? /* @__PURE__ */ e.jsx(he, {
    title: f,
    description: `${u._({
      id: "I4jL42"
    })}`,
    showDetails: !1,
    showAction: !0,
    actionText: `${u._({
      id: "Oem4yT"
    })}`,
    actionHandler: () => {
      b(null);
    }
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(Xe, {
        onSubmit: re,
        validate: (a) => {
          const d = {};
          return a.amount || (d.amount = u._({
            id: "c0D/2W"
          })), a.amount && a.amount < Number(t == null ? void 0 : t.min_initial_investment) && (d.amount = u._({
            id: "9LeqUy",
            values: {
              0: Le(Number(t == null ? void 0 : t.min_initial_investment))
            }
          })), a.amount && a.amount > Number(t == null ? void 0 : t.max_initial_investment) && (d.amount = u._({
            id: "DJzP4H",
            values: {
              0: Le(Number(t == null ? void 0 : t.max_initial_investment))
            }
          })), d;
        },
        children: (a) => /* @__PURE__ */ e.jsxs("form", {
          id: "orderForm",
          onSubmit: a.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(me, {
                  message: `${u._({
                    id: "4NN3aK"
                  })}`
                }),
                /* @__PURE__ */ e.jsx(ge, {
                  name: "amount",
                  children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Ae, {
                        id: d.input.name,
                        autoComplete: "off",
                        type: "text",
                        name: d.input.name,
                        value: We(d.input.value),
                        onChange: (x) => d.input.onChange(Qe(x.target.value)),
                        onInput: (x) => {
                          x.target.value = x.target.value.replace(Ze("0-9"), "");
                        },
                        placeholder: `${u._({
                          id: "cRSGbK"
                        })}`,
                        prefix: "₹",
                        status: d.meta.error && d.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      d.meta.error && d.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(Ge, {
                          type: "error",
                          variant: "field",
                          title: d.meta.error
                        })
                      }) : /* @__PURE__ */ e.jsx(Ge, {
                        type: "helper",
                        variant: "field",
                        title: u._({
                          id: "bYw+YE",
                          values: {
                            0: Le(Number(t == null ? void 0 : t.min_initial_investment))
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsx(W, {
              check: !!o,
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(me, {
                    message: `${u._({
                      id: "5vGD9a"
                    })}`
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "flex items-start",
                    children: [
                      /* @__PURE__ */ e.jsx(pe, {
                        variant: "iconBased",
                        name: "paymentMethodRadio",
                        group: [
                          {
                            icon: "Salary",
                            label: "Salary"
                          }
                        ],
                        disabled: []
                      }),
                      /* @__PURE__ */ e.jsx(gt, {
                        border: !0,
                        symbol: (I == null ? void 0 : I.groupPartnerLogo) || (S == null ? void 0 : S.fallbackLogo),
                        label: I == null ? void 0 : I.groupPartnerName,
                        value: `${u._({
                          id: "yFOTgq",
                          values: {
                            0: h != null && h.consideredSalaryDate ? ` in ${new Date(h == null ? void 0 : h.consideredSalaryDate).toLocaleString("default", {
                              month: "long"
                            })} ${new Date(h == null ? void 0 : h.consideredSalaryDate).getFullYear()}` : ""
                          }
                        })}`,
                        customClass: "ml-3 flex-1"
                      })
                    ]
                  })
                ]
              })
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "pt-8",
              children: /* @__PURE__ */ e.jsx(Ie, {
                type: "submit",
                fullwidth: !0,
                children: !o && !v ? u._({
                  id: "Ffad/b"
                }) : u._({
                  id: "H/E6fZ"
                })
              })
            })
          ]
        })
      }),
      g(),
      F(),
      c()
    ]
  }), s = () => {
    switch (ae) {
      case "initial":
        return l();
      case "scheme_not_allowed":
        return /* @__PURE__ */ e.jsx(he, {
          title: u._({
            id: "WddszM"
          }),
          description: u._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: u._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return l();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: P ? /* @__PURE__ */ e.jsx($e, {
      variant: "fullpage"
    }) : s()
  });
}
function qt({ frequencyOptions: t, submitStatus: L, handleSubmitStatus: C, scheme: M, placeRecurringOrder: o, isGroupInvestment: v, groupRecurringDateObject: _, isPartner: ne, investorProfile: H, amcCode: oe, handleShowOnboardingToMigratedUser: ce, handleSelectedFolioObj: f, handleFolioNumber: b, user: $, handleShouldOnboardMigratedUser: B, assistedWorkflowPAN: G, investmentAccounts: z }) {
  const { isMigratedUser: P } = He({
    user: $
  }), [D, Z] = j(t[0].value), N = /* @__PURE__ */ new Date(), Y = new Date((/* @__PURE__ */ new Date()).setDate(N.getDate())).getDate(), [le, p] = j(fe(Y, !1)), [se, V] = j(!1), [xe, A] = j(!1), [S, U] = j(!1), X = (i) => {
    V(i);
  }, ee = (i, te, J) => {
    i.stopPropagation(), p(fe(te, i.target.checked, J));
  }, de = Ee(() => {
    var i, te;
    return {
      frequency: D ? t == null ? void 0 : t.filter((J) => (J == null ? void 0 : J.value) === D)[0] : t[0],
      sip_start_date: Y,
      sip_instl_type: (i = je(D)) == null ? void 0 : i[0],
      sip_end_max_date: lt(D),
      group_sip_start_date: _ == null ? void 0 : _.installment_date,
      group_sip_end_date: _ != null && _.installment_date ? new Date(_ == null ? void 0 : _.installment_date.getFullYear(), (_ == null ? void 0 : _.installment_date.getMonth()) + st[D] * Number((te = M == null ? void 0 : M.sip_frequency_specific_data[D]) == null ? void 0 : te.min_installments), _ == null ? void 0 : _.installment_date.getDate()) : null
    };
  }, [
    D
  ]);
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(W, {
        check: !!L,
        children: /* @__PURE__ */ e.jsx(he, {
          title: L,
          description: `${u._({
            id: "mLEhv8"
          })}`,
          showDetails: !1,
          showAction: !0,
          actionText: `${u._({
            id: "RvsoP+"
          })}`,
          actionHandler: () => {
            C(""), Z(t[0].value);
          }
        })
      }),
      /* @__PURE__ */ e.jsx(W, {
        check: !L,
        children: /* @__PURE__ */ e.jsx(Xe, {
          keepDirtyOnReinitialize: !0,
          onSubmit: o,
          validate: (i) => Dt(i, M, D, v, _),
          initialValues: de,
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (i) => {
            var te, J, _e, n, ae, Q, m, r, w, y, h, I, K, O, ie, ue, re;
            return /* @__PURE__ */ e.jsxs("form", {
              id: "recurringOrderForm",
              onSubmit: i.handleSubmit,
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(me, {
                      message: `${u._({
                        id: "MznISI"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(ge, {
                      name: "amount",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: Fe(ke),
                      children: (F) => {
                        var g;
                        return /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(Ae, {
                              id: F.input.name,
                              autoComplete: "off",
                              type: "text",
                              name: F.input.name,
                              value: We(F.input.value),
                              onChange: (c) => F.input.onChange(Qe(c.target.value)),
                              onInput: (c) => {
                                c.target.value = c.target.value.replace(Ze("0-9"), "");
                              },
                              placeholder: `${u._({
                                id: "fK0Zpc"
                              })}`,
                              prefix: "₹",
                              status: dt(F) ? "error" : void 0,
                              autoFocus: !0
                            }),
                            De(F, `Minimum installment amount is ${`₹${Le(Number((g = M == null ? void 0 : M.sip_frequency_specific_data[D]) == null ? void 0 : g.min_installment_amount))}`} `)
                          ]
                        });
                      }
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(me, {
                      message: `${u._({
                        id: "n30BLK"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(ge, {
                      name: "frequency",
                      initialValue: de.frequency,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: Fe(ke),
                      children: (F) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(et, {
                            name: F.input.name,
                            value: F.input.value,
                            onChange: (g) => {
                              var c, l, s, a, d, x;
                              Z(g == null ? void 0 : g.value), i.form.change("sip_instl_type", je(g == null ? void 0 : g.value)[0]), (g == null ? void 0 : g.value) === "daily" && (Ce((c = i == null ? void 0 : i.values) == null ? void 0 : c.sip_start_date, "daily") ? p(fe((s = i == null ? void 0 : i.values) == null ? void 0 : s.sip_start_date, (a = i == null ? void 0 : i.values) == null ? void 0 : a.is_generate_first_installment, g == null ? void 0 : g.value)) : (i.form.change("is_generate_first_installment", !1), p(fe((l = i == null ? void 0 : i.values) == null ? void 0 : l.sip_start_date, !1, g == null ? void 0 : g.value)))), (g == null ? void 0 : g.value) !== "daily" && p(fe((d = i == null ? void 0 : i.values) == null ? void 0 : d.sip_start_date, (x = i == null ? void 0 : i.values) == null ? void 0 : x.is_generate_first_installment, g == null ? void 0 : g.value)), F.input.onChange(g);
                            },
                            options: t
                          }),
                          De(F, "Installments will be created on every business day", F.input.value.value === "daily")
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(me, {
                      message: `${u._({
                        id: "onTXId"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(ge, {
                      name: "sip_start_date",
                      validate: Fe(ke),
                      allowNull: !0,
                      children: (F) => /* @__PURE__ */ e.jsxs(W, {
                        check: !v,
                        children: [
                          /* @__PURE__ */ e.jsx(Ae, {
                            type: "text",
                            placeholder: "Select the day",
                            value: F.input.value !== null ? ct(F.input.value, D) : "",
                            onChange: () => null,
                            suffix: /* @__PURE__ */ e.jsx(Ie, {
                              type: "button",
                              variant: "text",
                              onClick: () => X(!0),
                              children: /* @__PURE__ */ e.jsx(tt, {
                                name: "Date"
                              })
                            })
                          }),
                          /* @__PURE__ */ e.jsx(at, {
                            name: F.input.name,
                            value: F.input.value,
                            isOpen: se,
                            toggleDaySelector: X,
                            onSelectDay: (g) => {
                              var c, l, s, a, d, x, k;
                              p(fe(g, (c = i == null ? void 0 : i.values) == null ? void 0 : c.is_generate_first_installment, (s = (l = i == null ? void 0 : i.values) == null ? void 0 : l.frequency) == null ? void 0 : s.value)), Ce(g, (d = (a = i == null ? void 0 : i.values) == null ? void 0 : a.frequency) == null ? void 0 : d.value) || (p(fe(g, !1, (k = (x = i == null ? void 0 : i.values) == null ? void 0 : x.frequency) == null ? void 0 : k.value)), i.form.change("is_generate_first_installment", !1)), F.input.onChange(g);
                            },
                            selectedDay: F.input.value,
                            title: "Select SIP day"
                          }),
                          De(F, le)
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(me, {
                      message: `${u._({
                        id: "kLHce5"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(ge, {
                      name: "sip_instl_type",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: Fe(ke),
                      children: (F) => {
                        var g, c;
                        return /* @__PURE__ */ e.jsx(pe, {
                          name: F.input.name,
                          value: F.input.value,
                          group: je(D),
                          size: "sm",
                          checked: ut(je(D), (c = (g = F == null ? void 0 : F.input) == null ? void 0 : g.value) == null ? void 0 : c.value),
                          onChange: (l, s) => {
                            F.input.onChange(s);
                          }
                        });
                      }
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((J = (te = i == null ? void 0 : i.values) == null ? void 0 : te.sip_instl_type) == null ? void 0 : J.value) === "installments",
                  children: ye("Number of installments", "installments", i, M, D)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((n = (_e = i == null ? void 0 : i.values) == null ? void 0 : _e.sip_instl_type) == null ? void 0 : n.value) === "number_of_days",
                  children: ye("Number of weeks", "number_of_days", i, M, D)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((Q = (ae = i == null ? void 0 : i.values) == null ? void 0 : ae.sip_instl_type) == null ? void 0 : Q.value) === "number_of_weeks",
                  children: ye("Number of weeks", "number_of_weeks", i, M, D)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((r = (m = i == null ? void 0 : i.values) == null ? void 0 : m.sip_instl_type) == null ? void 0 : r.value) === "number_of_installments",
                  children: ye("Number of instalments", "number_of_installments", i, M, D)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((y = (w = i == null ? void 0 : i.values) == null ? void 0 : w.sip_instl_type) == null ? void 0 : y.value) === "number_of_months",
                  children: ye("Number of months", "number_of_months", i, M, D)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((I = (h = i == null ? void 0 : i.values) == null ? void 0 : h.sip_instl_type) == null ? void 0 : I.value) === "number_of_quarters",
                  children: ye("Number of quarters", "number_of_quarters", i, M, D)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((O = (K = i == null ? void 0 : i.values) == null ? void 0 : K.sip_instl_type) == null ? void 0 : O.value) === "number_of_years",
                  children: ye("Number of years", "number_of_years", i, M, D)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: Ce((ie = i == null ? void 0 : i.values) == null ? void 0 : ie.sip_start_date, (re = (ue = i == null ? void 0 : i.values) == null ? void 0 : ue.frequency) == null ? void 0 : re.value),
                  children: /* @__PURE__ */ e.jsx(ge, {
                    name: "is_generate_first_installment",
                    validateFields: [],
                    type: "checkbox",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (F) => /* @__PURE__ */ e.jsx(it, {
                      checked: F.input.checked,
                      variant: "cardType",
                      description: u._({
                        id: "ia1tKm",
                        values: {
                          0: ne ? "Investor" : "You"
                        }
                      }),
                      id: "1",
                      onChange: (g) => {
                        var c, l;
                        ee(g, i.values.sip_start_date, (l = (c = i == null ? void 0 : i.values) == null ? void 0 : c.frequency) == null ? void 0 : l.value), F.input.onChange(g);
                      },
                      children: /* @__PURE__ */ e.jsxs("span", {
                        className: "dark:text-primary-DN110",
                        children: [
                          "Start first installment today",
                          " "
                        ]
                      })
                    })
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "pt-4",
                  children: /* @__PURE__ */ e.jsx(Ie, {
                    variant: "primary",
                    type: "submit",
                    fullwidth: !0,
                    children: "Confirm"
                  })
                })
              ]
            });
          }
        })
      }),
      /* @__PURE__ */ e.jsx(Me, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Select Folios",
        isOpen: xe,
        toggleModal: () => {
          A(!1);
        },
        contentHeightMax: !1,
        children: /* @__PURE__ */ e.jsx(Oe, {
          investorProfile: H,
          amc_code: oe,
          handleFolioSelection: (i) => {
            ce(i), f(i), b(i.number), A(!xe);
          },
          handleFolioCreation: () => {
            P && B(!0), b(""), f(null), A(!xe);
          },
          assistedWorkflowPAN: G,
          isPartner: ne
        })
      }),
      /* @__PURE__ */ e.jsx(Me, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Folio Details",
        isOpen: S,
        toggleModal: U,
        contentHeightMax: !0,
        children: /* @__PURE__ */ e.jsx(Je, {
          investorProfile: H,
          investment_accounts: z
        })
      })
    ]
  });
}
function zt({ scheme: t, investment_account_id: L, onSuccess: C, investorProfile: M, isGroupInvestment: o, isPartner: v, investment_accounts: _, user: ne, holdings: H, assistedWorkflowPAN: oe, assistedWorkflowFolioNumber: ce }) {
  var ae, Q;
  const { fpapi: f } = we(ze), { tenantConfig: b } = Te(), { loggedInPartner: $ } = we(Ye), { AppState: { employer: B } } = we(Ve), { isMigratedUser: G } = He({
    user: ne
  }), [z, P] = j(!1), [D, Z] = j(""), [N, Y] = j("initial"), [le, p] = j(""), [se, V] = j(null), [xe, A] = j(null), S = (ae = b == null ? void 0 : b.app_constants) == null ? void 0 : ae.plans_mode;
  let U = rt(t == null ? void 0 : t.sip_frequency_specific_data, S);
  const X = ((Q = b == null ? void 0 : b.groupInvestment) == null ? void 0 : Q.frequencyOptions) || [], ee = Ee(() => {
    var m, r, w, y, h, I;
    if (o) {
      const K = (r = (m = b == null ? void 0 : b.fundList) == null ? void 0 : m.find((O) => O.isin === (t == null ? void 0 : t.isin))) == null ? void 0 : r.nfo;
      return ((w = b == null ? void 0 : b.groupInvestment) == null ? void 0 : w.days) && ((y = b == null ? void 0 : b.groupInvestment) == null ? void 0 : y.cutOffDays) && ot({
        salaryDay: (h = b == null ? void 0 : b.groupInvestment) == null ? void 0 : h.days[0],
        salaryCutoffDay: (I = b == null ? void 0 : b.groupInvestment) == null ? void 0 : I.cutOffDays[0],
        NFO: K,
        purchaseDate: null
      });
    }
  }, [
    t
  ]);
  if (o) {
    const m = [];
    t != null && t.sip_frequency_specific_data && Object.keys(t == null ? void 0 : t.sip_frequency_specific_data).forEach((r, w) => {
      var y;
      X.includes(r) && m.push({
        id: w,
        label: ((y = _t(r)) == null ? void 0 : y.replace(/_/g, " ")) ?? "",
        value: r
      });
    }), U = m;
  }
  Ne(() => {
    var m;
    if (!((m = t == null ? void 0 : t.delivery_mode) != null && m.includes(yt(se, _).toUpperCase()))) {
      Y("scheme_not_allowed");
      return;
    }
    !q(se) && i(se), te();
  }, [
    G,
    L
  ]);
  const de = Ke((m) => {
    var w;
    const r = [];
    return (w = m == null ? void 0 : m.data) == null || w.forEach((y) => {
      y.number === ce && r.push(y);
    }), r;
  }, []), i = (m) => {
    var r, w;
    if (G) {
      const y = (r = H == null ? void 0 : H.folios) == null ? void 0 : r.filter((I) => I.folio_number === (m == null ? void 0 : m.number)), h = y != null && y.length ? (w = y[0].schemes) == null ? void 0 : w.find((I) => I.isin === (t == null ? void 0 : t.isin)) : [];
      q(h) ? P(!0) : P(!1);
    }
  }, te = async () => {
    try {
      const m = await f.fpClient.mf_folios().fetchAll({
        mf_investment_account: L
      }), w = (await f.fpClient.master_data().fetchAmcs()).amcs.find((h) => h.amc_id === (t == null ? void 0 : t.amc_id));
      let y;
      v ? y = de(m) : y = Ct(m.data, w.amc_code), A(w.amc_code), y.length ? (V(y[0]), p(y[0].number)) : G && P(!0);
    } catch (m) {
      window.debug.log(m), ve(m);
    }
  };
  async function J({ amount: m, frequency: r, installments: w, sip_start_date: y, sip_instl_type: h, number_of_days: I, number_of_weeks: K, number_of_installments: O, number_of_months: ie, number_of_quarters: ue, number_of_years: re, is_generate_first_installment: F }) {
    var g, c, l;
    Y("loading"), (h == null ? void 0 : h.value) === "perpetual" ? w = 1200 : I || K || O || ie || ue ? w = I ?? K ?? O ?? ie ?? ue : re && (w = ft(r.value, re));
    try {
      const s = {
        mf_investment_account: L.toString(),
        scheme: (t == null ? void 0 : t.isin) ?? "",
        frequency: r.value,
        folio_number: le,
        amount: +m,
        installment_day: r.value === "daily" ? void 0 : +y,
        number_of_installments: +w,
        systematic: !0,
        generate_first_installment_now: F,
        auto_generate_installments: !0,
        initiated_by: "investor",
        initiated_via: "mobile_app",
        user_ip: null,
        server_ip: null
      };
      if ((r == null ? void 0 : r.value) === "daily" && (y > (/* @__PURE__ */ new Date()).getDate() + 1 || y < (/* @__PURE__ */ new Date()).getDate())) {
        const d = new Date(ht(+y, !1)), x = d.setDate(d.getDate() - 1);
        s.activate_after = Ue(new Date(x), "yyyy-mm-dd");
      }
      v && $ && (s.partner = $), o && !q((g = b == null ? void 0 : b.groupInvestment) == null ? void 0 : g.employerPartner) && ((c = b == null ? void 0 : b.groupInvestment) != null && c.employerPartner[B == null ? void 0 : B.id]) && (s.partner = (l = b == null ? void 0 : b.groupInvestment) == null ? void 0 : l.employerPartner[B == null ? void 0 : B.id]), o && (ee != null && ee.activate_after) && (s.activate_after = Ue(ee == null ? void 0 : ee.activate_after, "yyy-mm-dd"));
      const a = await f.fpClient.mf_purchase_plans().create(s);
      F && !o ? (Y("initial"), C({
        mf_order: a,
        isGenerateFirstInstallment: F,
        shouldOnboardMigratedUser: z
      })) : (Y("initial"), C({
        mf_order: a,
        shouldOnboardMigratedUser: z
      }));
    } catch (s) {
      Y("initial"), Z(u._({
        id: "HbQ3GE"
      })), ve(s);
    }
  }
  const _e = () => /* @__PURE__ */ e.jsx(qt, {
    frequencyOptions: U,
    submitStatus: D,
    handleSubmitStatus: (m) => Z(m),
    scheme: t,
    placeRecurringOrder: J,
    isGroupInvestment: o,
    groupRecurringDateObject: ee,
    isPartner: v,
    investorProfile: M,
    amcCode: xe,
    handleShowOnboardingToMigratedUser: (m) => i(m),
    handleSelectedFolioObj: (m) => V(m),
    handleFolioNumber: (m) => p(m),
    user: ne,
    handleShouldOnboardMigratedUser: (m) => P(m),
    assistedWorkflowPAN: oe,
    investmentAccounts: _
  });
  return (() => {
    switch (N) {
      case "initial":
        return _e();
      case "loading":
        return /* @__PURE__ */ e.jsx($e, {
          variant: "fullpage"
        });
      case "scheme_not_allowed":
        return /* @__PURE__ */ e.jsx(he, {
          title: u._({
            id: "WddszM"
          }),
          description: u._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: u._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return _e();
    }
  })();
}
function Yt({ frequencyOptions: t, submitStatus: L, handleSubmitStatus: C, scheme: M, placeRecurringOrder: o, isGroupInvestment: v, groupRecurringDateObject: _, isPartner: ne, investorProfile: H, amcCode: oe, handleShowOnboardingToMigratedUser: ce, handleSelectedFolioObj: f, handleFolioNumber: b, user: $, handleShouldOnboardMigratedUser: B, assistedWorkflowPAN: G, investmentAccounts: z, selectedFolio: P }) {
  var _e;
  const { tenantConfig: D } = Te(), { isMigratedUser: Z } = He({
    user: $
  }), [N, Y] = j(t[0].value), le = /* @__PURE__ */ new Date(), p = new Date((/* @__PURE__ */ new Date()).setDate(le.getDate())).getDate(), [se, V] = j(fe(p, !1)), xe = ((_e = D == null ? void 0 : D.app_constants) == null ? void 0 : _e.non_systematic_min_installments) || 3, [A, S] = j(!1), [U, X] = j(!1), [ee, de] = j(!1), i = (n) => {
    S(n);
  }, te = (n, ae, Q) => {
    n.stopPropagation(), V(fe(ae, n.target.checked, Q));
  }, J = Ee(() => {
    var n, ae;
    return {
      frequency: N ? t == null ? void 0 : t.filter((Q) => (Q == null ? void 0 : Q.value) === N)[0] : t[0],
      sip_start_date: p,
      sip_instl_type: (n = je(N)) == null ? void 0 : n[0],
      sip_end_max_date: lt(N),
      group_sip_start_date: _ == null ? void 0 : _.installment_date,
      group_sip_end_date: _ != null && _.installment_date ? new Date(_ == null ? void 0 : _.installment_date.getFullYear(), (_ == null ? void 0 : _.installment_date.getMonth()) + st[N] * Number((ae = M == null ? void 0 : M.sip_frequency_specific_data[N]) == null ? void 0 : ae.min_installments), _ == null ? void 0 : _.installment_date.getDate()) : null
    };
  }, [
    N
  ]);
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(W, {
        check: !!L,
        children: /* @__PURE__ */ e.jsx(he, {
          title: L,
          description: `${u._({
            id: "mLEhv8"
          })}`,
          showDetails: !1,
          showAction: !0,
          actionText: `${u._({
            id: "RvsoP+"
          })}`,
          actionHandler: () => {
            C(""), Y(t[0].value);
          }
        })
      }),
      /* @__PURE__ */ e.jsx(W, {
        check: !L,
        children: /* @__PURE__ */ e.jsx(Xe, {
          keepDirtyOnReinitialize: !0,
          onSubmit: o,
          validate: (n) => It(n, M, N, v, P, xe, _),
          initialValues: J,
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (n) => {
            var ae, Q, m, r, w, y, h, I, K, O, ie, ue, re, F, g, c, l;
            return /* @__PURE__ */ e.jsxs("form", {
              id: "recurringOrderForm",
              onSubmit: n.handleSubmit,
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(me, {
                      message: `${u._({
                        id: "MznISI"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(ge, {
                      name: "amount",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: Fe(ke),
                      children: (s) => {
                        var a;
                        return /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(Ae, {
                              id: s.input.name,
                              autoComplete: "off",
                              type: "text",
                              name: s.input.name,
                              value: We(s.input.value),
                              onChange: (d) => s.input.onChange(Qe(d.target.value)),
                              onInput: (d) => {
                                d.target.value = d.target.value.replace(Ze("0-9"), "");
                              },
                              placeholder: `${u._({
                                id: "fK0Zpc"
                              })}`,
                              prefix: "₹",
                              status: dt(s) ? "error" : void 0,
                              autoFocus: !0
                            }),
                            De(s, `Minimum installment amount is ${`₹${Le(Number((a = M == null ? void 0 : M.sip_frequency_specific_data[N]) == null ? void 0 : a.min_installment_amount))}`} `)
                          ]
                        });
                      }
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(me, {
                      message: `${u._({
                        id: "n30BLK"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(ge, {
                      name: "frequency",
                      initialValue: J.frequency,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: Fe(ke),
                      children: (s) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(et, {
                            name: s.input.name,
                            value: s.input.value,
                            onChange: (a) => {
                              var d, x, k, E, T, R;
                              Y(a == null ? void 0 : a.value), n.form.change("sip_instl_type", je(a == null ? void 0 : a.value)[0]), (a == null ? void 0 : a.value) === "daily" && (Ce((d = n == null ? void 0 : n.values) == null ? void 0 : d.sip_start_date, "daily") ? V(fe((k = n == null ? void 0 : n.values) == null ? void 0 : k.sip_start_date, (E = n == null ? void 0 : n.values) == null ? void 0 : E.is_generate_first_installment, a == null ? void 0 : a.value)) : (n.form.change("is_generate_first_installment", !1), V(fe((x = n == null ? void 0 : n.values) == null ? void 0 : x.sip_start_date, !1, a == null ? void 0 : a.value)))), (a == null ? void 0 : a.value) !== "daily" && V(fe((T = n == null ? void 0 : n.values) == null ? void 0 : T.sip_start_date, (R = n == null ? void 0 : n.values) == null ? void 0 : R.is_generate_first_installment, a == null ? void 0 : a.value)), s.input.onChange(a);
                            },
                            options: t
                          }),
                          De(s, "Installments will be created on every business day", s.input.value.value === "daily")
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(me, {
                      message: `${u._({
                        id: "onTXId"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(ge, {
                      name: "sip_start_date",
                      validate: Fe(ke),
                      allowNull: !0,
                      children: (s) => /* @__PURE__ */ e.jsxs(W, {
                        check: !v,
                        children: [
                          /* @__PURE__ */ e.jsx(Ae, {
                            type: "text",
                            placeholder: "Select the day",
                            value: s.input.value !== null ? ct(s.input.value, N) : "",
                            onChange: () => null,
                            suffix: /* @__PURE__ */ e.jsx(Ie, {
                              type: "button",
                              variant: "text",
                              onClick: () => i(!0),
                              children: /* @__PURE__ */ e.jsx(tt, {
                                name: "Date"
                              })
                            })
                          }),
                          /* @__PURE__ */ e.jsx(at, {
                            name: s.input.name,
                            value: s.input.value,
                            isOpen: A,
                            toggleDaySelector: i,
                            onSelectDay: (a) => {
                              var d, x, k, E, T, R, Se;
                              V(fe(a, (d = n == null ? void 0 : n.values) == null ? void 0 : d.is_generate_first_installment, (k = (x = n == null ? void 0 : n.values) == null ? void 0 : x.frequency) == null ? void 0 : k.value)), Ce(a, (T = (E = n == null ? void 0 : n.values) == null ? void 0 : E.frequency) == null ? void 0 : T.value) || (V(fe(a, !1, (Se = (R = n == null ? void 0 : n.values) == null ? void 0 : R.frequency) == null ? void 0 : Se.value)), n.form.change("is_generate_first_installment", !1)), s.input.onChange(a);
                            },
                            selectedDay: s.input.value,
                            title: "Select SIP day"
                          }),
                          De(s, se)
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(me, {
                      message: `${u._({
                        id: "kLHce5"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(ge, {
                      name: "sip_instl_type",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: Fe(ke),
                      children: (s) => {
                        var a, d;
                        return /* @__PURE__ */ e.jsx(pe, {
                          name: s.input.name,
                          value: s.input.value,
                          group: je(N),
                          size: "sm",
                          checked: ut(je(N), (d = (a = s == null ? void 0 : s.input) == null ? void 0 : a.value) == null ? void 0 : d.value),
                          onChange: (x, k) => {
                            s.input.onChange(k);
                          }
                        });
                      }
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((Q = (ae = n == null ? void 0 : n.values) == null ? void 0 : ae.sip_instl_type) == null ? void 0 : Q.value) === "installments",
                  children: ye("Number of installments", "installments", n, M, N)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((r = (m = n == null ? void 0 : n.values) == null ? void 0 : m.sip_instl_type) == null ? void 0 : r.value) === "number_of_days",
                  children: ye("Number of weeks", "number_of_days", n, M, N)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((y = (w = n == null ? void 0 : n.values) == null ? void 0 : w.sip_instl_type) == null ? void 0 : y.value) === "number_of_weeks",
                  children: ye("Number of weeks", "number_of_weeks", n, M, N)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((I = (h = n == null ? void 0 : n.values) == null ? void 0 : h.sip_instl_type) == null ? void 0 : I.value) === "number_of_installments",
                  children: ye("Number of instalments", "number_of_installments", n, M, N)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((O = (K = n == null ? void 0 : n.values) == null ? void 0 : K.sip_instl_type) == null ? void 0 : O.value) === "number_of_months",
                  children: ye("Number of months", "number_of_months", n, M, N)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((ue = (ie = n == null ? void 0 : n.values) == null ? void 0 : ie.sip_instl_type) == null ? void 0 : ue.value) === "number_of_quarters",
                  children: ye("Number of quarters", "number_of_quarters", n, M, N)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: ((F = (re = n == null ? void 0 : n.values) == null ? void 0 : re.sip_instl_type) == null ? void 0 : F.value) === "number_of_years",
                  children: ye("Number of years", "number_of_years", n, M, N)
                }),
                /* @__PURE__ */ e.jsx(W, {
                  check: Ce((g = n == null ? void 0 : n.values) == null ? void 0 : g.sip_start_date, (l = (c = n == null ? void 0 : n.values) == null ? void 0 : c.frequency) == null ? void 0 : l.value),
                  children: /* @__PURE__ */ e.jsx(ge, {
                    name: "is_generate_first_installment",
                    validateFields: [],
                    type: "checkbox",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (s) => /* @__PURE__ */ e.jsx(it, {
                      checked: s.input.checked,
                      variant: "cardType",
                      description: u._({
                        id: "ia1tKm",
                        values: {
                          0: ne ? "Investor" : "You"
                        }
                      }),
                      id: "1",
                      onChange: (a) => {
                        var d, x;
                        te(a, n.values.sip_start_date, (x = (d = n == null ? void 0 : n.values) == null ? void 0 : d.frequency) == null ? void 0 : x.value), s.input.onChange(a);
                      },
                      children: /* @__PURE__ */ e.jsxs("span", {
                        className: "dark:text-primary-DN110",
                        children: [
                          "Start first installment today",
                          " "
                        ]
                      })
                    })
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "pt-4",
                  children: /* @__PURE__ */ e.jsx(Ie, {
                    variant: "primary",
                    type: "submit",
                    fullwidth: !0,
                    children: "Confirm"
                  })
                })
              ]
            });
          }
        })
      }),
      /* @__PURE__ */ e.jsx(Me, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Select Folios",
        isOpen: U,
        toggleModal: () => {
          X(!1);
        },
        contentHeightMax: !1,
        children: /* @__PURE__ */ e.jsx(Oe, {
          investorProfile: H,
          amc_code: oe,
          handleFolioSelection: (n) => {
            ce(n), f(n), b(n.number), X(!U);
          },
          handleFolioCreation: () => {
            Z && B(!0), b(""), f(null), X(!U);
          },
          assistedWorkflowPAN: G,
          isPartner: ne
        })
      }),
      /* @__PURE__ */ e.jsx(Me, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Folio Details",
        isOpen: ee,
        toggleModal: de,
        contentHeightMax: !0,
        children: /* @__PURE__ */ e.jsx(Je, {
          investorProfile: H,
          investment_accounts: z
        })
      })
    ]
  });
}
function Vt({ scheme: t, investment_account_id: L, onSuccess: C, investorProfile: M, isGroupInvestment: o, isPartner: v, investment_accounts: _, user: ne, holdings: H, assistedWorkflowPAN: oe }) {
  var Q, m;
  const { fpapi: ce } = we(ze), { tenantConfig: f } = Te(), { loggedInPartner: b } = we(Ye), { AppState: { employer: $ } } = we(Ve), { isMigratedUser: B } = He({
    user: ne
  }), [G, z] = j(!1), [P, D] = j(""), [Z, N] = j("initial"), [Y, le] = j(""), [p, se] = j(null), [V, xe] = j(null), [A, S] = j([]), U = (Q = f == null ? void 0 : f.app_constants) == null ? void 0 : Q.plans_mode;
  let X = rt(t == null ? void 0 : t.sip_frequency_specific_data, U);
  const ee = ((m = f == null ? void 0 : f.groupInvestment) == null ? void 0 : m.frequencyOptions) || [], de = Ee(() => {
    var r, w, y, h, I, K;
    if (o) {
      const O = (w = (r = f == null ? void 0 : f.fundList) == null ? void 0 : r.find((ie) => ie.isin === (t == null ? void 0 : t.isin))) == null ? void 0 : w.nfo;
      return ((y = f == null ? void 0 : f.groupInvestment) == null ? void 0 : y.days) && ((h = f == null ? void 0 : f.groupInvestment) == null ? void 0 : h.cutOffDays) && ot({
        salaryDay: (I = f == null ? void 0 : f.groupInvestment) == null ? void 0 : I.days[0],
        salaryCutoffDay: (K = f == null ? void 0 : f.groupInvestment) == null ? void 0 : K.cutOffDays[0],
        NFO: O,
        purchaseDate: null
      });
    }
  }, [
    t
  ]);
  if (o) {
    const r = [];
    U === "systematic" ? (t != null && t.sip_frequency_specific_data && Object.keys(t == null ? void 0 : t.sip_frequency_specific_data).forEach((w, y) => {
      var h;
      ee.includes(w) && r.push({
        id: y,
        label: ((h = _t(w)) == null ? void 0 : h.replace(/_/g, " ")) ?? "",
        value: w
      });
    }), X = r) : U === "nonSystematic" && (Object.values(X).forEach((w) => {
      ee.includes(w == null ? void 0 : w.value) && r.push(w);
    }), X = r);
  }
  Ne(() => {
    var r;
    if (!((r = t == null ? void 0 : t.delivery_mode) != null && r.includes(yt(p, _).toUpperCase()))) {
      N("scheme_not_allowed");
      return;
    }
    !q(p) && i(p), J();
  }, [
    B
  ]);
  const i = (r) => {
    var w, y;
    if (B) {
      const h = (w = H == null ? void 0 : H.folios) == null ? void 0 : w.filter((K) => K.folio_number === (r == null ? void 0 : r.number)), I = h != null && h.length ? (y = h[0].schemes) == null ? void 0 : y.find((K) => K.isin === (t == null ? void 0 : t.isin)) : [];
      q(I) ? z(!0) : z(!1);
    }
  }, te = Ke((r, w) => {
    const y = [];
    return r.forEach((h) => {
      h.amc === w && y.push(h);
    }), y;
  }, []), J = async () => {
    try {
      const r = await ce.fpClient.mf_folios().fetchAll({
        mf_investment_account: L
      }), y = (await ce.fpClient.master_data().fetchAmcs()).amcs.find((I) => I.amc_id === (t == null ? void 0 : t.amc_id)), h = te(r.data, y.amc_code);
      S(h), xe(y.amc_code), h.length ? (se(h[0]), le(h[0].number)) : B && z(!0);
    } catch (r) {
      window.debug.log(r), ve(r);
    }
  };
  async function _e({ amount: r, frequency: w, installments: y, sip_start_date: h, sip_instl_type: I, number_of_days: K, number_of_weeks: O, number_of_installments: ie, number_of_months: ue, number_of_quarters: re, number_of_years: F, is_generate_first_installment: g }) {
    var c, l, s;
    N("loading"), (I == null ? void 0 : I.value) === "perpetual" ? y = 1200 : K || O || ie || ue || re ? y = K ?? O ?? ie ?? ue ?? re : F && (y = ft(w.value, F));
    try {
      const a = {
        mf_investment_account: L.toString(),
        scheme: (t == null ? void 0 : t.isin) ?? "",
        frequency: w.value,
        folio_number: Y,
        amount: +r,
        installment_day: w.value === "daily" ? void 0 : +h,
        number_of_installments: +y,
        systematic: U === "systematic",
        generate_first_installment_now: g,
        auto_generate_installments: !0,
        initiated_by: "investor",
        initiated_via: "mobile_app",
        user_ip: null,
        server_ip: null
      };
      if ((w == null ? void 0 : w.value) === "daily" && (h > (/* @__PURE__ */ new Date()).getDate() + 1 || h < (/* @__PURE__ */ new Date()).getDate())) {
        const x = new Date(ht(+h, !1)), k = x.setDate(x.getDate() - 1);
        a.activate_after = Ue(new Date(k), "yyyy-mm-dd");
      }
      v && b && (a.partner = b), o && !q((c = f == null ? void 0 : f.groupInvestment) == null ? void 0 : c.employerPartner) && ((l = f == null ? void 0 : f.groupInvestment) != null && l.employerPartner[$ == null ? void 0 : $.id]) && (a.partner = (s = f == null ? void 0 : f.groupInvestment) == null ? void 0 : s.employerPartner[$ == null ? void 0 : $.id]), o && (de != null && de.activate_after) && (a.activate_after = Ue(de == null ? void 0 : de.activate_after, "yyy-mm-dd"));
      const d = await ce.fpClient.mf_purchase_plans().create(a);
      g && !o ? (N("initial"), C({
        mf_order: d,
        isGenerateFirstInstallment: g,
        shouldOnboardMigratedUser: G
      })) : (N("initial"), C({
        mf_order: d,
        shouldOnboardMigratedUser: G
      }));
    } catch (a) {
      N("initial"), D(u._({
        id: "HbQ3GE"
      })), ve(a);
    }
  }
  const n = () => /* @__PURE__ */ e.jsx(Yt, {
    frequencyOptions: X,
    submitStatus: P,
    handleSubmitStatus: (r) => D(r),
    scheme: t,
    placeRecurringOrder: _e,
    isGroupInvestment: o,
    groupRecurringDateObject: de,
    isPartner: v,
    investorProfile: M,
    amcCode: V,
    handleShowOnboardingToMigratedUser: (r) => i(r),
    handleSelectedFolioObj: (r) => se(r),
    handleFolioNumber: (r) => le(r),
    user: ne,
    handleShouldOnboardMigratedUser: (r) => z(r),
    assistedWorkflowPAN: oe,
    investmentAccounts: _,
    selectedFolio: Y
  });
  return (() => {
    switch (Z) {
      case "initial":
        return n();
      case "loading":
        return /* @__PURE__ */ e.jsx($e, {
          variant: "fullpage"
        });
      case "scheme_not_allowed":
        return /* @__PURE__ */ e.jsx(he, {
          title: u._({
            id: "WddszM"
          }),
          description: u._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: u._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return n();
    }
  })();
}
const pt = (t, L) => Number(qe.get()) ? Number(qe.get()) : t && L ? null : t ? 1 : 0;
function Wt(t) {
  var H, oe, ce, f, b, $, B;
  const { tenantConfig: L } = Te(), { scheme: C } = t, M = ((ce = (oe = (H = L == null ? void 0 : L.app_constants) == null ? void 0 : H.disabled_orders_mode) == null ? void 0 : oe.purchase) == null ? void 0 : ce.one_time) || !(C != null && C.purchase_allowed), o = (($ = (b = (f = L == null ? void 0 : L.app_constants) == null ? void 0 : f.disabled_orders_mode) == null ? void 0 : b.purchase) == null ? void 0 : $.recurring) || !(C != null && C.sip_allowed), v = ((B = L == null ? void 0 : L.app_constants) == null ? void 0 : B.plans_mode) === "systematic", _ = pt(M, o);
  return (() => {
    const G = () => /* @__PURE__ */ e.jsx(Kt, {
      ...t
    }), z = () => v ? /* @__PURE__ */ e.jsx(zt, {
      ...t
    }) : /* @__PURE__ */ e.jsx(Vt, {
      ...t
    }), P = () => /* @__PURE__ */ e.jsx(he, {
      title: u._({
        id: "JHDCYv"
      }),
      description: u._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    }), D = () => /* @__PURE__ */ e.jsx(he, {
      title: u._({
        id: "FL10+5"
      }),
      description: u._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    }), Z = () => {
      var N, Y, le, p, se, V;
      return M && o ? [] : M ? [
        {
          title: u._({
            id: "DUs4hi"
          }),
          content: (N = t == null ? void 0 : t.scheme) != null && N.purchase_allowed ? G() : P(),
          disabled: !0,
          disabledMessage: "One time is not allowed in this scheme"
        },
        {
          title: u._({
            id: "1VbNN2"
          }),
          content: (Y = t == null ? void 0 : t.scheme) != null && Y.sip_allowed ? z() : D()
        }
      ] : o ? [
        {
          title: u._({
            id: "DUs4hi"
          }),
          content: (le = t == null ? void 0 : t.scheme) != null && le.purchase_allowed ? G() : P()
        },
        {
          title: u._({
            id: "1VbNN2"
          }),
          content: (p = t == null ? void 0 : t.scheme) != null && p.sip_allowed ? z() : D(),
          disabled: !0,
          disabledMessage: "SIP is not allowed in this scheme"
        }
      ] : [
        {
          title: u._({
            id: "DUs4hi"
          }),
          content: (se = t == null ? void 0 : t.scheme) != null && se.purchase_allowed ? G() : P()
        },
        {
          title: u._({
            id: "1VbNN2"
          }),
          content: (V = t == null ? void 0 : t.scheme) != null && V.sip_allowed ? z() : D()
        }
      ];
    };
    return /* @__PURE__ */ e.jsx(mt, {
      selected: _,
      onChange: (N) => {
        N === 0 ? qe.set("0") : qe.set("1");
      },
      group: Z()
    });
  })();
}
function ma({ scheme: t, switch_out: L, isGroupInvestment: C }) {
  var re, F, g;
  const [M, o] = j("order"), [v, _] = j(null), [ne, H] = j(null), [oe, ce] = j(null), [f, b] = j(null), [$, B] = j(null), [G, z] = j(!1), [P, D] = j("purchase"), [Z, N] = j(null), [Y, le] = j(!1), [p, se] = j(null), { AppState: { employer: V, tenant: xe } } = we(Ve), { fpapi: A } = we(ze), { loggedInUser: S } = we(Ye), { tenantConfig: U } = Te(), X = (re = U == null ? void 0 : U.groupInvestment) == null ? void 0 : re.allowedEmailDomains;
  (F = U == null ? void 0 : U.app_constants) != null && F.kyc_refetch_interval_in_months;
  const ee = t != null && t.purchase_allowed ? ((g = U == null ? void 0 : U.app_constants) == null ? void 0 : g.plans_mode) || "" : "systematic";
  Ne(() => {
    _e();
  }, [
    U,
    S,
    t
  ]), vt(() => {
    const c = sessionStorage.getItem("product"), l = t == null ? void 0 : t.isin;
    l && (sessionStorage.setItem("product", l), c !== l && r());
  }, []);
  const de = (c) => q(c) ? {
    success: !1,
    stageToRender: "scheme_not_found"
  } : !(c != null && c.active) || !(c != null && c.purchase_allowed) && !(c != null && c.sip_allowed) ? {
    success: !1,
    stageToRender: "purchase_not_allowed"
  } : {
    success: !0
  }, i = ({ tenantConfig: c, user: l, investorProfile: s }) => {
    var d;
    return (X == null ? void 0 : X[(d = l == null ? void 0 : l.email_address) == null ? void 0 : d.split("@")[1]]) ? {
      success: !0,
      stageToRender: ""
    } : {
      success: !1,
      stageToRender: "invalid_email_domain"
    };
  }, te = ({ kycCheckObj: c, user: l, mfia: s }) => {
    var k, E;
    const a = ((k = l == null ? void 0 : l.annexure) == null ? void 0 : k.isonboarded) === "true";
    return !a || !(s != null && s.primary_investor) ? {
      success: !1,
      stageToRender: "onboard"
    } : a && !c.status ? {
      success: !1,
      stageToRender: "kyc_non_compliant"
    } : (E = s == null ? void 0 : s.folio_defaults) != null && E.payout_bank_account ? {
      success: !0,
      stageToRender: ""
    } : {
      success: !1,
      stageToRender: "bank_verification"
    };
  }, J = async (c) => {
    try {
      const l = await A.fpClient.investor_reports().fetchHoldings({
        investment_account_id: c == null ? void 0 : c.old_id
      });
      se(l);
    } catch (l) {
      window.debug.log(l, "Error");
    }
  }, _e = async () => {
    var c, l, s, a;
    try {
      if (z(!0), !S) {
        o("auth");
        return;
      }
      const d = de(t);
      if (!d.success) {
        o(d.stageToRender);
        return;
      }
      const x = [
        A.fetchUser(S.profile.preferred_username, xe),
        A.getInvestorProfile((c = S == null ? void 0 : S.profile) == null ? void 0 : c.fp_identifier),
        A.fpClient.mf_investment_accounts().fetchAll({
          investor: (s = (l = S == null ? void 0 : S.profile) == null ? void 0 : l.preferred_username) == null ? void 0 : s.toUpperCase()
        })
      ], [k, E, T] = await Promise.all(x);
      if (!E) {
        window.debug.error("Investor profile is not present"), o("error_page");
        return;
      }
      const R = k == null ? void 0 : k[0], Se = (a = T == null ? void 0 : T.data) == null ? void 0 : a[0];
      if (C) {
        const Re = i({
          tenantConfig: U,
          user: R,
          investorProfile: E
        });
        if (!Re.success) {
          o(Re.stageToRender);
          return;
        }
      }
      const [be, { isMigratedUser: Be, migratedMfiaList: xt, migratedFolioList: wt }] = await Promise.all([
        ie(R),
        jt(R, A)
      ]);
      Be ? (J(Se), await Ft(A, R, xt, wt), be.status ? o("order") : o("onboard")) : o("order"), H(R), b(E), B(T == null ? void 0 : T.data), N(be);
    } catch (d) {
      o("error_page"), window.debug.error(d), ve(d);
    } finally {
      z(!1);
    }
  };
  async function n(c, l) {
    if (q(l)) {
      o("finished"), _(c);
      return;
    }
    z(!0);
    try {
      const s = await A.fpClient.mf_purchases().fetch(l == null ? void 0 : l.id);
      _(s);
    } catch (s) {
      window.debug.log(s), ve(s);
    }
    o("payment"), z(!1);
  }
  const ae = (c) => {
    q(c) || (C ? o("finished") : (v == null ? void 0 : v.object) === "mf_purchase" && (_(v), o("payment")));
  }, Q = async (c, l) => {
    if (c) {
      if (C)
        o("finished");
      else if (v.object === "mf_purchase_plan") {
        if (!q(l) && ee === "nonSystematic") {
          const { emailData: s, phoneData: a } = await A.fetchEmailAndPhone(f);
          await A.fpClient.mf_purchases().update({
            id: l == null ? void 0 : l.id,
            consent: {
              email: s.email,
              isd_code: a.isd,
              mobile: a.number
            }
          });
        }
        n(v, l);
      }
    }
  };
  function m(c) {
    const { mf_order: l, isGenerateFirstInstallment: s, shouldOnboardMigratedUser: a } = c;
    if (a) {
      _(l), o("onboard_migrated_user");
      return;
    }
    if ((l == null ? void 0 : l.object) === "mf_purchase") {
      _(l), o("purchase_confirmation");
      return;
    }
    if ((l == null ? void 0 : l.object) === "mf_purchase_plan") {
      _(l), le(s), o("purchase_plan_confirmation");
      return;
    }
    (l == null ? void 0 : l.object) === "mf_switch" && (_(l), o("switch_confirmation")), (l == null ? void 0 : l.object) === "mf_switch_plan" && (_(l), o("switch_plan_confirmation"));
  }
  function r() {
    v && (v.object === "mf_purchase" || v.object === "mf_purchase_plan" ? D("purchase") : D("switch")), _({}), o("order");
  }
  function w() {
    C ? o("order") : v.object === "mf_purchase" && A.fpClient.mf_purchases().retry(v == null ? void 0 : v.id).then(() => {
      window.debug.log("Order Retry successful"), o("payment");
    }).catch((c) => {
      r(), ve(c);
    });
  }
  function y({ success: c, isWindowBlocked: l, paymentId: s }) {
    (async () => {
      if (s)
        try {
          const a = await A.fpClient.payments().fetch(Number(s));
          ce(a);
          let d;
          v.plan ? d = await A.fpClient.mf_purchase_plans().fetch(v == null ? void 0 : v.plan) : d = await A.fpClient.mf_purchases().fetch(v.id), _(d);
        } catch (a) {
          window.debug.log(a), ve(a);
        }
      o(c && !l || !c && !l ? "finished" : "finished_error");
    })();
  }
  const h = async (c, l, s) => {
    try {
      z(!0);
      const a = await ie(s), d = te({
        kycCheckObj: a,
        user: s,
        mfia: l == null ? void 0 : l[0]
      });
      d.success ? o("order") : o(d.stageToRender), H(s), N(a), b(c), B(l);
    } catch (a) {
      window.debug.error(a), ve(a), o("error_page");
    } finally {
      z(!1);
    }
  }, I = (c) => {
    _(c), o("finished");
  }, K = () => {
    var c;
    (c = A == null ? void 0 : A.userAuthService) == null || c.initiateLogout(window.location.href).then((l) => {
      window.location.href = l.userState;
    });
  }, O = async (c) => {
    var l;
    if ($ != null && $.length)
      try {
        const s = (l = $ == null ? void 0 : $[0]) == null ? void 0 : l.id, a = await A.fpClient.mf_investment_accounts().update({
          id: s,
          folio_defaults: {
            payout_bank_account: c == null ? void 0 : c.id
          }
        }), d = $.map((x) => {
          if (x.id === a.id) {
            const k = x;
            return k.folio_defaults.payout_bank_account = a.folio_defaults.payout_bank_account, k;
          } else
            return x;
        });
        B(d), o("order");
      } catch (s) {
        window.debug.error(s);
      }
  }, ie = async (c) => {
    const a = `${(/* @__PURE__ */ new Date()).toISOString().split(".")[0]}+05:30`;
    return {
      id: "5620fd1f-eb14-442e-b0ee-da96a6c305c0",
      source_ref_id: null,
      pan: "BNBPP9384M",
      entity_details: {
        name: "Tony Soprano"
      },
      status: !0,
      constraints: null,
      sources: [
        {
          name: "kra-cams",
          fetched_at: a
        }
      ],
      created_at: a,
      updated_at: a,
      action: null,
      reason: null
    };
  };
  function ue() {
    switch (M) {
      default:
        return /* @__PURE__ */ e.jsx($e, {
          variant: "fullpage"
        });
      case "auth":
        return /* @__PURE__ */ e.jsx(Bt, {
          redirectURL: window.location.href,
          allowRegistration: !0,
          showTestPan: {
            onboarded: !0,
            kycCompliant: !0,
            kycNonCompliant: !0
          },
          isGroupInvestment: C
        });
      case "onboard":
        return /* @__PURE__ */ e.jsx(Ht, {
          user: ne,
          isGroupInvestment: C || !1,
          setUser: H,
          onBoardingComplete: h,
          isPartner: !1
        });
      case "bank_verification":
        return /* @__PURE__ */ e.jsx(Et, {
          investorProfile: f,
          handleBankSelection: O,
          customPageSubtitle: u._({
            id: "KM15wf"
          })
        });
      case "order":
        return nt($) ? /* @__PURE__ */ e.jsx(e.Fragment, {}) : /* @__PURE__ */ e.jsx(Wt, {
          scheme: t,
          switch_out: L,
          investment_account_id: $[0].id,
          investment_accounts: $,
          defaultInvestmentType: P,
          investorProfile: f,
          isGroupInvestment: C,
          onSuccess: m,
          user: ne,
          holdings: p
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(Qt, {
          onContinue: () => {
            window.location.href = `${window.location.origin + Pe}/onboarding?${C ? `isGroupInvestment=${C}` : ""}${C ? `&employer=${V == null ? void 0 : V.id}` : ""}&redirectURL=${encodeURIComponent(`${window.location.origin + Pe}/${C ? "group-checkout" : "checkout"}/?isin=${t == null ? void 0 : t.isin}${C ? `&employer=${V == null ? void 0 : V.id}` : ""}`)}`;
          }
        });
      case "purchase_confirmation":
        return !q(f) && !q(v) ? /* @__PURE__ */ e.jsx(At, {
          investorProfile: f,
          id: v.id,
          showSchemeDetails: !1,
          isGroupInvestment: C,
          onSuccess: ae,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "purchase_plan_confirmation":
        return !q(f) && !q(v) ? /* @__PURE__ */ e.jsx($t, {
          investorProfile: f,
          id: v.id,
          showSchemeDetails: !1,
          isGroupInvestment: C,
          onSuccess: (c, l) => Q(c, l),
          isFirstInstallmentPayment: Y,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "payment":
        return !q(f) && !q(v) ? /* @__PURE__ */ e.jsx(Lt, {
          investorProfile: f,
          mf_purchase: v,
          onCompletion: y
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "finished":
        return !q(t) && !q(v) ? /* @__PURE__ */ e.jsx(Tt, {
          investorProfile: f,
          scheme: t,
          mf_order: v,
          payment: oe,
          isGroupInvestment: C,
          handleRetry: w,
          handleInvestAgain: r
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "finished_error":
        return /* @__PURE__ */ e.jsx(he, {
          title: u._({
            id: "sS+OUW"
          }),
          showAction: !0,
          actionText: u._({
            id: "RvsoP+"
          }),
          actionHandler: () => {
            o("payment");
          }
        });
      case "purchase_not_allowed":
        return /* @__PURE__ */ e.jsx(he, {
          title: u._({
            id: "YzYf5v"
          }),
          description: u._({
            id: "Y0g8oc"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: u._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      case "scheme_not_found":
        return /* @__PURE__ */ e.jsx(he, {
          title: u._({
            id: "+PjKpk"
          }),
          description: u._({
            id: "Y0g8oc"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: u._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      case "switch_confirmation":
        return !q(t) && !q(v) ? /* @__PURE__ */ e.jsx(Nt, {
          id: v == null ? void 0 : v.id,
          investorProfile: f,
          showSchemeDetails: !1,
          onSuccess: I,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "switch_plan_confirmation":
        return !q(t) && !q(v) ? /* @__PURE__ */ e.jsx(Mt, {
          id: v.id,
          investorProfile: f,
          onSuccess: I,
          showSchemeDetails: !1,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "invalid_email_domain":
        return /* @__PURE__ */ e.jsx(he, {
          title: u._({
            id: "ZXjii8"
          }),
          description: u._({
            id: "mpazQQ"
          }),
          showDetails: !1,
          actionText: u._({
            id: "IO46nP"
          }),
          showAction: !0,
          actionHandler: K
        });
      case "invalid_employer":
        return /* @__PURE__ */ e.jsx(he, {
          title: u._({
            id: "8gLo60"
          }),
          description: u._({
            id: "REewWf"
          }),
          showDetails: !1,
          actionText: u._({
            id: "IO46nP"
          }),
          showAction: !0,
          actionHandler: K
        });
      case "kyc_non_compliant":
        return Z ? /* @__PURE__ */ e.jsx(kt, {
          kycCheckObject: Z,
          customMessage: {
            unverified: u._({
              id: "V2UMyt"
            })
          }
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "error_page":
        return /* @__PURE__ */ e.jsx(he, {
          title: "Something went wrong",
          description: "",
          showDetails: !1,
          showAction: !1
        });
    }
  }
  return G ? /* @__PURE__ */ e.jsx($e, {
    variant: "fullpage"
  }) : ue();
}
const Qt = ({ onContinue: t }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(bt, {
      title: u._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(Ie, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => t(),
        children: "Continue"
      })
    })
  ]
});
export {
  ma as I,
  Wt as O
};
