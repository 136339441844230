import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as s } from "react";
import { i as n } from "./index-B3vUTrRT.js";
import { R as m } from "./index.esm-BV4iWo1a.js";
import { LocaleProviderContext as l } from "./LocaleProviderContext.js";
function f() {
  const { locale: r, updateLocale: o } = s(l), i = [
    {
      label: "English",
      value: "en"
    },
    {
      label: "हिन्दी",
      value: "hi"
    },
    {
      label: "தமிழ்",
      value: "tm"
    }
  ];
  return /* @__PURE__ */ e.jsx("div", {
    className: "mt-auto pt-8",
    children: /* @__PURE__ */ e.jsxs("div", {
      className: "mb-5 flex flex-col items-center",
      children: [
        /* @__PURE__ */ e.jsx("p", {
          className: "font-opensans text-size-14 text-primary-N60",
          children: n._({
            id: "A+i72/"
          })
        }),
        /* @__PURE__ */ e.jsx(m, {
          variant: "text",
          name: "radioname",
          group: i,
          disabled: [
            1,
            2,
            3,
            4,
            5
          ],
          onChange: (t, a) => {
            o(a), n.activate(a == null ? void 0 : a.value);
          },
          checked: i.findIndex((t) => t.value === (r == null ? void 0 : r.value))
        })
      ]
    })
  });
}
export {
  f as L
};
