import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { t as b } from "./utils-BTh-HRzb.js";
import { ErrorPage as o } from "./ErrorPage.js";
import { useState as n, useEffect as N } from "react";
import { a as d, P as u, D as v, e as p, T as j, M as y, B as x, I as S, q as L } from "./index.esm-BV4iWo1a.js";
import { u as A } from "./useConfiguration-B-lyaBB9.js";
function w({ onCompletion: s }) {
  const [t, l] = n(!1), [i, c] = n("initial"), a = "/images/capctha.jpeg", r = () => /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx("div", {
      className: "",
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "border-neutral-10 w-full max-w-md rounded-md border border-solid px-8 py-8 shadow-md",
        children: [
          /* @__PURE__ */ e.jsx(u, {
            title: "Signzy"
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(v, {
                type: "text",
                text: "You are about to link your DigiLocker account with Signzy application of Signzy Technologies Pvt.Ltd. You will be signed up for DigiLocker account if it does not exist."
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(p, {
                    message: "Aadhaar number"
                  }),
                  /* @__PURE__ */ e.jsx(j, {
                    type: "text",
                    placeholder: "Enter Aadhaar number"
                  })
                ]
              }),
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(p, {
                    message: "Please enter the following text in the box below."
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "flex justify-between",
                    children: [
                      /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 flex-1",
                        children: /* @__PURE__ */ e.jsx("p", {
                          className: "text-size-18 text-neutral-30 mr-2 block h-12 rounded-md px-4 py-2",
                          children: /* @__PURE__ */ e.jsx("img", {
                            src: a,
                            alt: "Captcha"
                          })
                        })
                      }),
                      /* @__PURE__ */ e.jsx("div", {
                        className: "flex-1",
                        children: /* @__PURE__ */ e.jsx(j, {
                          defaultValue: "U5K5GG",
                          readOnly: !0,
                          type: "text"
                        })
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "flex items-center",
                    children: [
                      /* @__PURE__ */ e.jsx(y, {
                        variant: "field",
                        type: "helper",
                        title: "Unable to read the above image? "
                      }),
                      /* @__PURE__ */ e.jsx(x, {
                        variant: "text",
                        customClass: "text-size-12 mt-1 mr-1",
                        children: "try another"
                      })
                    ]
                  })
                ]
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(x, {
            customClass: "mr-2 bg-green",
            variant: "primary",
            fullwidth: !0,
            onClick: () => s(),
            children: "Next"
          })
        ]
      })
    })
  });
  return t ? /* @__PURE__ */ e.jsx(d, {
    variant: "fullpage"
  }) : (() => {
    switch (i) {
      case "initial":
        return r();
      case "error":
        return /* @__PURE__ */ e.jsx(o, {
          title: "Something went wrong",
          description: ""
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function P({ onCompletion: s }) {
  const [t, l] = n(!1), [i, c] = n("initial"), a = () => /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsxs("div", {
      className: "flex flex-col items-center justify-center",
      children: [
        /* @__PURE__ */ e.jsxs("div", {
          className: "border-neutral-10 w-full max-w-md rounded-md border border-solid px-8 py-8 shadow-md",
          children: [
            /* @__PURE__ */ e.jsx(u, {
              title: "Signzy"
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(v, {
                  type: "text",
                  text: "UIDAI has sent a temporary OTP to your mobile"
                }),
                /* @__PURE__ */ e.jsx(j, {
                  type: "text",
                  value: "123456",
                  readOnly: !0,
                  placeholder: "Enter Text"
                }),
                /* @__PURE__ */ e.jsx(y, {
                  variant: "field",
                  type: "important",
                  title: "Wait few minutes for the OTP , do not refresh or close"
                })
              ]
            }),
            /* @__PURE__ */ e.jsx(x, {
              onClick: () => s(),
              fullwidth: !0,
              children: "Allow"
            })
          ]
        }),
        /* @__PURE__ */ e.jsx("a", {
          className: "mt-4",
          children: "Return to Signzy"
        })
      ]
    })
  });
  return t ? /* @__PURE__ */ e.jsx(d, {
    variant: "fullpage"
  }) : (() => {
    switch (i) {
      case "initial":
        return a();
      case "error":
        return /* @__PURE__ */ e.jsx(o, {
          title: "Something went wrong",
          description: ""
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function z({ onCompletion: s }) {
  const [t, l] = n(!1), [i, c] = n("initial"), a = () => /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsxs("div", {
      className: "flex flex-col items-center justify-center",
      children: [
        /* @__PURE__ */ e.jsxs("div", {
          className: "border-neutral-10 w-full max-w-md rounded-md border border-solid px-8 py-8 shadow-md",
          children: [
            /* @__PURE__ */ e.jsx(u, {
              title: "You are already registered with DigiLocker"
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(p, {
                  message: "Please enter your 6 digit DigiLocker Security PIN"
                }),
                /* @__PURE__ */ e.jsx(j, {
                  type: "text",
                  value: "123456",
                  readOnly: !0,
                  variant: "inputSecure",
                  placeholder: "Enter PIN"
                }),
                /* @__PURE__ */ e.jsx(x, {
                  variant: "text",
                  customClass: "text-size-12 mt-1",
                  children: "Forgot security PIN?"
                })
              ]
            }),
            /* @__PURE__ */ e.jsx(x, {
              onClick: () => s(),
              fullwidth: !0,
              children: "Allow"
            })
          ]
        }),
        /* @__PURE__ */ e.jsx("a", {
          className: "mt-4",
          children: "Return to Signzy"
        })
      ]
    })
  });
  return t ? /* @__PURE__ */ e.jsx(d, {
    variant: "fullpage"
  }) : (() => {
    switch (i) {
      case "initial":
        return a();
      case "error":
        return /* @__PURE__ */ e.jsx(o, {
          title: "Something went wrong",
          description: ""
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function C({ onCompletion: s, identity_id: t }) {
  const [l, i] = n(!1), [c, a] = n("initial"), { tenantConfig: r } = A(), m = async () => {
    var h;
    try {
      i(!0), await fetch(`${(h = r == null ? void 0 : r.fp) == null ? void 0 : h.endpoint}/v2/identity_documents/${t}/submit`), s(1), i(!1);
    } catch {
      a("error"), i(!1);
    }
  }, g = async () => {
    var h;
    try {
      i(!0), await fetch(`${(h = r == null ? void 0 : r.fp) == null ? void 0 : h.endpoint}/v2/identity_documents/${t}/cancel`), s(2), i(!1);
    } catch {
      a("error"), i(!1);
    }
  }, f = () => /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx("div", {
      className: "flex items-center justify-center",
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "border-neutral-10 w-full max-w-md rounded-md border border-solid px-8 py-8 shadow-md",
        children: [
          /* @__PURE__ */ e.jsx(u, {
            title: "Signzy"
          }),
          /* @__PURE__ */ e.jsx(v, {
            type: "text",
            text: "You are providing your concent to share your Digilocker document with signzy."
          }),
          /* @__PURE__ */ e.jsxs("div", {
            className: "flex justify-center",
            children: [
              /* @__PURE__ */ e.jsx(x, {
                onClick: m,
                size: "sm",
                customClass: "mr-2",
                children: "Allow"
              }),
              /* @__PURE__ */ e.jsx(x, {
                onClick: g,
                size: "sm",
                variant: "secondary",
                children: "Deny"
              })
            ]
          })
        ]
      })
    })
  });
  return l ? /* @__PURE__ */ e.jsx(d, {
    variant: "fullpage"
  }) : (() => {
    switch (c) {
      case "initial":
        return f();
      case "error":
        return /* @__PURE__ */ e.jsx(o, {
          title: "Something went wrong",
          description: ""
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function I() {
  const [s, t] = n(!1), [l, i] = n("initial"), c = () => /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx("div", {
      className: "flex min-h-screen flex-col items-center justify-center",
      children: /* @__PURE__ */ e.jsxs("div", {
        children: [
          /* @__PURE__ */ e.jsx(S, {
            name: "successInfo",
            className: "mx-auto mb-2 block h-12 w-12"
          }),
          /* @__PURE__ */ e.jsx("h3", {
            className: "text-neutral-dark-0 text-size-20 font-opensans dark:text-neutral-dark-110 pb-2 text-center font-semibold",
            children: "Thank you"
          }),
          /* @__PURE__ */ e.jsx("p", {
            className: "text-neutral-60 text-size-14 mb-5 mt-0 text-center",
            children: "You have successfully given access for your digilocker account."
          })
        ]
      })
    })
  });
  return s ? /* @__PURE__ */ e.jsx(d, {
    variant: "fullpage"
  }) : (() => {
    switch (l) {
      case "initial":
        return c();
      case "error":
        return /* @__PURE__ */ e.jsx(o, {
          title: "Something went wrong",
          description: ""
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function D() {
  const [s, t] = n(!1), [l, i] = n("initial"), c = () => /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx("div", {
      className: "flex min-h-screen flex-col items-center justify-center",
      children: /* @__PURE__ */ e.jsxs("div", {
        children: [
          /* @__PURE__ */ e.jsx(S, {
            name: "Error",
            className: "mx-auto mb-2 block h-12 w-12"
          }),
          /* @__PURE__ */ e.jsx("h3", {
            className: "text-neutral-dark-0 text-size-20 font-opensans dark:text-neutral-dark-110 pb-2 text-center font-semibold",
            children: "Something went wrong."
          }),
          /* @__PURE__ */ e.jsx("p", {
            className: "text-neutral-60 text-size-14 mb-5 mt-0 text-center",
            children: "We could not authorize your digilocker account."
          })
        ]
      })
    })
  });
  return s ? /* @__PURE__ */ e.jsx(d, {
    variant: "fullpage"
  }) : (() => {
    switch (l) {
      case "initial":
        return c();
      case "error":
        return /* @__PURE__ */ e.jsx(o, {
          title: "Something went wrong",
          description: ""
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function F({ identity_id: s }) {
  const [t, l] = n(!1), [i, c] = n("initial"), [a, r] = n(1);
  N(() => {
    s || c("error");
  }, [
    s
  ]);
  const m = () => {
    switch (a) {
      case 1:
        return /* @__PURE__ */ e.jsx(w, {
          onCompletion: () => r(a + 1)
        });
      case 2:
        return /* @__PURE__ */ e.jsx(P, {
          onCompletion: () => r(a + 1)
        });
      case 3:
        return /* @__PURE__ */ e.jsx(z, {
          onCompletion: () => r(a + 1)
        });
      case 4:
        return /* @__PURE__ */ e.jsx(C, {
          onCompletion: (f) => r(a + f),
          identity_id: s
        });
      case 5:
        return /* @__PURE__ */ e.jsx(I, {});
      case 6:
        return /* @__PURE__ */ e.jsx(D, {});
      default:
        return /* @__PURE__ */ e.jsx(w, {});
    }
  };
  return t ? /* @__PURE__ */ e.jsx(d, {
    variant: "fullpage"
  }) : (() => {
    switch (i) {
      case "initial":
        return m();
      case "error":
        return /* @__PURE__ */ e.jsx(o, {
          title: "Something went wrong",
          description: ""
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function E({ name: s, onCompletion: t }) {
  const [l, i] = n(!1), [c, a] = n("initial"), r = () => /* @__PURE__ */ e.jsx("div", {
    className: "flex items-center justify-center",
    children: /* @__PURE__ */ e.jsxs("div", {
      className: "border-neutral-10 w-full max-w-xl rounded-md border border-solid px-8 py-4 shadow-md",
      children: [
        /* @__PURE__ */ e.jsxs("div", {
          className: "mb-4 flex flex-col items-center",
          children: [
            /* @__PURE__ */ e.jsx(u, {
              title: "Investor Onboarding Document"
            }),
            /* @__PURE__ */ e.jsx(u, {
              variant: "sub",
              title: "Address proof"
            })
          ]
        }),
        /* @__PURE__ */ e.jsxs("ol", {
          className: "font-opensans text-size-14 mb-8 list-decimal px-4 font-semibold",
          children: [
            /* @__PURE__ */ e.jsx("li", {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4 flex",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "text-neutral-60 mr-2 shrink-0 basis-1/4 ",
                    children: "Address"
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "flex-1",
                    children: "Flat B 703, The akme harmony, bangalore - 560103"
                  })
                ]
              })
            }),
            /* @__PURE__ */ e.jsx("li", {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4 flex",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "text-neutral-60 mr-2 shrink-0 basis-1/4 ",
                    children: "City"
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "flex-1",
                    children: "Delhi"
                  })
                ]
              })
            }),
            /* @__PURE__ */ e.jsx("li", {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4 flex",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "text-neutral-60 mr-2 shrink-0 basis-1/4 ",
                    children: "District"
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "flex-1",
                    children: "Central Delhi"
                  })
                ]
              })
            }),
            /* @__PURE__ */ e.jsx("li", {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4 flex",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "text-neutral-60 mr-2 shrink-0 basis-1/4 ",
                    children: "DOB"
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "flex-1",
                    children: "05/05/2020"
                  })
                ]
              })
            }),
            /* @__PURE__ */ e.jsx("li", {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4 flex",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "text-neutral-60 mr-2 shrink-0 basis-1/4 ",
                    children: "Expiry date"
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "flex-1",
                    children: "05/05/2020"
                  })
                ]
              })
            }),
            /* @__PURE__ */ e.jsx("li", {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4 flex",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "text-neutral-60 mr-2 shrink-0 basis-1/4 ",
                    children: "Issue date"
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "flex-1",
                    children: "05/05/2020"
                  })
                ]
              })
            }),
            /* @__PURE__ */ e.jsx("li", {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4 flex",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "text-neutral-60 mr-2 shrink-0 basis-1/4 ",
                    children: "Name"
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "flex-1",
                    children: "05/05/2020"
                  })
                ]
              })
            }),
            /* @__PURE__ */ e.jsx("li", {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "mb-4 flex",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "text-neutral-60 mr-2 shrink-0 basis-1/4 ",
                    children: "Number"
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "flex-1",
                    children: "05/05/2020"
                  })
                ]
              })
            })
          ]
        }),
        /* @__PURE__ */ e.jsx("div", {
          className: "text-center",
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "mb-6 text-left",
            children: [
              /* @__PURE__ */ e.jsx(p, {
                message: "Signer's Name"
              }),
              /* @__PURE__ */ e.jsx(j, {
                type: "text",
                value: s,
                disabled: !0
              })
            ]
          })
        }),
        /* @__PURE__ */ e.jsxs("div", {
          className: "flex flex-col items-center",
          children: [
            /* @__PURE__ */ e.jsx(v, {
              type: "text",
              text: "Aadhar consent",
              customClass: "mb-2"
            }),
            /* @__PURE__ */ e.jsx(L, {
              id: "1",
              customClass: "mb-4",
              children: "The Aadhar holder gives his consent to Signzy to peform e-Sign for the Aadhar holder and retrieve his/her details sing Aadhar number and the OTP entered by the Aadhar holder."
            }),
            /* @__PURE__ */ e.jsx(x, {
              size: "sm",
              onClick: () => t(),
              children: "Confirm your signature"
            })
          ]
        })
      ]
    })
  });
  return l ? /* @__PURE__ */ e.jsx(d, {
    variant: "fullpage"
  }) : (() => {
    switch (c) {
      case "initial":
        return r();
      case "error":
        return /* @__PURE__ */ e.jsx(o, {
          title: "Something went wrong",
          description: ""
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function M({ esign_id: s, name: t }) {
  const [l, i] = n(!1), [c, a] = n("initial"), [r, m] = n(1);
  N(() => {
    (!s || !t) && a("error");
  }, [
    s,
    t
  ]);
  const g = () => {
    switch (r) {
      case 1:
        return /* @__PURE__ */ e.jsx(E, {
          name: t,
          onCompletion: () => m(r + 1)
        });
      default:
        return /* @__PURE__ */ e.jsx("h2", {
          children: "World"
        });
    }
  };
  return l ? /* @__PURE__ */ e.jsx(d, {
    variant: "fullpage"
  }) : (() => {
    switch (c) {
      case "initial":
        return g();
      case "error":
        return /* @__PURE__ */ e.jsx(o, {
          title: "Something went wrong",
          description: ""
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function W({ type: s, id: t, name: l }) {
  return (() => {
    switch (b.isStagingOrSandbox ? b.isProduction && (s = "production_error") : s = "error", s) {
      case "aadhaar":
        return /* @__PURE__ */ e.jsx(F, {
          identity_id: t
        });
      case "esign":
        return /* @__PURE__ */ e.jsx(M, {
          esign_id: t,
          name: l
        });
      case "error":
        return /* @__PURE__ */ e.jsx(o, {
          title: "Something went wrong",
          description: ""
        });
      case "production_error":
        return /* @__PURE__ */ e.jsx(o, {
          title: "Sorry, this workflow is only available in Sandbox/Staging",
          description: ""
        });
      default:
        return /* @__PURE__ */ e.jsx(o, {
          title: "Something went wrong",
          description: ""
        });
    }
  })();
}
export {
  W as Simulate
};
