import { j as a } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as Z, useState as b, useMemo as Me, useLayoutEffect as Se, useEffect as ce } from "react";
import { a as be, M as z, B as p, O as ae, S as Ce, o as Ie, C as J, c as q, E as Ue, G as Ye, H as he, e as Xe, T as He } from "./index.esm-BV4iWo1a.js";
import { u as fe } from "./useConfiguration-B-lyaBB9.js";
import { F as ye } from "./provider-DZPLM8yJ.js";
import { r as oe } from "./sentry-ZQO5C1U6.js";
import { aa as ze, H as ee, G as Oe, C as T, m as V, U as Ze, j as xe, _ as re, at as Ee, Q as Ge, i as Le, f as Qe, ag as Je, au as Te, a6 as Ve, X as Fe, o as Ke, as as Ne, $ as Re, B as Pe, am as pe, c as et, k as $e, n as qe } from "./utils-BTh-HRzb.js";
import { i as s } from "./index-B3vUTrRT.js";
import { AppContext as ie } from "./AppContext.js";
import { C as te } from "./CopyLinkButton-Ca05mnK-.js";
import { S as K, C as ue } from "./StatusMessage-DFmLnokA.js";
import { m as ke, M as tt } from "./MandateListing-BxVtIJW-.js";
import { F as st } from "./react-final-form.es-DAN9JLvE.js";
import { f as at, r as it } from "./validators-BArvL7cB.js";
const nt = [
  "pending",
  "cancelled",
  "confirmed",
  "submitted",
  "successful",
  "failed",
  "reversed"
], ot = [
  "pending",
  "success",
  "failed",
  "timedout",
  "initiated",
  "approved",
  "rejected",
  "submitted",
  ""
], lt = [
  [
    "awaitingPayment",
    "underProcess",
    "failed",
    "",
    "awaitingPayment",
    "",
    "",
    "",
    "paymentNotFound"
  ],
  [
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "",
    "cancelled"
  ],
  [
    "",
    "underProcess",
    "",
    "",
    "underProcess",
    "underProcess",
    "",
    "underProcess",
    "underProcess"
  ],
  [
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "",
    "underProcess"
  ],
  [
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "",
    "successful"
  ],
  [
    "",
    "",
    "paymentUnsuccessful",
    "",
    "",
    "",
    "paymentUnsuccessful",
    "",
    "failed"
  ],
  [
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "",
    "reversed"
  ]
], dt = [
  [
    "awaitingPayment",
    "underProcess",
    "",
    "",
    "awaitingPayment",
    "",
    "",
    "underProcess",
    "paymentNotFound"
  ],
  [
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled"
  ],
  [
    "",
    "underProcess",
    "",
    "",
    "underProcess",
    "underProcess",
    "",
    "underProcess",
    "underProcess"
  ],
  [
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess"
  ],
  [
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful"
  ],
  [
    "",
    "",
    "paymentUnsuccessful",
    "",
    "",
    "",
    "paymentUnsuccessful",
    "",
    "failed"
  ],
  [
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed"
  ]
], rt = (t) => {
  const e = `Your purchase reference is ${t}`;
  return {
    paymentNotFound: {
      title: "Awaiting payment",
      description: e,
      buttonText: "Complete payment",
      icon: "Alert",
      helpText: "Your order will be processed after payment completion",
      helpTextType: "alert"
    },
    awaitingPayment: {
      title: "Payment processing",
      description: e,
      buttonText: "Invest again",
      icon: "Alert",
      helpText: "We are waiting for payment confirmation from your bank. You will be able to complete payment after 10 minutes if the payment is not successful.",
      helpTextType: "alert"
    },
    underProcess: {
      title: "Under process",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    successful: {
      title: "Successful",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    cancelled: {
      title: "Cancelled",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    reversed: {
      title: "Reversed",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    paymentUnsuccessful: {
      title: "Payment failed",
      description: e,
      buttonText: "Retry payment",
      icon: "Error",
      helpText: "The amount debited from your bank account will be refunded within 5 working days. You can retry payment after 30 mins to complete this order.",
      helpTextType: "alert"
    },
    expired: {
      title: "Expired",
      description: e,
      buttonText: "Invest again",
      icon: "Error",
      helpText: "Order expired due to pending payment",
      helpTextType: "important"
    },
    failed: {
      title: "Failed",
      description: e,
      buttonText: "Invest again",
      icon: "Error"
    }
  };
};
function Be({ investorProfile: t, scheme: e, mf_purchase: i, payment: n, handleInvestAgain: l, handleRetry: d, showSchemeName: o, isGroupInvestment: u, isPartner: r, isFotOrder: g }) {
  var M;
  const { AppState: { tenant: f } } = Z(ie), x = i.state.toLowerCase(), j = n ? (M = n == null ? void 0 : n.status) == null ? void 0 : M.toLowerCase() : "", S = i.failure_code, [L, m] = b(s._({
    id: "s0Estp"
  })), [F, N] = b(""), [c, $] = b("Error"), [E, U] = b(s._({
    id: "wd9ucl"
  })), [Q, G] = b(""), [A, R] = b(""), [W, w] = b(null), [Y, B] = b(!1), [se, le] = b(null), { fpapi: ne } = Z(ye), { tenantConfig: C, banks: h, amcs: _e } = fe(), X = Me(() => {
    var _, v, D, I, O, P;
    const y = (v = (_ = C == null ? void 0 : C.fundList) == null ? void 0 : _.find((H) => H.isin === e.isin)) == null ? void 0 : v.nfo;
    return ((D = C == null ? void 0 : C.groupInvestment) == null ? void 0 : D.days) && ((I = C == null ? void 0 : C.groupInvestment) == null ? void 0 : I.cutOffDays) && ze({
      salaryDay: (O = C == null ? void 0 : C.groupInvestment) == null ? void 0 : O.days[0],
      salaryCutoffDay: (P = C == null ? void 0 : C.groupInvestment) == null ? void 0 : P.cutOffDays[0],
      NFO: y,
      purchaseDate: i.created_at
    });
  }, [
    e
  ]);
  Se(() => {
    u ? we() : me();
  }, []);
  const ve = (y) => u ? "Salary" : y ? Ze(y) : "-";
  async function me() {
    if (n && t) {
      B(!0);
      try {
        const { data: I } = await ne.fpClient.bank_accounts().fetchAll({
          profile: t == null ? void 0 : t.id
        }), O = I.find((P) => P.old_id === (n == null ? void 0 : n.from_bank_account_id));
        le(O);
      } catch (I) {
        oe(I);
      } finally {
        B(!1);
      }
    }
    const y = nt.indexOf(x), _ = ot.indexOf(j);
    if (y === -1 || _ === -1) return;
    let v;
    (n == null ? void 0 : n.method) === "EMANDATE" ? v = dt[y][_] : v = lt[y][_], v === "failed" && S === "order_expiry" && (v = "expired");
    const D = rt(i == null ? void 0 : i.id)[v];
    D && (m(D.title), N(r ? s._({
      id: "znLBZG",
      values: {
        0: i.id
      }
    }) : s._({
      id: "sNrEAc",
      values: {
        0: i.id
      }
    })), U(r ? s._({
      id: "quOB9l"
    }) : D.buttonText), $(D.icon), G(r ? s._({
      id: "5SyDtU"
    }) : D.helpText), R(D.helpTextType));
  }
  async function we() {
    B(!0);
    try {
      const y = await ne.fpClient.mf_settlement_details().fetchAll({
        mf_purchase: i.id
      });
      w(y);
    } catch (y) {
      window.debug.error(y), oe(y);
    }
    B(!1), x === "created" || x === "pending" || x === "submitted" || x === "confirmed" ? (N(`Your purchase reference is ${i.id}`), m(s._({
      id: "oiliCI"
    })), U(s._({
      id: "quOB9l"
    })), $("successInfo")) : x === "successful" ? (m(s._({
      id: "EbM80r"
    })), N(s._({
      id: "sNrEAc",
      values: {
        0: i.id
      }
    })), U(s._({
      id: "quOB9l"
    })), $("successInfo")) : x === "failed" && (m(s._({
      id: "mVq3N+"
    })), N(s._({
      id: "WX839d"
    })), U(s._({
      id: "quOB9l"
    })), $("Error"));
  }
  function ge() {
    return u ? x === "failed" ? d : l : r ? l : x === "pending" && !j || x === "failed" && j === "failed" ? d : l;
  }
  function je() {
    return !!(u && !W && x === "pending");
  }
  function de(y, _, v) {
    var I;
    const D = [
      {
        label: s._({
          id: "4Zu577"
        }),
        value: `₹ ${T(y.amount)}`
      },
      {
        label: s._({
          id: "rs9/UI"
        }),
        value: "One time"
      },
      {
        label: s._({
          id: "ItmT+q"
        }),
        value: y.created_at ? V(new Date(y.created_at), "dd-MMM-yyyy") : "-"
      },
      {
        label: s._({
          id: "cXcPd1"
        }),
        value: y.folio_number ? y.folio_number : "New folio"
      },
      !r && {
        label: s._({
          id: "lFCwug"
        }),
        value: ve(_ == null ? void 0 : _.method)
      },
      !r && {
        label: s._({
          id: "0EjvnG"
        }),
        value: u ? W ? W.id : "-" : _ != null && _.id ? _.id : "-"
      }
    ];
    return v && !u && D.push({
      label: s._({
        id: "/dl6Or"
      }),
      colSpan: "basis-full",
      value: v ? `${v.bank_name} (*****${v.account_number.slice(-4)})` : "-",
      logo: h[(I = v == null ? void 0 : v.ifsc_code) == null ? void 0 : I.slice(0, 4)] || (C == null ? void 0 : C.fallbackLogo)
    }), D;
  }
  function k() {
    return /* @__PURE__ */ a.jsx(ae, {
      title: o && e.name,
      tableData: de(i, n, se)
    });
  }
  return Y ? /* @__PURE__ */ a.jsx(be, {
    variant: "fullpage"
  }) : /* @__PURE__ */ a.jsxs(a.Fragment, {
    children: [
      " ",
      /* @__PURE__ */ a.jsx(K, {
        title: L,
        description: F,
        iconName: c,
        children: k()
      }),
      je() && /* @__PURE__ */ a.jsx(z, {
        type: "helper",
        variant: "flash",
        title: s._({
          id: "Gi+PAV",
          values: {
            0: X != null && X.consideredSalaryDate ? `in ${new Date(X == null ? void 0 : X.consideredSalaryDate).toLocaleString("default", {
              month: "long"
            })} ${new Date(X == null ? void 0 : X.consideredSalaryDate).getFullYear()}` : ""
          }
        }),
        customClass: "mb-5"
      }),
      Q && /* @__PURE__ */ a.jsx(z, {
        type: A,
        variant: "flash",
        title: Q,
        customClass: "mb-5"
      }),
      r && !g && /* @__PURE__ */ a.jsx(te, {
        hyperlink: ee(f) + `/consents?object_type=mf_purchase&action=confirm&id=${i.id}`,
        buttonText: "consent & payment link",
        customClass: "mb-2"
      }),
      (l || d && Oe(n == null ? void 0 : n.created_at)) && /* @__PURE__ */ a.jsx(p, {
        variant: "secondary",
        fullwidth: !0,
        onClick: ge(),
        customClass: "mb-2",
        children: E
      })
    ]
  });
}
const ut = (t, e) => {
  var g;
  const i = /* @__PURE__ */ new Date(), n = (/* @__PURE__ */ new Date()).getDate(), l = i.getFullYear(), d = i.getMonth(), o = new Date(l, d, t), u = new Date(l, d + 1, t);
  let r;
  return t ? (t <= n ? r = new Date(u) : t > n ? r = new Date(o) : r = new Date(o), e && (r = /* @__PURE__ */ new Date()), r == null ? void 0 : r.toString()) : (g = /* @__PURE__ */ new Date()) == null ? void 0 : g.toString();
}, qt = (t, e, i, n, l) => {
  var d;
  return /* @__PURE__ */ a.jsxs("div", {
    style: {
      marginTop: "-20px"
    },
    children: [
      /* @__PURE__ */ a.jsx(Ce, {
        customClass: "mb-8",
        children: /* @__PURE__ */ a.jsxs(Ie, {
          children: [
            /* @__PURE__ */ a.jsx(J, {
              span: 6,
              children: /* @__PURE__ */ a.jsx(q, {
                label: s._({
                  id: "4Zu577"
                }),
                value: t != null && t.amount ? `₹ ${T(t == null ? void 0 : t.amount, 2)}` : "-"
              })
            }),
            /* @__PURE__ */ a.jsx(J, {
              span: 6,
              children: /* @__PURE__ */ a.jsx(q, {
                label: s._({
                  id: "UMXPdU"
                }),
                value: t != null && t.frequency ? (d = xe(t == null ? void 0 : t.frequency)) == null ? void 0 : d.replace(/_/g, " ") : "-"
              })
            }),
            /* @__PURE__ */ a.jsx(J, {
              span: 6,
              children: /* @__PURE__ */ a.jsx(q, {
                label: s._({
                  id: "cXcPd1"
                }),
                value: t != null && t.folio_number ? t == null ? void 0 : t.folio_number : "New folio"
              })
            }),
            /* @__PURE__ */ a.jsx(J, {
              span: 6,
              children: /* @__PURE__ */ a.jsx(q, {
                label: s._({
                  id: "ZS3Gyi"
                }),
                value: re(t == null ? void 0 : t.number_of_installments)
              })
            }),
            /* @__PURE__ */ a.jsx(J, {
              span: 6,
              children: /* @__PURE__ */ a.jsx(q, {
                label: s._({
                  id: "cki4uJ"
                }),
                value: Ee(t == null ? void 0 : t.frequency, ut(t == null ? void 0 : t.installment_day, l))
              })
            }),
            /* @__PURE__ */ a.jsx(ue, {
              check: !!(t != null && t.end_date),
              children: /* @__PURE__ */ a.jsx(J, {
                span: 6,
                children: /* @__PURE__ */ a.jsx(q, {
                  label: s._({
                    id: "gqWUiZ"
                  }),
                  value: t != null && t.end_date ? Ge(t == null ? void 0 : t.end_date) : "-"
                })
              })
            }),
            /* @__PURE__ */ a.jsxs(ue, {
              check: !!e,
              children: [
                /* @__PURE__ */ a.jsx(J, {
                  span: 6,
                  children: /* @__PURE__ */ a.jsx(q, {
                    label: s._({
                      id: "6SUdWB"
                    }),
                    value: re(e == null ? void 0 : e.name)
                  })
                }),
                /* @__PURE__ */ a.jsx(J, {
                  span: 6,
                  children: /* @__PURE__ */ a.jsx(q, {
                    label: s._({
                      id: "cCU8Ub"
                    }),
                    value: re(e == null ? void 0 : e.license_code)
                  })
                })
              ]
            })
          ]
        })
      }),
      /* @__PURE__ */ a.jsx(ue, {
        check: !!l,
        children: /* @__PURE__ */ a.jsx(z, {
          type: "important",
          variant: "flash",
          title: s._({
            id: "JbUeSI"
          }),
          customClass: "mb-5"
        })
      })
    ]
  });
}, ct = ({ mandate: t, banks: e, fallbackLogo: i }) => {
  var d, o, u, r;
  const [n, l] = b(!1);
  return t ? /* @__PURE__ */ a.jsx("div", {
    className: "mb-4",
    children: /* @__PURE__ */ a.jsxs(Ue, {
      expanded: n,
      onChange: () => l(!n),
      variant: "panel",
      children: [
        /* @__PURE__ */ a.jsx(Ye, {
          active: !0,
          variant: "panel",
          children: /* @__PURE__ */ a.jsx("div", {
            className: "flex items-baseline",
            children: /* @__PURE__ */ a.jsx("span", {
              className: "mr-2",
              children: "Mandate details"
            })
          })
        }),
        /* @__PURE__ */ a.jsx(he, {
          variant: "panel",
          children: /* @__PURE__ */ a.jsx(Ce, {
            children: /* @__PURE__ */ a.jsxs(Ie, {
              children: [
                /* @__PURE__ */ a.jsx("div", {
                  className: "mb-3 basis-full pr-2 pl-2",
                  children: /* @__PURE__ */ a.jsx(q, {
                    label: s._({
                      id: "JVRi6u"
                    }),
                    value: `${(d = t.bank) == null ? void 0 : d.bank_name} (*****${(o = t.bank) == null ? void 0 : o.number.slice(-4)})`,
                    logo: e[(r = (u = t.bank) == null ? void 0 : u.ifsc) == null ? void 0 : r.slice(0, 4)] || i
                  })
                }),
                /* @__PURE__ */ a.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ a.jsx(q, {
                    label: s._({
                      id: "OzNl8x"
                    }),
                    value: t ? ke[t.mandate_status].value : "-",
                    icon: t ? ke[t.mandate_status].icon : "",
                    iconPlacement: "left"
                  })
                }),
                /* @__PURE__ */ a.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ a.jsx(q, {
                    label: s._({
                      id: "nlXCcY"
                    }),
                    value: "NACH"
                  })
                }),
                /* @__PURE__ */ a.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ a.jsx(q, {
                    label: s._({
                      id: "Zp6kam"
                    }),
                    value: `₹ ${T(t.mandate_limit)}`
                  })
                }),
                /* @__PURE__ */ a.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ a.jsx(q, {
                    label: s._({
                      id: "0OJ5oD"
                    }),
                    value: V(new Date(t.valid_from), "dd-MMM-yyyy")
                  })
                })
              ]
            })
          }, t.id)
        })
      ]
    })
  }) : /* @__PURE__ */ a.jsx(a.Fragment, {});
}, Bt = (t) => {
  switch (t) {
    case "daily":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "4UB9pl"
          }),
          value: "number_of_days"
        }
      ];
    case "day_in_a_week":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "cpH9Hi"
          }),
          value: "number_of_weeks"
        }
      ];
    case "four_times_a_month":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "0A81Au"
          }),
          value: "number_of_instalments"
        }
      ];
    case "half-yearly":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "cpH9Hi"
          }),
          value: "number_of_weeks"
        },
        {
          label: s._({
            id: "Xtu/a+"
          }),
          value: "number_of_years"
        }
      ];
    case "monthly":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "fBXQWJ"
          }),
          value: "number_of_months"
        },
        {
          label: s._({
            id: "Xtu/a+"
          }),
          value: "number_of_years"
        }
      ];
    case "quarterly":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "UpUWDH"
          }),
          value: "number_of_quarters"
        },
        {
          label: s._({
            id: "Xtu/a+"
          }),
          value: "number_of_years"
        }
      ];
    default:
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "vEYjLl"
          }),
          value: "installments"
        }
      ];
  }
}, At = (t, e) => {
  var i;
  return !Le(t) && (t != null && t.dp_id) || !Qe(e) && ((i = e[0].folio_defaults) != null && i.demat_account) ? "Demat" : "Physical";
}, Ae = (t, e) => {
  const i = /* @__PURE__ */ new Date(), n = i.getFullYear(), l = i.getMonth(), d = (/* @__PURE__ */ new Date()).getDate(), o = new Date(n, l + 1, t), u = new Date(n, l, t);
  let r;
  return t <= d ? r = new Date(o) : t > d ? r = new Date(u) : r = new Date(u), e && (r = /* @__PURE__ */ new Date()), r;
}, Wt = (t, e, i) => {
  let n = Ae(t, e);
  const l = /* @__PURE__ */ new Date();
  l.setDate(t);
  const d = new Date(l.setDate(l.getDate() + 1));
  return i === "daily" && !e && t === (/* @__PURE__ */ new Date()).getDate() && (n = d), s._({
    id: "wGedtw",
    values: {
      0: V(n, "dd-MMM-yyyy")
    }
  });
}, Ut = (t, e) => {
  const i = (/* @__PURE__ */ new Date()).getDate(), n = new Date((/* @__PURE__ */ new Date()).setDate((/* @__PURE__ */ new Date()).getDate() + 2)).getDate();
  return !((t < i || t >= n) && e === "daily");
};
function De(t, e) {
  const i = (/* @__PURE__ */ new Date()).getFullYear(), l = i % 4 === 0 && i % 100 !== 0 || i % 400 === 0 ? 366 : 365, d = {
    daily: l,
    day_in_a_week: l / 7,
    four_times_a_month: 4 * 12,
    day_in_a_fortnight: l / 14,
    twice_a_month: 2 * 12,
    monthly: 12,
    quarterly: 4,
    "half-yearly": 2,
    yearly: 1
  };
  return Math.ceil(e / d[t]);
}
const bt = (t, e, i) => {
  let n = `Minimum number of installments is ${t}`;
  const l = De("monthly", t);
  return e === "daily" ? n = `The minimum duration for creating a SIP is ${T(t)} ${t > 1 ? "days" : "day"}` : e === "monthly" && i === "number_of_months" ? n = `The minimum duration for creating a SIP is ${T(t)} months` : e === "monthly" && i === "number_of_years" ? n = `The minimum duration for creating a SIP is ${T(l)} ${l > 1 ? "years" : "year"}` : e === "quarterly" && i === "number_of_quarters" ? n = `The minimum duration for creating a SIP is ${T(t)} quarters` : e === "quarterly" && i === "number_of_years" ? n = `The minimum duration for creating a SIP is ${T(l)} ${l > 1 ? "years" : "year"}` : e === "day_in_a_week" && i === "number_of_weeks" ? n = `The minimum duration for creating a SIP is ${T(t)} weeks` : e === "half-yearly" && i === "number_of_installments" ? n = `Minimum number of installment is ${T(t)}` : e === "half-yearly" && i === "number_of_years" ? n = `The minimum duration for creating a SIP is ${T(l)} ${l > 1 ? "years" : "year"}` : e === "four_times_a_month" && i === "number_of_installments" ? n = `Minimum number of installment is ${T(t)}` : e === "twice_a_month" && i === "number_of_installments" ? n = `Minimum number of installment is ${T(t)}` : e === "day_in_a_fortnight" && i === "number_of_installments" ? n = `Minimum number of installment is ${T(t)}` : n = `Minimum number of installment is ${T(t)}`, n;
}, ft = (t, e) => {
  const i = `${t}_${e}`, n = {
    daily_number_of_days: "Enter number of days",
    monthly_number_of_months: "Enter number of months",
    quarterly_number_of_years: "Enter number of years",
    "half-yearly_number_of_years": "Enter number of years",
    monthly_number_of_years: "Enter number of years",
    quarterly_number_of_quarters: "Enter number of quarters",
    day_in_a_week_number_of_weeks: "Enter number of weeks",
    "half-yearly_number_of_installments": "Enter number of installments",
    four_times_a_month_number_of_installments: "Enter number of installments",
    twice_a_month_number_of_installments: "Enter number of installments",
    day_in_a_fortnight_number_of_installments: "Enter number of installments"
  };
  return n[i] ? s._({
    id: "ev7haI",
    values: {
      0: n[i]
    }
  }) : s._({
    id: "LQjwJ/"
  });
}, Yt = (t, e) => {
  const i = [];
  return t.forEach((n) => {
    n.amc === e && i.push(n);
  }), i;
}, ht = (t, e, i, n, l) => {
  var u, r, g, f, x, j, S, L, m, F;
  const d = {}, o = (u = e == null ? void 0 : e.sip_frequency_specific_data[i]) == null ? void 0 : u.min_installments;
  return t.amount || (d.amount = s._({
    id: "c0D/2W"
  })), t.amount && t.amount < Number((r = e == null ? void 0 : e.sip_frequency_specific_data[i]) == null ? void 0 : r.min_installment_amount) && (d.amount = s._({
    id: "9LeqUy",
    values: {
      0: T(Number((g = e == null ? void 0 : e.sip_frequency_specific_data[i]) == null ? void 0 : g.min_installment_amount))
    }
  })), t.amount && t.amount > Number((f = e == null ? void 0 : e.sip_frequency_specific_data[i]) == null ? void 0 : f.max_installment_amount) && (d.amount = s._({
    id: "DJzP4H",
    values: {
      0: T(Number((x = e == null ? void 0 : e.sip_frequency_specific_data[i]) == null ? void 0 : x.min_installment_amount))
    }
  })), Je(t.amount, Number((j = e == null ? void 0 : e.sip_frequency_specific_data[i]) == null ? void 0 : j.amount_multiples)) === !1 && (d.amount = s._({
    id: "O/Jkev",
    values: {
      0: Number((S = e == null ? void 0 : e.sip_frequency_specific_data[i]) == null ? void 0 : S.amount_multiples)
    }
  })), t.installments && Number(t.installments) < Number((L = e == null ? void 0 : e.sip_frequency_specific_data[i]) == null ? void 0 : L.min_installments) && (d.installments = s._({
    id: "NeTEIk",
    values: {
      0: Number((m = e == null ? void 0 : e.sip_frequency_specific_data[i]) == null ? void 0 : m.min_installments)
    }
  })), t.number_of_days && Number(t.number_of_days) < Number(o) && (d.number_of_days = s._({
    id: "Hs2tma",
    values: {
      currentSchemeMinimumInstallments: o
    }
  })), t.number_of_weeks && Number(t.number_of_weeks) < Number(o) && (d.number_of_weeks = s._({
    id: "5ZYiKc",
    values: {
      currentSchemeMinimumInstallments: o
    }
  })), t.number_of_installments && Number(t.number_of_installments) < Number(o) && (d.number_of_installments = s._({
    id: "JFGyQW",
    values: {
      currentSchemeMinimumInstallments: o
    }
  })), t.number_of_months && Number(t.number_of_months) < Number(o) && (d.number_of_months = s._({
    id: "iuifCd",
    values: {
      currentSchemeMinimumInstallments: o
    }
  })), t.number_of_quarters && Number(t.number_of_quarters) < Number(o) && (d.number_of_quarters = s._({
    id: "8NKzOT",
    values: {
      currentSchemeMinimumInstallments: o
    }
  })), t.number_of_years && Number(t == null ? void 0 : t.number_of_years) < De(t.frequency.value, Number(o)) && (d.number_of_years = s._({
    id: "joH0Rd",
    values: {
      0: De(t.frequency.value, Number(o))
    }
  })), t.number_of_years && Number(t == null ? void 0 : t.number_of_years) > 100 && (d.number_of_years = s._({
    id: "LJwidR"
  })), t.installments > 1200 && (d.installments = s._({
    id: "kX+aWa"
  })), t.number_of_days > 1200 && (d.number_of_days = s._({
    id: "kX+aWa"
  })), t.number_of_months > 1200 && (d.number_of_months = s._({
    id: "kX+aWa"
  })), t.number_of_quarters > 1200 && (d.number_of_quarters = s._({
    id: "kX+aWa"
  })), t.number_of_weeks > 1200 && (d.number_of_weeks = s._({
    id: "kX+aWa"
  })), t.number_of_installments > 1200 && (d.number_of_installments = s._({
    id: "kX+aWa"
  })), n && (l != null && l.installment_date) && !((F = e == null ? void 0 : e.sip_frequency_specific_data[i]) != null && F.dates.includes(l == null ? void 0 : l.installment_date.getDate())) && (d.frequency = s._({
    id: "S+LyGb"
  })), d;
}, Xt = (t, e, i, n, l, d, o) => {
  const u = {};
  return t.amount && t.amount > Number(e == null ? void 0 : e[Te(l).maxInvestmentAmount]) && (u.amount = s._({
    id: "DJzP4H",
    values: {
      0: T(Number(e == null ? void 0 : e[Te(l).maxInvestmentAmount]))
    }
  })), t.installments && t.installments < Number(d) && (u.installments = s._({
    id: "fY6D71",
    values: {
      nonSipMinimumInstallments: d
    }
  })), n && (o != null && o.installment_date) && !Ve(i).includes(o == null ? void 0 : o.installment_date.getDate()) && (u.frequency = s._({
    id: "S+LyGb"
  })), u;
}, yt = (t, e, i = !0) => Fe(t) ? /* @__PURE__ */ a.jsx("div", {
  className: "mb-2 mt-2",
  children: /* @__PURE__ */ a.jsx(z, {
    type: "error",
    variant: "field",
    title: t.meta.error
  })
}) : /* @__PURE__ */ a.jsx(ue, {
  check: i,
  children: /* @__PURE__ */ a.jsx(z, {
    type: "helper",
    variant: "field",
    title: s._({
      id: "2dlTkQ",
      values: {
        message: e
      }
    })
  })
}), Ht = (t, e, i, n, l) => /* @__PURE__ */ a.jsxs("div", {
  className: "mb-8",
  children: [
    /* @__PURE__ */ a.jsx(Xe, {
      message: `${s._({
        id: "hfWJYZ",
        values: {
          label: t
        }
      })}`
    }),
    /* @__PURE__ */ a.jsx(st, {
      name: e,
      subscription: {
        touched: !0,
        error: !0,
        value: !0
      },
      validate: at(it),
      children: (d) => {
        var o, u, r, g, f, x, j;
        return /* @__PURE__ */ a.jsxs(a.Fragment, {
          children: [
            /* @__PURE__ */ a.jsx(He, {
              id: d.input.name,
              autoComplete: "off",
              name: d.input.name,
              onChange: d.input.onChange,
              placeholder: ft((u = (o = i == null ? void 0 : i.values) == null ? void 0 : o.frequency) == null ? void 0 : u.value, (g = (r = i == null ? void 0 : i.values) == null ? void 0 : r.sip_instl_type) == null ? void 0 : g.value),
              status: Fe(d) ? "error" : void 0,
              onInput: (S) => {
                S.target.value = S.target.value.replace(Ke("0-9"), "");
              },
              inputMode: "numeric",
              autoFocus: !0
            }),
            yt(d, bt(Number((f = n == null ? void 0 : n.sip_frequency_specific_data[l]) == null ? void 0 : f.min_installments), (j = (x = i == null ? void 0 : i.values) == null ? void 0 : x.frequency) == null ? void 0 : j.value, e))
          ]
        });
      }
    })
  ]
});
function xt({ scheme: t, mf_purchase_plan: e, payment: i, handleInvestAgain: n, handleRetry: l, showSchemeName: d, isGroupInvestment: o, isPartner: u, investorProfile: r, isFotOrder: g }) {
  var de;
  let f = e.state.toLowerCase();
  const x = i != null && i.status ? (de = i == null ? void 0 : i.status) == null ? void 0 : de.toLowerCase() : "", [j, S] = b(""), [L, m] = b(""), [F, N] = b(""), [c, $] = b(s._({
    id: "wd9ucl"
  })), [E, U] = b(null), [Q, G] = b(!1), [A, R] = b(!1), { fpapi: W } = Z(ye), { tenantConfig: w, banks: Y } = fe(), B = w == null ? void 0 : w.fallbackLogo, se = Ne.get(), { AppState: { tenant: le } } = Z(ie), [ne, C] = b(s._({
    id: "bjf3K2"
  })), h = Me(() => {
    var k, M, y, _, v, D;
    if (o) {
      const I = (M = (k = w == null ? void 0 : w.fundList) == null ? void 0 : k.find((O) => O.isin === t.isin)) == null ? void 0 : M.nfo;
      return ((y = w == null ? void 0 : w.groupInvestment) == null ? void 0 : y.days) && ((_ = w == null ? void 0 : w.groupInvestment) == null ? void 0 : _.cutOffDays) && Re({
        salaryDay: (v = w == null ? void 0 : w.groupInvestment) == null ? void 0 : v.days[0],
        salaryCutoffDay: (D = w == null ? void 0 : w.groupInvestment) == null ? void 0 : D.cutOffDays[0],
        NFO: I,
        purchaseDate: e == null ? void 0 : e.created_at
      });
    }
  }, [
    t
  ]);
  ce(() => {
    o ? ve() : we();
  }, []), ce(() => {
    _e(A) && C("SIP activates once the investor sets up a payment method and gives consent");
  }, [
    A
  ]);
  const _e = (k) => !!(k && (k.mandate_status.toUpperCase() === "CREATED" || k.mandate_status.toUpperCase() === "RECEIVED")), X = async (k) => {
    var M, y, _;
    if (k)
      try {
        const v = await W.fpClient.mandates().fetch(Number(k)), D = await W.fpClient.bank_accounts().fetchAll({
          profile: r == null ? void 0 : r.id
        }), I = D == null ? void 0 : D.data;
        return I.length ? {
          ...v,
          bank: {
            bank_name: (M = I[0]) == null ? void 0 : M.bank_name,
            number: (y = I[0]) == null ? void 0 : y.account_number,
            ifsc: (_ = I[0]) == null ? void 0 : _.ifsc_code
          }
        } : v;
      } catch (v) {
        window.debug.log(v), oe(v);
      }
  }, ve = () => {
    f === "created" || f === "active" || f === "completed" ? (S("SIP registered"), m(s._({
      id: "u9h4AN",
      values: {
        0: e.id
      }
    })), $(s._({
      id: "quOB9l"
    })), N("successInfo")) : f === "failed" && (S("SIP Failed"), m(s._({
      id: "tNBTdB"
    })), $(s._({
      id: "quOB9l"
    })), N("Error"));
  }, me = async (k) => {
    var O, P;
    let M;
    try {
      M = await W.fpClient.mf_purchases().fetchAll({
        plan: k == null ? void 0 : k.id
      });
    } catch (H) {
      window.debug.log(H), oe(H);
    }
    if (!((O = M == null ? void 0 : M.data) != null && O.length))
      return null;
    let y;
    (P = M == null ? void 0 : M.data) != null && P.length && (y = M.data.filter((H) => H.state === "confirmed" || H.state === "successful" || H.state === "submitted" || H.state === "pending").sort((H, We) => new Date(H.scheduled_on).getTime() - new Date(We.scheduled_on).getTime())[0]);
    const _ = new Date(k == null ? void 0 : k.created_at), v = new Date(new Date(_).setDate(_.getDate() + 1)), D = new Date(new Date(_).setDate(_.getDate() + et(_))), I = new Date(y == null ? void 0 : y.scheduled_on);
    return I.toDateString() === _.toDateString() || I.toDateString() === v.toDateString() || I.toDateString() === D.toDateString() ? y : null;
  }, we = async () => {
    var v;
    G(!0), f = e.state.toLowerCase();
    const k = e == null ? void 0 : e.payment_source, M = await me(e), y = (v = window.location.pathname) == null ? void 0 : v.includes("payment");
    !Le(M) && y ? (U(M), f === "created" || f === "active" || f === "completed" ? (S(s._({
      id: "c6uuOj"
    })), $(s._({
      id: "quOB9l"
    })), m(u ? s._({
      id: "8d5Ob9",
      values: {
        0: e.id
      }
    }) : s._({
      id: "u9h4AN",
      values: {
        0: e.id
      }
    })), N("successInfo")) : f === "failed" && (S(s._({
      id: "uXDSGu"
    })), m(s._({
      id: "tNBTdB"
    })), $(s._({
      id: "quOB9l"
    })), N("Error"))) : f === "created" || f === "active" || f === "completed" ? (S(s._({
      id: "c6uuOj"
    })), $(s._({
      id: "quOB9l"
    })), m(u ? s._({
      id: "8d5Ob9",
      values: {
        0: e.id
      }
    }) : s._({
      id: "u9h4AN",
      values: {
        0: e.id
      }
    })), N("successInfo")) : f === "failed" && (S(s._({
      id: "uXDSGu"
    })), m(s._({
      id: "tNBTdB"
    })), $(s._({
      id: "quOB9l"
    })), N("Error"));
    const _ = await X(k);
    R(_), x === "failed" && $(s._({
      id: "vgDUPm"
    })), G(!1);
  };
  function ge() {
    x === "failed" ? l() : n();
  }
  const je = () => {
    var y, _;
    return [
      {
        label: s._({
          id: "rd49u+"
        }),
        value: `₹ ${T(e.amount)}`
      },
      {
        label: s._({
          id: "UMXPdU"
        }),
        value: u ? (y = xe(e.frequency)) == null ? void 0 : y.replace(/_/g, " ") : Ee(e == null ? void 0 : e.frequency, (_ = Ae(e == null ? void 0 : e.installment_day, !!E)) == null ? void 0 : _.toString())
      },
      e != null && e.start_date ? {
        label: s._({
          id: "NdlT2Q"
        }),
        value: re(V(new Date(e == null ? void 0 : e.start_date), "dd-MMM-yyyy"))
      } : {},
      {
        label: s._({
          id: "ZS3Gyi"
        }),
        value: (e == null ? void 0 : e.number_of_installments) >= 1200 ? "Ongoing until stopped" : e == null ? void 0 : e.number_of_installments
      },
      {
        label: s._({
          id: "cXcPd1"
        }),
        value: e.folio_number ? e.folio_number : "New folio"
      }
    ].filter((v) => {
      var D;
      return ((D = Object.keys(v)) == null ? void 0 : D.length) > 0;
    });
  };
  return Q ? /* @__PURE__ */ a.jsx(be, {
    variant: "fullpage"
  }) : /* @__PURE__ */ a.jsxs(a.Fragment, {
    children: [
      /* @__PURE__ */ a.jsx(K, {
        title: j,
        description: L,
        iconName: F,
        message: ne && u ? /* @__PURE__ */ a.jsx(z, {
          type: "alert",
          variant: "flash",
          title: ne,
          customClass: "mb-5"
        }) : null,
        children: /* @__PURE__ */ a.jsx(ae, {
          title: d && t.name,
          tableData: je()
        })
      }),
      /* @__PURE__ */ a.jsx(ct, {
        mandate: A,
        banks: Y,
        fallbackLogo: B
      }),
      E && !u && /* @__PURE__ */ a.jsxs(a.Fragment, {
        children: [
          /* @__PURE__ */ a.jsx(q, {
            value: s._({
              id: "R2xBSf"
            }),
            background: !0,
            customClass: "mb-4 items-center"
          }),
          /* @__PURE__ */ a.jsx(Be, {
            scheme: t,
            mf_purchase: E,
            isFotOrder: g,
            showSchemeName: d,
            isPartner: u,
            payment: i
          })
        ]
      }),
      _e(A) ? /* @__PURE__ */ a.jsx("div", {
        style: {
          marginBottom: "-4rem"
        },
        children: /* @__PURE__ */ a.jsx(K, {
          title: "Mandate details",
          iconName: "",
          children: /* @__PURE__ */ a.jsx(tt, {
            investorProfile: r,
            mandate_id: e == null ? void 0 : e.payment_source,
            status: [
              ""
            ],
            limit: null,
            isPartner: u
          })
        })
      }) : /* @__PURE__ */ a.jsx(a.Fragment, {}),
      o && /* @__PURE__ */ a.jsx(z, {
        type: "helper",
        variant: "flash",
        title: s._({
          id: "/tWtLh",
          values: {
            0: h != null && h.consideredSalaryDate ? ` ${new Date(h == null ? void 0 : h.consideredSalaryDate).toLocaleString("default", {
              month: "long"
            })} ${new Date(h == null ? void 0 : h.consideredSalaryDate).getFullYear()}` : ""
          }
        }),
        customClass: "mb-5"
      }),
      u && !g && !se && /* @__PURE__ */ a.jsx(te, {
        hyperlink: ee(le) + `/consents?object_type=mf_purchase_plan&action=confirm&id=${e.id}`,
        buttonText: "activation link",
        customClass: "mb-2"
      }),
      u && (g || se) && /* @__PURE__ */ a.jsx(te, {
        hyperlink: ee(le) + `/payment?order_id=${e.id}`,
        buttonText: "activation & payment link",
        customClass: "mb-2"
      }),
      (n || l) && /* @__PURE__ */ a.jsxs("div", {
        className: "flex items-center",
        children: [
          /* @__PURE__ */ a.jsx(p, {
            variant: "secondary",
            fullwidth: !0,
            onClick: () => {
              ge(), Ne.remove();
            },
            customClass: "flex-1 px-6 mb-4",
            children: c
          }),
          o && /* @__PURE__ */ a.jsx(p, {
            variant: "secondary",
            fullwidth: !0,
            onClick: () => {
              window.location.href = window.location.origin + Pe + `/plans-list?isGroupInvestment=true&${pe(window.location.search)}`;
            },
            customClass: "flex-1 ml-2 mb-4",
            children: s._({
              id: "Pk/Rga"
            })
          })
        ]
      })
    ]
  });
}
function _t({ scheme: t, mf_redemption_plan: e, handleRetry: i, handleInvestAgain: n, showSchemeName: l, isPartner: d }) {
  var c;
  const { AppState: { tenant: o } } = Z(ie), u = e.state.toLowerCase(), [r, g] = b(""), [f, x] = b(""), [j, S] = b(""), [L, m] = b(""), [F] = b("Plan will be activated after investor provides consent");
  ce(() => {
    u === "active" || u === "pending" || u === "confirmed" || u === "submitted" || u === "successful" || u === "created" ? (g(s._({
      id: "M0nG+B"
    })), x(d ? s._({
      id: "9RjYo2",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : s._({
      id: "hIAwc2",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), m(s._({
      id: "K7WMZD"
    })), S("successInfo")) : u === "failed" && (g(s._({
      id: "jcgQHg"
    })), x(d ? s._({
      id: "9RjYo2",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : s._({
      id: "hIAwc2",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), m(s._({
      id: "JGhVxc"
    })), S("Error"));
  }, []);
  function N() {
    return u === "failed" ? i : n;
  }
  return /* @__PURE__ */ a.jsxs(a.Fragment, {
    children: [
      /* @__PURE__ */ a.jsx(K, {
        title: r,
        description: f,
        iconName: j,
        children: /* @__PURE__ */ a.jsx(ae, {
          title: l && t.name,
          tableData: [
            {
              label: s._({
                id: "uzz6vZ"
              }),
              value: e != null && e.amount ? `₹ ${T(e.amount)}` : "-"
            },
            {
              label: s._({
                id: "Sgxaf5"
              }),
              value: e.frequency ? `${(c = xe(e.frequency)) == null ? void 0 : c.replace(/_/g, " ")}` : "-"
            },
            {
              label: s._({
                id: "cXcPd1"
              }),
              value: e != null && e.folio_number ? e == null ? void 0 : e.folio_number : "New folio"
            },
            {
              label: s._({
                id: "osv0u0"
              }),
              value: e != null && e.created_at ? `${V(new Date(e.created_at), "dd-MMM-yyyy")}` : "-"
            },
            e != null && e.end_date ? {
              label: s._({
                id: "w3AOkt"
              }),
              value: e != null && e.end_date && (e != null && e.end_date) ? new Date(e == null ? void 0 : e.end_date).getFullYear() >= 2099 ? "Until you stop" : V(new Date(e == null ? void 0 : e.end_date), "dd-MMM-yyyy") : "-"
            } : ""
          ]
        })
      }),
      F && d && /* @__PURE__ */ a.jsx(z, {
        type: "alert",
        variant: "flash",
        title: F,
        customClass: "mb-5"
      }),
      d && /* @__PURE__ */ a.jsx(te, {
        hyperlink: ee(o) + `/consents?object_type=mf_redemption_plan&action=confirm&id=${e == null ? void 0 : e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      (i || n) && /* @__PURE__ */ a.jsxs(p, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: N(),
        children: [
          L,
          " "
        ]
      })
    ]
  });
}
function vt({ scheme: t, mf_redemption: e, handleInvestAgain: i, showSchemeName: n, isPartner: l }) {
  const { AppState: { tenant: d } } = Z(ie), o = e.state.toLowerCase(), [u, r] = b(""), [g, f] = b(""), [x, j] = b(""), [S, L] = b(""), [m] = b("Order will be processed after investor provides consent");
  return ce(() => {
    o === "pending" || o === "confirmed" || o === "submitted" || o === "successful" ? (r(s._({
      id: "9CS0ZY"
    })), f(l ? s._({
      id: "lKqNAW",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : s._({
      id: "/8rqV/",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), L(s._({
      id: "K7WMZD"
    })), j("successInfo")) : o === "failed" && (r(s._({
      id: "4qtFW3"
    })), f(l ? s._({
      id: "FvapA5"
    }) : s._({
      id: "CsBuck"
    })), L(s._({
      id: "K7WMZD"
    })), j("Error"));
  }, []), /* @__PURE__ */ a.jsxs(a.Fragment, {
    children: [
      /* @__PURE__ */ a.jsx(K, {
        title: u,
        description: g,
        iconName: x,
        children: /* @__PURE__ */ a.jsx(ae, {
          title: n && t.name,
          tableData: [
            {
              label: e != null && e.amount ? s._({
                id: "uzz6vZ"
              }) : s._({
                id: "7P4OtI"
              }),
              value: e.amount ? `₹ ${T(e.amount)}` : e.units ? e.units : "-"
            },
            {
              label: s._({
                id: "cXcPd1"
              }),
              value: e != null && e.folio_number ? e == null ? void 0 : e.folio_number : "New folio"
            },
            {
              label: s._({
                id: "1/YYB1"
              }),
              value: e.created_at ? V(new Date(e.created_at), "dd-MMM-yyyy") : "-"
            }
          ]
        })
      }),
      m && l && /* @__PURE__ */ a.jsx(z, {
        type: "alert",
        variant: "flash",
        title: m,
        customClass: "mb-5"
      }),
      l && /* @__PURE__ */ a.jsx(te, {
        hyperlink: ee(d) + `/consents?object_type=mf_redemption&action=confirm&id=${e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      i && /* @__PURE__ */ a.jsx(p, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: i,
        children: S
      })
    ]
  });
}
function mt({ scheme: t, mf_switch: e, handleInvestAgain: i, showSchemeName: n, isPartner: l }) {
  const { AppState: { tenant: d } } = Z(ie), o = e.state.toLowerCase(), [u, r] = b(""), [g, f] = b(""), [x, j] = b(""), [S, L] = b(!1), [m, F] = b(""), { fpapi: N } = Z(ye), [c, $] = b({}), { tenantConfig: E, amcs: U } = fe(), Q = E == null ? void 0 : E.fallbackLogo, G = E == null ? void 0 : E.showSchemeLogo, [A] = b("Order will be processed after investor provides consent"), R = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  return Se(() => {
    async function W() {
      L(!0);
      try {
        const w = await N.fpClient.master_data().fetchScheme(e.switch_in_scheme), Y = await N.fpClient.master_data().fetchScheme(e.switch_out_scheme), B = G && U[Y == null ? void 0 : Y.amc_id] || Q;
        $({
          ...e,
          schemeLogo: B,
          switch_in_scheme_name: w.name,
          switch_out_scheme_name: Y.name
        }), o === "pending" || o === "confirmed" || o === "submitted" || o === "successful" ? (r(s._({
          id: "9CS0ZY"
        })), f(l ? s._({
          id: "QZntO/",
          values: {
            0: e == null ? void 0 : e.id
          }
        }) : s._({
          id: "7q2PJy",
          values: {
            0: e == null ? void 0 : e.id
          }
        })), F(s._({
          id: "K7WMZD"
        })), j("successInfo")) : o === "failed" && (r(s._({
          id: "4qtFW3"
        })), f(s._({
          id: "h3ZVI0"
        })), F(s._({
          id: "Oem4yT"
        })), j("Error")), L(!1);
      } catch (w) {
        r(s._({
          id: "LnNz4Q"
        })), f(s._({
          id: "F05jOJ"
        })), F(s._({
          id: "Oem4yT"
        })), j("Error"), oe(w);
      }
    }
    W();
  }, [
    N,
    e,
    o
  ]), S ? /* @__PURE__ */ a.jsx(be, {
    variant: "fullpage"
  }) : /* @__PURE__ */ a.jsxs(a.Fragment, {
    children: [
      /* @__PURE__ */ a.jsx(K, {
        title: u,
        description: g,
        iconName: x,
        children: /* @__PURE__ */ a.jsx(ae, {
          title: n && t.name,
          tableData: [
            {
              label: s._({
                id: "6Xqu8f"
              }),
              value: c != null && c.switch_out_scheme_name ? /* @__PURE__ */ a.jsx(a.Fragment, {
                children: /* @__PURE__ */ a.jsxs("div", {
                  className: "mb-2 flex",
                  children: [
                    /* @__PURE__ */ a.jsx("img", {
                      src: c == null ? void 0 : c.schemeLogo,
                      alt: "logo",
                      className: R
                    }),
                    /* @__PURE__ */ a.jsxs("div", {
                      className: "flex flex-col",
                      children: [
                        /* @__PURE__ */ a.jsx("div", {
                          children: `${$e(c.switch_out_scheme_name)}`
                        }),
                        /* @__PURE__ */ a.jsx("div", {
                          className: "text-primary-N40 text-size-12 block pr-1",
                          children: t.investment_option && qe(t.investment_option)
                        })
                      ]
                    })
                  ]
                })
              }) : "-",
              colSpan: "basis-full"
            },
            {
              label: c.amount ? s._({
                id: "TSMEuV"
              }) : s._({
                id: "hKtDqN"
              }),
              value: c.amount ? `₹ ${T(c.amount)}` : c.units ? c.units : "-"
            },
            {
              label: s._({
                id: "cXcPd1"
              }),
              value: c.folio_number ? c.folio_number : "New folio"
            }
          ]
        })
      }),
      A && l && /* @__PURE__ */ a.jsx(z, {
        type: "alert",
        variant: "flash",
        title: A,
        customClass: "mb-5"
      }),
      l && /* @__PURE__ */ a.jsx(te, {
        hyperlink: ee(d) + `/consents?object_type=mf_switch&action=confirm&id=${e == null ? void 0 : e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      i && /* @__PURE__ */ a.jsx(p, {
        variant: "secondary",
        fullwidth: !0,
        onClick: i,
        customClass: "mt-2 mb-4",
        children: m
      })
    ]
  });
}
function wt({ scheme: t, mf_switch: e, handleInvestAgain: i, showSchemeName: n, isPartner: l }) {
  var W;
  const { AppState: { tenant: d } } = Z(ie), o = e.state.toLowerCase(), [u, r] = b(""), [g, f] = b(""), [x, j] = b(""), [S, L] = b(!1), [m, F] = b(""), { fpapi: N } = Z(ye), [c, $] = b({}), { tenantConfig: E, amcs: U } = fe(), Q = E == null ? void 0 : E.fallbackLogo, G = E == null ? void 0 : E.showSchemeLogo, [A] = b("Plan will be activated after investor provides consent"), R = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  return Se(() => {
    async function w() {
      L(!0);
      try {
        const Y = await N.fpClient.master_data().fetchScheme(e.switch_in_scheme), B = await N.fpClient.master_data().fetchScheme(e.switch_out_scheme), se = G && U[B == null ? void 0 : B.amc_id] || Q;
        $({
          ...e,
          schemeLogo: se,
          switch_in_scheme_name: Y.name,
          switch_out_scheme_name: B.name
        }), o === "active" || o === "created" || o === "pending" || o === "confirmed" || o === "submitted" || o === "successful" ? (r(s._({
          id: "PAOIq9"
        })), f(s._({
          id: "1DWHoM",
          values: {
            0: e == null ? void 0 : e.id
          }
        })), F(s._({
          id: "K7WMZD"
        })), j("successInfo")) : o === "failed" && (r(s._({
          id: "rP1ZG1"
        })), f(s._({
          id: "jLiWEA"
        })), F(s._({
          id: "Oem4yT"
        })), j("Error")), L(!1);
      } catch {
        r(s._({
          id: "LnNz4Q"
        })), f(s._({
          id: "F05jOJ"
        })), F(s._({
          id: "Oem4yT"
        })), j("Error");
      }
    }
    w();
  }, [
    N,
    e,
    o
  ]), S ? /* @__PURE__ */ a.jsx(be, {
    variant: "fullpage"
  }) : /* @__PURE__ */ a.jsxs(a.Fragment, {
    children: [
      /* @__PURE__ */ a.jsx(K, {
        title: u,
        description: g,
        iconName: x,
        children: /* @__PURE__ */ a.jsx(ae, {
          title: n && t.name,
          tableData: [
            {
              label: s._({
                id: "6Xqu8f"
              }),
              value: c != null && c.switch_out_scheme_name ? /* @__PURE__ */ a.jsx(a.Fragment, {
                children: /* @__PURE__ */ a.jsxs("div", {
                  className: "flex",
                  children: [
                    /* @__PURE__ */ a.jsx("img", {
                      src: c == null ? void 0 : c.schemeLogo,
                      alt: "logo",
                      className: R
                    }),
                    /* @__PURE__ */ a.jsxs("div", {
                      className: "flex flex-col",
                      children: [
                        /* @__PURE__ */ a.jsx("div", {
                          children: `${$e(c.switch_out_scheme_name)}`
                        }),
                        /* @__PURE__ */ a.jsx("div", {
                          className: "text-primary-N40 text-size-12 block pr-1",
                          children: t.investment_option && qe(t.investment_option)
                        })
                      ]
                    })
                  ]
                })
              }) : "-",
              colSpan: "basis-full"
            },
            {
              label: s._({
                id: "TSMEuV"
              }),
              value: c.amount ? `₹ ${T(c.amount)}` : "-"
            },
            {
              label: s._({
                id: "12iDzS"
              }),
              value: c.frequency ? (W = xe(c.frequency)) == null ? void 0 : W.replace(/_/g, " ") : "-"
            },
            {
              label: s._({
                id: "cXcPd1"
              }),
              value: c.folio_number ? c.folio_number : "New folio"
            },
            c.end_date ? {
              label: s._({
                id: "w3AOkt"
              }),
              value: c.end_date && (c != null && c.end_date) ? new Date(c.end_date).getFullYear() >= 2099 ? "Until you stop" : V(new Date(c.end_date), "dd-MMM-yyyy") : "-"
            } : ""
          ]
        })
      }),
      A && l && /* @__PURE__ */ a.jsx(z, {
        type: "alert",
        variant: "flash",
        title: A,
        customClass: "mb-5"
      }),
      l && /* @__PURE__ */ a.jsx(te, {
        hyperlink: ee(d) + `/consents?object_type=mf_switch_plan&action=confirm&id=${e == null ? void 0 : e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      i ? /* @__PURE__ */ a.jsx(p, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: i,
        children: m
      }) : /* @__PURE__ */ a.jsx(a.Fragment, {})
    ]
  });
}
function zt({ investorProfile: t, scheme: e, mf_order: i, payment: n, showSchemeName: l, isGroupInvestment: d, handleInvestAgain: o, handleRetry: u, isPartner: r, isFotOrder: g }) {
  const f = i == null ? void 0 : i.object;
  function x() {
    switch (f) {
      case "mf_purchase":
        return /* @__PURE__ */ a.jsx(Be, {
          investorProfile: t,
          scheme: e,
          mf_purchase: i,
          payment: n,
          handleInvestAgain: o,
          handleRetry: u,
          showSchemeName: l,
          isGroupInvestment: d,
          isPartner: r
        });
      case "mf_switch":
        return /* @__PURE__ */ a.jsx(mt, {
          scheme: e,
          mf_switch: i,
          handleInvestAgain: o,
          showSchemeName: l,
          isPartner: r
        });
      case "mf_redemption":
        return /* @__PURE__ */ a.jsx(vt, {
          scheme: e,
          mf_redemption: i,
          handleInvestAgain: o,
          showSchemeName: l,
          isPartner: r
        });
      case "mf_purchase_plan":
        return /* @__PURE__ */ a.jsx(xt, {
          scheme: e,
          mf_purchase_plan: i,
          payment: n,
          handleInvestAgain: o,
          handleRetry: u,
          showSchemeName: l,
          isGroupInvestment: d,
          isPartner: r,
          investorProfile: t,
          isFotOrder: g
        });
      case "mf_switch_plan":
        return /* @__PURE__ */ a.jsx(wt, {
          scheme: e,
          mf_switch: i,
          handleInvestAgain: o,
          showSchemeName: l,
          isPartner: r
        });
      case "mf_redemption_plan":
        return /* @__PURE__ */ a.jsx(_t, {
          scheme: e,
          mf_redemption_plan: i,
          handleInvestAgain: o,
          handleRetry: u,
          showSchemeName: l,
          isPartner: r
        });
      default:
        return /* @__PURE__ */ a.jsx(a.Fragment, {
          children: /* @__PURE__ */ a.jsx(K, {
            title: s._({
              id: "1nq6/U"
            }),
            description: s._({
              id: "AHVSVV"
            }),
            iconName: "Alert"
          })
        });
    }
  }
  return x();
}
export {
  ct as M,
  zt as O,
  yt as a,
  Ut as b,
  Ht as c,
  At as d,
  ht as e,
  Yt as f,
  Wt as g,
  Ae as h,
  Xt as i,
  qt as r,
  Bt as s
};
