import { useContext as g, useState as i, useEffect as M } from "react";
import { F as x } from "./provider-DZPLM8yJ.js";
const h = ({ user: t }) => {
  const { fpapi: n } = g(x), [c, d] = i(!1), [l, m] = i([]), [p, u] = i([]);
  return M(() => {
    (async () => {
      var r, f;
      const o = t == null ? void 0 : t.username;
      if (!o || (r = t.annexure) != null && r.isonboarded && ((f = t.annexure) == null ? void 0 : f.isonboarded) === "true")
        return;
      const { data: e } = await n.fpClient.mf_investment_accounts().fetchAll({
        investor: o.toUpperCase()
      });
      if (!e.length)
        return;
      m(e);
      const a = e[0], { data: s } = await n.fpClient.mf_folios().fetchAll({
        mf_investment_account: a == null ? void 0 : a.id
      });
      s.length && (u(s), d(!0));
    })();
  }, []), {
    isMigratedUser: c,
    migratedMfiaList: l,
    migratedFolioList: p
  };
};
export {
  h as u
};
