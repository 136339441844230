import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as f, useState as r, useLayoutEffect as L, useEffect as E } from "react";
import { AppContext as y } from "./AppContext.js";
import { F } from "./provider-DZPLM8yJ.js";
import { LoginContext as M } from "./LoginContext.js";
import { P as I, a as R } from "./index.esm-BV4iWo1a.js";
import { r as W } from "./sentry-ZQO5C1U6.js";
import { i as T } from "./utils-BTh-HRzb.js";
import { i as n } from "./index-B3vUTrRT.js";
import { W as b } from "./Welcome-3PCWRbTF.js";
import { M as k } from "./MandateListing-BxVtIJW-.js";
import { ErrorPage as O } from "./ErrorPage.js";
import { A as U } from "./Auth-CjS0P6BG.js";
function X({ mandate_id: d }) {
  const { fpapi: i } = f(F), { loggedInUser: e } = f(M), { AppState: { showWelcomePage: m }, setShowWelcomePage: u } = f(y), [g, c] = r(l), [x, h] = r({}), [w, j] = r({}), [v, s] = r(!1), P = [
    {
      step: 1,
      content: n._({
        id: "wtcwqs"
      })
    },
    {
      step: 2,
      content: n._({
        id: "d4wYsU"
      })
    },
    {
      step: 3,
      content: n._({
        id: "xU5OS9"
      })
    },
    {
      step: 4,
      content: n._({
        id: "ltQaxv"
      })
    },
    {
      step: 5,
      content: n._({
        id: "k4RKPZ"
      })
    }
  ];
  L(() => {
    var a;
    i && e && (s(!0), i.getInvestorProfile((a = e == null ? void 0 : e.profile) == null ? void 0 : a.fp_identifier).then((o) => {
      o && (h(o), i.fpClient.mf_investment_accounts().fetchAll({
        investor: o.pan
      }).then((S) => {
        const p = S.data.filter((C) => C.primary_investor === e.profile.fp_identifier);
        if (!p.length) {
          s(!1);
          return;
        }
        j(p[0]), s(!1);
      }));
    }).catch((o) => {
      s(!1), W(o);
    }));
  }, []), E(() => {
    c(l());
  }, [
    m
  ]);
  function l() {
    return m ? "welcome" : "mandate";
  }
  function _(a) {
    u(!1), c("mandate");
  }
  function A() {
    return g === "welcome" ? /* @__PURE__ */ t.jsx(t.Fragment, {
      children: /* @__PURE__ */ t.jsx(b, {
        onAck: _,
        welcomeSteps: P
      })
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: v ? /* @__PURE__ */ t.jsx(R, {
        variant: "fullpage"
      }) : e ? T(w) ? /* @__PURE__ */ t.jsx(O, {
        title: "No mandates found",
        description: "You have not setup any mandates",
        showDetails: !1
      }) : /* @__PURE__ */ t.jsx(k, {
        investorProfile: x,
        mandate_id: d,
        status: [
          ""
        ],
        limit: null,
        isPartner: !1
      }) : /* @__PURE__ */ t.jsx(U, {
        redirectURL: window.location.href,
        allowRegistration: !1,
        showTestPan: {
          onboarded: !0
        }
      })
    });
  }
  return /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(I, {
        title: n._({
          id: "4h1gSz"
        })
      }),
      A()
    ]
  });
}
export {
  X as ManageMandates
};
