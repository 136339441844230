import { j as i } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as is, useState as n, useRef as Us, useMemo as As, useEffect as ms } from "react";
import { a as ls, S as ps, c as D, e as P, j as $s, T as Ss, M as H, k as Fs, F as Ns, B as ts, b as Rs, u as Vs, R as Ts, O as Bs } from "./index.esm-BV4iWo1a.js";
import { F as _s } from "./provider-DZPLM8yJ.js";
import { f as r, r as O } from "./validators-BArvL7cB.js";
import { u as es } from "./useConfiguration-B-lyaBB9.js";
import { a6 as Ks, C as V, j as fs, a7 as Xs, E as gs, a5 as Ms, o as us, a8 as Hs, b as ys, P as ks, u as Ls, i as Ws, k as Zs, m as hs, a9 as qs, d as zs, e as Ys } from "./utils-BTh-HRzb.js";
import { R as Cs, F as ss } from "./react-final-form.es-DAN9JLvE.js";
import { r as G } from "./sentry-ZQO5C1U6.js";
import { i as o } from "./index-B3vUTrRT.js";
import { M as Js } from "./MandateSelector-FCOUQdtt.js";
import { LoginContext as Es } from "./LoginContext.js";
import { ErrorPage as Ds } from "./ErrorPage.js";
import { P as ns } from "./Product-DewIFE9c.js";
import { M as Gs, m as ds } from "./MandateListing-BxVtIJW-.js";
import { S as Is } from "./StatusMessage-DFmLnokA.js";
function fi({ currentPlanObject: s, editField: A, handleEditCompletion: C, isPartner: W, assistedWorkflowPAN: z }) {
  var h;
  const { loggedInUser: B } = is(Es), [E, R] = n(!1), [L, T] = n("initial"), { tenantConfig: w, amcs: F } = es(), I = w == null ? void 0 : w.fallbackLogo, Y = w == null ? void 0 : w.showSchemeLogo, [S, K] = n({
    name: "",
    investment_option: "",
    minInitialAmount: 0,
    maxInitialAmount: 0,
    schemeLogo: ""
  }), { fpapi: u } = is(_s), [x, y] = n({}), U = ((h = w == null ? void 0 : w.app_constants) == null ? void 0 : h.non_systematic_min_installments) || 3, _ = /* @__PURE__ */ new Date(), [v, M] = n(null), [as, os] = n(null), [k, Z] = n(!1), N = Us(null), p = {
    amount: {
      label: o._({
        id: "Dh9Eyz"
      }),
      value: "amount"
    },
    payment_method: {
      label: o._({
        id: "1sWe0g"
      }),
      value: "payment_method"
    },
    installment_day: {
      label: o._({
        id: "tnH8DU"
      }),
      value: "installment_day"
    },
    no_of_installment: {
      label: o._({
        id: "6wLMYD"
      }),
      value: "number_of_installments"
    }
  };
  s != null && s.systematic && (delete p.installment_day, delete p.no_of_installment), (s == null ? void 0 : s.object) !== "mf_purchase_plan" && delete p.payment_method, (s == null ? void 0 : s.frequency) === "daily" && p != null && p.installment_day && delete p.installment_day, A != null && A.length && Object.keys(p).forEach((l) => {
    A != null && A.includes(l) || delete p[l];
  });
  const g = As(() => ({
    edit_options: p[Object.keys(p)[0]],
    edit_start_date: new Date(_).setDate(_.getDate() + 2),
    edit_max_start_date: new Date(_).setDate(_.getDate() + 30),
    edit_allowed_dates: Ks(s == null ? void 0 : s.frequency).filter((l) => l !== (s == null ? void 0 : s.installment_day)),
    mandate: v
  }), [
    v
  ]);
  ms(() => {
    c(), Q();
  }, [
    s
  ]);
  const c = async () => {
    const l = (s == null ? void 0 : s.object) === "mf_switch_plan" ? s == null ? void 0 : s.switch_in_scheme : s == null ? void 0 : s.scheme;
    if (l) {
      let a;
      try {
        a = await u.fpClient.master_data().fetchScheme(l);
      } catch (j) {
        window.debug.error(j), G(j);
      }
      let f;
      (s == null ? void 0 : s.object) === "mf_purchase_plan" ? f = a == null ? void 0 : a.sip_frequency_specific_data : (s == null ? void 0 : s.object) === "mf_redemption_plan" ? f = a == null ? void 0 : a.swp_frequency_specific_data : (s == null ? void 0 : s.object) === "mf_switch_plan" && (f = a == null ? void 0 : a.stp_frequency_specific_data), y(f);
      const q = Y ? {
        name: a == null ? void 0 : a.name,
        investment_option: a == null ? void 0 : a.investment_option,
        minInitialAmount: a == null ? void 0 : a.min_initial_investment,
        maxInitialAmount: a == null ? void 0 : a.max_initial_investment,
        schemeLogo: F[a == null ? void 0 : a.amc_id] || I
      } : {
        name: a == null ? void 0 : a.name,
        investment_option: a == null ? void 0 : a.investment_option,
        minInitialAmount: a == null ? void 0 : a.min_initial_investment,
        maxInitialAmount: a == null ? void 0 : a.max_initial_investment
      };
      K(q);
    }
  }, Q = async () => {
    var l, a, f;
    if ((s == null ? void 0 : s.object) === "mf_purchase_plan")
      try {
        R(!0);
        let q = await u.getInvestorProfile((l = B == null ? void 0 : B.profile) == null ? void 0 : l.fp_identifier);
        if (W) {
          const X = await ((a = u == null ? void 0 : u.fpClient.investor_profiles()) == null ? void 0 : a.fetchAll({
            pan: z
          }));
          q = (f = X == null ? void 0 : X.data) == null ? void 0 : f[0];
        }
        os(q);
        const j = await u.fpClient.bank_accounts().fetchAll({
          profile: q == null ? void 0 : q.id
        }), $ = j.data.map((X) => X.old_id), d = (await u.fpClient.mandates().fetchAll({
          bank_account_id: $.join(","),
          size: 100
        })).mandates;
        if (d.sort((X, ws) => Number(new Date(ws.created_at)) - Number(new Date(X.created_at))), d.length > 0) {
          j.data.forEach((J) => {
            d.forEach((xs) => {
              xs.bank_account_id === J.old_id && (xs.bank = {
                bank_name: J.bank_name,
                number: J.account_number,
                ifsc: J.ifsc_code
              });
            });
          });
          const X = [], ws = [];
          if (d.forEach((J) => {
            J.mandate_status === "CREATED" || J.mandate_status === "SUBMITTED" ? X.push(J) : J.mandate_status === "APPROVED" && ws.push(J);
          }), (s == null ? void 0 : s.payment_method) === "mandate" && (s != null && s.payment_source)) {
            const J = d.find((xs) => xs.id.toString() === s.payment_source);
            M(J);
          }
          R(!1);
        } else
          R(!1);
      } catch (q) {
        window.debug.error(q), G(q);
      }
  }, b = async ({ editType: l, amount: a, installment_day: f, installments: q, mandate: j }) => {
    R(!0);
    try {
      let $ = {}, t = {}, d = {};
      l.value === "amount" ? d = {
        id: s == null ? void 0 : s.id,
        amount: a
      } : l.value === "installment_day" ? d = {
        id: s == null ? void 0 : s.id,
        installment_day: new Date(f).getDate()
      } : l.value === "number_of_installments" ? d = {
        id: s == null ? void 0 : s.id,
        number_of_installments: q
      } : l.value === "payment_method" && (d = {
        id: s == null ? void 0 : s.id,
        payment_method: "mandate",
        payment_source: j.id
      }), (s == null ? void 0 : s.object) === "mf_purchase_plan" ? $ = await u.fpClient.mf_purchase_plans().update(d) : (s == null ? void 0 : s.object) === "mf_redemption_plan" ? $ = await u.fpClient.mf_redemption_plans().update(d) : (s == null ? void 0 : s.object) === "mf_switch_plan" && ($ = await u.fpClient.mf_switch_plans().update(d)), l.value === "amount" ? t = s.amount ? {
        ...$,
        previousAmount: s.amount
      } : {
        ...$,
        previousUnits: s.units
      } : l.value === "installment_day" ? t = {
        ...$,
        previousInstallmentDay: s.installment_day
      } : l.value === "number_of_installments" ? t = {
        ...$,
        previousInstallments: s.number_of_installments
      } : l.value === "payment_method" && (t = {
        ...$,
        previousPaymentMethod: s.payment_method || "not_setup",
        previouosPaymentSource: s.payment_source
      }), C(t), R(!1);
    } catch ($) {
      R(!1), T("error"), G($);
    }
  };
  function e() {
    var l;
    return /* @__PURE__ */ i.jsxs("div", {
      className: "pb-6",
      children: [
        /* @__PURE__ */ i.jsx(ns, {
          scheme: S
        }),
        /* @__PURE__ */ i.jsx(ps, {
          children: /* @__PURE__ */ i.jsxs("div", {
            className: "flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ i.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ i.jsx(D, {
                  label: s != null && s.amount ? o._({
                    id: "7sZGpa"
                  }) : o._({
                    id: "2pa2q1"
                  }),
                  value: s != null && s.amount ? `₹ ${V(s == null ? void 0 : s.amount, 2)}` : s != null && s.units ? s.units : "-"
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ i.jsx(D, {
                  label: o._({
                    id: "UMXPdU"
                  }),
                  value: s && s.frequency ? (l = fs(s.frequency)) == null ? void 0 : l.replace(/_/g, " ") : "-"
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ i.jsx(Cs, {
          onSubmit: b,
          validate: (a) => {
            const f = {};
            return s != null && s.systematic && a.amount && a.amount > x[s == null ? void 0 : s.frequency].max_installment_amount && (f.amount = o._({
              id: "5K7M8h",
              values: {
                0: V(x[s == null ? void 0 : s.frequency].max_installment_amount)
              }
            })), s != null && s.systematic && a.amount && a.amount < x[s == null ? void 0 : s.frequency].min_installment_amount && (f.amount = o._({
              id: "AiK/vj",
              values: {
                0: V(x[s == null ? void 0 : s.frequency].min_installment_amount)
              }
            })), s != null && s.systematic && a.amount && x[s == null ? void 0 : s.frequency].amount_multiples && Xs(a.amount.toString(), x[s == null ? void 0 : s.frequency].amount_multiples.toString()) !== 0 && (f.amount = o._({
              id: "aXWCv/",
              values: {
                0: x[s == null ? void 0 : s.frequency].amount_multiples
              }
            })), !(s != null && s.systematic) && a.amount && a.amount > (S == null ? void 0 : S.maxInitialAmount) && (f.amount = o._({
              id: "5K7M8h",
              values: {
                0: V(S == null ? void 0 : S.maxInitialAmount)
              }
            })), !(s != null && s.systematic) && a.amount && a.amount < (S == null ? void 0 : S.minInitialAmount) && (f.amount = o._({
              id: "AiK/vj",
              values: {
                0: V(S == null ? void 0 : S.minInitialAmount)
              }
            })), !(s != null && s.systematic) && a.installments && a.installments < U && (f.installments = o._({
              id: "0GGsEN",
              values: {
                nonSipMinimumInstallments: U
              }
            })), !(s != null && s.systematic) && a.installments && a.installments > 1200 && (f.installments = o._({
              id: "j7AzBN"
            })), (s == null ? void 0 : s.object) === "mf_purchase_plan" && (s != null && s.payment_source) && a.amount && a.amount > (v == null ? void 0 : v.mandate_limit) && (f.amount = o._({
              id: "E5Y5Dh",
              values: {
                0: V(v == null ? void 0 : v.mandate_limit)
              }
            })), (s == null ? void 0 : s.object) === "mf_purchase_plan" && v && a.mandate && a.mandate.id === v.id && (f.mandate = o._({
              id: "Cp20Hl"
            })), f;
          },
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (a) => {
            var f, q, j, $;
            return /* @__PURE__ */ i.jsxs("form", {
              id: "editOrderForm",
              onSubmit: a.handleSubmit,
              children: [
                /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(P, {
                      message: o._({
                        id: "5TUqdC"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(ss, {
                      name: "editType",
                      initialValue: g.edit_options,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: r(O),
                      children: (t) => /* @__PURE__ */ i.jsx($s, {
                        name: t.input.name,
                        value: t.input.value,
                        onChange: (d) => {
                          t.input.onChange(d);
                        },
                        options: Object.values(p)
                      })
                    })
                  ]
                }),
                ((f = a.values.editType) == null ? void 0 : f.value) === "amount" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(P, {
                      message: o._({
                        id: "OFH0cI"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(ss, {
                      name: "amount",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: r(O),
                      children: (t) => {
                        var d;
                        return /* @__PURE__ */ i.jsxs(i.Fragment, {
                          children: [
                            /* @__PURE__ */ i.jsx(Ss, {
                              id: t.input.name,
                              autoComplete: "off",
                              type: "text",
                              name: t.input.name,
                              value: gs(t.input.value),
                              onChange: (X) => t.input.onChange(Ms(X.target.value)),
                              onInput: (X) => {
                                X.target.value = X.target.value.replace(us("0-9"), "");
                              },
                              placeholder: o._({
                                id: "fK0Zpc"
                              }),
                              prefix: "₹",
                              status: t.meta.error && t.meta.touched ? "error" : void 0,
                              autoFocus: !0
                            }),
                            t.meta.error && t.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                              className: "mb-2 mt-2",
                              children: /* @__PURE__ */ i.jsx(H, {
                                type: "error",
                                variant: "field",
                                title: t.meta.error
                              })
                            }) : /* @__PURE__ */ i.jsx(H, {
                              type: "helper",
                              variant: "field",
                              title: o._({
                                id: "9v2zaG",
                                values: {
                                  0: s != null && s.systematic ? `${V(Number((d = x[s == null ? void 0 : s.frequency]) == null ? void 0 : d.min_installment_amount))}` : `${V(Number(S == null ? void 0 : S.minInitialAmount))}`
                                }
                              })
                            })
                          ]
                        });
                      }
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                ((q = a.values.editType) == null ? void 0 : q.value) === "payment_method" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(P, {
                      message: o._({
                        id: "lFCwug"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(ss, {
                      name: "mandate",
                      initialValue: g.mandate,
                      validate: r(O),
                      children: (t) => (N.current = t, /* @__PURE__ */ i.jsxs(i.Fragment, {
                        children: [
                          /* @__PURE__ */ i.jsx(Js, {
                            mandate: t.input.value,
                            handleMandateModalChange: (d) => {
                              d.preventDefault(), Z(!k);
                            }
                          }),
                          t.meta.error && t.meta.touched && /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(H, {
                              type: "error",
                              variant: "field",
                              title: t.meta.error
                            })
                          })
                        ]
                      }))
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                ((j = a.values.editType) == null ? void 0 : j.value) === "installment_day" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(P, {
                      message: o._({
                        id: "VXfUoN"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(ss, {
                      name: "installment_day",
                      initialValue: g.edit_start_date,
                      validate: r(O),
                      children: (t) => /* @__PURE__ */ i.jsx(i.Fragment, {
                        children: /* @__PURE__ */ i.jsx(Fs, {
                          variant: "keyboardInputDOB",
                          onKeyDown: (d) => d.preventDefault(),
                          disableFuture: !1,
                          minDate: new Date(g.edit_start_date),
                          maxDate: new Date(g.edit_max_start_date),
                          shouldDisableDate: (d) => Hs(d, g.edit_allowed_dates),
                          name: t.input.name,
                          value: t.input.value,
                          format: "dd-MMM-yyyy",
                          selectedDate: t.input.value || null,
                          error: t.meta.error && t.meta.touched,
                          views: [
                            "date"
                          ],
                          handleDateChange: (d) => {
                            t.input.onChange(d);
                          }
                        })
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                (($ = a.values.editType) == null ? void 0 : $.value) === "number_of_installments" ? /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(P, {
                      message: o._({
                        id: "kGmYAl"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(ss, {
                      name: "installments",
                      validate: r(O),
                      children: (t) => /* @__PURE__ */ i.jsxs(i.Fragment, {
                        children: [
                          /* @__PURE__ */ i.jsx(Ss, {
                            id: t.input.name,
                            autoComplete: "off",
                            type: "text",
                            name: t.input.name,
                            value: gs(t.input.value),
                            onChange: (d) => t.input.onChange(Ms(d.target.value)),
                            onInput: (d) => {
                              d.target.value = d.target.value.replace(us("0-9"), "");
                            },
                            placeholder: o._({
                              id: "3Oj1cs"
                            }),
                            status: t.meta.error && t.meta.touched ? "error" : void 0
                          }),
                          t.meta.error && t.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(H, {
                              type: "error",
                              variant: "field",
                              title: t.meta.error
                            })
                          }) : /* @__PURE__ */ i.jsx(H, {
                            type: "helper",
                            variant: "field",
                            title: o._({
                              id: "C40jmw",
                              values: {
                                0: V(Number(U))
                              }
                            })
                          })
                        ]
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                /* @__PURE__ */ i.jsx(Ns, {
                  children: /* @__PURE__ */ i.jsx("div", {
                    className: "mb-4 pt-1",
                    children: /* @__PURE__ */ i.jsx(ts, {
                      variant: "primary",
                      type: "submit",
                      fullwidth: !0,
                      children: o._({
                        id: "H/E6fZ"
                      })
                    })
                  })
                })
              ]
            });
          }
        }),
        /* @__PURE__ */ i.jsx(Rs, {
          variant: "fullPage",
          title: "Select mandate",
          isOpen: k,
          toggleModal: () => {
            Z(!1);
          },
          contentHeightMax: !0,
          children: /* @__PURE__ */ i.jsx(Gs, {
            investorProfile: as,
            handleMandateSelection: (a) => {
              Z(!1), N.current.input.onChange(a);
            },
            isPartner: W
          })
        }),
        /* @__PURE__ */ i.jsx(H, {
          type: "important",
          variant: "spot",
          content: o._({
            id: "24gA27"
          }),
          customClass: "mb-5"
        })
      ]
    });
  }
  function m() {
    switch (L) {
      default:
        return e();
      case "initial":
        return e();
      case "error":
        return /* @__PURE__ */ i.jsx(Ds, {
          title: o._({
            id: "rz8jSA"
          }),
          description: o._({
            id: "RxIw8D"
          })
        });
    }
  }
  return E ? /* @__PURE__ */ i.jsx(ls, {
    variant: "fullpage"
  }) : m();
}
function ni({ editOrder: s, handleGoToPlansList: A }) {
  const { loggedInUser: C } = is(Es), W = s == null ? void 0 : s.state.toLowerCase(), [z, B] = n(""), [E, R] = n(""), [L, T] = n(""), { fpapi: w } = is(_s), { tenantConfig: F, amcs: I, banks: Y } = es(), S = F == null ? void 0 : F.fallbackLogo, K = F == null ? void 0 : F.showSchemeLogo, [u, x] = n({}), [y, U] = n(!1), [_, v] = n(null), [M, as] = n(null), os = async () => {
    U(!0);
    const p = (s == null ? void 0 : s.scheme) || (s == null ? void 0 : s.switch_in_scheme);
    w.fpClient.master_data().fetchScheme(p).then((g) => {
      k(g);
    }).catch((g) => {
      window.debug.log(g), G(g);
    });
  };
  function k(p) {
    if (!p)
      x([]);
    else {
      const g = K ? {
        ...p,
        schemeLogo: I[p == null ? void 0 : p.amc_id] || S
      } : {
        ...p
      };
      x(g);
    }
    U(!1);
  }
  ms(() => {
    W === "active" && (B(`${ys(s == null ? void 0 : s.id)} updated`), R("successInfo")), T(o._({
      id: "d9gGIK"
    })), os(), Z();
  }, []);
  const Z = async () => {
    var p;
    if (s != null && s.previousPaymentMethod)
      try {
        U(!0);
        const g = [], c = w.fpClient.mandates().fetch(s == null ? void 0 : s.payment_source);
        if (g.push(c), s != null && s.previouosPaymentSource && (s == null ? void 0 : s.previousPaymentMethod) === "mandate") {
          const m = w.fpClient.mandates().fetch(s.previouosPaymentSource);
          g.push(m);
        }
        const Q = await w.getInvestorProfile((p = C == null ? void 0 : C.profile) == null ? void 0 : p.fp_identifier), b = await w.fpClient.bank_accounts().fetchAll({
          profile: Q == null ? void 0 : Q.id
        }), e = await Promise.all(g);
        b.data.forEach((m) => {
          e[0].bank_account_id === m.old_id && (e[0].bank = {
            bank_name: m.bank_name,
            number: m.account_number,
            ifsc: m.ifsc_code
          }), e[1] && e[1].bank_account_id === m.old_id && (e[1].bank = {
            bank_name: m.bank_name,
            number: m.account_number,
            ifsc: m.ifsc_code
          });
        }), as(e[0]), e.length > 1 && v(e[1]), U(!1);
      } catch (g) {
        window.debug.error(g), G(g);
      }
  };
  function N() {
    var p, g, c, Q, b, e;
    return /* @__PURE__ */ i.jsxs(i.Fragment, {
      children: [
        /* @__PURE__ */ i.jsx(ns, {
          scheme: u
        }),
        /* @__PURE__ */ i.jsx(Is, {
          title: z,
          description: `Your ${(p = ys(s == null ? void 0 : s.id)) == null ? void 0 : p.toLowerCase()} reference is ${s == null ? void 0 : s.id}`,
          iconName: E
        }),
        /* @__PURE__ */ i.jsxs(Vs, {
          children: [
            /* @__PURE__ */ i.jsxs("div", {
              className: "-mr-2 -ml-2 flex flex-wrap items-center px-4 pt-2",
              children: [
                !(s != null && s.previousAmount) && !(s != null && s.previousUnits) ? /* @__PURE__ */ i.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ i.jsx(D, {
                    label: o._({
                      id: "KqeLJ1"
                    }),
                    value: s != null && s.amount ? `₹ ${V(s == null ? void 0 : s.amount, 2)}` : "-"
                  })
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                s != null && s.previousAmount || s != null && s.previousUnits ? /* @__PURE__ */ i.jsxs(i.Fragment, {
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(D, {
                        label: s != null && s.previousAmount ? o._({
                          id: "aoh6Eh"
                        }) : o._({
                          id: "ATWL+3"
                        }),
                        value: s != null && s.previousAmount ? `₹ ${V(s == null ? void 0 : s.previousAmount, 2)}` : s.previousUnits ? s.previousUnits : "-"
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(D, {
                        label: s != null && s.amount ? o._({
                          id: "bjwBd3"
                        }) : o._({
                          id: "uQVwS2"
                        }),
                        value: s != null && s.amount ? `₹ ${V(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                s != null && s.previousInstallmentDay ? /* @__PURE__ */ i.jsxs(i.Fragment, {
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(D, {
                        label: o._({
                          id: "3+LOee"
                        }),
                        value: s != null && s.previousInstallmentDay ? ks(s == null ? void 0 : s.previousInstallmentDay) : "-"
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(D, {
                        label: o._({
                          id: "VXfUoN"
                        }),
                        value: s != null && s.installment_day ? ks(s == null ? void 0 : s.installment_day) : "-"
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                s != null && s.previousInstallments ? /* @__PURE__ */ i.jsxs(i.Fragment, {
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(D, {
                        label: o._({
                          id: "CClCym"
                        }),
                        value: s != null && s.previousInstallments ? s == null ? void 0 : s.previousInstallments : "-"
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(D, {
                        label: o._({
                          id: "5q9LAN"
                        }),
                        value: s != null && s.number_of_installments ? s == null ? void 0 : s.number_of_installments : "-"
                      })
                    })
                  ]
                }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
                /* @__PURE__ */ i.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ i.jsx(D, {
                    label: o._({
                      id: "UMXPdU"
                    }),
                    value: s && s.frequency ? (g = fs(s.frequency)) == null ? void 0 : g.replace(/_/g, " ") : "-"
                  })
                })
              ]
            }),
            _ ? /* @__PURE__ */ i.jsxs("section", {
              children: [
                /* @__PURE__ */ i.jsx("div", {
                  children: /* @__PURE__ */ i.jsx(D, {
                    label: o._({
                      id: "QU/PK0"
                    }),
                    background: !0,
                    value: `${_.bank.bank_name} - ****${_.bank.number.slice(-4)}`,
                    logo: Y[_.bank.ifsc.slice(0, 4)]
                  })
                }),
                /* @__PURE__ */ i.jsxs("div", {
                  className: "-mr-2 -ml-2 flex flex-wrap items-center px-4 pt-2",
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(D, {
                        label: o._({
                          id: "5zmJqL"
                        }),
                        value: `₹ ${gs(_.mandate_limit)}`
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(D, {
                        label: o._({
                          id: "RdQmLp"
                        }),
                        value: (c = ds[_.mandate_status]) == null ? void 0 : c.value,
                        icon: (Q = ds[_.mandate_status]) == null ? void 0 : Q.icon,
                        iconPlacement: "left"
                      })
                    })
                  ]
                })
              ]
            }) : /* @__PURE__ */ i.jsx(i.Fragment, {}),
            M ? /* @__PURE__ */ i.jsxs("section", {
              children: [
                /* @__PURE__ */ i.jsx("div", {
                  children: /* @__PURE__ */ i.jsx(D, {
                    label: o._({
                      id: "40IXuE"
                    }),
                    background: !0,
                    value: `${M.bank.bank_name} - ****${M.bank.number.slice(-4)}`,
                    logo: Y[M.bank.ifsc.slice(0, 4)]
                  })
                }),
                /* @__PURE__ */ i.jsxs("div", {
                  className: "-mr-2 -ml-2 flex flex-wrap items-center px-4 pt-2",
                  children: [
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(D, {
                        label: o._({
                          id: "SSgIhw"
                        }),
                        value: `₹ ${gs(M.mandate_limit)}`
                      })
                    }),
                    /* @__PURE__ */ i.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ i.jsx(D, {
                        label: o._({
                          id: "xtmxre"
                        }),
                        value: (b = ds[M.mandate_status]) == null ? void 0 : b.value,
                        icon: (e = ds[M.mandate_status]) == null ? void 0 : e.icon,
                        iconPlacement: "left"
                      })
                    })
                  ]
                })
              ]
            }) : /* @__PURE__ */ i.jsx(i.Fragment, {})
          ]
        }),
        /* @__PURE__ */ i.jsx(H, {
          type: "important",
          variant: "spot",
          content: o._({
            id: "24gA27"
          }),
          customClass: "mb-5"
        }),
        A && /* @__PURE__ */ i.jsx(ts, {
          fullwidth: !0,
          onClick: A,
          children: L
        })
      ]
    });
  }
  return y ? /* @__PURE__ */ i.jsx(ls, {
    variant: "fullpage"
  }) : N();
}
const vs = [
  {
    label: o._({
      id: "iHHiiF"
    }),
    value: "installments"
  },
  {
    label: o._({
      id: "cE8shf"
    }),
    value: "fixed"
  }
];
function hi({ currentPlanObject: s, handlePauseCompletion: A }) {
  const { tenantConfig: C, amcs: W } = es(), z = C == null ? void 0 : C.fallbackLogo, B = C == null ? void 0 : C.showSchemeLogo, { fpapi: E } = is(_s), [R, L] = n(!1), T = /* @__PURE__ */ new Date(), [w, F] = n({}), [I, Y] = n({}), [S, K] = n("initial"), [u, x] = n([]), [y, U] = n(0), _ = As(() => ({
    pause_plan_instl_type: vs[Ls(vs, "installments")] || null,
    pause_plan_start_date: u != null && u.includes(new Date((/* @__PURE__ */ new Date()).setDate(T.getDate() + 2)).toDateString()) ? null : new Date((/* @__PURE__ */ new Date()).setDate(T.getDate() + 2))
  }), [
    u
  ]);
  ms(() => {
    Z(), os();
  }, [
    s
  ]);
  function v(e, m) {
    const h = new Date(m);
    return h.setDate(h.getDate() + e), h;
  }
  function M(e, m) {
    const h = [];
    let l = new Date(e);
    for (; l.valueOf() <= new Date(m).valueOf(); )
      h.push(new Date(l).toDateString()), l = new Date(v(1, l));
    return h;
  }
  const as = (e) => e || (s == null ? void 0 : s.end_date), os = async () => {
    L(!0);
    try {
      (await E.fpClient.skip_instructions().fetchAll((s == null ? void 0 : s.object) + "s", s == null ? void 0 : s.id)).data.map((h) => {
        const l = M(new Date(h.from_date), new Date(as(h.to_date)));
        x((a) => a.concat(l)), U((a) => a + Number(h.remaining_installments) + Number(h.skipped_installments));
      }), L(!1);
    } catch {
      K("error"), L(!1);
    }
  }, k = () => {
    let e = 1;
    const m = s.start_date ? new Date(s.start_date) : /* @__PURE__ */ new Date();
    return s != null && s.systematic || (e = p()), new Date(m == null ? void 0 : m.getFullYear(), (m == null ? void 0 : m.getMonth()) + qs[s == null ? void 0 : s.frequency] * e, m == null ? void 0 : m.getDate());
  }, Z = async () => {
    L(!0);
    const e = (s == null ? void 0 : s.object) === "mf_switch_plan" ? s == null ? void 0 : s.switch_in_scheme : s == null ? void 0 : s.scheme;
    let m;
    try {
      m = await E.fpClient.master_data().fetchScheme(e);
    } catch (a) {
      window.debug.error(a), G(a);
    }
    let h = {};
    if ((s == null ? void 0 : s.object) === "mf_switch_plan")
      try {
        h = await E.fpClient.master_data().fetchScheme(s == null ? void 0 : s.switch_out_scheme), Y(h);
      } catch (a) {
        window.debug.error(a), G(a);
      }
    const l = B ? {
      name: m == null ? void 0 : m.name,
      investment_option: m == null ? void 0 : m.investment_option,
      schemeLogo: W[m == null ? void 0 : m.amc_id] || z
    } : {
      name: m == null ? void 0 : m.name,
      investment_option: m == null ? void 0 : m.investment_option
    };
    F(l), L(!1);
  }, N = async ({ installments: e, pause_plan_end_date: m, pause_plan_instl_type: h }) => {
    let l;
    const a = new Date(s == null ? void 0 : s.next_installment_date);
    (h == null ? void 0 : h.value) === "installments" ? (l = new Date(a.setMonth(a.getMonth() + (e - 1) * qs[s == null ? void 0 : s.frequency])), l = hs(new Date(l), "yyyy-mm-dd")) : (h == null ? void 0 : h.value) === "fixed" && (l = hs(new Date(m), "yyyy-mm-dd"));
    const f = hs(new Date((/* @__PURE__ */ new Date()).setDate(T.getDate() + 1)), "yyyy-mm-dd");
    try {
      L(!0);
      const q = await E.fpClient.skip_instructions().create(s.object + "s", s.id, {
        from: f,
        to: l
      });
      A(q), L(!1);
    } catch (q) {
      L(!1), K("error"), G(q);
    }
  }, p = () => Math.floor((s == null ? void 0 : s.remaining_installments) / 2) > 10 ? 10 : Math.floor((s == null ? void 0 : s.remaining_installments) / 2), g = () => y >= 2 ? 0 : 2 - y, c = (e) => !!(u != null && u.includes(new Date(e).toDateString()));
  function Q() {
    var e;
    return /* @__PURE__ */ i.jsxs("div", {
      className: "pb-6",
      children: [
        /* @__PURE__ */ i.jsx(ns, {
          scheme: w
        }),
        /* @__PURE__ */ i.jsx(ps, {
          children: /* @__PURE__ */ i.jsxs("div", {
            className: "flex flex-wrap items-center",
            children: [
              Ws(I) ? /* @__PURE__ */ i.jsx(i.Fragment, {}) : /* @__PURE__ */ i.jsx("div", {
                className: "mb-3 basis-full ",
                children: /* @__PURE__ */ i.jsx(D, {
                  label: o._({
                    id: "6Xqu8f"
                  }),
                  logo: B && (I != null && I.amc_id) ? W[I == null ? void 0 : I.amc_id] || z : "",
                  value: Zs(I.name)
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(D, {
                  label: s != null && s.amount ? o._({
                    id: "KqeLJ1"
                  }) : o._({
                    id: "6ssn5V"
                  }),
                  value: s != null && s.amount ? `₹ ${V(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(D, {
                  label: o._({
                    id: "UMXPdU"
                  }),
                  value: s && s.frequency ? (e = fs(s.frequency)) == null ? void 0 : e.replace(/_/g, " ") : "-"
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ i.jsx(H, {
          type: "important",
          variant: "spot",
          content: /* @__PURE__ */ i.jsx("p", {
            children: o._({
              id: "uVHufo",
              values: {
                0: hs(new Date(s == null ? void 0 : s.next_installment_date), "dd-MMM-yyyy")
              }
            })
          }),
          customClass: "mb-5"
        }),
        /* @__PURE__ */ i.jsx(Cs, {
          onSubmit: N,
          validate: (m) => {
            const h = {};
            m.installments <= 0 && (h.installments = o._({
              id: "L9XIrm",
              values: {
                0: g()
              }
            })), s != null && s.remaining_installments && m.installments > g() && (s != null && s.systematic) && (h.installments = o._({
              id: "B29U8p",
              values: {
                0: g()
              }
            })), s != null && s.remaining_installments && m.installments > p() && !(s != null && s.systematic) && (h.installments = o._({
              id: "B29U8p",
              values: {
                0: p()
              }
            }));
            const l = _.pause_plan_start_date ? _.pause_plan_start_date : new Date((/* @__PURE__ */ new Date()).setDate(T.getDate() + 2));
            return (new Date(m.pause_plan_end_date).valueOf() < new Date(l).valueOf() || new Date(m.pause_plan_end_date).valueOf() > new Date(k()).valueOf()) && (s != null && s.systematic) && (h.pause_plan_end_date = o._({
              id: "B29U8p",
              values: {
                0: g()
              }
            })), (new Date(m.pause_plan_end_date).valueOf() < new Date(l).valueOf() || new Date(m.pause_plan_end_date).valueOf() > new Date(k()).valueOf()) && !(s != null && s.systematic) && (h.pause_plan_end_date = o._({
              id: "B29U8p",
              values: {
                0: p()
              }
            })), h;
          },
          initialValues: _,
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (m) => {
            var h, l;
            return /* @__PURE__ */ i.jsxs("form", {
              onSubmit: m.handleSubmit,
              children: [
                /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(P, {
                      message: o._({
                        id: "pgRnHV"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(ss, {
                      name: "pause_plan_instl_type",
                      initialValue: _.pause_plan_instl_type,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: r(O),
                      children: (a) => {
                        var f, q;
                        return /* @__PURE__ */ i.jsx(Ts, {
                          name: a.input.name,
                          group: vs,
                          size: "sm",
                          checked: Ls(vs, (q = (f = a == null ? void 0 : a.input) == null ? void 0 : f.value) == null ? void 0 : q.value),
                          onChange: (j, $) => {
                            a.input.onChange($);
                          },
                          disabled: []
                        });
                      }
                    })
                  ]
                }),
                ((h = m.values.pause_plan_instl_type) == null ? void 0 : h.value) === "installments" && /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(P, {
                      message: o._({
                        id: "ZS3Gyi"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(ss, {
                      name: "installments",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: r(O),
                      children: (a) => /* @__PURE__ */ i.jsxs(i.Fragment, {
                        children: [
                          /* @__PURE__ */ i.jsx(Ss, {
                            id: a.input.name,
                            autoComplete: "off",
                            name: a.input.name,
                            onChange: a.input.onChange,
                            placeholder: o._({
                              id: "eEWdWP"
                            }),
                            status: a.meta.error && a.meta.touched ? "error" : void 0,
                            onInput: (f) => {
                              f.target.value = f.target.value.replace(us("0-9"), "");
                            },
                            autoFocus: !0
                          }),
                          a.meta.error && a.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(H, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          }) : s != null && s.systematic ? /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(H, {
                              type: "helper",
                              variant: "field",
                              title: `Maximum installments that can be skipped is ${g()}`
                            })
                          }) : /* @__PURE__ */ i.jsx(i.Fragment, {})
                        ]
                      })
                    })
                  ]
                }),
                ((l = m.values.pause_plan_instl_type) == null ? void 0 : l.value) === "fixed" && /* @__PURE__ */ i.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ i.jsx(P, {
                      message: o._({
                        id: "BrsP9r"
                      })
                    }),
                    /* @__PURE__ */ i.jsx(ss, {
                      name: "pause_plan_end_date",
                      initialValue: _.pause_plan_start_date,
                      allowNull: !0,
                      validate: r(O),
                      children: (a) => /* @__PURE__ */ i.jsxs(i.Fragment, {
                        children: [
                          /* @__PURE__ */ i.jsx(Fs, {
                            variant: "keyboardInputDOB",
                            onKeyDown: (f) => f.preventDefault(),
                            disableFuture: !1,
                            name: a.input.name,
                            value: a.input.value,
                            format: "dd-MMM-yyyy",
                            minDate: _.pause_plan_start_date ?? new Date((/* @__PURE__ */ new Date()).setDate(T.getDate() + 2)),
                            maxDate: k(),
                            placeholder: _.pause_plan_start_date ? "" : "Select skip end date",
                            selectedDate: a.input.value,
                            shouldDisableDate: c,
                            error: a.meta.error && a.meta.touched,
                            views: [
                              "date"
                            ],
                            handleDateChange: (f) => {
                              a.input.onChange(f);
                            }
                          }),
                          a.meta.error && a.meta.touched ? /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(H, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          }) : s != null && s.systematic ? /* @__PURE__ */ i.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ i.jsx(H, {
                              type: "helper",
                              variant: "field",
                              title: `Maximum installments that can be skipped is ${g()}`
                            })
                          }) : /* @__PURE__ */ i.jsx(i.Fragment, {})
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ i.jsx(Ns, {
                  children: /* @__PURE__ */ i.jsx("div", {
                    className: "mb-4 pt-1",
                    children: /* @__PURE__ */ i.jsx(ts, {
                      variant: "primary",
                      type: "submit",
                      fullwidth: !0,
                      children: o._({
                        id: "H/E6fZ"
                      })
                    })
                  })
                })
              ]
            });
          }
        })
      ]
    });
  }
  function b() {
    switch (S) {
      default:
        return Q();
      case "initial":
        return Q();
      case "error":
        return /* @__PURE__ */ i.jsx(Ds, {
          title: o._({
            id: "fgTdCR"
          }),
          description: o._({
            id: "QEKMeg"
          })
        });
    }
  }
  return R ? /* @__PURE__ */ i.jsx(ls, {
    variant: "fullpage"
  }) : b();
}
function pi({ pauseOrder: s, handleGoToPlansList: A }) {
  const C = s == null ? void 0 : s.state.toLowerCase(), [W, z] = n(""), [B, E] = n(""), [R, L] = n(""), [T, w] = n(""), { fpapi: F } = is(_s), { tenantConfig: I, amcs: Y } = es(), S = I == null ? void 0 : I.fallbackLogo, K = I == null ? void 0 : I.showSchemeLogo, [u, x] = n({}), [y, U] = n(!1), [_, v] = n({}), M = async () => {
    U(!0);
    let k = "";
    const Z = Ys(s == null ? void 0 : s.plan);
    if (Z === "mf_purchase_plan") {
      const N = await F.fpClient.mf_purchase_plans().fetch(s == null ? void 0 : s.plan);
      v(N), k = N == null ? void 0 : N.scheme;
    } else if (Z === "mf_redemption_plan") {
      const N = await F.fpClient.mf_redemption_plans().fetch(s == null ? void 0 : s.plan);
      v(N), k = N == null ? void 0 : N.scheme;
    } else if (Z === "mf_switch_plan") {
      const N = await F.fpClient.mf_switch_plans().fetch(s == null ? void 0 : s.plan);
      v(N), k = N == null ? void 0 : N.switch_in_scheme;
    }
    F.fpClient.master_data().fetchScheme(k).then((N) => {
      as(N);
    }).catch((N) => {
      window.debug.log(N), G(N);
    });
  };
  function as(k) {
    if (!k)
      x({});
    else {
      const Z = K ? {
        ...k,
        schemeLogo: Y[k == null ? void 0 : k.amc_id] || S
      } : {
        ...k
      };
      x(Z);
    }
    U(!1);
  }
  ms(() => {
    (C === "active" || C === "pending") && (z(`${zs(s == null ? void 0 : s.plan)} plan skipped`), E(o._({
      id: "xz7+py",
      values: {
        0: s == null ? void 0 : s.id
      }
    })), w(o._({
      id: "d9gGIK"
    })), L("successInfo")), M();
  }, []);
  function os() {
    var k;
    return /* @__PURE__ */ i.jsxs(i.Fragment, {
      children: [
        /* @__PURE__ */ i.jsx(ns, {
          scheme: u
        }),
        /* @__PURE__ */ i.jsxs(Is, {
          title: W,
          description: B,
          iconName: R,
          children: [
            /* @__PURE__ */ i.jsx(ps, {
              children: /* @__PURE__ */ i.jsxs("div", {
                className: "flex flex-wrap items-center",
                children: [
                  /* @__PURE__ */ i.jsx("div", {
                    className: "w-1/2",
                    children: /* @__PURE__ */ i.jsx(D, {
                      label: _ != null && _.amount ? o._({
                        id: "KqeLJ1"
                      }) : o._({
                        id: "6ssn5V"
                      }),
                      value: _ != null && _.amount ? `₹ ${V(_ == null ? void 0 : _.amount, 2)}` : _.units ? _.units : "-"
                    })
                  }),
                  /* @__PURE__ */ i.jsx("div", {
                    className: "w-1/2",
                    children: /* @__PURE__ */ i.jsx(D, {
                      label: o._({
                        id: "UMXPdU"
                      }),
                      value: _ && _.frequency ? (k = fs(_.frequency)) == null ? void 0 : k.replace(/_/g, " ") : "-"
                    })
                  })
                ]
              })
            }),
            /* @__PURE__ */ i.jsx(Bs, {
              tableData: [
                {
                  label: o._({
                    id: "iFYyXY"
                  }),
                  value: s != null && s.to_date ? `${hs(new Date(s == null ? void 0 : s.to_date), "dd-MMM-yyyy")}` : "-"
                },
                {
                  label: o._({
                    id: "ZS3Gyi"
                  }),
                  value: s != null && s.remaining_installments ? `${s == null ? void 0 : s.remaining_installments}` : "-"
                }
              ]
            })
          ]
        }),
        A && /* @__PURE__ */ i.jsx(ts, {
          fullwidth: !0,
          onClick: A,
          children: T
        })
      ]
    });
  }
  return y ? /* @__PURE__ */ i.jsx(ls, {
    variant: "fullpage"
  }) : os();
}
function xi({ currentPlanObject: s, handleStopCompletion: A }) {
  const { tenantConfig: C, amcs: W } = es(), z = C == null ? void 0 : C.fallbackLogo, B = C == null ? void 0 : C.showSchemeLogo, { fpapi: E } = is(_s), [R, L] = n(!1), [T, w] = n({}), [F, I] = n("initial");
  ms(() => {
    Y();
  }, [
    s
  ]);
  const Y = async () => {
    L(!0);
    const x = (s == null ? void 0 : s.object) === "mf_switch_plan" ? s == null ? void 0 : s.switch_in_scheme : s == null ? void 0 : s.scheme;
    let y;
    try {
      y = await E.fpClient.master_data().fetchScheme(x);
    } catch (_) {
      window.debug.error(_), G(_);
    }
    const U = B ? {
      name: y == null ? void 0 : y.name,
      investment_option: y == null ? void 0 : y.investment_option,
      schemeLogo: W[y == null ? void 0 : y.amc_id] || z
    } : {
      name: y == null ? void 0 : y.name,
      investment_option: y == null ? void 0 : y.investment_option
    };
    w(U), L(!1);
  }, S = async () => {
    L(!0);
    try {
      let x = {};
      (s == null ? void 0 : s.object) === "mf_purchase_plan" ? x = await E.fpClient.mf_purchase_plans().cancel(s == null ? void 0 : s.id) : (s == null ? void 0 : s.object) === "mf_redemption_plan" ? x = await E.fpClient.mf_redemption_plans().cancel(s == null ? void 0 : s.id) : (s == null ? void 0 : s.object) === "mf_switch_plan" && (x = await E.fpClient.mf_switch_plans().cancel(s == null ? void 0 : s.id)), L(!1), A(x);
    } catch (x) {
      L(!1), I("error"), G(x);
    }
  };
  function K() {
    var x;
    return /* @__PURE__ */ i.jsxs("div", {
      className: "pb-6",
      children: [
        /* @__PURE__ */ i.jsx(ns, {
          scheme: T
        }),
        /* @__PURE__ */ i.jsx(ps, {
          children: /* @__PURE__ */ i.jsxs("div", {
            className: "flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(D, {
                  label: s != null && s.amount ? o._({
                    id: "KqeLJ1"
                  }) : o._({
                    id: "6ssn5V"
                  }),
                  value: s != null && s.amount ? `₹ ${V(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                })
              }),
              /* @__PURE__ */ i.jsx("div", {
                className: "w-1/2",
                children: /* @__PURE__ */ i.jsx(D, {
                  label: o._({
                    id: "UMXPdU"
                  }),
                  value: s && s.frequency ? (x = fs(s.frequency)) == null ? void 0 : x.replace(/_/g, " ") : "-"
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ i.jsx(H, {
          type: "alert",
          variant: "flash",
          title: /* @__PURE__ */ i.jsx("p", {
            children: o._({
              id: "qULvXd",
              values: {
                0: s != null && s.object ? s == null ? void 0 : s.object.split("_")[1] : ""
              }
            })
          }),
          customClass: "mb-5"
        }),
        /* @__PURE__ */ i.jsx(Cs, {
          onSubmit: S,
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (y) => /* @__PURE__ */ i.jsx("form", {
            onSubmit: y.handleSubmit,
            children: /* @__PURE__ */ i.jsx(Ns, {
              children: /* @__PURE__ */ i.jsx("div", {
                className: "mb-4 pt-1",
                children: /* @__PURE__ */ i.jsx(ts, {
                  variant: "primary",
                  type: "submit",
                  fullwidth: !0,
                  children: o._({
                    id: "H/E6fZ"
                  })
                })
              })
            })
          })
        })
      ]
    });
  }
  function u() {
    switch (F) {
      default:
        return K();
      case "initial":
        return K();
      case "error":
        return /* @__PURE__ */ i.jsx(Ds, {
          title: o._({
            id: "sxmdml"
          }),
          description: o._({
            id: "QEKMeg"
          })
        });
    }
  }
  return R ? /* @__PURE__ */ i.jsx(ls, {
    variant: "fullpage"
  }) : u();
}
function di({ currentPlanObject: s, handleGoToPlansList: A }) {
  const C = s == null ? void 0 : s.state.toLowerCase(), [W, z] = n(""), [B, E] = n(""), [R, L] = n(""), { fpapi: T } = is(_s), { tenantConfig: w, amcs: F } = es(), I = w == null ? void 0 : w.fallbackLogo, Y = w == null ? void 0 : w.showSchemeLogo, [S, K] = n({}), [u, x] = n(!1), y = async () => {
    x(!0);
    const v = (s == null ? void 0 : s.scheme) || (s == null ? void 0 : s.switch_in_scheme);
    T.fpClient.master_data().fetchScheme(v).then((M) => {
      U(M);
    }).catch((M) => {
      window.debug.log(M), G(M);
    });
  };
  function U(v) {
    if (!v)
      K([]);
    else {
      const M = Y ? {
        ...v,
        schemeLogo: F[v == null ? void 0 : v.amc_id] || I
      } : {
        ...v
      };
      K(M);
    }
    x(!1);
  }
  ms(() => {
    (C === "failed" || C === "cancelled") && (z(o._({
      id: "2i30Zx",
      values: {
        0: ys(s == null ? void 0 : s.id)
      }
    })), L(o._({
      id: "9jt2i+"
    })), E("successInfo")), y();
  }, []);
  function _() {
    var v, M;
    return /* @__PURE__ */ i.jsxs(i.Fragment, {
      children: [
        /* @__PURE__ */ i.jsx(ns, {
          scheme: S
        }),
        /* @__PURE__ */ i.jsx(Is, {
          title: W,
          description: `Your ${(v = ys(s == null ? void 0 : s.id)) == null ? void 0 : v.toLowerCase()} reference is ${s == null ? void 0 : s.id}`,
          iconName: B,
          children: /* @__PURE__ */ i.jsx(ps, {
            children: /* @__PURE__ */ i.jsxs("div", {
              className: "flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ i.jsx("div", {
                  className: "w-1/2",
                  children: /* @__PURE__ */ i.jsx(D, {
                    label: s != null && s.amount ? o._({
                      id: "KqeLJ1"
                    }) : o._({
                      id: "6ssn5V"
                    }),
                    value: s != null && s.amount ? `₹ ${V(s == null ? void 0 : s.amount, 2)}` : s.units ? s.units : "-"
                  })
                }),
                /* @__PURE__ */ i.jsx("div", {
                  className: "w-1/2",
                  children: /* @__PURE__ */ i.jsx(D, {
                    label: o._({
                      id: "UMXPdU"
                    }),
                    value: s && s.frequency ? (M = fs(s.frequency)) == null ? void 0 : M.replace(/_/g, " ") : "-"
                  })
                })
              ]
            })
          })
        }),
        /* @__PURE__ */ i.jsx(H, {
          type: "important",
          variant: "spot",
          content: /* @__PURE__ */ i.jsx("p", {
            children: o._({
              id: "wxJxUZ"
            })
          }),
          customClass: "mb-5"
        }),
        A && /* @__PURE__ */ i.jsx(ts, {
          fullwidth: !0,
          onClick: A,
          children: R
        })
      ]
    });
  }
  return u ? /* @__PURE__ */ i.jsx(ls, {
    variant: "fullpage"
  }) : _();
}
export {
  ni as E,
  pi as P,
  di as S,
  xi as a,
  hi as b,
  fi as c
};
