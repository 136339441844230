import { j as r } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as _, useState as l, useEffect as q } from "react";
import { a as g } from "./index.esm-BV4iWo1a.js";
import { F as B } from "./provider-DZPLM8yJ.js";
import { u as k, O as D } from "./OtpForm-ZSChm5PA.js";
import { i as p } from "./index-B3vUTrRT.js";
import { r as G } from "./sentry-ZQO5C1U6.js";
import { AppContext as H } from "./AppContext.js";
import { ErrorPage as V } from "./ErrorPage.js";
import { S as J } from "./StatusMessage-DFmLnokA.js";
import { N as Q } from "./NomineeListing-CmNmufGR.js";
function st({ investorProfile: o, onSuccess: u, isGroupInvestment: h = !1, handleGoBack: C }) {
  const { fpapi: d } = _(B), { AppState: { tenant: w } } = _(H), { collectedAcquisition: x, initiateSendOTP: j } = k(), [W, y] = l(null), [N, A] = l(null), [E, f] = l(!1), [S, m] = l(""), [T, L] = l(null), [M, O] = l(""), F = async (t) => {
    var c;
    const a = (c = t == null ? void 0 : t.username) == null ? void 0 : c.toUpperCase(), i = t == null ? void 0 : t.profile;
    let e = null;
    const n = await d.fpClient.mf_investment_accounts().fetchAll({
      investor: a
    }), s = n == null ? void 0 : n.data;
    return s != null && s.length && (e = s.find((R) => R.primary_investor === i)), e;
  }, I = (t, a) => {
    if (t && a) {
      const i = [];
      return a.forEach((e) => {
        var n, s, c;
        (((n = t == null ? void 0 : t.folio_defaults) == null ? void 0 : n.nominee1) === (e == null ? void 0 : e.id) || ((s = t == null ? void 0 : t.folio_defaults) == null ? void 0 : s.nominee2) === (e == null ? void 0 : e.id) || ((c = t == null ? void 0 : t.folio_defaults) == null ? void 0 : c.nominee3) === (e == null ? void 0 : e.id)) && i.push(e);
      }), i;
    }
  }, U = async () => {
    try {
      f(!0);
      const { data: t } = await d.fpClient.related_parties().fetchAll({
        profile: o.id
      }), a = await d.fetchUser(o == null ? void 0 : o.pan, w);
      y(a[0]);
      const i = await F(a[0]);
      A(i);
      const e = I(i, t);
      L(e);
      const n = e.length === 0, s = {
        given_for_object: n ? "investor_profile" : "related_party",
        given_for: n ? [
          o == null ? void 0 : o.id
        ] : e.map((c) => c.id),
        action: n ? "nominee_opt_out" : "nominee_opt_in"
      };
      await j(s), m("consent");
    } catch (t) {
      window.debug.error(t), m("error"), O("We couldn't initiate OTP. Please try again."), G(t);
    } finally {
      f(!1);
    }
  };
  q(() => {
    U();
  }, []);
  const b = () => {
    u && u({
      isConsentGiven: !0
    });
  };
  return E ? /* @__PURE__ */ r.jsx(g, {
    variant: "fullpage"
  }) : (() => {
    switch (S) {
      case "consent":
        return /* @__PURE__ */ r.jsxs(r.Fragment, {
          children: [
            o && /* @__PURE__ */ r.jsx(Q, {
              handleGoBack: C,
              investorProfile: o,
              nomineeList: T ?? [],
              mfia: N
            }),
            /* @__PURE__ */ r.jsx(D, {
              investorProfile: o,
              handleSubmit: b,
              pageTitle: p._({
                id: "Js6Dfn"
              }),
              collectAcquisition: x,
              isGroupInvestment: h
            })
          ]
        });
      case "success":
        return /* @__PURE__ */ r.jsx(J, {
          title: p._({
            id: "8Erf1o"
          }),
          description: p._({
            id: "MVO6I6"
          }),
          iconName: "successInfo"
        });
      case "error":
        return /* @__PURE__ */ r.jsx(V, {
          title: p._({
            id: "kCTQHV"
          }),
          description: p._({
            id: "UDGyUe"
          }),
          showAction: !!M,
          actionHandler: () => {
            window.location.reload();
          },
          actionText: "Retry",
          showDetails: !1
        });
      default:
        return /* @__PURE__ */ r.jsx(g, {
          variant: "fullpage"
        });
    }
  })();
}
export {
  st as N
};
