import { j as m } from "./jsx-runtime-C8rcB1Lr.js";
import { B as f } from "./index.esm-BV4iWo1a.js";
import { useState as a, useEffect as h } from "react";
function g({ hyperlink: r, buttonText: n = "this link", variant: c, customClass: l }) {
  const [s, d] = a(!1), [i, t] = a("");
  h(() => {
    /Mobi|Android/i.test(navigator.userAgent) && typeof navigator.share == "function" && d(!0);
  }, []);
  const u = () => {
    if (s)
      navigator.share({
        title: "Share this link",
        url: r
      }).then(() => {
        t("shared"), setTimeout(() => t(""), 2e3);
      }).catch((e) => {
        console.error("Sharing failed:", e);
      });
    else if (navigator != null && navigator.clipboard)
      navigator.clipboard.writeText(r).then(() => {
        t("copied"), setTimeout(() => t(""), 2e3);
      }).catch((e) => console.error("Copy failed:", e));
    else {
      const e = document.createElement("textarea");
      e.value = r, e.style.opacity = "0", document.body.appendChild(e), e.focus(), e.select();
      try {
        const o = document.execCommand("copy");
        t(o ? "copied" : ""), setTimeout(() => t(""), 2e3);
      } catch (o) {
        o instanceof DOMException ? console.error("DOMException:", o.name, o.message) : console.error("Unknown Error:", o);
      }
      document.body.removeChild(e);
    }
  }, p = () => i === "copied" ? "Copied!" : i === "shared" ? "Shared!" : s ? `Share ${n}` : `Copy ${n}`;
  return /* @__PURE__ */ m.jsx(f, {
    variant: c ?? "primary",
    fullwidth: !0,
    onClick: u,
    customClass: l,
    disabled: i !== "",
    children: p()
  });
}
export {
  g as C
};
